import { TOGGLE_PHONE_NUMBER_AUTH, SET_AUTH_TYPE } from './constants';

const initialState = {
  phoneNumberAuth: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_PHONE_NUMBER_AUTH:
      return {
        ...state,
        phoneNumberAuth: !state.phoneNumberAuth,
      };
    case SET_AUTH_TYPE:
      return {
        ...state,
        phoneNumberAuth: action.value,
      };
    default:
      return state;
  }
};

export default reducer;
