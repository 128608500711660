import { SOL_MIGRATION_CHANGE_VALUE } from './constants';

const initialState = {
  data: {
    cardId: '',
    solPassword: '',
  },
  address: false,
  startMigration: false,
  mobileMigration: false,
};

const reducer = (state = initialState, action) => {
  const { type, name, value } = action;

  if (type === SOL_MIGRATION_CHANGE_VALUE) {
    return { ...state, [name]: value };
  }

  return state;
};

export default reducer;
