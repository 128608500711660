export const SET_COMMS_VALUE = '[AIRMILES] SET_COMMS_VALUE-TOKEN';

const initialState = {
  comms: {
    optIn: null,
  },
};

export default function (state = initialState, action) {
  if (action.type === SET_COMMS_VALUE) {
    return { ...state, comms: { optIn: action.optIn } };
  }

  return state;
}
