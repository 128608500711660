import { createSelector } from 'reselect';
import { makeSelectFormPageName } from '../dynamic-form/selectors';
import { CHANGE_EMAIL_PAGE } from '../../shared/formPages';

const selectVerifyEmailDomain = () => (state) => state.verifyEmail;

const makeSelectEmailToVerify = () =>
  createSelector(
    selectVerifyEmailDomain(),
    (verifyEmail) => verifyEmail.emailToVerify
  );

const makeSelectIsRegisterFlow = () =>
  createSelector(
    selectVerifyEmailDomain(),
    (verifyEmail) => verifyEmail.isRegisterFlow
  );

const makeSelectIsChangeEmailFlow = () =>
  createSelector(makeSelectFormPageName(), (formPage) => {
    return formPage === CHANGE_EMAIL_PAGE;
  });

export {
  selectVerifyEmailDomain,
  makeSelectEmailToVerify,
  makeSelectIsRegisterFlow,
  makeSelectIsChangeEmailFlow,
};
