import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { Button, Text } from '@shell-b2c/web-design-system';
import Styling from './style';

const NotificationButtons = ({
  translate,
  notificationActions,
  isMobile,
  notification,
}) => {
  const getButton = (button, specialStyles = {}) => {
    const addStyle = isMobile ? { maxWidth: '100%' } : {};

    const handleClick = () =>
      button.offLineEvent
        ? button.offLineEvent()
        : notificationActions.buttonClick(
            button.keepOpen ?? false,
            button.event
          );
    return (
      <div style={{ ...Styling.button, ...addStyle, ...specialStyles }}>
        <Button
          id={button.id || button.label}
          data-testid={button.testId || button.label || 'submit-Button'}
          onClick={() => handleClick()}
          disabled={button.disabled}
          fullWidth
          variant={button.variant}
          color="primary"
        >
          {translate(button.label)}
        </Button>
      </div>
    );
  };

  const getLinks = (button) => {
    const link = button.type
      ? `${button.type}:${translate(button.link, true)}`
      : translate(button.link, true);

    return (
      <div style={{ ...Styling.Links, ...button.style }}>
        <Text variant="body2" style={Styling.twoButtonsStyle}>
          {translate(button.label)}
          <a style={{ paddingLeft: '5px' }} href={link}>
            {translate(button.link)}
          </a>
        </Text>
      </div>
    );
  };

  const generateButtons = () => {
    const { notification: _notification } = notification;

    return _notification.buttons.map((button, index) => {
      if (button.link) {
        return (
          <span key={button.label || button.id}>
            <div style={button.btnWrapper}>{getLinks(button)}</div>
          </span>
        );
      }

      const marginBottom =
        index === _notification.buttons.length - 1 ? '16px' : '8px';
      return (
        <span key={button.label || button.id}>
          <div
            style={{
              ...button.btnWrapper,
              marginBottom,
            }}
          >
            {getButton(button, !isMobile ? button.btnSpecialStyles : {})}
          </div>
        </span>
      );
    });
  };
  const { notification: _notification2 } = notification;
  const wrapperStyles = isMobile ? Styling.buttonWrapper : {};
  return (
    <Grid
      item
      xs={12}
      style={
        _notification2.buttonsWrapper && !isMobile
          ? _notification2.buttonsWrapper
          : wrapperStyles
      }
    >
      {generateButtons()}
    </Grid>
  );
};

NotificationButtons.propTypes = {
  translate: PropTypes.func.isRequired,
  notificationActions: PropTypes.object.isRequired,
  notification: PropTypes.object.isRequired,
  isMobile: PropTypes.bool,
};

export default NotificationButtons;
