import { SET_VERIFY_EMAIL_CHANGE_FORM, SET_UUID } from './constants';
import { VALIDATION_ERROR } from '../dynamic-form/constants';
import { CHANGE_EMAIL_PAGE } from '../../shared/formPages';
import { makeSelectUUID } from './selectors';
import {
  showDefaultErrorNotification,
  showNotification,
} from '../notification/actions';
import { getChangeEmailForm } from '@shell-b2c/http-frontend/dist/src/components/core/forms';
import { setEmailToVerify } from '../verify-email/actions';
import {
  emailChange,
  updateUserEmail,
} from '@shell-b2c/http-frontend/dist/src/components/core/user';
import { validateForm, resetForm, setForm } from '../dynamic-form/actions';
import { makeSelectFormData } from '../dynamic-form/selectors';
import { LOGIN_PATHNAME } from '../../shared/pathnames';
import browserHistory from '../../router/history';
import { sendAdobeAnalyticsEvent } from '../app/actions';
import { makeSelectAccessToken } from '../app/selectors';
import adobeAnalyticTags from '../../shared/adobeAnalyticTags';
import { showEmailAlreadyExistPopup } from '../sol/migration/email/actions';
import lokaliseTags from '../../shared/lokaliseTags';
import { buildNotification } from '../notification/utils';
import ConeAnimation from '../../shared/components/ConeAnimation';
import { NUMBERS } from '../../shared/constants';

export function initializeVerifyEmailChange(
  addHeaderAction,
  removeHeaderAction
) {
  return async (dispatch, getState) => {
    const verifyEmailChange = getState().verifyEmailChange;

    dispatch(sendAdobeAnalyticsEvent(adobeAnalyticTags[CHANGE_EMAIL_PAGE].tag));

    const uuid = makeSelectUUID()(getState());

    if (!uuid) {
      browserHistory.replace('/');
    }

    if (Object.keys(verifyEmailChange.form).length > 0) {
      dispatch(setVerifyEmailChangeForm(verifyEmailChange.form));
    } else {
      try {
        const data = await getChangeEmailForm();
        dispatch(resetForm());
        dispatch(setVerifyEmailChangeForm(data));
      } catch (error) {
        dispatch(showDefaultErrorNotification());
      }
    }

    addHeaderAction(
      () => {
        removeHeaderAction();
        browserHistory.back();
      },
      { headerLeftIcon: 'back' }
    );
  };
}

function callChangeEmailRequest() {
  return async (dispatch, getState) => {
    try {
      const formData = makeSelectFormData()(getState());
      const uuid = makeSelectUUID()(getState());
      const isAuthenticated = !!makeSelectAccessToken()(getState());
      const emailAddress = formData.emailAddress.toLowerCase();

      await dispatch(validateForm(CHANGE_EMAIL_PAGE));
      const data = {
        newEmail: emailAddress,
      };
      if (isAuthenticated) {
        await updateUserEmail(data);
      } else {
        await emailChange({ uuid, ...data });
      }

      dispatch(setEmailToVerify(formData.emailAddress.toLowerCase()));
      browserHistory.back();
    } catch (error) {
      if (
        error.type === VALIDATION_ERROR ||
        error.status === NUMBERS.THREE_HUNDRED_SIXTY_ONE
      ) {
        return;
      }
      if (error.badRequest()) {
        dispatch(
          showNotification(
            buildNotification({
              animation: ConeAnimation,
              title: lokaliseTags.SSO_VERIFY_EMAIL_CHANGE_INVALID_TITLE,
              text: lokaliseTags.SSO_VERIFY_EMAIL_CHANGE_INVALID_TEXT,
              buttons: [
                {
                  label: lokaliseTags.SSO_GENERAL_TRY_AGAIN,
                  event: () => {
                    browserHistory.push(LOGIN_PATHNAME);
                  },
                },
              ],
            })
          )
        );
      } else {
        dispatch(
          showDefaultErrorNotification(
            null,
            lokaliseTags.SSO_ERROR_GENERIC_JANRAIN
          )
        );
      }
    }
  };
}

export function changeEmail() {
  return async (dispatch, _) => {
    dispatch(
      sendAdobeAnalyticsEvent(
        adobeAnalyticTags[CHANGE_EMAIL_PAGE].cta,
        'tileClick'
      )
    );
    try {
      await dispatch(validateForm(CHANGE_EMAIL_PAGE));
      dispatch(callChangeEmailRequest());
    } catch (error) {
      if (
        error?.detail?.emailAddress ===
        lokaliseTags.SSO_FORMS_ERRORS_EMAIL_ADDRESS_IS_ALREADY_IN_USE
      ) {
        dispatch(showEmailAlreadyExistPopup());
      }
    }
  };
}

function setVerifyEmailChangeForm(form) {
  return (dispatch, getState) => {
    const dynamicForm = getState().dynamicForm;
    const changeEmailForm = {
      name: CHANGE_EMAIL_PAGE,
      data: {
        form: form || dynamicForm.form[CHANGE_EMAIL_PAGE],
        errors: {},
      },
    };

    dispatch(setForm(changeEmailForm));
    dispatch({
      type: SET_VERIFY_EMAIL_CHANGE_FORM,
      form,
    });
  };
}

function setUUID(uuid) {
  return {
    type: SET_UUID,
    payload: uuid,
  };
}

export default {
  initializeVerifyEmailChange,
  setUUID,
  changeEmail,
};
