import React from 'react';
import sanitizeHtml from 'sanitize-html-react';
import { ENV, NUMBERS } from './constants';
import { ENGLISH } from './languages';

function translateTag(prop, translations, clean, market, appVersion) {
  if (prop) {
    if (prop.raw) return prop.key;
    const desktop = window.innerWidth > NUMBERS.SEVEN_HUNDRED_SIXTY_SEVEN;

    let translation;

    if (translations[prop]) {
      translation =
        desktop && translations[`${prop}_desktop`]
          ? translations[`${prop}_desktop`]
          : translations[prop];
    }

    if (!translation && window.NREUM) {
      const newRelicAttributes = {
        ExceptionName: 'MissingLabel',
        label: prop,
        section: '',
        market,
        appVersion,
        environment: ENV,
      };
      window.NREUM.addPageAction('MissingLabel', newRelicAttributes);
    }

    if (clean) {
      return translation || prop;
    }

    const html = sanitizeHtmlTranslation(translation || prop);
    return <span dangerouslySetInnerHTML={{ __html: html }} />;
  }
  return '';
}

function translateObject(prop, translations, market, appVersion, values, raw) {
  if (raw) {
    return prop.key;
  } else if (values) {
    let translation = translateTag(
      prop,
      translations,
      true,
      market,
      appVersion
    );
    translation = translation.replace(/%s|%S|%N[1,2]|%n[1,2]|%@/g, (match) => {
      if (match === '%s' || match === '%S') {
        return values[NUMBERS.ZERO];
      } else if (
        match.substring(NUMBERS.ZERO, NUMBERS.TWO) === '%n' ||
        match.substring(NUMBERS.ZERO, NUMBERS.TWO) === '%N'
      ) {
        return (
          values[match.substring(NUMBERS.TWO, NUMBERS.THREE) - NUMBERS.ONE] ||
          ''
        );
      }
      return '';
    });
    const html = sanitizeHtmlTranslation(translation);
    return <span dangerouslySetInnerHTML={{ __html: html.trim() }} />;
  }
  return '';
}

export function getTranslation(
  prop,
  translations,
  returnKey,
  clean,
  market,
  appVersion,
  values
) {
  if (prop) {
    if (returnKey || Object.keys(translations).length === NUMBERS.ZERO) {
      if (clean?.waiting) return 'Shell';
      return typeof prop === 'object' ? prop.key : prop;
    } else if (values) {
      return translateObject(
        prop,
        translations,
        market,
        appVersion,
        values,
        prop.raw
      );
    } else if (prop.values) {
      return translateObject(
        prop.key,
        translations,
        market,
        appVersion,
        prop.values,
        prop.raw
      );
    } else {
      return translateTag(prop, translations, clean, market, appVersion);
    }
  }
  return '';
}

export function sanitizeHtmlTranslation(dirty) {
  return sanitizeHtml(dirty, {
    allowedTags: ['a', 'strong', 'br', 'i', 'u', 'span', 'ul', 'li', 'b'],
    allowedAttributes: {
      a: ['href', 'class', 'target'],
      span: ['class'],
    },
    allowedSchemes: ['data', 'https', 'mailto', 'tel', 'http'],
    parser: {
      lowerCaseTags: false,
    },
    transformTags: {
      a: (_tag, attribs) => ({
        tagName: 'a',
        attribs: {
          class: attribs.class || 'link',
          href: attribs.href || '',
          target: attribs.target || '',
        },
      }),
      strong: () => ({
        tagName: 'span',
        attribs: {
          class: 'bold',
        },
      }),
    },
  });
}

export function getLanguage(market) {
  if (market) {
    return market.split('-')[NUMBERS.ZERO];
  }
  return ENGLISH;
}
