import {
  makeSelectIsGoPlusActivated,
  makeSelectUUID,
  makeSelectIsGoplusMarketingConsentEnabled,
  makeSelectIsMgmUser,
} from '../../profile/selectors';
import {
  makeSelectQueryMarket,
  makeSelectIsGoPlusCVP,
  makeSelectIsPartnerCVP,
  makeSelectQueryRedirect,
  makeSelectIsMotoristFirebladeCVP,
  makeSelectIsMotoristApp,
} from '../../app/selectors';
import {
  lodProxyActivateUser,
  lodAttach,
} from '@shell-b2c/http-frontend/dist/src/components/core/lod';
import {
  redirectToCVP,
  setQuery,
  sendAdobeAnalyticsEvent,
} from '../../app/actions';
import { CLOSE_HEADER } from './headers';
import {
  showNotification,
  showDefaultErrorNotification,
} from '../../notification/actions';
import {
  makeSelectIsGoPlusActivatedInSettings,
  makeSelectGoPlusWebSiteLink,
} from '../../settings/selectors';
import { LOGIN_PATHNAME, PROFILE_PATHNAME } from '../../../shared/pathnames';
import { DEFAULT_ERROR_NOTIFICATION } from '../../app/notifications';
import browserHistory from '../../../router/history';
import adobeAnalyticTags from '../../../shared/adobeAnalyticTags';
import { REGISTER_PAGE } from '../../../shared/formPages';
import {
  makeSelectIsAndroid,
  makeSelectIsIOS,
  makeSelectIsMobile,
} from '../../browser/selectors';
import {
  ANDROID_APP,
  buildNotification,
  IOS_APP,
} from '../../notification/utils';
import lokaliseTags from '../../../shared/lokaliseTags';
import TickAnimation from '../../../shared/components/TickAnimation';

export function initialize(setHeader) {
  return async (dispatch, getState) => {
    const isGoPlusActivatedInProfile = makeSelectIsGoPlusActivated()(
      getState()
    );
    const isGoPlusActivatedInSettings = makeSelectIsGoPlusActivatedInSettings()(
      getState()
    );
    const isGoPlusCVP = makeSelectIsGoPlusCVP()(getState());
    const isPartnerCVP = makeSelectIsPartnerCVP()(getState());
    const isFirebladeApp = makeSelectIsMotoristFirebladeCVP()(getState());
    const isMobile = makeSelectIsMobile()(getState());
    const isIOSApp = makeSelectIsIOS()(getState());
    const isMgmUser = makeSelectIsMgmUser()(getState());
    const isAndroidApp = makeSelectIsAndroid()(getState());

    if (!isPartnerCVP && !isGoPlusActivatedInSettings) {
      browserHistory.replace('/');
      return;
    }

    if (isGoPlusActivatedInProfile) {
      dispatch(redirectToCVP());
      return;
    }

    const market = makeSelectQueryMarket()(getState());
    const uuid = makeSelectUUID()(getState());
    if (uuid && market) {
      try {
        await lodProxyActivateUser({
          market,
          uuid,
        });
      } catch (error) {
        return dispatch(
          showDefaultErrorNotification(() => {
            if (makeSelectIsMotoristApp()(getState())) {
              dispatch(redirectToCVP());
            } else {
              browserHistory.push(LOGIN_PATHNAME);
            }
          }, lokaliseTags.SSO_GENERIC_LOD)
        );
      }
    }
    try {
      const goPlusMarketingOptIn = makeSelectIsGoplusMarketingConsentEnabled()(
        getState()
      );
      const consents = {
        goPlusMarketingOptIn,
        vocOptIn: goPlusMarketingOptIn,
      };
      await lodAttach({ consents });
    } catch (error) {
      dispatch(showLodAttachErrorNotification());
    }

    if (isGoPlusCVP) {
      dispatch(changeHeaderBehaviorForGoPlus(setHeader));
      dispatch(
        showNotification(
          buildNotification({
            animation: TickAnimation,
            title: lokaliseTags.SSO_VERIFY_SETUP_GO_PLUS_LOGIN_TITLE,
            text: isMgmUser
              ? lokaliseTags.SSO_VERIFY_EMAIL_VERIFIED_MGM_TEXT
              : lokaliseTags.SSO_VERIFY_SETUP_GO_PLUS_LOGIN_TEXT,
            appStoreButtons: isAndroidApp
              ? [ANDROID_APP]
              : isIOSApp
              ? [IOS_APP]
              : [ANDROID_APP, IOS_APP],
            QRCode: !isFirebladeApp && !isMobile,
            buttons: [
              {
                label:
                  lokaliseTags.SSO_VERIFY_EMAIL_VERIFIED_CONTINUE_IN_BROWSER_LINK,
                variant: 'text',
                event: () => dispatch(redirectToCVP()),
              },
            ],
          })
        )
      );
    } else if (isPartnerCVP) {
      dispatch(
        showNotification(
          buildNotification({
            animation: TickAnimation,
            title: lokaliseTags.SSO_VERIFY_ACCOUNT_LABEL,
            text: lokaliseTags.SSO_VERIFY_CONFIRMATION_GO_PLUS_TEXT,
            appStoreButtons: [ANDROID_APP, IOS_APP],
            buttons: [
              {
                label:
                  lokaliseTags.SSO_VERIFY_EMAIL_VERIFIED_CONTINUE_IN_BROWSER_LINK,
                variant: 'text',
              },
            ],
          })
        )
      );
    }
  };
}

export function showGoPlusNotification(ntf) {
  return (dispatch, getState) => {
    const isFirebladeApp = makeSelectIsMotoristFirebladeCVP()(getState());
    const isMobile = makeSelectIsMobile()(getState());
    const isIOSApp = makeSelectIsIOS()(getState());
    const isAndroidApp = makeSelectIsAndroid()(getState());
    const isMgmUser = makeSelectIsMgmUser()(getState());
    const notificationAction = () => {
      const tag = {
        ...adobeAnalyticTags[REGISTER_PAGE].registrationCompleteCTA,
        signInType: 'Standard',
      };
      dispatch(sendAdobeAnalyticsEvent(tag, 'tileClick'));
      dispatch(transitionToGoPlusWeb());
    };

    const notification = {
      ...ntf,
      QRCode: !isFirebladeApp && !isMobile,
      appStoreButtons: isAndroidApp
        ? [ANDROID_APP]
        : isIOSApp
        ? [IOS_APP]
        : [ANDROID_APP, IOS_APP],
    };

    if (isMgmUser) {
      notification.message.text.key =
        lokaliseTags.SSO_VERIFY_EMAIL_VERIFIED_MGM_TEXT;
    }
    notification.header.icon.event = notificationAction;
    notification.buttons[0].event = notificationAction;

    dispatch(showNotification(notification));
  };
}

function showLodAttachErrorNotification() {
  return (dispatch, getState) => {
    const notification = DEFAULT_ERROR_NOTIFICATION;
    const redirect = getState().app.query.redirect;
    const redirectEvent = () => {
      if (redirect) {
        dispatch(redirectToCVP());
      } else {
        browserHistory.push(PROFILE_PATHNAME);
      }
    };
    notification.header.icon.event = redirectEvent;
    notification.buttons[0].event = redirectEvent;
    dispatch(showNotification(notification));
  };
}

function changeHeaderBehaviorForGoPlus(setHeader) {
  return (dispatch) => {
    const header = CLOSE_HEADER;
    header.menu.leftMenu.event = () => {
      dispatch(transitionToGoPlusWeb());
    };
    setHeader(header);
  };
}

export function transitionToGoPlusWeb() {
  return (dispatch, getState) => {
    const isPartnerCVP = makeSelectIsPartnerCVP()(getState());
    const redirect = isPartnerCVP
      ? makeSelectQueryRedirect()(getState())
      : makeSelectGoPlusWebSiteLink()(getState());
    dispatch(setQuery({ redirect }));
    const redirectData = {
      url: redirect,
    };
    dispatch(redirectToCVP(redirectData));
  };
}

export default { initialize };
