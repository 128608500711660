import ConeAnimation from '../../shared/components/ConeAnimation';
import LockAnimation from '../../shared/components/LockAnimation';
import { WHITE, MID_GREY } from '../../shared/colors';
import { getLanguage } from '../../shared/translation-utils';
import {
  ARABIC,
  FRENCH,
  POLISH,
  ENGLISH,
  HUNGARIAN,
  INDONESIAN,
  BULGARIAN,
  CZECH,
  SLOVAK,
  DUTCH,
  GERMAN,
  DANISH,
  SPANISH,
} from '../../shared/languages';
import lokaliseTags from '../../shared/lokaliseTags';

export const AUTHORIZATION_FAILED = {
  header: {
    icon: {
      name: lokaliseTags.SSO_GENERAL_CLOSE,
      icon: 'close',
    },
  },
  animation: {
    component: ConeAnimation,
  },
  message: {
    title: {
      key: 'Authorization failed',
      raw: true,
    },
    text: {
      key: 'The connection to our dataprovider has failed, please try again',
      raw: true,
    },
  },
  buttons: [],
};

const translationFailedHeader = {
  [ENGLISH]: 'Technical Issue',
  [HUNGARIAN]: 'Technikai probléma',
  [POLISH]: 'Problemy techniczne',
  [INDONESIAN]: 'Masalah Teknis',
  [BULGARIAN]: 'Технически проблем',
  [CZECH]: 'Technické problémy',
  [SLOVAK]: 'Technický problém',
  [FRENCH]: 'Problème technique',
  [ARABIC]: 'مسألة تقنية',
  [SPANISH]: 'Problema técnico',
  [GERMAN]: 'Technisches Problem',
  [DUTCH]: 'Technisch probleem',
  [DANISH]: 'Teknisk problem',
};

const translationFailedBody = {
  [ENGLISH]: 'Our server is not responding, please try again later.',
  [HUNGARIAN]: 'A szerverünk nem válaszol, próbálkozzon később.',
  [POLISH]: 'Nasz serwer chwilowo nie odpowiada. Spróbuj ponownie później.',
  [INDONESIAN]: 'Server kami tidak menjawab, cobalah beberapa saat lagi.',
  [BULGARIAN]: 'Нашият сървър не отговаря, моля, опитайте отново по-късно.',
  [CZECH]: 'Náš server nereaguje, zkuste to prosím později.',
  [SLOVAK]: 'Náš server neodpovedá, prosím, skúste to znova neskôr.',
  [FRENCH]: 'Notre serveur ne répond pas. Réessayez plus tard.',
  [ARABIC]: 'خادمنا لا يستجيب، يرجى المحاولة مرة أخرى في وقت لاحق.',
  [SPANISH]: 'Nuestro servidor no reponde, intente nuevamente más tarde.',
  [GERMAN]:
    'Unser Server antwortet nicht. Bitte versuchen Sie es später erneut.',
  [DUTCH]: 'Onze server reageert niet, probeer het later opnieuw.',
  [DANISH]: 'Vores server svarer ikke. Prøv igen senere.',
};

const translationFailedButton = {
  [ENGLISH]: 'Ok, got it',
  [HUNGARIAN]: 'Rendben',
  [POLISH]: 'OK',
  [INDONESIAN]: 'Ok, paham',
  [BULGARIAN]: 'Ок, разбрах',
  [CZECH]: 'OK, rozumím',
  [SLOVAK]: 'Ok, rozumiem',
  [FRENCH]: "D'accord, bien reçu",
  [ARABIC]: 'حسنا',
  [SPANISH]: 'Ok lo tengo',
  [GERMAN]: 'Weiter',
  [DUTCH]: 'Ok, ik snap het',
  [DANISH]: 'Prøv igen',
};

export const getTranslationsFailedNotification = (market) => {
  const language = getLanguage(market);
  return {
    header: {
      icon: {
        name: lokaliseTags.SSO_GENERAL_CLOSE,
        icon: 'close',
      },
    },
    animation: {
      component: ConeAnimation,
    },
    message: {
      title: {
        key: translationFailedHeader[language],
        raw: true,
      },
      text: {
        key: translationFailedBody[language],
        raw: true,
      },
    },
    buttons: [
      {
        label: translationFailedButton[language],
      },
    ],
  };
};

export const TRANSLATIONS_FAILED = {
  header: {
    icon: {
      name: lokaliseTags.SSO_GENERAL_CLOSE,
      icon: 'close',
    },
  },
  animation: {
    component: ConeAnimation,
  },
  message: {
    title: {
      key: 'Translations unavailable',
      raw: true,
    },
    text: {
      key: 'Sorry we where not able to gather the translations. please try again',
      raw: true,
    },
  },
  buttons: [],
};
export const CONTENT_SETTINGS_FAILED = {
  header: {
    icon: {
      name: lokaliseTags.SSO_GENERAL_CLOSE,
      icon: 'close',
    },
  },
  animation: {
    component: ConeAnimation,
  },
  message: {
    title: lokaliseTags.SSO_GENERAL_DYNAMO_UNAVAILABLE_HEADER,
    text: lokaliseTags.SSO_GENERAL_DYNAMO_UNAVAILABLE_TEXT,
  },
  buttons: [],
};
export const OFFLINE_NOTIFICATION = {
  header: {
    icon: {
      name: lokaliseTags.SSO_GENERAL_CLOSE,
      icon: 'close',
    },
  },
  animation: {
    component: ConeAnimation,
  },
  message: {
    title: lokaliseTags.SSO_GENERAL_NO_INTERNET_CONNECTION_HEADER,
    text: lokaliseTags.SSO_GENERAL_NO_INTERNET_CONNECTION_TEXT,
  },
  buttons: [
    {
      label: lokaliseTags.SSO_GENERAL_TRY_AGAIN,
    },
  ],
};
export const BACKEND_NOTIFICATION = {
  header: {
    icon: {
      name: lokaliseTags.SSO_GENERAL_CLOSE,
      icon: 'close',
    },
  },
  animation: {
    component: ConeAnimation,
  },
  message: {
    title: lokaliseTags.SSO_GENERAL_NO_BACKEND_CONNECTION_HEADER,
    text: lokaliseTags.SSO_GENERAL_NO_BACKEND_CONNECTION_TEXT,
  },
  buttons: [
    {
      label: lokaliseTags.SSO_GENERAL_TRY_AGAIN,
    },
  ],
};
export const NO_MARKET = {
  header: {
    icon: {
      name: lokaliseTags.SSO_GENERAL_CLOSE,
      icon: 'close',
    },
  },
  animation: {
    component: ConeAnimation,
  },
  message: {
    title: {
      key: 'No Market',
      raw: true,
    },
    text: {
      key: 'There was no market provided, please contact CSC about this issue.',
      raw: true,
    },
  },
  buttons: [],
  force: true,
};
export const WRONG_MARKET = {
  header: {
    icon: {
      name: lokaliseTags.SSO_GENERAL_CLOSE,
      icon: 'close',
    },
  },
  animation: {
    component: ConeAnimation,
  },
  message: {
    title: lokaliseTags.SSO_GENERAL_WRONG_MARKET,
    text: lokaliseTags.SSO_GENERAL_WRONG_MARKET_TEXT,
  },
  buttons: [
    {
      label: lokaliseTags.SSO_GENERAL_TRY_AGAIN,
    },
  ],
};
export const LOYALTY_ACTIVATION_FAILED = {
  header: {
    icon: {
      name: lokaliseTags.SSO_GENERAL_CLOSE,
      icon: 'close',
    },
  },
  animation: {
    component: ConeAnimation,
  },
  message: {
    title: lokaliseTags.SSO_LOYALTY_ACTIVATE_ERROR_TITLE,
    text: {
      key: lokaliseTags.SSO_GENERAL_ACTIVATE_ERROR_TEXT,
      values: [],
    },
    textStyle: {
      color: MID_GREY,
    },
  },
  buttons: [
    {
      label: lokaliseTags.SSO_RESET_PASSWORD_CALL_US,
      link: lokaliseTags.SSO_RESET_PASSWORD_CALL_US_NUMBER,
      backgroundColor: WHITE,
      keepOpen: true,
      type: 'tel',
    },
    {
      label: lokaliseTags.SSO_RESET_PASSWORD_EMAIL_US,
      link: lokaliseTags.SSO_RESET_PASSWORD_EMAIL_US_ADDRESS,
      backgroundColor: WHITE,
      keepOpen: true,
      type: 'mailto',
    },
  ],
};

export const GOPLUS_ACTIVATION_FAILED = {
  header: {
    icon: {
      name: lokaliseTags.SSO_GENERAL_CLOSE,
      icon: 'close',
    },
  },
  animation: {
    component: ConeAnimation,
  },
  message: {
    title: lokaliseTags.SSO_VERIFY_GO_PLUS_ERROR_EMAIL_CONFIRMED_TITLE,
    text: {
      key: lokaliseTags.SSO_VERIFY_GO_PLUS_ERROR_EMAIL_CONFIRMED_TEXT,
      values: [],
    },
    textStyle: {
      color: MID_GREY,
    },
  },
  buttons: [
    {
      label: lokaliseTags.SSO_RESET_PASSWORD_CALL_US,
      link: lokaliseTags.SSO_RESET_PASSWORD_CALL_US_NUMBER,
      backgroundColor: WHITE,
      keepOpen: true,
      type: 'tel',
    },
    {
      label: lokaliseTags.SSO_RESET_PASSWORD_EMAIL_US,
      link: lokaliseTags.SSO_RESET_PASSWORD_EMAIL_US_ADDRESS,
      backgroundColor: WHITE,
      keepOpen: true,
      type: 'mailto',
    },
  ],
};
export const ACCOUNT_LOCKED = {
  animation: LockAnimation,
  title: lokaliseTags.SSO_ACCOUNT_LOCKED_LABEL,
  text: {
    key: lokaliseTags.SSO_ACCOUNT_LOCKED_MESSAGE_TEXT,
    values: [],
  },
  subText: lokaliseTags.SSO_ACCOUNT_LOCKED_INFO_TEXT,
  buttons: [
    {
      label: lokaliseTags.SSO_ACCOUNT_LOCKED_RESET_PASSWORD,
    },
  ],
};

export const DEFAULT_ERROR_NOTIFICATION = {
  header: {
    icon: {
      name: lokaliseTags.SSO_GENERAL_CLOSE,
      icon: 'close',
    },
  },
  animation: {
    component: ConeAnimation,
  },
  message: {
    title: lokaliseTags.SSO_GENERAL_NO_BACKEND_CONNECTION_HEADER,
    text: lokaliseTags.SSO_GENERAL_NO_BACKEND_CONNECTION_TEXT,
  },
  buttons: [
    {
      label: lokaliseTags.SSO_GENERAL_TRY_AGAIN,
    },
  ],
};
