import { TOGGLE_PHONE_NUMBER_AUTH, SET_AUTH_TYPE } from './constants';
import { selectAuthDomain } from './selectors';

export function toggleAuthType() {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_PHONE_NUMBER_AUTH,
    });
  };
}

export function setAuthType(value) {
  return (dispatch, getState) => {
    const auth = selectAuthDomain()(getState());
    // Only set initial value if null
    if (auth.phoneNumberAuth === null) {
      dispatch({
        type: SET_AUTH_TYPE,
        value,
      });
    }
  };
}

export default {
  toggleAuthType,
  setAuthType,
};
