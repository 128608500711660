export const SET_MIGRATION_DATA = '[SSO Migration] SET_MIGRATION_DATA';
export const SET_MIGRATION_ID_FORM = '[SSO Migration] SET_MIGRATION_ID_FORM';
export const SET_MIGRATION_PASSWORD_FORM =
  '[SSO Migration] SET_MIGRATION_PASSWORD_FORM';
export const SET_MIGRATION_VALIDATION_FORM =
  '[SSO Migration] SET_MIGRATION_VALIDATION_FORM';
export const SET_MIGRATION_ID = '[SSO Migration] SET_MIGRATION_ID';
export const SET_MIGRATION_PASSWORD = '[SSO Migration] SET_MIGRATION_PASSWORD';
export const SET_MIGRATION_COMMUNICATION =
  '[SSO Migration] SET_MIGRATION_COMMUNICATION';

const initialState = {
  data: { profile: {}, consents: {} },
  migrationIdForm: null,
  migrationPasswordForm: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_MIGRATION_DATA:
      return { ...state, data: action.data };

    case SET_MIGRATION_ID_FORM:
      return { ...state, migrationIdForm: action.form };

    case SET_MIGRATION_PASSWORD_FORM:
      return { ...state, migrationPasswordForm: action.form };

    case SET_MIGRATION_VALIDATION_FORM:
      return { ...state, migrationValidationForm: action.form };

    case SET_MIGRATION_ID:
      const { idType, id } = action;

      return {
        ...state,
        data: {
          ...state.data,
          profile: {
            ...state.data.profile,
            [idType]: id,
          },
        },
      };

    case SET_MIGRATION_PASSWORD:
      return {
        ...state,
        data: { ...state.data, newPassword: action.newPassword },
      };

    case SET_MIGRATION_COMMUNICATION:
      return {
        ...state,
        data: {
          ...state.data,
          consents: Object.keys(state.data.consents).reduce((acc, current) => {
            return {
              ...acc,
              [current]: action.areConsentsAccepted,
            };
          }, {}),
        },
      };

    default:
      return state;
  }
}
