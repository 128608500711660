import {
  SET_MULTIPLE_DYNAMIC_FORMS_PAGE,
  SET_PAGENUMBERS,
  SET_LASTPAGE,
} from './constants';

export const initialState = {
  pages: {},
  pageNumber: 0,
  pageOrderNumber: 0,
  lastPage: false,
};

const multipleDynamicForms = (state = { ...initialState }, action) => {
  switch (action.type) {
    case SET_MULTIPLE_DYNAMIC_FORMS_PAGE:
      return {
        ...state,
        pages: { ...state.pages, [action.payload.page]: action.payload.pages },
      };
    case SET_PAGENUMBERS:
      return {
        ...state,
        pageNumber: action.pageNumber,
        pageOrderNumber: action.pageOrderNumber,
      };
    case SET_LASTPAGE:
      return {
        ...state,
        lastPage: action.lastPage,
      };
    default: {
      return state;
    }
  }
};

export default multipleDynamicForms;
