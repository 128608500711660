export const UK_MARKET = 'gb';
export const HU_MARKET = 'hu';
export const BG_MARKET = 'bg';
export const PL_MARKET = 'pl';
export const CZ_MARKET = 'cz';
export const SK_MARKET = 'sk';
export const IN_MARKET = 'in';
export const PH_MARKET = 'ph';
export const TH_MARKET = 'th';
export const DE_MARKET = 'de';
export const AT_MARKET = 'at';

export const getMarketCountry = (market) => market?.split('-')[1].toLowerCase();
