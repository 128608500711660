import {
  NOTIFICATION_CHANGE_ATTRIBUTE,
  SHOW_NOTIFICATION,
  SET_NOTIFICATION_SNACKBAR_ERROR,
} from './constants';

export const initialState = {
  notification: {
    backgroundStyle: {},
    image: {
      url: '',
      title: '',
      titleStyle: {},
      text: '',
      textStyle: {},
    },
    imageStyle: {},
    header: {
      title: '',
      subTitle: '',
      icon: {
        name: '',
        icon: '',
        event: null,
        keepOpen: false,
        mobile: false,
        desktop: false,
      },
    },
    message: {
      title: '',
      titleStyle: {},
      text: {
        // or send string with tag
        key: '',
        raw: true,
      },
      textStyle: {},
      subText: '',
      subTextStyle: {},
    },
    buttons: [],
    sliders: [],
    checkboxes: [],
  },
  extendText: false,
  visible: false,
  force: false,
  snackbarError: false,
};

const notification = (state = { ...initialState }, action) => {
  const { name, value } = action;
  switch (action.type) {
    case SET_NOTIFICATION_SNACKBAR_ERROR:
      return { ...state, snackbarError: value };
    case NOTIFICATION_CHANGE_ATTRIBUTE:
      return { ...state, [name]: value };
    case SHOW_NOTIFICATION:
      if (state.force) {
        return state;
      }
      return {
        ...state,
        notification: { ...initialState.notification, ...value },
        visible: true,
      };
    default:
      return state;
  }
};

export default notification;
