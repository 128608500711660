import React, { useEffect, useState, useRef } from 'react';

export function useTimeout(cb, ms = 0) {
  const [isActive, setIsActive] = useState(false);
  const savedRefCallback = useRef();

  useEffect(() => {
    savedRefCallback.current = cb;
  }, [cb]);

  function callback() {
    savedRefCallback?.current();
    clear();
  }

  const clear = React.useCallback(() => setIsActive(false), []);

  const start = React.useCallback(() => setIsActive(true), []);

  useEffect(() => {
    if (isActive) {
      const timeout = window.setTimeout(callback, ms);
      return () => {
        window.clearTimeout(timeout);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  return {
    clear,
    start,
    isActive,
  };
}
