export const SET_LOYALTY_FUTURES_ATTACH_VALIDATION_FORM =
  '[LF] SET_LOYALTY_FUTURES_ATTACH_VALIDATION_FORM';
export const SET_LOYALTY_FUTURES_ATTACH_DATA =
  '[LF] SET_LOYALTY_FUTURES_ATTACH_DATA';

const initialState = {
  attachValidationForm: null,
  attachData: null,
};

export default function reducer(state = initialState, action) {
  if (action.type === SET_LOYALTY_FUTURES_ATTACH_VALIDATION_FORM) {
    return { ...state, attachValidationForm: action.form };
  }

  if (action.type === SET_LOYALTY_FUTURES_ATTACH_DATA) {
    return { ...state, attachData: action.data };
  }

  return state;
}
