import { SET_UUID, SET_VERIFY_MOBILE_CHANGE_FORM } from './constants';

export const initialState = {
  data: {
    emailAddress: '',
  },
  form: {},
  uuid: '',
};

const verifyMobileChange = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_VERIFY_MOBILE_CHANGE_FORM:
      return {
        ...state,
        form: action.form,
        loaded: true,
      };
    case SET_UUID:
      return {
        ...state,
        uuid: payload,
      };
    default:
      return state;
  }
};

export default verifyMobileChange;
