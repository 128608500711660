import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { getIcon } from '../../features/app/utils';
import Styling from './style';
import { redirectToCVP } from '../../features/app/actions';
import { Text } from '@shell-b2c/web-design-system';
import { NUMBERS } from '../../shared/constants';

export default function NotificationHeader({
  translate,
  notification,
  notificationActions,
  isMobile,
  redirect,
  isMotoristApp,
}) {
  const header = notification.notification.header;
  const onClick = () =>
    header.icon.offLineEvent
      ? header.icon.offLineEvent()
      : notificationActions.buttonClick(
          header.icon.keepOpen,
          header.icon.event,
          redirectToCVP
        );

  const hideHeader =
    !header || (!header.icon.event && !redirect && !isMotoristApp);

  if (hideHeader) {
    return null;
  }
  return (
    <Grid container style={Styling.toTop}>
      {(isMobile ? header.icon.mobile : header.icon.desktop) && (
        <>
          <Grid
            item
            xs={12}
            style={{
              ...Styling.headerIconWrapper,
              ...((!isMobile && Styling.headerIconWrapperDesktop) || {}),
            }}
            onClick={onClick}
          >
            {getIcon(header.icon, header.icon)}
          </Grid>
        </>
      )}
      <Grid
        item
        xs={isMobile ? NUMBERS.EIGHT : NUMBERS.TWELVE}
        style={Styling.headerTitleWrapper}
      >
        <Text variant="h6" style={Styling.headerTitle}>
          {translate(header.title)}
        </Text>
        <Text variant="body2" style={Styling.headerSubTitle}>
          {translate(header.subTitle)}
        </Text>
      </Grid>
    </Grid>
  );
}

NotificationHeader.propTypes = {
  translate: PropTypes.func.isRequired,
  notificationActions: PropTypes.object.isRequired,
  notification: PropTypes.object.isRequired,
  isMobile: PropTypes.bool,
  redirect: PropTypes.string,
  isMotoristApp: PropTypes.bool.isRequired,
};
