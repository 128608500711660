import { createSelector } from 'reselect';

/**
 * Direct selector to the translations state domain
 */
const selectBrowserDomain = () => (state) => state.browser;

const makeSelectIsMobile = () =>
  createSelector(selectBrowserDomain(), (browser) => browser.lessThan.medium);

const makeSelectIsAndroid = () =>
  createSelector(selectBrowserDomain(), (browser) => browser.isAndroid);

const makeSelectIsIOS = () =>
  createSelector(selectBrowserDomain(), (browser) => browser.isIOS);

export {
  selectBrowserDomain,
  makeSelectIsAndroid,
  makeSelectIsIOS,
  makeSelectIsMobile,
};
