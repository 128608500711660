import {
  makeSelectQuery,
  makeSelectAccessToken,
  makeSelectQueryRedirect,
  makeSelectIsMotoristApp,
} from '../app/selectors';
import {
  exchangeAccessCode,
  logoutFromAllDevices,
} from '@shell-b2c/http-frontend/dist/src/components/core/auth';
import { showDefaultErrorNotification } from '../notification/actions';
import browserHistory from '../../router/history';
import { LOGIN_PATHNAME } from '../../shared/pathnames';
import { deleteAllStorageData } from '../app/utils';
import { LOGOUT_APP_PATHNAME } from '../../shared/appPathnames';
import {
  setLocation,
  setSpinner,
  setQuery,
  redirectToCVP,
} from '../app/actions';
import { resetForm } from '../dynamic-form/actions';
import { RESET_DATA } from '../profile/constants';
import { SEE_YOU_SOON_HEADER } from '../../features/logout/headers';
import lokaliseTags from '../../shared/lokaliseTags';
import { makeSelectUserProfile } from '../profile/selectors';

export function initializeLogout() {
  return async (dispatch, getState) => {
    const { accessCode } = makeSelectQuery()(getState());

    if (accessCode) {
      try {
        await exchangeAccessCode({ code: accessCode });
        dispatch(logout());
      } catch (error) {
        dispatch(
          showDefaultErrorNotification(
            null,
            lokaliseTags.SSO_ERROR_GENERIC_JANRAIN
          )
        );
      }
    } else {
      browserHistory.push(LOGIN_PATHNAME);
    }
  };
}

export function initializeSeeYouSoon(setHeader) {
  return (dispatch) => {
    const header = SEE_YOU_SOON_HEADER;
    header.menu.leftMenu.event = () => {
      dispatch(logout(true, false));
    };
    header.menu.rightMenu.event = () => {
      dispatch(logout(true, false));
    };
    setHeader(header);
  };
}
export function logout(
  inAllDevices = false,
  accountLocked = false,
  { doRedirectToCVP = false } = {}
) {
  return async (dispatch, getState) => {
    try {
      const accessToken = makeSelectAccessToken()(getState());
      if (!accessToken) {
        if (doRedirectToCVP) return dispatch(redirectToCVP());
        return browserHistory.push(LOGIN_PATHNAME);
      }

      const profile = makeSelectUserProfile()(getState());
      const isUserLoggedIn = profile.loaded;
      const browserHistoryPushPath = inAllDevices ? LOGIN_PATHNAME : '/';

      if (inAllDevices) {
        await logoutFromAllDevices({ accessToken }, { noSpinner: true });
      }

      deleteAllStorageData();
      const query = {
        accessToken: null,
        refreshToken: null,
        accessCode: null,
      };
      dispatch(setQuery(query));
      let redirect = makeSelectQueryRedirect()(getState());

      dispatch({
        type: RESET_DATA,
      });

      if (makeSelectIsMotoristApp()(getState()) && !accountLocked) {
        browserHistory.push(browserHistoryPushPath);
        return dispatch(setLocation(LOGOUT_APP_PATHNAME));
      }

      if (!isUserLoggedIn) {
        dispatch(setSpinner(true));
        if (doRedirectToCVP) return dispatch(redirectToCVP());
        return browserHistory.push(browserHistoryPushPath);
      }

      if (redirect) {
        dispatch(setSpinner(true));
        redirect = `${redirect}${
          redirect.indexOf('?') === -1 ? '?' : '&'
        }action=logout`;

        return dispatch(setLocation(redirect));
      }
      if (inAllDevices) {
        dispatch(setSpinner(false));
      } else {
        dispatch(resetForm());
      }
      browserHistory.push(browserHistoryPushPath);
    } catch (error) {
      dispatch(
        showDefaultErrorNotification(
          null,
          lokaliseTags.SSO_ERROR_GENERIC_JANRAIN
        )
      );
    }
  };
}

export default { logout };
