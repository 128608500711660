import isArray from 'lodash/isArray';
import flattenDeep from 'lodash/flattenDeep';
import reverse from 'lodash/reverse';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import {
  CHANGE_STEP,
  FOCUS_ATTRIBUTE,
  REMOVE_ERROR,
  RESET_FORM,
  RESET_MODE,
  SERVER_RESPONSE,
  SET_ACTIONS,
  SET_DATA,
  SET_EMAIL_VERIFIED,
  SET_ERROR,
  SET_FORM,
  SET_STEP,
  UPDATE_ATTRIBUTE,
  UPDATE_ERROR,
  VALIDATION_ERROR,
} from './constants';
import { WIZARD_HEADER } from '../register/headers';
import { WIZARD_HEADER as LOYALTY_HEADER } from '../sol/shared/headers';
import { OFFLINE_NOTIFICATION } from '../app/notifications';
import { LOYALTY_NEW_CHANGE_VALUE } from '../sol/attach/personalized-or-not/constants';
import { NEW_LOYALTY_PATHNAME } from '../../shared/pathnames';
import {
  EDIT_PROFILE_PAGE,
  LOYALTY_MIGRATION_PAGE,
  LOYALTY_PAGE,
} from '../../shared/formPages';
import { makeSelectIsMobile } from '../browser/selectors';
import {
  makeSelectFormData,
  makeSelectFormErrors,
  makeSelectFormPage,
  makeSelectFormPageName,
  makeSelectNumberDesktopSteps,
  makeSelectNumberMobileSteps,
} from './selectors';
import { noBackendNotification, sendAdobeAnalyticsEvent } from '../app/actions';
import {
  DRIVE_SERVICE,
  LOYALTY_SERVICE,
  PAYMENTS_SERVICE,
} from '../../shared/formServices';
import {
  checkIfSkipValidate,
  validateField,
} from '../../shared/validator-utils';
import {
  showDefaultErrorNotification,
  showNotification,
} from '../notification/actions';
import adobeAnalyticTags from '../../shared/adobeAnalyticTags';
import lokaliseTags from '../../shared/lokaliseTags';
import { sendKochavaEvent, setDataToStore } from '../app/utils';
import { makeSelectIsPPFlow } from '../progressive-profiling/selectors';
import { NUMBERS } from '../../shared/constants';

export function resetForm() {
  return {
    type: RESET_FORM,
  };
}

export function setForm(form) {
  return {
    type: SET_FORM,
    ...form,
  };
}

export function saveActions(actions) {
  return {
    type: SET_ACTIONS,
    actions,
  };
}

const getPageNameWithField = (
  tag,
  page,
  dynamicForm,
  step,
  showNameField = false
) => {
  const fields = dynamicForm.form[page].order.mobile[step];
  const field = isArray(fields) ? fields[0] : fields;
  return showNameField && field ? `${tag.pageName} ${field}` : tag.pageName;
};

export function sendAdobeAnalyticsErrorForm(error, page) {
  return (dispatch, getState) => {
    const pageLoadTag =
      get(adobeAnalyticTags, `${[page]}.tag`, false) ||
      get(adobeAnalyticTags, `${[page]}.pageLoad`, false);

    const tag = {
      ...(pageLoadTag instanceof Function ? pageLoadTag() : pageLoadTag),
    };

    if (!isEmpty(tag)) {
      const isMobile = makeSelectIsMobile()(getState());
      const dynamicForm = getState().dynamicForm;
      tag.errorMessage = isObject(error)
        ? Object.values(error).join('|')
        : error;

      if (tag.errorMessage) {
        tag.pageName = getPageNameWithField(
          tag,
          page,
          dynamicForm,
          dynamicForm.step,
          isMobile && dynamicForm.step > 0
        );
        dispatch(sendAdobeAnalyticsEvent(tag, 'error'));
      }
    }
  };
}

export function sendAdobeAnalyticsClickFormEvent(
  page,
  cta = lokaliseTags.SSO_GENERAL_NEXT_LABEL
) {
  return (dispatch, getState) => {
    const tag = {
      ...get(adobeAnalyticTags, `${[page]}.tag`, false),
    };
    if (!isEmpty(tag)) {
      const isMobile = makeSelectIsMobile()(getState());
      const dynamicForm = getState().dynamicForm;
      tag.pageName = getPageNameWithField(
        tag,
        page,
        dynamicForm,
        dynamicForm.step,
        isMobile && dynamicForm.step > 0
      );
      tag.clickarea = tag.pageName;
      tag.pushState = null;
      tag.cta = cta;
      dispatch(sendAdobeAnalyticsEvent(tag, 'tileClick'));
    }
  };
}

export function sendAdobeAnalyticsVPVFormEvent(step, page) {
  return (dispatch, getState) => {
    const tag = {
      ...get(adobeAnalyticTags, `${[page]}.tag`, false),
    };
    const formPage = makeSelectFormPageName()(getState());

    if (!isEmpty(tag)) {
      const isMobile = makeSelectIsMobile()(getState());
      const dynamicForm = getState().dynamicForm;

      tag.pageName = getPageNameWithField(
        tag,
        page,
        dynamicForm,
        step,
        isMobile && step > 0
      );
      tag.signInType = formPage === 'Standard';
      dispatch(sendAdobeAnalyticsEvent(tag));
    }
  };
}

export function nextStep(
  successCallback,
  errorCallback,
  setHeaderMenuLeft,
  { passedSpecialAnalytic = false, passedKochavaAnalytics } = {}
) {
  return (dispatch, getState) => {
    const dynamicForm = getState().dynamicForm;
    const isMobile = makeSelectIsMobile()(getState());
    const isAppLoading = getState().app.loading;
    const validatedArray = [];
    const fieldsToValidate = flattenDeep(
      dynamicForm.form[dynamicForm.page].order[isMobile ? 'mobile' : 'desktop'][
        dynamicForm.step
      ]
    );

    const validationResult = {
      type: VALIDATION_ERROR,
      errors: [],
      detail: {},
    };

    if (!isAppLoading) {
      fieldsToValidate.forEach((prop) => {
        const skip = dispatch(checkIfSkipValidate(prop, dynamicForm.formData));
        validatedArray.push(
          dispatch(
            doValidation(
              dynamicForm.form[dynamicForm.page],
              dynamicForm.formData,
              prop,
              skip
            )
          )
        );
      });

      if (!navigator.onLine) {
        return dispatch(showNotification(OFFLINE_NOTIFICATION));
      }

      const loadData = setTimeout(() => {
        dispatch(noBackendNotification());
      }, getState().app.timeOut);

      Promise.all(flattenDeep(validatedArray)).then(() => {
        clearTimeout(loadData);
        const errors = getState().dynamicForm.errors;
        let passed = true;
        fieldsToValidate.forEach((prop) => {
          if (errors[prop] !== undefined) {
            validationResult.detail[prop] = errors[prop];
            validationResult.errors.push(prop);
            passed = false;
          }
        });

        if (passed) {
          if (passedSpecialAnalytic) {
            dispatch(
              sendAdobeAnalyticsEvent({ ...passedSpecialAnalytic }, 'tileClick')
            );
          } else {
            dispatch(sendAdobeAnalyticsClickFormEvent(dynamicForm.page));
          }

          if (passedKochavaAnalytics) {
            for (const eventName of passedKochavaAnalytics.eventNames) {
              if (eventName)
                dispatch(
                  sendKochavaEvent(eventName, {
                    fakeId: passedKochavaAnalytics.fakeId,
                  })
                );
            }
          }

          dispatch(
            sendAdobeAnalyticsVPVFormEvent(
              dynamicForm.step + 1,
              dynamicForm.page
            )
          );
          if (window.location.pathname.indexOf(NEW_LOYALTY_PATHNAME) !== -1) {
            dispatch({
              type: LOYALTY_NEW_CHANGE_VALUE,
              name: 'fails',
              value: 0,
            });
          }
          dispatch(passedValidation(dynamicForm, setHeaderMenuLeft));
          if (successCallback) successCallback(true);
        } else {
          if (window.location.pathname === NEW_LOYALTY_PATHNAME) {
            dispatch({
              type: LOYALTY_NEW_CHANGE_VALUE,
              name: 'fails',
              value: getState().loyaltyNew.fails + 1,
            });
          }
          if (errorCallback) errorCallback(validationResult);
        }
      });
    }

    dispatch(
      sendAdobeAnalyticsErrorForm(
        makeSelectFormErrors()(getState()),
        makeSelectFormPageName()(getState())
      )
    );
  };
}

export function previousStep() {
  return (dispatch, getState) => {
    const formState = getState().dynamicForm;
    if (window.location.pathname === NEW_LOYALTY_PATHNAME) {
      dispatch({
        type: LOYALTY_NEW_CHANGE_VALUE,
        name: 'fails',
        value: 0,
      });
    }
    if (formState.step === 0) {
      if (formState.actions.onBack) {
        formState.actions.onBack();
      }
      dispatch({
        type: RESET_MODE,
      });
    } else {
      dispatch(
        sendAdobeAnalyticsVPVFormEvent(formState.step - 1, formState.page)
      );
      dispatch({
        type: CHANGE_STEP,
        value: -1,
      });
      const header = WIZARD_HEADER;
      const newStep = getState().dynamicForm.step;
      const page = getState().dynamicForm.page;
      if (
        getState().dynamicForm.page === LOYALTY_PAGE &&
        LOYALTY_HEADER.subtitle.mobile.preText.values[1]
      ) {
        header.subtitle.mobile.preText.values = [
          newStep + NUMBERS.TWO,
          LOYALTY_HEADER.subtitle.mobile.preText.values[1],
        ];
      } else {
        header.subtitle.mobile.preText.values = [
          newStep + 1,
          getState().dynamicForm.form[page].order.mobile.length,
        ];
      }

      const newStepName =
        getState().dynamicForm.form[page].order.mobile[newStep];
      if (newStepName === 'newPassword' || newStepName === 'currentPassword') {
        const data = getState().dynamicForm.formData;
        data[newStepName] = '';
        dispatch(setFormData(data));
      }
    }
  };
}

export function onAttributeFocus(name) {
  return {
    type: FOCUS_ATTRIBUTE,
    name,
  };
}

export function onAttributeBlur(name) {
  return (dispatch, getState) => {
    const dynamicForm = getState().dynamicForm;
    const formData = dynamicForm.formData;
    const formErrors = makeSelectFormErrors()(getState());
    dispatch(
      doValidation(dynamicForm.form[dynamicForm.page], formData, name, {
        skip: true,
        errorValidation: formErrors[name],
      })
    );
    if (name === 'cardId') {
      const dateOfBirth = formData.birthdate || '';
      if (dateOfBirth !== '') {
        dispatch(
          doValidation(
            dynamicForm.form[dynamicForm.page],
            formData,
            'birthdate',
            {
              skip: true,
              errorValidation: formErrors[name],
            }
          )
        );
      }
    }
    dispatch(onAttributeFocus(''));
    document.activeElement.blur();
  };
}

export function onMultiSelectChange(name, value) {
  return (dispatch, getState) => {
    const dynamicForm = getState().dynamicForm;
    const selectedOptions = [...dynamicForm.formData[name]];

    if (value.checked) {
      selectedOptions.push(value.id);
    } else {
      const removeKey = selectedOptions.indexOf(value.id);
      selectedOptions.splice(removeKey, 1);
    }
    const attribute = {
      name,
      value: selectedOptions,
    };
    dispatch(updateAttribute(attribute));
  };
}

export function onAttributeChange(name, value) {
  return (dispatch, getState) => {
    let updatedValue;
    if (typeof value === 'boolean') {
      updatedValue = value;
    } else {
      updatedValue = value;
      if (updatedValue && updatedValue.length > NUMBERS.TWO_HUNDRED) {
        updatedValue = updatedValue.substring(0, NUMBERS.TWO_HUNDRED);
      }
    }
    if (name.indexOf('.') !== -1) {
      const attributeName = name.split('.');
      const attribute = {
        name: attributeName[0],
        value: {
          ...getState().dynamicForm.formData[attributeName[0]],
          [attributeName[1]]: updatedValue,
        },
      };
      dispatch(updateAttribute(attribute));
    } else {
      const attribute = {
        name,
        value: updatedValue,
      };
      dispatch(updateAttribute(attribute));
    }
  };
}

export function onRadioChange(name, value) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_ATTRIBUTE,
      name,
      value: value.id === 0,
    });
  };
}

export function onRegisterSelectChange(name, value) {
  return (dispatch, getState) => {
    const dynamicForm = getState().dynamicForm;
    const newValue = value || null;
    const attribute = {
      name,
      value: newValue,
    };
    dispatch(updateAttribute(attribute));
    const formData = { ...dynamicForm.formData, [name]: value };
    dispatch(doValidation(dynamicForm.form[dynamicForm.page], formData, name));
  };
}

export function saveAttribute(change) {
  return (dispatch, getState) => {
    const formState = getState().dynamicForm;
    const formProps = getState().dynamicForm.form[formState.page];

    if (!navigator.onLine) {
      return dispatch(showNotification(OFFLINE_NOTIFICATION));
    }

    const checkData = {
      ...formState.formData,
      [formState.editField.name]: formState.editField.value,
    };
    if (change) {
      const field = getState().dynamicForm.editField;
      let validation = [];

      if (typeof field.value === 'object') {
        const v = [];
        Object.keys(field.value || {}).forEach((f) => {
          v.push(
            dispatch(doValidation(formProps, checkData, `${field.name}.${f}`))
          );
        });
        Object.keys(v).forEach((item) => {
          Object.keys(v[item] || {}).forEach((val) => {
            validation.push(v[item][val]);
          });
        });
      } else {
        validation = dispatch(doValidation(formProps, checkData, field.name));
      }

      const loadData = setTimeout(() => {
        dispatch(noBackendNotification());
      }, getState().app.timeOut);
      Promise.all(validation).then(() => {
        clearTimeout(loadData);
        let noErrors = true;
        if (typeof field.value === 'object') {
          Object.keys(field.value || {}).forEach((f) => {
            if (getState().dynamicForm.errors[f] !== undefined) {
              noErrors = false;
            }
          });
        } else {
          noErrors = getState().dynamicForm.errors[field.name] === undefined;
        }
        if (noErrors) {
          const attribute = {
            name: getState().dynamicForm.editField.name,
            value: getState().dynamicForm.editField.value,
          };
          dispatch(updateAttribute(attribute));
          if (formState.actions.onSave) {
            formState.actions.onSave();
          }
        }
      });
    } else {
      dispatch(setFormError([]));
    }
  };
}

export function validateForm(formName) {
  return async (dispatch, getState) => {
    const isMobile = makeSelectIsMobile()(getState());
    const validation = [];

    const formPageData = makeSelectFormPage(formName)(getState());
    const formSettings = getState().dynamicForm.form[formName];

    formPageData.order[isMobile ? 'mobile' : 'desktop'].forEach((screen) => {
      screen.forEach((row) => {
        if (!isArray(row)) {
          if (formPageData.fields[row].type !== 'parent') {
            const skip = dispatch(
              checkIfSkipValidate(row, formPageData.values)
            );
            return validation.push(
              dispatch(
                doValidation(formSettings, formPageData.values, row, skip)
              )
            );
          }

          return formPageData.fields[row].order[
            isMobile ? 'mobile' : 'desktop'
          ].forEach((r) => {
            if (!isArray(r)) {
              return validation.push(
                dispatch(doValidation(formSettings, formPageData.values, r))
              );
            }
            return r.forEach((fieldName) => {
              validation.push(
                dispatch(
                  doValidation(formSettings, formPageData.values, fieldName)
                )
              );
            });
          });
        }

        return row.forEach((fieldName) => {
          validation.push(
            dispatch(doValidation(formSettings, formPageData.values, fieldName))
          );
        });
      });
    });

    for (const element of validation) {
      await Promise.all(element);
    }

    const errors = getState().dynamicForm.errors;
    if (formName === LOYALTY_MIGRATION_PAGE) {
      delete errors.birthdate;
    }

    const errorsArray = Object.keys(errors);

    if (errorsArray.length === 0) {
      return true;
    }

    dispatch(
      sendAdobeAnalyticsErrorForm(
        makeSelectFormErrors()(getState()),
        makeSelectFormPageName()(getState())
      )
    );
    return Promise.reject({
      type: VALIDATION_ERROR,
      errors: errorsArray,
      detail: errors,
    });
  };
}

export function validateStep(setHeaderMenuLeft) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(nextStep(resolve, reject, setHeaderMenuLeft));
    });
  };
}

export function doValidation(
  dynamicFormPage,
  dynamicData,
  fieldName,
  skipValidation = false
) {
  return (dispatch, getState) => {
    // no validations available/provided
    if (!dynamicFormPage) {
      return false;
    }

    const validatedArray = [];
    let formData = {};
    let options;
    let fieldParent = false;

    if (fieldName.indexOf('.') !== -1) {
      fieldParent = fieldName.split('.')[0];
      fieldName = fieldName.split('.')[1];
    }

    Object.keys(dynamicFormPage.properties || {}).forEach((data) => {
      if (dynamicFormPage.properties[data].name === fieldName) {
        formData = dynamicFormPage.properties[data];
        options = dynamicFormPage.properties[data].options;
      }
    });
    dispatch(removeFieldError(fieldName));

    const market = getState().app.query.market;
    const services = getServices(getState());

    let fieldData = fieldParent
      ? dynamicData[fieldParent][fieldName]
      : dynamicData[fieldName];
    if (fieldData && typeof fieldData === 'object') {
      fieldData = fieldData[fieldName];
    }
    if (
      typeof fieldName === 'string' &&
      fieldName.endsWith('2') &&
      fieldName !== 'address2'
    ) {
      const parentField = fieldName.substring(0, fieldName.length - 1);
      if (typeof dynamicData[parentField] === 'object') {
        fieldData = dynamicData[parentField][fieldName];
      }
    }

    if (
      fieldData &&
      typeof fieldData === 'string' &&
      fieldData.trim() !== fieldData
    ) {
      fieldData = fieldData.trim();
      if (fieldParent) {
        const value = dynamicData[fieldParent];
        value[fieldName] = fieldData;
        const attribute = {
          name: fieldParent,
          value,
        };
        dispatch(updateAttribute(attribute));
      } else {
        const attribute = {
          name: fieldName,
          value: fieldData,
        };
        dispatch(updateAttribute(attribute));
      }
    }
    if (formData.type === 'checkbox') {
      fieldData = get(dynamicData, `${fieldName}.checked`, '');
    }
    const fieldDataValidation =
      fieldData !== '' && fieldData !== null && fieldData !== undefined;
    if (
      formData.required === true ||
      fieldDataValidation ||
      checkFormDataServices(formData, services)
    ) {
      const stateParam = getState();
      const updateErrors = [];
      reverse(
        Object.keys(formData.validations || {}).forEach((rule) => {
          validatedArray.push(
            validateField({
              state: stateParam,
              value: fieldData,
              rule: formData.validations[rule],
              options: options || dynamicData,
              market,
              skipValidation,
              type: formData.type,
              callback: (response) => {
                if (response === 'preRegistered') {
                  dispatch({
                    type: UPDATE_ERROR,
                    name: 'cardId',
                    value: 'card_already_registered',
                  });
                  dispatch(setFormStep(0));
                  const subtitle = { ...getState().header.subtitle };
                  subtitle.mobile.preText.values[0] = 1;
                } else if (
                  response !== false &&
                  !(
                    formData.validations[rule].rule === 'unique' &&
                    fieldData === getState().profile.profile.emailAddress &&
                    getState().dynamicForm.page === EDIT_PROFILE_PAGE
                  )
                ) {
                  setFormErrorPriority({
                    rule: formData.validations[rule],
                    value: {
                      type: UPDATE_ERROR,
                      name: fieldName,
                      value: response,
                    },
                  });
                }
                if (
                  (response === 'preRegistered' ||
                    response === 'blocked_card' ||
                    response === 'invalid_card_and_dob') &&
                  window.innerWidth > NUMBERS.SEVEN_HUNDRED_SIXTY_EIGHT
                ) {
                  dispatch({
                    type: LOYALTY_NEW_CHANGE_VALUE,
                    name: 'fails',
                    value: getState().loyaltyNew.fails + 1,
                  });
                }
                if (
                  response === false &&
                  formData.name === 'emailAddress' &&
                  !skipValidation.skip
                ) {
                  dispatch({
                    type: SET_EMAIL_VERIFIED,
                    value: {
                      email: fieldData,
                    },
                  });
                }
                if (response === 'recaptcha') {
                  dispatch(
                    showDefaultErrorNotification(
                      null,
                      lokaliseTags.SSO_TECHNICAL_ERROR_ARCHETYPE
                    )
                  );
                }
              },
            })
          );
        })
      );

      function setFormErrorPriority(formError) {
        if (updateErrors.length > 0) {
          updateErrors.push(formError);
          // check the form errors priority
          for (let i = 0; i < formData.validations.length; i++) {
            const formValidation = formData.validations[i];
            const findedValue = updateErrors.find(
              (val) => val.rule.message === formValidation.message
            );
            if (findedValue) return dispatch(findedValue.value);
          }
        } else {
          dispatch(formError.value);
          updateErrors.push(formError);
        }
      }
    }
    return validatedArray;
  };
}

function getServices(state) {
  return {
    loyalty:
      state.profile.loyalty !== undefined
        ? state.profile.loyalty.activated || false
        : false,
    drive:
      state.profile.drive !== undefined
        ? state.profile.drive.activated || false
        : false,
    payments:
      state.profile.payments !== undefined
        ? state.profile.payments.activated || false
        : false,
  };
}

function checkFormDataServices(formData, services) {
  const loyaltyServices =
    formData.services !== undefined &&
    formData.services.indexOf(LOYALTY_SERVICE) !== -1 &&
    services.loyalty;
  const driveServices =
    formData.services !== undefined &&
    formData.services.indexOf(DRIVE_SERVICE) !== -1 &&
    services.drive;
  const paymentsServices =
    formData.services !== undefined &&
    formData.services.indexOf(PAYMENTS_SERVICE) !== -1 &&
    services.payments;

  return loyaltyServices || driveServices || paymentsServices;
}

export function passedValidation(formState, setHeaderMenuLeft) {
  return (dispatch, getState) => {
    const isMobile = makeSelectIsMobile()(getState());
    const totalSteps = isMobile
      ? makeSelectNumberMobileSteps(formState.page)(getState())
      : makeSelectNumberDesktopSteps(formState.page)(getState());
    let header = {};
    // hide passwords again
    if (formState.step < totalSteps) {
      dispatch({
        type: CHANGE_STEP,
        value: 1,
      });
      header = WIZARD_HEADER;
      if (
        formState.page === LOYALTY_PAGE &&
        LOYALTY_HEADER.subtitle.mobile.preText.values[1]
      ) {
        header.subtitle.mobile.preText = {
          key: lokaliseTags.SSO_REGISTER_HEADER_STEP_LABEL,
          values: [
            formState.step + NUMBERS.THREE,
            LOYALTY_HEADER.subtitle.mobile.preText.values[1],
          ],
        };
      } else {
        header.subtitle.mobile.preText = {
          key: lokaliseTags.SSO_REGISTER_HEADER_STEP_LABEL,
          values: [formState.step + NUMBERS.TWO, totalSteps + 1],
        };
      }
      header.menu.leftMenu.event = () => {
        getState().dynamicForm.actions.previousStep();
      };
      if (setHeaderMenuLeft) setHeaderMenuLeft(header.menu.leftMenu);
    } else if (formState.actions.onComplete) {
      formState.actions.onComplete(formState.formData).then((resp) => {
        dispatch(serverResponse(resp));
      });
    }
  };
}

export function setFormStep(step = 0) {
  return {
    type: SET_STEP,
    value: step,
  };
}

export function setFormData(value = {}) {
  return {
    type: SET_DATA,
    value,
  };
}

export function updateAttribute(payload) {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_ATTRIBUTE,
      ...payload,
    });
    const isPPflow = makeSelectIsPPFlow()(getState());
    if (isPPflow) {
      setDataToStore(
        makeSelectFormPageName()(getState()),
        JSON.stringify(makeSelectFormData()(getState())),
        true
      );
    }
  };
}

export function setFormError(value = {}) {
  return {
    type: SET_ERROR,
    value,
  };
}

export function removeFieldError(fieldName) {
  return {
    type: REMOVE_ERROR,
    name: fieldName,
  };
}

export function serverResponse(response) {
  return {
    type: SERVER_RESPONSE,
    value: response,
  };
}

export default {
  resetForm,
  setForm,
  saveActions,
  nextStep,
  previousStep,
  onAttributeFocus,
  onAttributeBlur,
  onMultiSelectChange,
  onAttributeChange,
  onRegisterSelectChange,
  onRadioChange,
  saveAttribute,
  validateForm,
  doValidation,
  passedValidation,
  setFormStep,
};
