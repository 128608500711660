import {
  YELLOW,
  RED,
  VERY_DARK_GREY,
  DARK_YELLOW,
  LIGHT_GREY,
  WHITE,
} from './colors';

import { theme as dsTheme } from '@shell-b2c/web-design-system';

export const theme = {
  typography: {
    ...dsTheme.typography,
  },
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 600,
      md: 959,
      lg: 1223,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: YELLOW,
      dark: DARK_YELLOW,
      contrastText: VERY_DARK_GREY,
    },
    error: {
      main: RED,
    },
    background: {
      default: WHITE,
    },
    grey: {
      800: '#404040',
    },
    text: {
      primary: '#595959',
      secondary: '#404040',
    },
  },
  overrides: {
    MuiExpansionPanel: {
      root: {
        boxShadow: 'none',
        borderBottom: `1px solid ${LIGHT_GREY}`,
        borderRadius: 0,
      },
    },

    MuiExpansionPanelSummary: {
      root: {
        padding: 0,
        color: VERY_DARK_GREY,
        '&$expanded': {
          minHeight: '0 !important',
          margin: 0,
        },
      },
      expandIcon: {
        marginRight: '-4px',
        fill: VERY_DARK_GREY,
      },
      content: {
        margin: '0 !important',
        padding: '16px',
        paddingRight: '70px',
      },
    },

    MuiExpansionPanelDetails: {
      root: {
        color: VERY_DARK_GREY,
        padding: '16px',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: 1.42857143,
      },
    },

    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },

    MuiSwitch: {
      switchBase: {
        '&$checked': {
          '&+ $track': {
            opacity: 1,
          },
        },
      },
    },

    MuiButtonBase: {
      root: {
        backgroundColor: 'none',
        borderRadius: 'none',
        border: 'null',
        color: 'none',
      },
    },
  },
  props: {
    MuiInput: {
      inputProps: {
        maxLength: 200,
      },
    },
  },
};
