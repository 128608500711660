import { SOL_ATTACH_SMARTDEAL_PATHNAME } from '../../shared/pathnames';

export const INITIALIZE_HEADER = 'INITIALIZE_HEADER';
export const SET_HEADER = 'SET_HEADER';
export const SET_HEADER_MENU_LEFT = 'SET_HEADER_MENU_LEFT';
export const ADD_ACTION = 'ADD_ACTION';
export const REMOVE_ACTION = 'REMOVE_ACTION';
export const SET_HEADER_LEFT_ICON = 'SET_HEADER_LEFT_ICON';
export const TOGGLE_TAGS = 'TOGGLE_TAGS';

export const DISABLE_HEADER_PATHNAMES = ['/', SOL_ATTACH_SMARTDEAL_PATHNAME];
