export function createUserProfileFromLoyaltyInitialFormData({ cardId }) {
  return {
    loyalty: {
      activatedGoPlus: true,
      migrationOption: 'MIGRATE',
      accounts: [
        {
          accountProvider: 'HYBRIS',
          status: 'ENABLED',
          card: [
            {
              cardId,
              cardType: 'PLASTIC',
              cardEnabled: true,
              cardStatusReason: 'Migrated in SSO',
            },
          ],
        },
      ],
    },
  };
}
