/* eslint jsx-a11y/no-static-element-interactions: 0 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import Styling from './style';
import { DISABLE_HEADER_PATHNAMES } from './constants';
import { Icon, Text } from '@shell-b2c/web-design-system';
import { makeStyles } from '@mui/styles';
import { getStepCounter } from '../register/actions';
import { useDispatch, useSelector } from 'react-redux';
import { registerFlowPathnames } from '../../shared/registerFlowConstants';
import { makeSelectPhoneAuthentication } from '../settings/selectors';
import {
  makeSelectFormPageName,
  makeSelectFormStep,
} from '../dynamic-form/selectors';
import { VERIFICATION_CODE_PATHNAME } from '../../shared/pathnames';
import { REGISTER_PAGE } from '../../shared/formPages';

const iconSwitch = {
  back: (
    <Icon
      type="arrow-left"
      style={Styling.arrowLeftIcon}
      data-testid="arrow-left"
    />
  ),
  close: <Icon type="close" data-testid="close" />,
};

export const Header = ({
  app,
  header,
  handleToggleTag,
  handleRemoveAction,
  isMobile,
  location,
  translate,
  handleRedirectToCVP,
}) => {
  const { disabledHeader, background } = header;
  const styles = useStyles();
  const dispatch = useDispatch();
  const [stepCounter, setStepCounter] = useState('');
  const isMobileAuth = useSelector(makeSelectPhoneAuthentication());
  const formPage = useSelector(makeSelectFormPageName());
  const formStep = useSelector(makeSelectFormStep());

  useEffect(() => {
    if (dispatch) {
      setStepCounter(dispatch(getStepCounter(translate)));
    }
  }, [dispatch, formStep, translate]);

  if (DISABLE_HEADER_PATHNAMES.includes(location.pathname) && isMobile) {
    return null;
  }

  const checkRenderStepCounter = () => {
    const pathname = window.location.pathname;

    const fromCorrectFlow =
      (registerFlowPathnames.includes(pathname) ||
        (isMobileAuth && pathname === VERIFICATION_CODE_PATHNAME)) &&
      formPage === REGISTER_PAGE;
    return fromCorrectFlow;
  };

  const handleClick = (e) => {
    if (!header.disabledMenu) e();
  };

  const getIcon = (button, visible) => {
    if (!visible) {
      return <span />;
    }
    return (
      iconSwitch[button.icon] || (
        <span style={Styling.defaultICon}>{translate(button.name)}</span>
      )
    );
  };

  const showShellLogo = () => (
    <Grid
      item
      xs={2}
      onClick={() => {
        handleToggleTag();
      }}
    >
      <Icon type="pecten-small" style={Styling.shellLogo} />
    </Grid>
  );

  const handleDivClick = (actions, leftMenuEvent) => {
    if (actions.length <= 0) {
      handleClick(leftMenuEvent);
      return;
    }

    handleClick(actions[0]);
    handleRemoveAction();
  };
  const renderHeaderMobile = () => {
    const { menu, actions } = header;
    return (
      <Grid container className={styles.containerParent}>
        <div
          id={'headerMenuLeft'}
          onClick={(e) => {
            e.preventDefault();
            handleDivClick(actions, menu.leftMenu.event);
          }}
          className={styles.child1}
        >
          <Hidden smUp>
            <div style={Styling.left}>
              {getIcon(menu.leftMenu, menu.leftMenu.mobile)}
            </div>
          </Hidden>
        </div>
        <Grid
          item
          id={'headerInvisibleToggleTags'}
          xs={4}
          onClick={() => {
            handleToggleTag();
          }}
          style={Styling.toggleTagHeader}
          className={styles.child2}
        />
        {checkRenderStepCounter() && (
          <div className={styles.child3}>
            <Text
              variant="caption"
              color="textSecondary"
              className={styles.subtitleCounter}
            >
              {stepCounter}
            </Text>
          </div>
        )}
      </Grid>
    );
  };

  const renderHeaderDesktop = () => {
    const redirect = app.query.redirect;
    const hasCustomEvent =
      redirect && header.menu.rightMenu.event && header.menu.rightMenu.desktop;
    const isHeaderDisabled = header.menu.rightMenu.disabled || false;
    const headerEvent = hasCustomEvent
      ? header.menu.rightMenu.event
      : handleRedirectToCVP;
    const icon = getIcon(header.menu.rightMenu, header.menu.rightMenu.desktop);
    return (
      <Grid container>
        {showShellLogo()}
        {redirect && !isHeaderDisabled && (
          <React.Fragment>
            <Grid item xs={9} />
            <div onClick={headerEvent} className={styles.child2}>
              <Hidden smDown>
                <div style={Styling.right}>{icon}</div>
              </Hidden>
            </div>
          </React.Fragment>
        )}
      </Grid>
    );
  };

  return (
    !disabledHeader && (
      <Grid
        item
        xs={12}
        style={{
          ...Styling.headerContainer,
          ...(isMobile ? Styling.headerContainerMobile : {}),
          ...(background ? { background } : {}),
        }}
      >
        {isMobile ? renderHeaderMobile() : renderHeaderDesktop()}
      </Grid>
    )
  );
};
const useStyles = makeStyles({
  containerParent: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
  },
  child1: {
    display: 'flex',
  },
  child2: {
    display: 'flex',
  },
  child3: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  subtitleCounter: {
    marginBottom: '0',
  },
});
Header.propTypes = {
  translate: PropTypes.func.isRequired,
  header: PropTypes.object.isRequired,
  handleRedirectToCVP: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  app: PropTypes.object.isRequired,
  redirect: PropTypes.string,
  handleToggleTag: PropTypes.func.isRequired,
  handleRemoveAction: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default Header;
