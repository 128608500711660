import React from 'react';
import PropTypes from 'prop-types';
import { MFA_OTP_PATHNAME, PROFILE_PATHNAME } from '../../shared/pathnames';
import browserHistory from '../../router/history';
import { makeStyles } from '@mui/styles';
import { Text } from '@shell-b2c/web-design-system';
function Subtitle({ children, className, ...props }) {
  const inProfile = browserHistory.location.pathname === PROFILE_PATHNAME;
  const inMfa = browserHistory.location.pathname === MFA_OTP_PATHNAME;
  const classes = useStyles();
  return (
    <Text
      variant="body1"
      className={`${classes.subtitle} ${inProfile ? classes.inProfile : ''} 
      ${inMfa ? classes.inMfa : ''}${className}`}
      gutterBottom
      color={'textPrimary'}
      data-testid="subtitle"
      {...props}
    >
      {children}
    </Text>
  );
}
const useStyles = makeStyles({
  subtitle: {
    fontSize: '16px',
    marginBottom: '16px',
  },
  inProfile: {
    marginBottom: '20px',
    textAlign: 'left',
    fontSize: '14px',
  },
  inMfa: {
    marginBottom: '0px',
  },
});
Subtitle.defaultProps = {
  className: '',
};

Subtitle.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
};

export default Subtitle;
