import {
  PROFILE_LOADED,
  SET_PROFILE_LOADING_STATE,
  RECEIVE_NOTIFICATIONS,
  SET_PROFILE_OPTION,
  SET_IS_LOYALTY_VERIFIED,
  UPDATE_CONSENTS,
  UPDATE_VEHICLE_REGISTRATION_NUMBER,
  RESET_DATA,
  UPDATE_CUSTOMER_TYPE,
  SET_PROFILE_LOADED,
} from './constants';

export const initialState = {
  profile: {
    providers: [],
  },
  url: {},
  uuid: '',
  user: {
    firstName: '',
    lastName: '',
    emailAddress: '',
  },
  drive: {},
  payments: {},
  loyalty: {},
  market: {},
  lion: {},
  consents: {
    notifications: false,
    driveOptIn: false,
    loyaltyOptIn: false,
    goPlusOptIn: false,
    tos: false,
    newsletter: false,
  },
  desktopOption: false,
  loading: false,
  loaded: false,
  maxImageSize: 3145728,
  isLoyaltyVerified: false,
  externalSystemIds: [],
};

const profile = (state = { ...initialState }, action) => {
  switch (action.type) {
    case PROFILE_LOADED:
      return {
        ...state,
        consents: { ...action.profile.consents },
        profile: { ...action.profile.profile },
        drive: { ...action.profile.drive },
        payments: { ...action.profile.payments },
        loyalty: { ...action.profile.loyalty },
        lion: { ...action.profile.lion },
        market: { ...action.profile.market },
        uuid: action.profile.uuid,
        loading: false,
        loaded: true,
        goplus: { ...action.profile.goplus },
        isLoyaltyVerified: false,
        externalSystemIds: action.profile.externalSystemIds
          ? [...action.profile.externalSystemIds]
          : [],
      };
    case UPDATE_CONSENTS:
      return {
        ...state,
        consents: {
          ...state.consents,
          ...action.payload,
        },
      };
    case UPDATE_VEHICLE_REGISTRATION_NUMBER:
      return {
        ...state,
        loyalty: {
          ...state.loyalty,
          vehicleRegistrationNumber: action.payload,
        },
      };
    case UPDATE_CUSTOMER_TYPE:
      return {
        ...state,
        profile: {
          ...state.profile,
          customerType: action.payload,
        },
      };
    case SET_PROFILE_LOADING_STATE:
      return {
        ...state,
        loading: action.value,
      };
    case RECEIVE_NOTIFICATIONS:
      return {
        ...state,
        consents: {
          ...state.consents,
          [action.name]: action.value,
        },
      };
    case SET_PROFILE_OPTION:
      return {
        ...state,
        desktopOption: action.option,
      };
    case SET_IS_LOYALTY_VERIFIED:
      return {
        ...state,
        isLoyaltyVerified: true,
      };
    case RESET_DATA:
      return {
        ...initialState,
      };
    case SET_PROFILE_LOADED:
      return {
        ...state,
        loaded: action.isLoaded,
      };
    default:
      return state;
  }
};
export default profile;
