import lokaliseTags from '../../shared/lokaliseTags';
import { TOGGLE_TAGS } from './constants';

export function initializeHeader(
  leftMenuEvent,
  rightMenuEvent,
  setHeaderNew,
  otherParams = {}
) {
  return () => {
    const iconInitial =
      leftMenuEvent && otherParams.headerLeftIcon
        ? otherParams.headerLeftIcon
        : false;
    const initialData = {
      name: lokaliseTags.SSO_GENERAL_CLOSE,
      icon: iconInitial || 'close-white',
    };
    const header = {
      step: undefined,
      disabledHeader: false,
      actions: [],
      background: undefined,
      menu: {
        leftMenu: {
          event: null,
          name: '',
          icon: '',
          mobile: false,
          desktop: false,
          enabled: true,
        },
        rightMenu: {
          event: null,
          name: '',
          icon: '',
          mobile: false,
          desktop: false,
          enabled: true,
        },
      },
    };

    if (leftMenuEvent)
      header.menu.leftMenu = {
        ...initialData,
        event: leftMenuEvent,
        mobile: true,
        desktop: false,
      };

    if (rightMenuEvent)
      header.menu.rightMenu = {
        ...initialData,
        event: rightMenuEvent,
        mobile: false,
        desktop: true,
      };

    setHeaderNew(header);
  };
}

export function toggleTag() {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_TAGS,
    });
  };
}

export default {
  initializeHeader,
  toggleTag,
};
