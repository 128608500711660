import lokaliseTags from '../../lokaliseTags';
import { cleanLodUser } from '../global-utils';

const initialUserProfileData = {
  newPassword: null,
  consents: {
    mobileLoyaltyOptIn: false,
    mobileGoPlusMarketingOptIn: false,
    mobileNewsletters: false,
    goPlusMarketingOptIn: false,
  },
  profile: {
    firstName: null,
    lastName: null,
    mobile: null,
    birthdate: null,
    gender: lokaliseTags.SSO_GENDER_UNDISCLOSED,
    generalUserId: 'no-guid-provided',
    udid: 'no-udid-provided',
  },
  market: {
    code: null,
    country: null,
  },
};

export function createUserProfileFromAppData(user) {
  const cleanedUser = cleanLodUser(user);

  const data = {
    ...initialUserProfileData,
    profile: {
      ...initialUserProfileData.profile,
      firstName: cleanedUser.firstName,
      lastName: cleanedUser.lastName,
      birthdate: cleanedUser.dob,
      mobile: cleanedUser.mobilePhone,
      gender: cleanedUser.gender?.toLowerCase(),
      primaryAddress: {
        zip: cleanedUser.address.postCode,
        city: cleanedUser.address.city,
        street: cleanedUser.address.street,
        houseNumber: cleanedUser.address.houseNumber,
        district: cleanedUser.address.locality,
        province: cleanedUser.address.municipality,
      },
    },
    market: {
      code: cleanedUser.market,
      country: cleanedUser.market.split('-')[1],
    },
    consents: {
      ...initialUserProfileData.consents,
    },
    loyalty: {
      activatedGoPlus: true,
      migrationOption: 'MIGRATE',
      accounts: [
        {
          accountProvider: 'LF',
          status: 'ENABLED',
          card: [
            {
              cardEnabled: 'true',
              cardType: 'PLASTIC',
              cardStatusReason: 'Migrated in SSO',
              cardId: cleanedUser.cardId,
            },
          ],
        },
      ],
    },
  };

  if (cleanedUser.email) {
    data.profile.emailAddress = cleanedUser.email;
  }

  return data;
}

export function createUserProfileFromLoyaltyInitialFormData(user) {
  const cleanedUser = cleanLodUser(user);

  const data = {
    loyalty: {
      activatedGoPlus: true,
      migrationOption: 'MIGRATE',
      accounts: [
        {
          accountProvider: 'LF',
          status: 'ENABLED',
          card: [
            {
              cardId: cleanedUser.cardId,
              cardType: 'PLASTIC',
              cardEnabled: true,
              cardStatusReason: 'Migrated in SSO',
            },
          ],
        },
      ],
    },
    profile: {
      primaryAddress: {
        zip: cleanedUser.address.postCode,
        city: cleanedUser.address.city,
        street: cleanedUser.address.street,
        houseNumber: cleanedUser.address.houseNumber,
        district: cleanedUser.address.locality,
        province: cleanedUser.address.municipality,
      },
    },
  };

  if (cleanedUser.email) {
    data.profile.emailAddress = cleanedUser.email;
  }

  return data;
}
