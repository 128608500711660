export const YELLOW = '#FBCE07';
export const DARK_YELLOW = '#e9bf03'; // yellow darkened by an 8%
export const RED = '#DD1D21';
export const GREEN = '#008443';
export const WHITE = '#FFFFFF';
export const GREY = '#F7F7F7';
export const VERY_PALE_GREY = '#F1F1F1';
export const PALE_GREY = '#D9D9D9';
export const LIGHT_GREY = '#A6A6A6';
export const MID_GREY = '#7F7F7F';
export const DARK_GREY = '#595959'; // rgba(89,89,89)
export const VERY_DARK_GREY = '#404040'; // rgba(64,64,64)
export const DISABLED_GREY = '#E5E5E5';
export const BLUE_FACEBOOK = '#3B5998';
