import { POPUP_CHANGE_ATTRIBUTE, SHOW_POPUP } from './constants';

export const initialState = {
  popup: {
    image: {
      background: false,
      url: '',
      title: '',
      titleStyle: {},
      text: '',
      textStyle: {},
    },
    imageStyle: {},
    header: {
      title: '',
      icon: {
        name: '',
        icon: '',
        event: null,
        mobile: false,
        desktop: false,
      },
    },
    message: {
      title: '',
      titleStyle: {},
      text: '',
      textStyle: {},
      note: '',
    },
    vertical: false,
    buttons: [],
  },
  visible: false,
  sliderValue: 0.5,
  cropper: false,
};

const popup = (state = { ...initialState }, action) => {
  const { name, value } = action;
  switch (action.type) {
    case POPUP_CHANGE_ATTRIBUTE:
      return { ...state, [name]: value };
    case SHOW_POPUP:
      return {
        ...initialState,
        popup: {
          ...initialState.popup,
          ...value,
        },
        visible: true,
      };
    default:
      return state;
  }
};

export default popup;
