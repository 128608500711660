import get from 'lodash/get';
import { PROFILE_HEADER } from './headers';
import { PROFILE_LOADED, SET_PROFILE_OPTION, RESET_DATA } from './constants';
import {
  EDIT_PROFILE_PATHNAME,
  LOD_PROFILE_PATHNAME,
  LOGIN_PATHNAME,
  PAYMENTS_PATHNAME,
  PROFILE_PATHNAME,
  SOL_LOYALTY_CARDS_PATHNAME,
  SEE_YOU_SOON_PATHNAME,
} from '../../shared/pathnames';
import {
  UPDATE_PROFILE_APP_PATHNAME,
  SETUP_LOYALTY_APP_PATHNAME,
  PAYMENTS_SETTINGS_APP_PATHNAME,
  SETUP_PAYMENTS_APP_PATHNAME,
  LOGOUT_APP_PATHNAME,
  SETUP_GOPLUS_PATHNAME,
} from '../../shared/appPathnames';
import { makeSelectIsMobile, makeSelectIsIOS } from '../browser/selectors';
import { getProfile } from '@shell-b2c/http-frontend/dist/src/components/core/user';
import { exchangeAccessCode } from '@shell-b2c/http-frontend/dist/src/components/core/auth';
import {
  setDataToStore,
  deleteStorage,
  deleteAllStorageData,
  sendKochavaEvent,
} from '../app/utils';
import {
  redirectToCVP,
  setQuery,
  setSpinner,
  setLocation,
  sendAdobeAnalyticsEvent,
} from '../app/actions';
import {
  showDefaultErrorNotification,
  showNotification,
  setNotificationVisible,
} from '../notification/actions';
import { setFormData, setFormStep } from '../dynamic-form/actions';
import { ACCOUNT_LOCKED } from '../app/notifications';
import { LOYALTY_SERVICE } from '../../shared/formServices';
import {
  makeSelectFormPage,
  makeSelectFormPageName,
} from '../dynamic-form/selectors';
import { REGISTER_PAGE, FORGOT_PASSWORD_PAGE } from '../../shared/formPages';
import browserHistory from '../../router/history';
import {
  makeSelectIsMotoristApp,
  makeSelectQuery,
  makeSelectIsUserLogged,
  makeSelectQueryAuthCode,
  makeSelectQueryClientId,
  makeSelectCVP,
} from '../app/selectors';
import { makeSelectIsGoPlusActivated } from './selectors';
import adobeAnalyticTags from '../../shared/adobeAnalyticTags';
import {
  MOTORIST_FIREBLADE_AMERICA_CVP,
  MOTORIST_FIREBLADE_CVP,
} from '../../shared/cvps';
import { logout } from '../logout/actions';
import menuItems from './menuItems';
import routes from '../../router/routes';
import redirections from '../../router/redirections';
import { showPopup, closePopup } from '../popup/actions';
import { buildPopup } from '../popup/utils';
import lokaliseTags from '../../shared/lokaliseTags';
import { buildNotification } from '../notification/utils';
import ConeAnimation from '../../shared/components/ConeAnimation';
import PaperAirplaneAnimation from '../../shared/components/PaperAirplaneAnimation';
import { twoFaCheckMarket } from '@shell-b2c/http-frontend/dist/src/components/core/twofa';
import { generateHash } from '../../http/core/utils';
import kochavaTags from '../../shared/kochavaTags';
import { NUMBERS } from '../../shared/constants';

const NOTIFICATION_CHECK_EMAIL = {
  header: {
    icon: {
      name: lokaliseTags.SSO_GENERAL_CLOSE,
      icon: 'close',
    },
  },
  animation: {
    component: PaperAirplaneAnimation,
  },
  message: {
    title: {
      key: lokaliseTags.SSO_RESET_PASSWORD_CHECK_EMAIL_LABEL,
      values: [],
    },
    text: {
      key: lokaliseTags.SSO_VERIFY_ACCOUNT_MESSAGE_TEXT,
      values: [],
    },
  },
  buttons: [
    {
      label: lokaliseTags.SSO_GENERAL_OPEN_EMAIL_BUTTON,
    },
  ],
};

export function refreshProfile() {
  return async (dispatch) => {
    try {
      const userProfile = await getProfile();
      dispatch(loadUserProfile({ ...userProfile }));
      return userProfile;
    } catch (error) {
      dispatch(
        showDefaultErrorNotification(
          null,
          lokaliseTags.SSO_ERROR_GENERIC_JANRAIN
        )
      );
      return error;
    }
  };
}

function handleUserProfile(
  event = () => {
    // Do nothing
  },
  additionalData = {}
) {
  return async (dispatch, getState) => {
    const queryParams = makeSelectQuery()(getState());
    const { accessToken, refreshToken, accessCode } = queryParams;
    const isMotoristApp = makeSelectIsMotoristApp()(getState());

    if (
      accessToken &&
      refreshToken &&
      (!accessCode || queryParams[accessCode])
    ) {
      if (getState().profile.loaded) {
        event(getState().profile, additionalData);
      } else {
        try {
          const response = await getProfile({
            noSpinner: additionalData?.noSpinner,
          });
          dispatch(loadUserProfile({ ...response }));
          event(response, additionalData);
        } catch (error) {
          dispatch(setQuery({ [accessCode]: false }));
          if (isMotoristApp) {
            dispatch(setLocation(LOGOUT_APP_PATHNAME));
          } else {
            browserHistory.push(LOGIN_PATHNAME);
          }
        }
      }
    } else if (accessCode) {
      try {
        const response = await exchangeAccessCode({
          code: accessCode,
          apiConfig: {
            noSpinner: additionalData?.noSpinner,
          },
        });
        const newTokens = {
          accessToken: response.accessToken,
          refreshToken: response.refreshToken,
          [accessCode]: true, // Mark as used so no exchange in handleUserProfile
        };
        dispatch(setQuery(newTokens));
        const query = { ...getState().app.query, ...newTokens };
        setDataToStore('query', JSON.stringify(query));
        setDataToStore('auth', JSON.stringify(newTokens), true);
        const authCode = makeSelectQueryAuthCode()(getState());
        const clientId = makeSelectQueryClientId()(getState());
        if (isMotoristApp && !authCode && !clientId) {
          const redirectData = {
            url: UPDATE_PROFILE_APP_PATHNAME,
          };
          dispatch(redirectToCVP(redirectData));
        }
        dispatch(loadUserProfile({ ...response }));
        event(response, additionalData);
      } catch (error) {
        if (isMotoristApp) {
          deleteAllStorageData();
          deleteStorage('authCode');
          dispatch(setQuery({ [accessCode]: false }));
          const redirectErrorData = {
            error: [{ 400: 'AccessCode Invalid' }],
          };
          dispatch(redirectToCVP(redirectErrorData));
        } else {
          browserHistory.push(LOGIN_PATHNAME);
        }
      }
    } else if (isMotoristApp) {
      const redirectErrorData = {
        error: [{ 400: 'no accessToken and/or refreshToken provided' }],
        message: {
          title: 'Returning to app',
          text: 'Missing accessToken and/or refreshToken.',
        },
      };
      dispatch(redirectToCVP(redirectErrorData));
    } else {
      browserHistory.push(LOGIN_PATHNAME);
      return { message: 'Returning to login', returnLogin: true };
    }
  };
}

export const initializeProfileMenu = (setTwoFaEnabled) => {
  return async (dispatch) => {
    try {
      await twoFaCheckMarket();

      setTwoFaEnabled(true);
    } catch (error) {
      if (error.twoFaMarketCheckError()) {
        setTwoFaEnabled(false);
        return;
      }
      dispatch(showDefaultErrorNotification());
      return;
    }
  };
};

function initializeProfile(setHeader, setInitialLoaded) {
  return (dispatch, getState) => {
    const data = getState().dynamicForm.formData;
    dispatch(setFormData(data));

    const routesToPreload = routes.filter((route) =>
      menuItems.some((menuItem) => menuItem.path === route.path)
    );
    routesToPreload.forEach((route) => {
      route.component.preload();
    });
    dispatch(
      handleUserProfile(
        () => dispatch(startProfile(setHeader, setInitialLoaded)),
        (...args) => dispatch(setProfileOption(...args))
      )
    );
  };
}

function startProfile(setHeader, setInitialLoaded) {
  return (dispatch, getState) => {
    const header = PROFILE_HEADER;
    const { profile } = getState();
    header.menu.leftMenu.event = () => {
      dispatch(redirectToCVP());
    };
    const pageRedirection = getState().app.query.page;
    if (pageRedirection && pageRedirection !== 'secureprofile') {
      const query = getState().app.query;
      const pathname = `/${query.page}`;
      const profileOption =
        redirections.find((redirection) => redirection[0] === pathname)?.[1] ||
        pathname;
      dispatch(setProfileOption(profileOption));
      delete query.page;
      dispatch(setQuery(query));
    }
    if (profile.profile) {
      const email = {
        key: profile.profile.emailAddress,
        raw: true,
      };
      header.subtitle.desktop.preText = email;
      header.subtitle.mobile.preText = email;
    }
    setHeader(header);
    setInitialLoaded(true);
  };
}

const CUSTOM_PROFILE_OPTION_ACTIONS = {
  [SOL_LOYALTY_CARDS_PATHNAME]: (dispatch, getState) => {
    const { profile } = getState();
    const isMotoristApp = makeSelectIsMotoristApp()(getState());

    if (isMotoristApp && !profile.loyalty.activated) {
      dispatch(setLocation(SETUP_LOYALTY_APP_PATHNAME));
      return true;
    } else if (
      profile.loyalty.activated &&
      profile.loyalty.cardStatus === 'BLOCKED'
    ) {
      dispatch(setProfileOption(EDIT_PROFILE_PATHNAME));
      dispatch(
        showNotification(
          buildNotification({
            animation: ConeAnimation,
            title: lokaliseTags.SSO_LOYALTY_CARD_BLOCKED,
            text: lokaliseTags.SSO_LOYALTY_CARD_BLOCKED_TEXT,
            buttons: [
              {
                label: lokaliseTags.SSO_GENERAL_CONTINUE,
              },
            ],
          })
        )
      );
      return true;
    }
  },
  [PAYMENTS_PATHNAME]: (dispatch, getState) => {
    const { profile } = getState();
    const isMotoristApp = makeSelectIsMotoristApp()(getState());
    setTimeout(() => {
      if (isMotoristApp) {
        if (profile.payments.activated) {
          dispatch(setLocation(PAYMENTS_SETTINGS_APP_PATHNAME));
        } else {
          dispatch(setLocation(SETUP_PAYMENTS_APP_PATHNAME));
        }
      }
      return true;
    }, NUMBERS.THREE_HUNDRED);
  },
  [LOD_PROFILE_PATHNAME]: (dispatch, getState) => {
    const isMotoristApp = makeSelectIsMotoristApp()(getState());
    const isGoPlusActivatedUserProfile = makeSelectIsGoPlusActivated()(
      getState()
    );
    if (!isGoPlusActivatedUserProfile && isMotoristApp) {
      const redirectData = {
        url: SETUP_GOPLUS_PATHNAME,
      };
      dispatch(redirectToCVP(redirectData));
      return true;
    }
    return false;
  },
};

export function setProfileOption(option) {
  return (dispatch, getState) => {
    const isMobile = makeSelectIsMobile()(getState());
    dispatch({
      type: SET_PROFILE_OPTION,
      option,
    });

    let actionDone = false;
    if (CUSTOM_PROFILE_OPTION_ACTIONS[option]) {
      actionDone = dispatch(CUSTOM_PROFILE_OPTION_ACTIONS[option]);
    }

    const menuItem = menuItems.find((item) => item.path === option);

    if (!actionDone && (isMobile || !menuItem)) {
      browserHistory.push(option);
    } else if (browserHistory.location.pathname !== PROFILE_PATHNAME) {
      browserHistory.replace(PROFILE_PATHNAME);
    }
  };
}

export function loadUserProfile(profile) {
  return {
    type: PROFILE_LOADED,
    profile,
  };
}

export function userAccountLocked(isLockedForever) {
  return (dispatch, getState) => {
    const query = makeSelectQuery()(getState());
    const isUserLogged = makeSelectIsUserLogged()(getState());
    if (isUserLogged) {
      dispatch(logout(false, true));
    }
    const notification = ACCOUNT_LOCKED;

    if (isLockedForever) {
      notification.text.key =
        'This account is permanently blocked, please call CSC';
    }

    notification.text.values.push(
      getState().dynamicForm.formData.signInEmailAddress || ''
    );

    notification.buttons[0].event = () => {
      dispatch(
        sendAdobeAnalyticsEvent(
          adobeAnalyticTags.accountLockedTags.accountLockedClose,
          'tileClick'
        )
      );
      browserHistory.push(FORGOT_PASSWORD_PAGE);
      dispatch(setSpinner(false));
    };
    notification.closeAction = () => {
      if (query.service === LOYALTY_SERVICE) {
        dispatch(
          setLocation(
            `${query.redirect}${
              query.redirect.indexOf('?') === -1 ? '?' : '&'
            }action=logout`
          )
        );
      } else {
        dispatch(logout(true, true));
      }
    };
    browserHistory.push(LOGIN_PATHNAME);
    dispatch(setFormData({}));
    dispatch(showNotification(buildNotification({ ...notification })));
    dispatch(
      sendAdobeAnalyticsEvent(adobeAnalyticTags.accountLockedTags.accountLocked)
    );
  };
}

export function userToVerifyEmail({ email }, reset, notificationType) {
  return (dispatch, getState) => {
    const isiOS = makeSelectIsIOS()(getState());
    const notification = notificationType || NOTIFICATION_CHECK_EMAIL;
    const cvp = makeSelectCVP()(getState());
    const formPage = makeSelectFormPageName()(getState());

    if (formPage === REGISTER_PAGE) {
      const analyticsObject = adobeAnalyticTags[REGISTER_PAGE].VerifyEmail;
      analyticsObject.fakeId = generateHash(email);
      dispatch(sendAdobeAnalyticsEvent(analyticsObject));
    }

    notification.message.text.values = [email];
    notification.header.icon.event = () => {
      if (
        formPage === FORGOT_PASSWORD_PAGE &&
        [MOTORIST_FIREBLADE_AMERICA_CVP, MOTORIST_FIREBLADE_CVP].indexOf(
          cvp
        ) !== -1
      ) {
        dispatch(redirectToCVP());
      } else {
        if (reset) {
          dispatch({
            type: RESET_DATA,
          });
        }
        const step = 0;
        dispatch(setFormStep(step));
        browserHistory.push(LOGIN_PATHNAME);
      }
    };

    const form = makeSelectFormPage(formPage)(getState());
    const fakeId = generateHash(
      form.values.emailAddress || form.values.mobile?.replaceAll(' ', '')
    );

    if (isiOS) {
      notification.buttons[0].event = () => {
        const tag = {
          ...get(adobeAnalyticTags[formPage], 'openEmailAppCTA'),
        };
        if (tag) {
          tag.cta = lokaliseTags.SSO_GENERAL_OPEN_EMAIL_BUTTON;
          dispatch(sendAdobeAnalyticsEvent(tag, 'tileClick'));
        }
        dispatch(
          sendKochavaEvent(kochavaTags.register.confirmEmail, { fakeId })
        );
        window.location.href = 'message://';
      };
    } else {
      notification.buttons[0].event = () => {
        const tag = get(adobeAnalyticTags[formPage], 'openEmailAppCTA');
        if (tag) {
          tag.cta = lokaliseTags.SSO_GENERAL_CONTINUE;
          dispatch(sendAdobeAnalyticsEvent(tag, 'tileClick'));
        }
        dispatch(
          sendKochavaEvent(kochavaTags.register.confirmEmail, { fakeId })
        );

        if (
          formPage === FORGOT_PASSWORD_PAGE &&
          makeSelectCVP()(getState()) === MOTORIST_FIREBLADE_AMERICA_CVP
        ) {
          dispatch(redirectToCVP());
        } else {
          dispatch(setNotificationVisible(false));
        }
      };
      notification.buttons[0].label = lokaliseTags.SSO_GENERAL_CONTINUE;
    }
    dispatch(showNotification(notification));
  };
}

export function showLogoutPopup(navigate) {
  return (dispatch) => {
    dispatch(
      showPopup(
        buildPopup({
          titleStyle: { color: 'very dark grey' },
          title: lokaliseTags.SSO_PROFILE_SIGN_OUT_ALL_DEVICES,
          text: lokaliseTags.SSO_PROFILE_SIGN_OUT_MESSAGE,
          buttons: [
            {
              label: lokaliseTags.SSO_PROFILE_SIGN_OUT_ONE_DEVICE_BUTTON,
              outlined: true,
              event: () => {
                navigate(SEE_YOU_SOON_PATHNAME);
              },
            },
            {
              label: lokaliseTags.SSO_GENERAL_CANCEL_BUTTON,
              event: () => {
                dispatch(closePopup());
              },
            },
          ],
        })
      )
    );
  };
}

export default {
  refreshProfile,
  handleUserProfile,
  initializeProfile,
  startProfile,
  setProfileOption,
};
