import omit from 'lodash/omit';
import { Utf8Decode, getCountryFromMarket, hasToShowBirthdate } from '../utils';
import {
  getBirthDate,
  getGender,
  getDriveDataFromAppData,
  getPaymentsDataFromAppData,
} from './common-utils';
import { removeFormPropertyInOrder } from '../../features/dynamic-form/utils';
import { createUserInitialLoyaltyProfileFromCard } from './sol-utils';
import lokaliseTags from '../lokaliseTags';

const initialUserProfileData = {
  newPassword: null,
  consents: {
    loyaltyOptIn: false,
    driveOptIn: false,
    newsletters: false,
  },
  profile: {
    firstName: null,
    lastName: null,
    emailAddress: null,
    phoneNumber: null,
    gender: lokaliseTags.SSO_GENDER_UNDISCLOSED,
    generalUserId: 'no-guid-provided',
    udid: 'no-udid-provided',
    primaryAddress: {
      address1: null,
      address2: null,
      zip: null,
      city: null,
      houseNumber: null,
    },
  },
  market: {
    code: null,
    country: null,
  },
};

const initialLoyaltyData = {
  cardId: null,
  migrationOption: 'MIGRATE',
};

function getAddressAndHouseNumber(street = '') {
  const addressSpace = street.indexOf(' ');
  return {
    address: Utf8Decode(street.substr(addressSpace + 1)) || null,
    houseNumber: Utf8Decode(street.substr(0, addressSpace)),
  };
}

export function getLoyaltyDataFromAppData(profile, loyalty = {}) {
  const { fields, ...others } = loyalty;
  const data = {
    loyalty: {
      ...initialLoyaltyData,
      ...others,
    },
  };
  if (fields) {
    const { address, houseNumber } = getAddressAndHouseNumber(fields.street);
    data.profile = {
      ...profile,
      birthdate: profile.birthdate || getBirthDate(fields.date_of_birth),
      emailAddress: (profile.emailAddress || Utf8Decode(fields.email)).trim(),
      firstName: profile.firstName || Utf8Decode(fields.first),
      lastName: profile.lastName || Utf8Decode(fields.family),
      phoneNumber: profile.phoneNumber || Utf8Decode(fields.mphone),
      gender: getGender(profile.gender),
      primaryAddress: {
        ...profile.primaryAddress,
        address1: profile.primaryAddress.address1 || address,
        address2: profile.primaryAddress.address2 || Utf8Decode(fields.county),
        city: profile.primaryAddress.city || Utf8Decode(fields.city),
        zip: profile.primaryAddress.zip || Utf8Decode(fields.zip),
        houseNumber: profile.primaryAddress.houseNumber || houseNumber,
      },
    };
  }
  return data;
}

export function getLoyaltyDataFromSOLData(solData) {
  const { fields, dob } = solData;
  const hasNotificationsActivated =
    solData.general_marketing || solData.personal_marketing || false;
  const loyalty = {
    ...initialLoyaltyData,
    cardId: solData.card_id,
  };
  const { address, houseNumber } = getAddressAndHouseNumber(fields.street);
  return {
    consents: {
      loyaltyOptIn: hasNotificationsActivated,
      newsletters: hasNotificationsActivated,
    },
    profile: {
      birthdate: getBirthDate(fields.date_of_birth || dob),
      emailAddress: (Utf8Decode(fields.email) || '').trim(),
      firstName: Utf8Decode(fields.first),
      lastName: Utf8Decode(fields.family),
      phoneNumber: Utf8Decode(fields.mphone),
      gender: getGender(fields.gender),
      primaryAddress: {
        address1: address,
        address2: Utf8Decode(fields.county),
        city: Utf8Decode(fields.city),
        zip: Utf8Decode(fields.zip),
        houseNumber,
      },
    },
    loyalty,
  };
}

export function getLoyaltyAttachDataFromSOLData(solData) {
  const { address, houseNumber } = getAddressAndHouseNumber(
    solData.fields.street
  );
  return {
    address1: address,
    address2: Utf8Decode(solData.fields.county),
    birthdate: solData.dob,
    cardId: solData.card_id,
    city: Utf8Decode(solData.fields.city),
    zip: Utf8Decode(solData.fields.zip),
    houseNumber,
    gender: getGender(solData.fields.gender),
  };
}

export function createUserProfileFromAppData(user, udid, guid) {
  const data = {
    ...initialUserProfileData,
    profile: {
      ...initialUserProfileData.profile,
      ...user.profile,
      primaryAddress: {
        ...initialUserProfileData.profile.primaryAddress,
        ...user.profile.primaryAddress,
      },
      gender: getGender(user.profile.gender),
      generalUserId:
        guid ||
        user.profile.generalUserId ||
        initialUserProfileData.profile.generalUserId,
      udid: udid || user.profile.udid || initialUserProfileData.profile.udid,
    },
    market: {
      ...initialUserProfileData.market,
      ...user.market,
    },
    consents: {
      ...initialUserProfileData.consents,
      ...user.consents,
    },
  };
  // order matters here, because we need to check and add data following this order
  // profile > payments > drive > loyalty
  if (user.loyalty) {
    const loyaltyData = getLoyaltyDataFromAppData(data.profile, user.loyalty);
    data.profile = {
      ...loyaltyData.profile,
    };
    data.loyalty = {
      ...loyaltyData.loyalty,
    };
  }
  if (user.drive) {
    const driveData = getDriveDataFromAppData(
      data.profile,
      user.drive,
      user.myGarage
    );
    data.profile = {
      ...driveData.profile,
    };
    data.drive = {
      ...driveData.drive,
    };
    data.myGarage = {
      ...driveData.myGarage,
    };
  }
  if (user.payments) {
    const paymentsData = getPaymentsDataFromAppData(
      data.profile,
      user.payments
    );
    data.profile = {
      ...paymentsData.profile,
    };
    data.payments = {
      ...paymentsData.payments,
    };
  }
  return data;
}

export function createUserProfileFromSOLData(solData, market) {
  const { consents, ...others } = getLoyaltyDataFromSOLData(solData);
  return {
    ...initialUserProfileData,
    ...others,
    market: {
      code: market,
      country: getCountryFromMarket(market),
    },
    consents: {
      ...initialUserProfileData.consents,
      ...consents,
    },
  };
}

export function cleanMigrationFormData(migrationData) {
  const data = {
    ...omit(migrationData, [
      'noSetStates',
      'submitCall',
      'mode',
      'missingFields',
      'loaded',
      'page',
      'focus',
      'step',
      'emailAddress',
      'profile',
    ]),
    profile: {
      ...migrationData.profile,
      generalUserId: migrationData.profile.generalUserId || 'no-guid-provided',
      udid: migrationData.profile.udid || 'no-udid-provided',
      emailAddress: (
        migrationData.emailAddress ||
        migrationData.profile.emailAddress ||
        ''
      )
        .trim()
        .toLowerCase(),
    },
  };
  return data.profile.gender ? data : omit(data, ['profile.gender']);
}

export function cleanLodUser(user) {
  const cleanedAddress = Object.keys(user.address).reduce((acc, field) => {
    if (['N/A', 'NA'].indexOf(user.address[field]) !== -1) {
      acc[field] = null;
    } else {
      acc[field] = user.address[field];
    }
    return acc;
  }, {});

  return { ...user, address: cleanedAddress };
}

export function createUserProfileFromLoyaltyInitialFormData({
  cardId,
  cardType,
  migrationOption,
  vehicleRegistrationNumber,
  address1,
  address2,
  zip,
  city,
  houseNumber,
  ...others
}) {
  return {
    loyalty: {
      ...createUserInitialLoyaltyProfileFromCard({
        cardId,
        cardType,
        migrationOption,
      }),
      vehicleRegistrationNumber,
    },
    profile: {
      ...others,
      primaryAddress: {
        address1,
        address2,
        zip,
        city,
        houseNumber,
      },
    },
  };
}

export function createUserProfileFormData(form, userProfile) {
  const showBirthdate = hasToShowBirthdate(userProfile);
  return {
    form: showBirthdate ? form : removeFormPropertyInOrder(form, 'birthdate'),
    user: {
      ...userProfile.profile,
      generalUserId: userProfile.profile.generalUserId || 'no-guid-provided',
      udid: userProfile.profile.udid || 'no-udid-provided',
      vehicleRegistrationNumber: userProfile.loyalty.vehicleRegistrationNumber,
    },
    errors: {},
  };
}

export function getCardIdRegex() {
  return '^700405[0-9]{13}$';
}
