import React from 'react';
import { Router } from 'react-router-dom';
import history from './history';

// Custom router created so we can still use our history on react router version 6
// as well as the history.push events instead of using the navigate hook from react router

const CustomRouter = ({
  basename,
  children,
}) => {
  const [state, setState] = React.useState({
    action: history.action,
    location: history.location,
  });

  React.useLayoutEffect(() => history.listen(setState), []);

  return (
    <Router
      basename={basename}
      children={children}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  );
};

export default CustomRouter;