import { SET_EMAIL_TO_VERIFY, SET_IS_REGISTER_FLOW } from './constants';

export const initialState = {
  emailToVerify: '',
  isRegisterFlow: true,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_EMAIL_TO_VERIFY:
      return {
        ...state,
        emailToVerify: payload,
      };
    case SET_IS_REGISTER_FLOW:
      return {
        ...state,
        isRegisterFlow: payload,
      };
    default:
      return state;
  }
};
