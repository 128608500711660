// This file contains all Motorist App pathnames aka deeplinks
export const UPDATE_PROFILE_APP_PATHNAME = 'sso://update_profile';
export const LOGOUT_APP_PATHNAME = 'sso://logout';
export const SETUP_DRIVE_APP_PATHNAME = 'sso://setup_drive';
export const EXIT_APP_PATHNAME = 'sso://exit';
export const EXIT_APP_PROFILE = 'sso://exit_profile';
export const HIDE_NAVIGATION_APP_PATHNAME = 'sso://hide_navigation';
export const WIDGET_READY_APP_PATHNAME = 'sso://widget_ready';
export const SHOW_NAVIGATION_APP_PATHNAME = 'sso://show_navigation';
export const STATION_LOCATOR_APP_PATHNAME = 'sso://station_locator';
export const VERIFY_MIGRATION_APP_PATHNAME = 'sso://verify_migration';
export const SETUP_LOYALTY_APP_PATHNAME = 'sso://setup_loyalty';
export const PAYMENTS_SETTINGS_APP_PATHNAME = 'sso://payments_settings';
export const SETUP_PAYMENTS_APP_PATHNAME = 'sso://setup_payments';
export const SDA_MIGRATION_APP_PATHNAME = 'sso://sda_migration';
export const LOYALTY_ATTACHED_APP_PATHNAME = 'sso://loyalty_attached';
export const ERROR_APP_PATHNAME = 'sso://error';
export const DELETE_ACCOUNT_APP_PATHNAME = 'sso://delete_account';
export const LOYALTY_ACTIVATED_APP_PATHNAME = 'sso://loyalty_activated';
export const SDA_LOGIN_APP_PATHNAME = 'sso://sda_login';
export const REGISTRATION_READY_PATHNAME = 'sso://registration_ready';
export const LOD_ATTACHED_APP_PATHNAME = 'sso://lod_attached';
export const SETUP_GOPLUS_PATHNAME = 'sso://setup_goplus';
export const REGISTRATION_MOBILE_PATHNAME = 'sso://registration_mobile';
export const USER_REGISTRATION = 'sso://user_registration';
export const LOGIN_READY_APP_PATHNAME = 'sso://login_ready';
export const SAVE_CREDENTIALS_PATHNAME = 'sso://save_credentials';
export const UPDATE_CREDENTIALS_PATHNAME = 'sso://update_credentials';
export const OPEN_HELP_MFA_APP = 'sso://help_mfa';
export const SUCCESS_APP_PATHNAME = 'sso://success';
export const TWOFA_DISABLE_PATHNAME = 'sso://2fa_disable';
export const AIRMILES_NOT_SYNCHRO = 'sso://airmiles_not_synchro';
