/* eslint-disable no-underscore-dangle */
import { Api } from '@shell-b2c/http-frontend/dist/src';
import {
  makeSelectQuery,
  makeSelectIsMotoristApp,
} from '../../../features/app/selectors';
import { refreshToken } from '@shell-b2c/http-frontend/dist/src/components/core/auth';
import {
  setDataToStore,
  deleteAllStorageData,
} from '../../../features/app/utils';
import {
  setQuery,
  setSendTokens,
  authorizationFailedNotification,
  noBackendNotification,
  redirectToCVP,
} from '../../../features/app/actions';
import { userAccountLocked } from '../../../features/profile/actions';
import { logout } from '../../../features/logout/actions';
import {
  ACCESS_CODE_URL,
  TWOFA_SEND,
} from '@shell-b2c/http-frontend/dist/src/components/core/endpoints';
import { showDefaultErrorNotification } from '../../../features/notification/actions';
import { makeSelectIsPhoneNumberFormActive } from '../../../features/auth/selectors';

const NETWORK_ERROR = 'Network Error';

function retryCall(error, data) {
  const { config } = error;

  if (data) {
    config.data = data;
  }

  if (!config) return Promise.reject(error);

  const endpoint = config.url.replace(`${config.baseURL}/`, '');

  return Api.axiosInstance({
    ...config,
    url: endpoint,
  });
}

export function isProfileVerified(isPhoneNumberMarket, profile) {
  const isPhoneMarketAndNotVerified =
    isPhoneNumberMarket &&
    profile.hasOwnProperty('mobileVerified') &&
    !profile.mobileVerified;
  const isMailMarketAndNotVerified =
    !isPhoneNumberMarket &&
    profile.hasOwnProperty('emailVerified') &&
    !profile.emailVerified;

  return !(isPhoneMarketAndNotVerified || isMailMarketAndNotVerified);
}

export default function ({ dispatch, getState }) {
  Api.axiosInstance.interceptors.response.use(
    (response) => {
      const { accessToken, profile } = response;

      // If the response have a profile and an accessToken then it is
      // a successfull login or exchangeAccessCode so we can validate if
      // the account is verified
      if (profile && accessToken) {
        const isPhoneNumberMarket = makeSelectIsPhoneNumberFormActive()(
          getState()
        );

        if (!isProfileVerified(isPhoneNumberMarket, profile)) {
          dispatch(logout());
        }
      }

      return response;
    },
    (error) => {
      const query = makeSelectQuery()(getState());
      const isMotoristApp = makeSelectIsMotoristApp()(getState());

      if (error === NETWORK_ERROR) {
        dispatch(
          showDefaultErrorNotification(() => {
            if (isMotoristApp || query.redirect) {
              dispatch(redirectToCVP());
            }
          })
        );
        return Promise.reject(error);
      }

      // Check account locked
      if (error.accountLocked()) {
        dispatch(userAccountLocked(error.isLockedForever()));
        if (query.lock) {
          deleteAllStorageData();
        }
        return Promise.reject(error);
      }

      if (error.invalidApiToken()) {
        dispatch(authorizationFailedNotification());
      }

      // Check if the Bearer {accessToken} is invalid
      if (error.invalidAccessToken()) {
        if (!query.accessToken || !query.refreshToken) {
          return Promise.reject(error);
        }
        return refreshToken({ refreshTk: query.refreshToken })
          .then((refresh) => {
            const newTokens = {
              accessToken: refresh.accessToken,
              refreshToken: refresh.refreshToken,
            };
            Api.accessToken = refresh.accessToken;
            dispatch(setQuery(newTokens));
            setDataToStore(
              'query',
              JSON.stringify(makeSelectQuery()(getState()))
            );
            setDataToStore('auth', JSON.stringify(newTokens), true);
            if (makeSelectIsMotoristApp()(getState())) {
              dispatch(setSendTokens(true));
            }

            if (
              error.url.indexOf(ACCESS_CODE_URL) !== -1 ||
              error.url.indexOf(TWOFA_SEND) !== -1
            ) {
              const newData =
                error.config.data && JSON.parse(error.config.data);
              if (newData?.accessToken) {
                newData.accessToken = Api.accessToken;
              } else if (newData?.access_token) {
                newData.access_token = Api.accessToken;
              }
              return retryCall(error, JSON.stringify(newData));
            }
            return retryCall(error);
          })
          .catch((refreshTokenError) => {
            if (refreshTokenError.invalidRefreshToken()) {
              dispatch(logout());
            }
            return Promise.reject(refreshTokenError);
          });
      }

      // Check if service unavailable
      if (error.serviceUnavailable()) {
        dispatch(noBackendNotification());
      }

      return Promise.reject(error);
    }
  );

  return (next) => (action) => next(action);
}
