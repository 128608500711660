import { createSelector } from 'reselect';
import { makeSelectIsGoPlusActivatedInSettings } from '../../settings/selectors';
import { makeSelectIsGoPlusActivated } from '../../profile/selectors';
import {
  makeSelectIsUserLogged,
  makeSelectIsGoPlusCVP,
} from '../../app/selectors';

const makeSelectIsGoPlusServiceAccessFlow = () =>
  createSelector(
    makeSelectIsGoPlusActivatedInSettings(),
    makeSelectIsGoPlusActivated(),
    makeSelectIsUserLogged(),
    (isGoPlusActivatedInSettings, isGoPlusActivatedInProfile, isUserLogged) =>
      isGoPlusActivatedInSettings && !isGoPlusActivatedInProfile && isUserLogged
  );

const makeSelectIsGoPlusSetupAccessFlow = () =>
  createSelector(
    makeSelectIsGoPlusActivatedInSettings(),
    makeSelectIsGoPlusCVP(),
    (isGoPlusActivatedInSettings, isGoPlusCVP) =>
      isGoPlusActivatedInSettings && isGoPlusCVP
  );

const makeSelectIsGoPlusFlow = () =>
  createSelector(
    makeSelectIsGoPlusServiceAccessFlow(),
    makeSelectIsGoPlusSetupAccessFlow(),
    (isServiceAccessFlow, isSetupAccessFlow) =>
      isServiceAccessFlow || isSetupAccessFlow
  );

export {
  makeSelectIsGoPlusServiceAccessFlow,
  makeSelectIsGoPlusSetupAccessFlow,
  makeSelectIsGoPlusFlow,
};
