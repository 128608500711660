import { REGISTER_PAGE } from '../../shared/formPages';
import { makeSelectFormPageName } from '../dynamic-form/selectors';
import { makeSelectIsPPFlow } from '../progressive-profiling/selectors';
import { createUserInSSO } from '../register/actions';
import { editUserFromPP } from './actions';

export function createUser(accepted = true, consents = {}) {
  return (dispatch, getState) => {
    const fromPP = makeSelectIsPPFlow()(getState());
    const formPage = makeSelectFormPageName()(getState());
    if (formPage === REGISTER_PAGE) {
      dispatch(createUserInSSO(consents));
    } else if (fromPP) {
      dispatch(editUserFromPP(accepted));
    }
  };
}
