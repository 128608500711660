import {
  getClientConfigScopes,
  putScopesToProfile,
} from '@shell-b2c/http-frontend/dist/src/components/core/scopes';
import { accessCode as getAccessCode } from '@shell-b2c/http-frontend/dist/src/components/core/auth';
import {
  redirectToCVP,
  setQuery,
  setSpinner,
  sendAdobeAnalyticsEvent,
} from '../../app/actions';
import {
  makeSelectQueryClientId,
  makeSelectQueryScopes,
} from '../../app/selectors';
import {
  showDefaultErrorNotification,
  showNotification,
} from '../../notification/actions';
import { closePopup, showPopup } from '../../popup/actions';
import { buildPopup } from '../../popup/utils';
import {
  SET_ALREADY_ACCEPTED_SCOPES,
  SET_SCOPES_CONFIG,
  SET_SCOPES_ERRORS,
} from '../reducer';
import {
  selectIsFromRegistration,
  selectScopesConfigDomain,
} from '../selectors';
import lokaliseTags from '../../../shared/lokaliseTags';
import adobeAnalyticTags from '../../../shared/adobeAnalyticTags';
import { getStoredData, setDataToStore } from '../../app/utils';
import {
  LOGIN_PATHNAME,
  SCOPES_ACCEPTED_SCREEN,
} from '../../../shared/pathnames';
import browserHistory from '../../../router/history';
import ConeAnimation from '../../../shared/components/ConeAnimation';
import { buildNotification } from '../../notification/utils';
import { makeSelectIsRegisterFlow } from '../../verify-email/selectors';
import { makeSelectPhoneAuthentication } from '../../settings/selectors';
import {
  ACTIVATE_ACCOUNT_SUCCESS,
  showActivationNotification,
} from '../../account-activation/actions';
import { setIsRegisterFlow } from '../../verify-email/actions';

export const initialize = (setNonAcceptedScopes) => {
  return async (dispatch, getState) => {
    const clientId = makeSelectQueryClientId()(getState());
    const { scopesErrors, alreadyAcceptedScopes } = selectScopesConfigDomain()(
      getState()
    );

    try {
      const scopesConfig = await getClientConfigScopes(clientId);
      setDataToStore('scopesConfig', JSON.stringify(scopesConfig), true);
      dispatch(setScopesConfig(scopesConfig));
      const isFromRegistration = selectIsFromRegistration()(getState());
      const queryScopes = makeSelectQueryScopes()(getState());
      let nonAcceptedScopes;
      if (isFromRegistration) {
        nonAcceptedScopes = scopesConfig.scopes.filter((scope) => {
          for (let i = 0; i < queryScopes.length; i++) {
            const queryScope = queryScopes[i];
            const scopeValues = queryScope.split(':');
            if (
              scope.action === scopeValues[0] &&
              scope.name === scopeValues[1]
            ) {
              return true;
            }
          }
          return false;
        });
      } else {
        const { missingScopes } =
          scopesErrors.missingScopes?.length > 0
            ? scopesErrors
            : getStoredData('scopesError', true);
        nonAcceptedScopes = missingScopes
          .map((missingScope) => {
            return scopesConfig.scopes.filter(
              (scope) => scope.name === missingScope.name
            )[0];
          })
          .filter((el) => el !== undefined);
      }

      setNonAcceptedScopes(nonAcceptedScopes);

      const newAnalyticsData = {
        partnerScopesRequested: nonAcceptedScopes,
        partnerScopesAlreadyAllowed: alreadyAcceptedScopes,
      };
      dispatch(
        sendAdobeAnalyticsEvent({
          ...adobeAnalyticTags.scopesAuthFlow.pageLoad,
          ...newAnalyticsData,
        })
      );
    } catch (error) {
      dispatch(showDefaultErrorNotification());
    }
  };
};

const setScopesConfig = (scopesConfig) => {
  return {
    type: SET_SCOPES_CONFIG,
    scopesConfig,
  };
};

export const setAcceptedScopes = (acceptedScopes) => {
  return {
    type: SET_ALREADY_ACCEPTED_SCOPES,
    acceptedScopes,
  };
};

export const setScopesErrors = (scopesErrors) => {
  return { type: SET_SCOPES_ERRORS, scopesErrors };
};

export const showDeclinePopUp = (nonAcceptedScopes) => {
  return (dispatch) => {
    dispatch(
      showPopup(
        buildPopup({
          title: lokaliseTags.SCOPES_PERMISSIONS_PROMPT_TITLE,
          text: lokaliseTags.SCOPES_PERMISSIONS_PROMPT_BODY,
          options: {
            inlineButtons: true,
            vertical: false,
          },
          buttons: [
            {
              label: lokaliseTags.SCOPES_PERMISSIONS_PROMPT_DECLINE_BUTTON,
              outlined: true,
              event: () => {
                const redirectData = {
                  params: [
                    { param: 'status', value: 'declined_scope' },
                    { param: 'declined', value: 'read:profile-write:offer' },
                  ],
                };
                const newAnalyticsData = {
                  partnerScopesRequested: nonAcceptedScopes,
                };
                dispatch(
                  sendAdobeAnalyticsEvent(
                    {
                      ...adobeAnalyticTags.scopesAuthFlow.declineScopes,
                      ...newAnalyticsData,
                    },
                    'tileClick'
                  )
                );
                dispatch(setSpinner(true));
                return dispatch(redirectToCVP(redirectData));
              },
            },
            {
              label: lokaliseTags.SCOPES_PERMISSIONS_PROMPT_CANCEL_BUTTON,
              event: () => {
                dispatch(closePopup());
              },
            },
          ],
        })
      )
    );
  };
};
export const showAlreadyAcceptedScopes = () => {
  return async (dispatch) => {
    dispatch(
      sendAdobeAnalyticsEvent(
        adobeAnalyticTags.scopesAuthFlow.showAlreadyAcceptedScopes,
        'tileClick'
      )
    );
    browserHistory.push(`${SCOPES_ACCEPTED_SCREEN}`);
  };
};
export const acceptScopes = (acceptedScopes, kochava) => {
  return async (dispatch, getState) => {
    const newAnalyticsData = {
      partnerScopesRequested: acceptedScopes,
    };
    dispatch(
      sendAdobeAnalyticsEvent(
        {
          ...adobeAnalyticTags.scopesAuthFlow.acceptScopes,
          ...newAnalyticsData,
        },
        'tileClick'
      )
    );
    dispatch(
      sendAdobeAnalyticsEvent(
        adobeAnalyticTags.scopesAuthFlow.acceptScopes,
        'tileClick'
      )
    );
    const { scopesErrors } = selectScopesConfigDomain()(getState());
    let { otpToken } = scopesErrors;
    if (!otpToken) {
      const query = getState().app.query;
      otpToken = query.otp_token;
    }
    const acceptedScopesData = acceptedScopes.map(
      ({ scope_id, name, action }) => ({
        scope_id,
        scope: name,
        action,
      })
    );

    try {
      const scopesConfig = {
        otp_token: otpToken,
        scopes: acceptedScopesData,
      };
      const { accessToken } = await putScopesToProfile(scopesConfig, {
        noSpinner: true,
      });
      await dispatch(completeScopesAuthFlow({ kochava, accessToken }));
    } catch (err) {
      if (err.otpTokenIsExpired()) {
        const notification = {
          animation: ConeAnimation,
          image: {},
          closeAction: () => {
            dispatch(setSpinner(true));
            browserHistory.push(LOGIN_PATHNAME);
          },
          title: lokaliseTags.SCOPES_ERROR_OTP_EXPIRED_TITLE,
          text: lokaliseTags.SCOPES_ERROR_OTP_EXPIRED_BODY,
          buttons: [
            {
              label: lokaliseTags.TWOFA_ACTIVATION_BUTTON_DECLINE,
              event: () => {
                dispatch(setSpinner(true));
                browserHistory.push(LOGIN_PATHNAME);
              },
              testId: 'skip-button',
            },
          ],
        };
        return dispatch(showNotification(buildNotification(notification)));
      }
      return dispatch(showDefaultErrorNotification());
    }
  };
};

export const completeScopesAuthFlow = ({ kochava, accessToken = false }) => {
  return async (dispatch, getState) => {
    const registerMobileFlow = makeSelectIsRegisterFlow()(getState());
    const isPhoneAuth = makeSelectPhoneAuthentication()(getState());
    const isFromRegistration = selectIsFromRegistration()(getState());
    if (
      (registerMobileFlow === 'scopes' && isPhoneAuth) ||
      isFromRegistration
    ) {
      if (accessToken) {
        const { accessCode } = await getAccessCode({ accessToken });
        dispatch(setQuery({ accessCode, from: 'scopesAuth' }));
      }

      dispatch(setIsRegisterFlow(false));
      return dispatch(
        showActivationNotification(ACTIVATE_ACCOUNT_SUCCESS, kochava)
      );
    }
    dispatch(setSpinner(true));
    return dispatch(redirectToCVP());
  };
};
