import lokaliseTags from '../../shared/lokaliseTags';

export const DEFAULT_HEADER = {
  title: {
    mobile: lokaliseTags.SSO_SETTINGS_SETTINGS_LABEL,
    desktop: lokaliseTags.SSO_SETTINGS_SETTINGS_LABEL,
  },
  menu: {
    leftMenu: {
      name: lokaliseTags.SSO_GENERAL_CLOSE,
      icon: 'close',
      mobile: true,
      desktop: false,
    },
  },
};

export const PRIVACY_POLICY_HEADER = {
  title: {
    mobile: lokaliseTags.SSO_SETTINGS_PRIVACY_POLICY,
    desktop: lokaliseTags.SSO_SETTINGS_PRIVACY_POLICY,
  },
  menu: {
    leftMenu: {
      name: lokaliseTags.SSO_GENERAL_BACK,
      icon: 'back',
      mobile: true,
      desktop: true,
    },
  },
};
export const TERMS_CONDITIONS_HEADER = {
  title: {
    mobile: lokaliseTags.SSO_SETTINGS_TERMS_CONDITIONS,
    desktop: lokaliseTags.SSO_SETTINGS_TERMS_CONDITIONS,
  },
  menu: {
    leftMenu: {
      name: lokaliseTags.SSO_GENERAL_BACK,
      icon: 'back',
      mobile: true,
      desktop: true,
    },
  },
};
export const DELETE_ACCOUNT_HEADER = {
  title: {
    mobile: lokaliseTags.SSO_SETTINGS_DELETE_MY_ACCOUNT_LABEL,
    desktop: lokaliseTags.SSO_SETTINGS_DELETE_MY_ACCOUNT_LABEL,
  },
  menu: {
    leftMenu: {
      name: lokaliseTags.SSO_GENERAL_BACK,
      icon: 'back',
      mobile: true,
      desktop: true,
    },
  },
};
export const DELETE_ACCOUNT_NOW_HEADER = {
  title: {
    mobile: lokaliseTags.SSO_SETTINGS_DELETE_MY_ACCOUNT_LABEL,
    desktop: lokaliseTags.SSO_SETTINGS_DELETE_MY_ACCOUNT_LABEL,
  },
  menu: {
    leftMenu: {
      name: lokaliseTags.SSO_GENERAL_CLOSE,
      icon: 'close',
      mobile: true,
      desktop: false,
    },
  },
};
