import { NUMBERS } from '../../../shared/constants';
import lokaliseTags from '../../../shared/lokaliseTags';

export const DEFAULT_HEADER = {
  title: {
    mobile: lokaliseTags.SSO_PROFILE_SHELL_LOYALTY_HEADER,
    desktop: lokaliseTags.SSO_PROFILE_SHELL_LOYALTY_HEADER,
  },
  menu: {
    leftMenu: {
      name: lokaliseTags.SSO_GENERAL_CLOSE,
      icon: 'close',
      mobile: true,
      desktop: true,
    },
  },
};
export const WIZARD_HEADER = {
  title: {
    mobile: lokaliseTags.SSO_PROFILE_SHELL_LOYALTY_HEADER,
    desktop: lokaliseTags.SSO_PROFILE_SHELL_LOYALTY_HEADER,
  },
  subtitle: {
    mobile: {
      preText: {
        key: lokaliseTags.SSO_REGISTER_HEADER_STEP_LABEL,
        values: [NUMBERS.TWO, NUMBERS.FOUR],
      },
      text: '',
      url: '',
      afterText: '',
    },
    desktop: {
      preText: {
        key: lokaliseTags.SSO_REGISTER_HEADER_STEP_LABEL,
        values: [NUMBERS.TWO, NUMBERS.THREE],
      },
      text: '',
      url: '',
      afterText: '',
    },
  },
  menu: {
    leftMenu: {
      name: lokaliseTags.SSO_GENERAL_BACK,
      icon: 'back',
      mobile: true,
      desktop: true,
    },
  },
};
export const ATTACH_CARD_HEADER = {
  title: {
    mobile: lokaliseTags.SSO_LOYALTY_CONNECT,
    desktop: lokaliseTags.SSO_LOYALTY_CONNECT,
  },
  subtitle: {
    mobile: {
      preText: lokaliseTags.SSO_LOYALTY_SHELL_DRIVERS_CLUB,
      text: '',
      url: '',
      afterText: '',
    },
    desktop: {
      preText: lokaliseTags.SSO_LOYALTY_SHELL_DRIVERS_CLUB,
      text: '',
      url: '',
      afterText: '',
    },
  },
  menu: {
    leftMenu: {
      name: lokaliseTags.SSO_GENERAL_BACK,
      icon: 'back',
      mobile: true,
      desktop: true,
    },
  },
};
export const ATTACH_CARD_HEADER_ADDRESS = {
  title: {
    mobile: lokaliseTags.SSO_LOYALTY_COMPLETE_ADDRESS,
    desktop: lokaliseTags.SSO_LOYALTY_COMPLETE_ADDRESS,
  },
  subtitle: {
    mobile: {
      preText: lokaliseTags.SSO_LOYALTY_SHELL_DRIVERS_CLUB,
      text: '',
      url: '',
      afterText: '',
    },
    desktop: {
      preText: lokaliseTags.SSO_LOYALTY_SHELL_DRIVERS_CLUB,
      text: '',
      url: '',
      afterText: '',
    },
  },
  menu: {
    leftMenu: {
      name: lokaliseTags.SSO_GENERAL_BACK,
      icon: 'back',
      mobile: true,
      desktop: true,
    },
  },
};
