import lokaliseTags from '../../../shared/lokaliseTags';

export const CLOSE_HEADER = {
  title: {
    mobile: lokaliseTags.SSO_RESET_PASSWORD_RESET_LABEL,
    desktop: lokaliseTags.SSO_RESET_PASSWORD_RESET_LABEL,
  },
  menu: {
    leftMenu: {
      event: null,
      name: lokaliseTags.SSO_GENERAL_CLOSE,
      icon: 'close',
      mobile: true,
      desktop: false,
    },
  },
};
