import { SEND_UID } from '../../app/constants';
import { CHANGE_VALUE, CARD_STATION } from './constants';

export const initialState = {
  data: {
    cardId: '',
    birthdate: '',
    address1: '',
    address2: '',
    city: '',
    houseNumber: '',
    zipCode: '',
  },
  migrationLoaded: false,
  migration: {
    user: {
      solEmail: '',
      solPassword: '',
    },
    form: {
      properties: [
        {
          label: 'email',
          name: 'solEmailAddress',
          type: 'text',
          required: true,
          validations: [
            {
              message: 'email_is_required',
              value: true,
              rule: 'required',
            },
          ],
        },
        {
          label: 'password',
          name: 'solPassword',
          type: 'password',
          required: true,
          validations: [
            {
              message: 'password_is_required',
              value: true,
              rule: 'required',
            },
          ],
        },
      ],
      order: {
        mobile: [['solEmailAddress', 'solPassword']],
        desktop: ['solEmailAddress', 'solPassword'],
      },
    },
  },
  loyaltyInitial: {},
  changeCardIframe: false,
  cardStation: {},
  showCSC: 0,
  loyaltyChangeCardId: false,
  goToAddress: false,
  address: false,
  sendUid: false,
};

const sol = (state = initialState, action) => {
  const { name, value } = action;

  switch (action.type) {
    case CHANGE_VALUE:
      return {
        ...state,
        [name]: value,
      };
    case CARD_STATION:
      return {
        ...state,
        cardStation: value,
      };
    case SEND_UID:
      return {
        ...state,
        sendUid: true,
      };
    default:
      return state;
  }
};

export default sol;
