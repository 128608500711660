import lokaliseTags from '../../shared/lokaliseTags';

export const DEFAULT_HEADER = {
  title: {
    mobile: lokaliseTags.SSO_OVERVIEW_CREATE_AN_ACCOUNT_LABEL,
    desktop: lokaliseTags.SSO_OVERVIEW_CREATE_AN_ACCOUNT_LABEL,
  },
  subtitle: {
    desktop: {
      preText: lokaliseTags.SSO_OVERVIEW_OR_LABEL,
      text: lokaliseTags.SSO_OVERVIEW_SIGN_IN_LABEL,
      url: 'login',
      afterText: '',
    },
  },
  menu: {
    leftMenu: {
      name: lokaliseTags.SSO_GENERAL_BACK,
      icon: 'back',
      mobile: true,
      desktop: false,
    },
  },
};
export const WIZARD_HEADER = {
  title: {
    mobile: lokaliseTags.SSO_OVERVIEW_CREATE_AN_ACCOUNT_LABEL,
    desktop: lokaliseTags.SSO_OVERVIEW_CREATE_AN_ACCOUNT_LABEL,
  },
  subtitle: {
    mobile: {
      preText: {
        key: lokaliseTags.SSO_REGISTER_HEADER_STEP_LABEL,
        values: [],
      },
      text: '',
      url: '',
      afterText: '',
    },
    desktop: {
      preText: lokaliseTags.SSO_OVERVIEW_OR_LABEL,
      text: lokaliseTags.SSO_OVERVIEW_SIGN_IN_LABEL,
      url: 'login',
      afterText: '',
    },
  },
  menu: {
    leftMenu: {
      name: lokaliseTags.SSO_GENERAL_BACK,
      icon: 'back',
      mobile: true,
      desktop: false,
    },
  },
};
export const OVERVIEW_HEADER = {
  title: {
    mobile: lokaliseTags.SSO_OVERVIEW_CREATE_AN_ACCOUNT_LABEL,
    desktop: lokaliseTags.SSO_OVERVIEW_CREATE_AN_ACCOUNT_LABEL,
  },
  subtitle: {
    mobile: {
      preText: lokaliseTags.SSO_REGISTER_REVIEW_SUBMIT_LABEL,
      text: '',
      url: '',
      afterText: '',
    },
    desktop: {
      preText: lokaliseTags.SSO_OVERVIEW_OR_LABEL,
      text: lokaliseTags.SSO_OVERVIEW_SIGN_IN_LABEL,
      url: 'login',
      afterText: '',
    },
  },
  menu: {
    leftMenu: {
      name: lokaliseTags.SSO_GENERAL_CLOSE,
      icon: 'close',
      mobile: true,
      desktop: false,
    },
  },
};
