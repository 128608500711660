import { createSelector } from 'reselect';
import {
  makeSelectEmailAuthentication,
  makeSelectPhoneAuthentication,
  makeSelectDefaultAuthentication,
} from '../settings/selectors';

const selectAuthDomain = () => (state) => state.auth;

const makeSelectShowChangeAuth = () =>
  createSelector(
    makeSelectIsPhoneNumberFormActive(),
    makeSelectEmailAuthentication(),
    makeSelectPhoneAuthentication(),
    (isPhoneNumberActive, isEmailAuthActive, isPhoneNumberAuthActive) =>
      (isPhoneNumberActive && isEmailAuthActive) ||
      (!isPhoneNumberActive && isPhoneNumberAuthActive)
  );

const makeSelectIsPhoneNumberFormActive = () =>
  createSelector(
    selectAuthDomain(),
    makeSelectDefaultAuthentication(),
    ({ phoneNumberAuth }, defaultMethod) => {
      if (phoneNumberAuth === null) {
        if (defaultMethod === 'phone') {
          return true;
        }
        return false;
      }
      return phoneNumberAuth;
    }
  );

export {
  selectAuthDomain,
  makeSelectShowChangeAuth,
  makeSelectIsPhoneNumberFormActive,
};
