import {
  responsiveStoreEnhancer,
  createResponsiveStateReducer,
} from 'redux-responsive';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import logger from 'redux-logger';
import errorMiddleware from '../http/widget/interceptors/errorInterceptor';
import spinnerMiddleware from '../http/widget/interceptors/spinnerInterceptor';
import { ENV } from '../shared/constants';
import app from '../features/app/reducer';
import notification from '../features/notification/reducer';
import popup from '../features/popup/reducer';
import login from '../features/login/reducer';
import register from '../features/register/reducer';
import dynamicForm from '../features/dynamic-form/reducer';
import profile from '../features/profile/reducer';
import settings from '../features/settings/reducer';
import editProfile from '../features/edit-profile/reducer';
import loyalty from '../features/sol/shared/reducer';
import loyaltyAttach from '../features/sol/attach/registered/reducer';
import loyaltyNew from '../features/sol/attach/personalized-or-not/reducer';
import migration from '../features/sol/migration/email/reducer';
import solMigration from '../features/sol/migration/card-number/reducer';
import solForgotPassword from '../features/sol/forgot-password/reducer';
import security from '../features/secure-profile/reducer';
import resetPassword from '../features/reset-password/reducer';
import forgotPassword from '../features/forgot-password/reducer';
import verifyEmailChange from '../features/verify-email-change/reducer';
import verifyMobileChange from '../features/verify-mobile-change/reducer';
import verifyEmail from '../features/verify-email/reducer';
import lodProfile from '../features/goplus/lod-profile/reducer';
import auth from '../features/auth/reducer';
import verificationCode from '../features/verification-code/reducer';
import loyaltyFuturesSignIn from '../features/loyalty-futures/sign-in/reducer';
import loyaltyFuturesMigration from '../features/loyalty-futures/migration/reducer';
import loyaltyFuturesAttach from '../features/loyalty-futures/attach/reducer';
import sapHybrisAttach from '../features/sap-hybris/attach/reducer';
import mfa from '../features/mfa/reducer';
import scopes from '../features/scopes/reducer';
import twoFaMobile from '../features/twofa/mobile/reducer';
import multipleDynamicForms from '../features/multiple-dynamic-forms/reducer';
import progressiveProfiling from '../features/progressive-profiling/reducer';
import airmilesStore from '../features/migrate-noLoyalty-to-loyalty/reducer';

const getMobile = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return {
    isAndroid: !/windows phone/i.test(userAgent) && /android/i.test(userAgent),
    isIOS: /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream,
  };
};

export const ssoApp = combineReducers({
  browser: createResponsiveStateReducer(
    {
      extraSmall: 480,
      small: 767,
      medium: 1023,
      mediumLarge: 1279,
      large: 1499,
    },
    {
      extraFields: () => getMobile(),
    }
  ),
  app,
  notification,
  popup,
  login,
  register,
  migration,
  dynamicForm,
  profile,
  settings,
  editProfile,
  loyalty,
  loyaltyAttach,
  loyaltyNew,
  solMigration,
  solForgotPassword,
  security,
  resetPassword,
  forgotPassword,
  verifyEmailChange,
  verifyEmail,
  lodProfile,
  auth,
  verificationCode,
  verifyMobileChange,
  loyaltyFuturesSignIn,
  loyaltyFuturesMigration,
  loyaltyFuturesAttach,
  sapHybrisAttach,
  mfa,
  scopes,
  twoFaMobile,
  multipleDynamicForms,
  progressiveProfiling,
  airmilesStore,
});

const ssoMiddlewares = [thunkMiddleware, errorMiddleware, spinnerMiddleware];

const createStoreWithMiddleware =
  ENV === 'PROD'
    ? compose(...[responsiveStoreEnhancer, applyMiddleware(...ssoMiddlewares)])(
        createStore
      )
    : composeWithDevTools(
        ...[responsiveStoreEnhancer, applyMiddleware(...ssoMiddlewares, logger)]
      )(createStore);

export default function configureStore(initialState) {
  return createStoreWithMiddleware(ssoApp, initialState);
}
