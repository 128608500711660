import each from 'lodash/each';
import isArray from 'lodash/isArray';

export function removeFormPropertyInOrder(form, prop) {
  const newForm = {
    ...form,
    order: {
      desktop: [],
      mobile: [],
    },
  };
  each(form.order, (_, key) => {
    each(form.order[key], (step, index) => {
      newForm.order[key].push([]);
      each(step, (field) => {
        if (isArray(field)) {
          const fields = field.filter((fieldName) => fieldName !== prop);
          if (fields.length) {
            newForm.order[key][index].push(fields);
          }
        } else if (field !== prop) {
          newForm.order[key][index].push(field);
        }
      });
    });
    newForm.order[key] = newForm.order[key].filter((data) => data.length > 0);
  });
  return newForm;
}
