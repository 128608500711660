import XRegExp from 'xregexp';
import IDNumber from 'id-number';
import {
  cardValidate,
  cardDobValidate,
  promotionCodeValidate,
} from '@shell-b2c/http-frontend/dist/src/components/core/loyalty';
import {
  checkEmailExist,
  checkPhoneExist,
} from '@shell-b2c/http-frontend/dist/src/components/core/settings';
import {
  CARD_ALREADY_MIGRATED_TO_SSO,
  SOL_REGISTRATION_BELONGS_TO_THIS_CARD_NUMBER,
  CARD_BLOCKED,
} from './solResponseErrorCodes';
import { lodMobileVerification } from '@shell-b2c/http-frontend/dist/src/components/core/lod';
import { makeSelectIsPPFlow } from '../features/progressive-profiling/selectors';
import {
  makeSelectFormEmailVerified,
  makeSelectFormErrors,
  makeSelectFormPageName,
} from '../features/dynamic-form/selectors';
import { NEGATIVE_NUMBERS, NUMBERS } from './constants';
import { getCaptchaToken } from './utils';

const validatedCards = {};
const validatedDobForCards = {};

function testRule(value, rule) {
  const objectValidation = new RegExp(rule.pattern, rule.flags);
  const result = objectValidation.exec(value);
  return result !== null && result[0] === value;
}

function testRuleX(value, rule) {
  const objectValidation = new XRegExp(rule.pattern, rule.flags);
  const result = objectValidation.exec(value);
  return result !== null && result[0] === value;
}

function testRulePartially(value, rule) {
  const objectValidation = new RegExp(rule);
  return objectValidation.test(value);
}

function testRuleXPartially(value, rule) {
  const objectValidation = new XRegExp(rule);
  return objectValidation.test(value);
}

function isValidDate(date) {
  if (date) {
    return date.match(/(\d{4})-(\d{2})-(\d{2})/);
  }
  return null;
}

function getAge(val) {
  const diff = Date.now() - new Date(val).getTime();
  const ageDate = new Date(diff);
  return ageDate.getUTCFullYear() - NUMBERS.NINETEEN_SEVENTY;
}

function checkMatch(options, value) {
  let showError = true;
  Object.keys(options || {}).forEach((option) => {
    if (options[option].value === value) {
      showError = false;
    }
  });
  return showError;
}
export function syncCheck(rule, value) {
  switch (rule.rule) {
    case 'regex':
      return testRulePartially(value, rule.value.pattern);
    case 'xregexp':
      return testRuleXPartially(value, rule.value.pattern);
    case 'minLength':
      return value && value.length >= rule.value;
    default:
  }
  return true;
}

function checkMatches(value, options, rule) {
  let val = value;
  if (value === undefined && typeof options[rule.value] === 'object') {
    val = options[rule.value][`${rule.value}2`];
  }

  const otherVal =
    typeof options[rule.value] === 'object'
      ? options[rule.value][rule.value]
      : options[rule.value];
  return val !== otherVal;
}

export async function validateField({
  value,
  state,
  rule,
  options,
  market,
  skipValidation,
  type,
  callback,
}) {
  switch (rule.rule) {
    case 'regex': {
      if (
        !testRule(
          value && type === 'tel' ? value.replace(/ /g, '') : value,
          rule.value
        )
      ) {
        callback(rule.message);
      } else {
        callback(false);
      }
      break;
    }
    case 'xregexp': {
      if (!testRuleX(value, rule.value)) {
        callback(rule.message);
      } else {
        callback(false);
      }
      break;
    }
    case 'required': {
      if (rule.value && (!value || value.length === 0 || value === 0)) {
        callback(rule.message);
      } else {
        callback(false);
      }
      break;
    }
    case 'maxLength': {
      if (value && value.length > rule.value) {
        callback(rule.message);
      } else {
        callback(false);
      }
      break;
    }
    case 'minLength': {
      if (value && value.length < rule.value) {
        callback(rule.message);
      } else {
        callback(false);
      }
      break;
    }
    case 'maxYears': {
      const age = getAge(value);
      if (rule.value <= age) {
        callback(rule.message);
      } else {
        callback(false);
      }
      break;
    }
    case 'minYears': {
      const age = getAge(value);
      if (rule.value > age) {
        callback(rule.message);
      } else {
        callback(false);
      }
      break;
    }
    case 'matchOptions': {
      if (checkMatch(options, value)) {
        callback(rule.message);
      } else {
        callback(false);
      }
      break;
    }
    case 'unique': {
      const isPhoneNumberType = type === 'tel';
      const ppFlow = makeSelectIsPPFlow()(state);
      const pageName = makeSelectFormPageName()(state);
      const recaptchaToken = await getCaptchaToken(pageName);
      const emailOrPhone = value ? value.toLowerCase() : '';
      if (skipValidation.skip || !emailOrPhone) {
        return skipValidation.errorValidation === rule.message
          ? callback(skipValidation.errorValidation)
          : false;
      }
      return isPhoneNumberType
        ? checkPhoneExist(
            { mobile: emailOrPhone.replace(/ /g, '') },
            { noSpinner: ppFlow }
          )
            .then(() => {
              return callback(rule.message);
            })
            .catch(() => {
              return callback(false);
            })
        : checkEmailExist({ email: emailOrPhone, recaptchaToken })
            .then(() => {
              return callback(rule.message);
            })
            .catch((error) => {
              if (error.status === NUMBERS.FOUR_HUNDRED) {
                return callback(rule.message);
              } else if (error.failedCaptcha()) {
                return callback('recaptcha');
              }
              return callback(false);
            });
    }
    case 'lodUnique': {
      if (!value || skipValidation.skip) {
        return skipValidation.errorValidation
          ? callback(skipValidation.errorValidation)
          : false;
      }

      return lodMobileVerification({ mobile: value.replace(/ /g, '') })
        .then(() => {
          callback(false);
        })
        .catch(() => {
          callback(rule.message);
        });
    }
    case 'card': {
      if (skipValidation.skip) {
        return skipValidation.errorValidation
          ? callback(skipValidation.errorValidation)
          : false;
      }

      const cardId = {
        cardId: value,
        market,
      };

      const key = `${value}-${market}`;

      if (validatedCards[key]) {
        callback(false);
        break;
      }

      return cardValidate(cardId)
        .then((response) => {
          if (response.response_status !== 0) {
            callback(rule.message);
          } else {
            validatedCards[key] = response;
            callback(false);
          }
        })
        .catch(() => {
          callback(false);
        });
    }

    case 'cardDob': {
      if (!isValidDate(value) || skipValidation.skip) {
        return skipValidation.errorValidation
          ? callback(skipValidation.errorValidation)
          : false;
      }
      const cardId = options.cardId || '';
      const cardDob = {
        cardId: cardId.trim(),
        birthdate: value,
        market,
      };

      const key = `${cardId}-${value}-${market}`;

      if (validatedDobForCards[key]) {
        callback(false);
        break;
      }

      return cardDobValidate(cardDob)
        .then((response) => {
          if (
            response.response_status ===
              SOL_REGISTRATION_BELONGS_TO_THIS_CARD_NUMBER ||
            response.response_status === CARD_ALREADY_MIGRATED_TO_SSO
          ) {
            callback('preRegistered');
          } else if (response.response_status === CARD_BLOCKED) {
            callback('blocked_card_temp');
          } else if (response.response_status !== 0) {
            callback(rule.message);
          } else {
            validatedDobForCards[key] = response;
            callback(false);
          }
        })
        .catch(() => {
          callback(rule.message);
        });
    }
    case 'promotionCodeChecker': {
      if (skipValidation.skip) {
        return skipValidation.errorValidation
          ? callback(skipValidation.errorValidation)
          : false;
      }
      const data = {
        promotionCode: value,
        market,
      };
      return promotionCodeValidate(data)
        .then((response) => {
          if (response.response_status === NEGATIVE_NUMBERS.TWENTYFOUR_FIFTY) {
            callback(rule.message);
          } else {
            callback(false);
          }
        })
        .catch(() => {
          callback(false);
        });
    }
    case 'matches': {
      if (checkMatches(value, options, rule)) {
        callback(rule.message);
      } else {
        callback(false);
      }
      break;
    }
    case 'checkNRIC': {
      const validator = IDNumber.getValidator('SG', 'NRIC');
      const result = validator(value);
      if (result.success) {
        callback(false);
      } else {
        callback(rule.message);
      }
      return false;
    }
    default:
      callback(false);
  }
  return false;
}
export function checkIfSkipValidate(type, value) {
  return (_, getState) => {
    if (type === 'emailAddress') {
      const emailVerified = makeSelectFormEmailVerified()(getState());
      const formErrors = makeSelectFormErrors()(getState());
      return {
        skip: emailVerified.email === value[type],
        errorValidation: formErrors[type],
      };
    }
    return false;
  };
}
