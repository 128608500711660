import { createSelector } from 'reselect';

export const selectMultipleDynamicForms = (page) => (state) =>
  state.multipleDynamicForms.pages[page];
export const selectDynamicFormsPages = (page) =>
  createSelector(selectMultipleDynamicForms(page), (forms) => {
    const pagenames = [];
    for (let i = 0; i < forms?.length; i++) {
      const element = forms[i];
      pagenames.push(element.pageName);
    }
    return pagenames;
  });
export const selectDynamicFormsPageNames = () => (state) =>
  Object.keys(state.multipleDynamicForms.pages);

export const selectPageNumbers = () => (state) => {
  return {
    pageNumber: state.multipleDynamicForms.pageNumber,
    pageOrderNumber: state.multipleDynamicForms.pageOrderNumber,
  };
};
export const selectIsLastPage = () => (state) =>
  state.multipleDynamicForms.lastPage;
