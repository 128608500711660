import { makeSelectIsMobile } from '../browser/selectors';
import {
  makeSelectFormDataBackendFormated,
  makeSelectFormPage,
  makeSelectFormPageName,
} from '../dynamic-form/selectors';
import {
  PROGRESSIVE_PROFILING_PAGE,
  REGISTER_PAGE,
} from '../../shared/formPages';
import {
  makeSelectIsLoyaltyActivatedInAppSettings,
  makeSelectIsDriveActivatedInAppSettings,
  makeSelectIsGoPlusActivatedInSettings,
  makeSelectIsLoyaltyFuturesActivatedInAppSettings,
  makeSelectIsSAPHybrisActivatedInAppSettings,
  makeSelectCanAttachMultipleCards,
  makeSelectCanLoyaltyBeDone,
} from '../settings/selectors';
import { onAttributeChange } from '../dynamic-form/actions';
import { showPopup } from '../popup/actions';
import browserHistory from '../../router/history';
import {
  PROGRESSIVE_PROFILING_DYNAMICFORM,
  PROGRESSIVE_PROFILING_SUCCESS,
  SOL_ATTACH_WELCOME_PATHNAME,
} from '../../shared/pathnames';
import {
  makeSelectQueryMarket,
  makeSelectQueryCountry,
} from '../app/selectors';
import adobeAnalyticTags from '../../shared/adobeAnalyticTags';
import { sendAdobeAnalyticsEvent } from '../app/actions';
import { makeSelectIsPhoneNumberFormActive } from '../auth/selectors';
import { buildPopup } from '../popup/utils';
import lokaliseTags from '../../shared/lokaliseTags';
import {
  attachLoyaltyDigitalCard,
  getProfile,
  saveProfile,
} from '@shell-b2c/http-frontend/dist/src/components/core/user';
import { loadUserProfile } from '../profile/actions';
import { lodAttach } from '@shell-b2c/http-frontend/dist/src/components/core/lod';
import { makeSelectUUID } from '../profile/selectors';
import { makeSelectIsPPFlow } from '../progressive-profiling/selectors';
import { showDefaultErrorNotification } from '../notification/actions';
import { logout } from '../logout/actions';
import {
  selectDynamicFormsPageNames,
  selectDynamicFormsPages,
} from '../multiple-dynamic-forms/selector';
import { createUser } from './utils';
import {
  deleteStorage,
  getStoredData,
  makeActivate,
  sendKochavaEvent,
} from '../app/utils';
import { generateHash } from '../../http/core/utils';
import kochavaTags from '../../shared/kochavaTags';

export function initialize(setHeader) {
  return (dispatch, getState) => {
    const fromPP = makeSelectIsPPFlow()(getState());
    const formPage = makeSelectFormPageName()(getState());
    const isRegisterFlow = formPage === REGISTER_PAGE;
    const isMobile = makeSelectIsMobile()(getState());

    dispatch(
      sendAdobeAnalyticsEvent(
        fromPP
          ? adobeAnalyticTags.progressiveProfiling.consents.pageLoad
          : adobeAnalyticTags.consentsAdobeTags.tag[formPage]
      )
    );

    if (fromPP || isRegisterFlow) {
      if (!isMobile) return;
      const header = {
        menu: {
          leftMenu: {
            name: lokaliseTags.SSO_GENERAL_BACK,
            icon: 'back',
            mobile: false,
            desktop: false,
            event: () => {
              if (fromPP) {
                return browserHistory.push(PROGRESSIVE_PROFILING_DYNAMICFORM);
              }
              browserHistory.back();
            },
          },
          rightMenu: {
            name: lokaliseTags.SSO_GENERAL_CLOSE,
            icon: 'back',
            mobile: false,
            desktop: true,
            event: () => {
              if (fromPP)
                return browserHistory.push(PROGRESSIVE_PROFILING_DYNAMICFORM);
            },
          },
        },
      };
      setHeader(header);
    } else {
      if (getStoredData('ppFlow') === 'enabled') {
        deleteStorage('ppFlow');
        return dispatch(logout(true, false, { doRedirectToCVP: true }));
      }
      browserHistory.push('/');
    }
  };
}

function getConsentNames(isMobile) {
  if (isMobile) {
    return {
      NEWSLETTERS: 'mobileNewsletters',
      LOYALTY: 'mobileLoyaltyOptIn',
      DRIVE: 'driveOptIn',
      GOPLUS: 'mobileGoPlusMarketingOptIn',
    };
  }

  return {
    NEWSLETTERS: 'newsletters',
    LOYALTY: 'loyaltyOptIn',
    DRIVE: 'driveOptIn',
    GOPLUS: 'goPlusMarketingOptIn',
  };
}

export function acceptCommunications() {
  return (dispatch, getState) => {
    const fromPP = makeSelectIsPPFlow()(getState());
    const consentNames = getConsentNames(
      makeSelectIsPhoneNumberFormActive()(getState())
    );

    const adobeTag =
      adobeAnalyticTags.consentsAdobeTags.ClickButtonConsentsEvent;
    adobeTag.signInType = 'Standard';
    adobeTag.cta = lokaliseTags.SSO_GENERAL_HELPFUL_UPDATES_YES_BUTTON;
    adobeTag.optinType = true;

    dispatch(
      sendAdobeAnalyticsEvent(
        fromPP
          ? adobeAnalyticTags.progressiveProfiling.consents.accept
          : adobeTag,
        'click'
      )
    );

    const form = makeSelectFormPage(REGISTER_PAGE)(getState());
    const fakeId = generateHash(
      form.values.emailAddress || form.values.mobile?.replaceAll(' ', '')
    );

    dispatch(sendKochavaEvent('Registration Consent', { fakeId }));

    dispatch(onAttributeChange(consentNames.NEWSLETTERS, true));

    if (makeSelectIsLoyaltyActivatedInAppSettings()(getState())) {
      dispatch(onAttributeChange(consentNames.LOYALTY, true));
    }

    if (makeSelectIsDriveActivatedInAppSettings()(getState())) {
      dispatch(onAttributeChange(consentNames.DRIVE, true));
    }

    if (
      makeSelectIsGoPlusActivatedInSettings()(getState()) ||
      makeSelectIsLoyaltyFuturesActivatedInAppSettings()(getState()) ||
      makeSelectIsSAPHybrisActivatedInAppSettings()(getState())
    ) {
      dispatch(onAttributeChange(consentNames.GOPLUS, true));

      if (makeSelectIsPhoneNumberFormActive()(getState())) {
        dispatch(onAttributeChange('newsletters', true));
        dispatch(onAttributeChange('goPlusMarketingOptIn', true));
      }
    }
    dispatch(createUser());
  };
}

export function rejectCommunications() {
  return (dispatch, getState) => {
    const fromPP = makeSelectIsPPFlow()(getState());
    const adobeTag =
      adobeAnalyticTags.consentsAdobeTags.ClickButtonConsentsEvent;
    adobeTag.signInType = 'Standard';
    adobeTag.cta = lokaliseTags.SSO_GENERAL_HELPFUL_UPDATES_NO_BUTTON;
    adobeTag.optinType = false;

    dispatch(
      sendAdobeAnalyticsEvent(
        fromPP
          ? adobeAnalyticTags.progressiveProfiling.consents.decline
          : adobeTag,
        'click'
      )
    );

    const form = makeSelectFormPage(REGISTER_PAGE)(getState());
    const fakeId = generateHash(
      form.values.emailAddress || form.values.mobile?.replaceAll(' ', '')
    );

    dispatch(sendKochavaEvent(kochavaTags.register.consent, { fakeId }));

    const consentNames = getConsentNames(
      makeSelectIsPhoneNumberFormActive()(getState())
    );
    // Ensure consents to false when rejected
    Object.keys(consentNames).forEach((consent) =>
      dispatch(onAttributeChange(consentNames[consent], false))
    );
    dispatch(createUser(false));
  };
}

export function displayWarningPopup() {
  return (dispatch) => {
    const popup = {
      title: lokaliseTags.SSO_GENERAL_HELPFUL_UPDATES_PROMPT_TITLE,
      text: lokaliseTags.SSO_GENERAL_HELPFUL_UPDATES_PROMPT_TEXT,
      options: {
        inlineButtons: true,
      },
      buttons: [
        {
          label: lokaliseTags.SSO_GENERAL_HELPFUL_UPDATES_PROMPT_BUTTON,
        },
      ],
    };

    dispatch(showPopup(buildPopup(popup)));
  };
}

export function editUserFromPP(_accepted = true) {
  return async (dispatch, getState) => {
    const dynFormPageNames = selectDynamicFormsPages(
      PROGRESSIVE_PROFILING_PAGE
    )(getState());
    const formData = makeSelectFormDataBackendFormated(dynFormPageNames)(
      getState()
    );
    const consents = { tos: true };
    const listConsentsEmail = getConsentNames(false);
    const listConsentsMobile = getConsentNames(true);
    Object.keys(listConsentsEmail).forEach((consent) => {
      consents[listConsentsEmail[consent]] =
        formData[listConsentsEmail[consent]] || false;
      if (listConsentsEmail[consent] in formData) {
        delete formData[listConsentsEmail[consent]];
      }
    });
    Object.keys(listConsentsMobile).forEach((consent) => {
      consents[listConsentsMobile[consent]] =
        formData[listConsentsMobile[consent]] || false;
      if (listConsentsMobile[consent] in formData) {
        delete formData[listConsentsMobile[consent]];
      }
    });
    const dataToPut = {
      profile: formData,
      consents,
      market: {
        code: makeSelectQueryMarket()(getState()),
        country: makeSelectQueryCountry()(getState()),
      },
    };
    try {
      await saveProfile(dataToPut);
      const response = await getProfile();
      dispatch(loadUserProfile({ ...response }));
      const uuid = makeSelectUUID()(getState());
      const isSapHybrisActivatedinProfile =
        makeSelectIsSAPHybrisActivatedInAppSettings()(getState());
      const canLoyaltyBeDone = makeSelectCanLoyaltyBeDone()(getState());
      const lfEnabled = makeSelectIsLoyaltyActivatedInAppSettings()(getState());
      const goPlusEnabled = makeSelectIsGoPlusActivatedInSettings()(getState());

      if (makeSelectCanAttachMultipleCards()(getState())) {
        return browserHistory.push(SOL_ATTACH_WELCOME_PATHNAME);
      }
      if (canLoyaltyBeDone) {
        await makeActivate({
          lfEnabled,
          goPlusEnabled,
          isSapHybrisActivatedinProfile,
          activateParam: {
            market: makeSelectQueryMarket()(getState()),
            uuid,
          },
        });
        if (lfEnabled && !goPlusEnabled) {
          await attachLoyaltyDigitalCard({
            loyalty: {
              activated: true,
              cardStatus: 'UNVERIFIED',
              migrationOption: 'FRESH',
            },
            profile: formData,
          });
        } else if (!isSapHybrisActivatedinProfile) {
          await lodAttach({});
        }
      }
      const formsPages = selectDynamicFormsPageNames()(getState());
      for (let i = 0; i < formsPages.length; i++) {
        const dynforms = selectDynamicFormsPages(formsPages[i])(getState());
        for (let index = 0; index < dynforms.length; index++) {
          deleteStorage(dynforms[index]);
        }
      }
      deleteStorage('ppFlow');

      return browserHistory.push(PROGRESSIVE_PROFILING_SUCCESS);
    } catch (_) {
      const logoutFn = () => {
        dispatch(logout(true, false, { doRedirectToCVP: true }));
      };
      dispatch(showDefaultErrorNotification(logoutFn, false, logoutFn));
    }
  };
}
