import { RESET_DATA, SERVER_RESPONSE } from '../dynamic-form/constants';
import {
  SET_REGISTER_FORM,
  REGISTER_BY_EMAIL,
  SET_CONSOLIDATED_FLOW,
} from './constants';

export const initialState = {
  step: 0,
  user: {},
  showPassword: false,
  editField: {
    name: '',
    value: '',
  },
  errors: {},
  form: {
    properties: [],
    order: {
      desktop: [],
      mobile: [],
    },
  },
  focus: false,
  byEmail: false,
  missingFields: {},
  message: {
    title: '',
    message: '',
    submessage: '',
  },
  confirmation: {
    title: '',
    message: '',
  },
  accessToken: '',
  consolidatedFlow: false,
};

const register = (state = initialState, action) => {
  const { value } = action;
  switch (action.type) {
    case SET_CONSOLIDATED_FLOW:
      return {
        ...state,
        consolidatedFlow: value,
      };
    case RESET_DATA:
      return {
        ...state,
        user: {
          ...initialState.user,
        },
      };
    case REGISTER_BY_EMAIL: {
      return {
        ...state,
        byEmail: value,
      };
    }
    case SERVER_RESPONSE: {
      return {
        ...state,
        confirmation: value,
      };
    }
    case SET_REGISTER_FORM: {
      if (state?.missingFields.code) {
        const userObject = { ...state.missingFields.fields };

        const form = action.data;
        form.order = { mobile: [], desktop: [] };
        return {
          ...state,
          user: userObject,
          form,
        };
      }
      const registerData = action.data;
      return {
        ...state,
        form: registerData,
      };
    }
    default: {
      return state;
    }
  }
};

export default register;
