import React from 'react';
import { Icon } from '@shell-b2c/web-design-system';

const IconGiftFilled = () => <Icon type="gift-filled" />;

const DEFAULT_ICON = IconGiftFilled;

const ICONS = {
  consents: IconGiftFilled,
};

export function getIcon(iconKey) {
  if (iconKey) {
    return ICONS[iconKey] || DEFAULT_ICON;
  }
  return iconKey;
}
