import { createSelector } from 'reselect';
import get from 'lodash/get';
import {
  makeSelectIsPartnerCVP,
  makeSelectQueryClientId,
  makeSelectQueryRedirect,
} from '../app/selectors';
import { addQueryStringParam } from '../app/utils';

const selectSettingsDomain = () => (state) => state.settings;

const makeSelectIsSettingsLoaded = () =>
  createSelector(selectSettingsDomain(), (settings) => settings.loaded);

const makeSelectCanLoyaltyBeDone = () =>
  createSelector(
    makeSelectDoesLoyaltyObjectExist(),
    makeSelectIsLoyaltyFuturesActivatedInAppSettings(),
    makeSelectIsGoPlusActivatedInSettings(),
    makeSelectIsSAPHybrisActivatedInAppSettings(),
    makeSelectIsLoyaltyFuturesActivatedInAppSettings(),
    (
      doesLoyaltyExist,
      isLoyaltyFuturesActivated,
      isGoPlusActivated,
      isSapHybrisActivated,
      isLFActivated
    ) => {
      const anyLoyaltyActivated =
        isLoyaltyFuturesActivated ||
        isGoPlusActivated ||
        isSapHybrisActivated ||
        isLFActivated;
      return doesLoyaltyExist && anyLoyaltyActivated;
    }
  );
const makeSelectDoesLoyaltyObjectExist = () =>
  createSelector(selectSettingsDomain(), (settings) =>
    get(settings, 'loyalty', false)
  );
const makeSelectIsLoyaltyActivatedInAppSettings = () =>
  createSelector(selectSettingsDomain(), (settings) =>
    get(settings, 'loyalty.loyalty_enabled', false)
  );

const makeSelectIsSAPHybrisActivatedInAppSettings = () =>
  createSelector(selectSettingsDomain(), (settings) =>
    get(settings, 'loyalty.saphybrisloyalty_enabled', false)
  );

const makeSelectIsLoyaltyFuturesActivatedInAppSettings = () =>
  createSelector(selectSettingsDomain(), (settings) =>
    get(settings, 'loyalty.loyaltyfutures_enabled', false)
  );

const makeSelectIsGoPlusActivatedInSettings = () =>
  createSelector(selectSettingsDomain(), (settings) =>
    get(settings, 'loyalty.goplus_live', false)
  );

const makeSelectIsDriveActivatedInAppSettings = () =>
  createSelector(selectSettingsDomain(), (settings) =>
    get(settings, 'general_settings.drive_enabled', false)
  );

const makeSelectGoPlusWebSiteLink = () =>
  createSelector(
    selectSettingsDomain(),
    makeSelectIsPartnerCVP(),
    makeSelectQueryRedirect(),
    (settings, isPartnerCVP, redirectUrl) =>
      isPartnerCVP ? redirectUrl : get(settings, 'loyalty.goplus_url', '')
  );

const makeSelectLoyaltyChangeCardUrl = (accessCode) =>
  createSelector(selectSettingsDomain(), (settings) => {
    const url = get(settings, 'loyalty.change_card_id', '');

    return addQueryStringParam(
      url,
      'accessCode',
      encodeURIComponent(accessCode)
    );
  });

const makeSelectLoyaltyPayonsiteUrl = (accessCode) =>
  createSelector(selectSettingsDomain(), (settings) => {
    const url = get(settings, 'loyalty.payonsite_url', '');

    return addQueryStringParam(
      url,
      'accessCode',
      encodeURIComponent(accessCode)
    );
  });

const makeSelectDateFormat = () =>
  createSelector(selectSettingsDomain(), (settings) =>
    get(settings, 'l10n.country_date_format', 'dd/mm/yyyy')
  );

const makeSelectGetCountryFlagImage = () =>
  createSelector(selectSettingsDomain(), (settings) =>
    get(settings, 'general_settings.country_flag_image.thumbnail')
  );

const makeSelectDefaultAuthentication = () =>
  createSelector(selectSettingsDomain(), (settings) =>
    get(settings, 'general_settings.default_authentication_method', 'email')
  );

const makeSelectEmailAuthentication = () =>
  createSelector(selectSettingsDomain(), (settings) =>
    get(settings, 'general_settings.email_authentication', false)
  );

const makeSelectPhoneAuthentication = () =>
  createSelector(selectSettingsDomain(), (settings) =>
    get(settings, 'general_settings.phone_authentication', false)
  );

const makeSelectTermsAndConditions = () =>
  createSelector(selectSettingsDomain(), (settings) =>
    get(settings, 'termsAndConditions')
  );

const makeSelectPrivacyPolicy = () =>
  createSelector(selectSettingsDomain(), (settings) =>
    get(settings, 'privacyPolicy')
  );

const makeSelectGoPlusAttachCard = () =>
  createSelector(selectSettingsDomain(), (settings) =>
    get(settings, 'loyalty.goplus_attach_card')
  );

const makeSelectCanAttachMultipleCards = () =>
  createSelector(selectSettingsDomain(), (settings) =>
    get(settings, 'loyalty.attach_multiple_cards', false)
  );

const makeSelectIsLoyaltyMigrateCardsActivatedInAppSettings = () =>
  createSelector(selectSettingsDomain(), (settings) =>
    get(settings, 'loyalty.migrate_cards')
  );

const makeSelectCanAttachPersonalizedCards = () =>
  createSelector(selectSettingsDomain(), (settings) =>
    get(settings, 'loyalty.attach_personalised_cards')
  );

const makeSelectSettingsRedirect = () =>
  createSelector(selectSettingsDomain(), (settings) =>
    get(settings, 'general_settings.redirect_url')
  );

const makeSelectSettingsDownloadIOSUrl = () =>
  createSelector(selectSettingsDomain(), (settings) =>
    get(settings, 'general_settings.ios_download_url', null)
  );

const makeSelectSettingsDownloadAndroidUrl = () =>
  createSelector(selectSettingsDomain(), (settings) =>
    get(settings, 'general_settings.android_download_url', null)
  );

const makeSelectSettingsHelpURL = () =>
  createSelector(selectSettingsDomain(), (settings) =>
    get(settings, 'general_settings.help_url', null)
  );

const makeSelectSettingsPartnersByClientId = () =>
  createSelector(
    selectSettingsDomain(),
    makeSelectQueryClientId(),
    (settings, clientId) => {
      const partners = get(
        settings,
        'partners_management.partner_client_ids',
        null
      );

      const partnerByClientId = partners?.find(
        (partner) => partner.client_id === clientId
      );

      return partnerByClientId || null;
    }
  );

const makeSelectSettingsOtpAuthentication = () =>
  createSelector(
    selectSettingsDomain(),
    (settings) => settings.general_settings?.otp_authentication || false
  );

const makeSelectSettingsShowVehiclePreferenceScreen = () =>
  createSelector(
    selectSettingsDomain(),
    (settingsContent) =>
      settingsContent?.general_settings?.show_vehicle_preference_screen || false
  );

export default selectSettingsDomain;

export {
  selectSettingsDomain,
  makeSelectIsSettingsLoaded,
  makeSelectIsLoyaltyActivatedInAppSettings,
  makeSelectCanLoyaltyBeDone,
  makeSelectIsGoPlusActivatedInSettings,
  makeSelectIsDriveActivatedInAppSettings,
  makeSelectGoPlusWebSiteLink,
  makeSelectLoyaltyChangeCardUrl,
  makeSelectLoyaltyPayonsiteUrl,
  makeSelectDateFormat,
  makeSelectGetCountryFlagImage,
  makeSelectDefaultAuthentication,
  makeSelectEmailAuthentication,
  makeSelectPhoneAuthentication,
  makeSelectTermsAndConditions,
  makeSelectPrivacyPolicy,
  makeSelectGoPlusAttachCard,
  makeSelectIsLoyaltyFuturesActivatedInAppSettings,
  makeSelectCanAttachMultipleCards,
  makeSelectIsSAPHybrisActivatedInAppSettings,
  makeSelectIsLoyaltyMigrateCardsActivatedInAppSettings,
  makeSelectCanAttachPersonalizedCards,
  makeSelectSettingsRedirect,
  makeSelectSettingsDownloadIOSUrl,
  makeSelectSettingsDownloadAndroidUrl,
  makeSelectSettingsPartnersByClientId,
  makeSelectSettingsOtpAuthentication,
  makeSelectSettingsHelpURL,
  makeSelectSettingsShowVehiclePreferenceScreen,
};
