const kochavaTags = {
  loginTags: {
    loginClick: 'Widget Sign In',
    mfaClick: 'Widget Sign In Entered OTP',
  },
  register: {
    consent: 'Registration Consent',
    confirmOTP: 'Registration Confirm OTP',
    name: 'Registration Name',
    email: 'Registration Email',
    confirmEmail: 'Registration Confirm Email',
    complete: 'Registration Complete',
    mobile: 'Registration Mobile',
    password: 'Registration Password',
    vehicleType: 'Vehicle Type',
  },
};

export default kochavaTags;
