import {
  UPDATE_EDIT_PROFILE_FORM,
  EDIT_PROFILE_CHANGE_VALUE,
} from './constants';

export const initialState = {
  user: {
    firstName: '',
    lastName: '',
    emailAddress: '',
  },
  confirmation: {},
  form: {
    properties: [],
    order: {
      desktop: [],
      mobile: [],
    },
  },
  errors: {},
  latestDataSubmitted: null,
};

const editProfileForm = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_PROFILE_CHANGE_VALUE:
      return {
        ...state,
        [action.name]: action.value,
      };
    case UPDATE_EDIT_PROFILE_FORM: {
      return {
        ...state,
        form: action.value,
      };
    }
    default:
      return state;
  }
};

export default editProfileForm;
