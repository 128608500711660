import get from 'lodash/get';
import {
  makeSelectQueryMarket,
  makeSelectIsFRCVP,
  makeSelectIsSolCVP,
  makeSelectIsGoPlusCVP,
  makeSelectQueryAccessCode,
  makeSelectIsMotoristApp,
  makeSelectIsPartnerCVP,
  makeSelectIsLoyaltyFuturesCVP,
  makeSelectRegistrationActivationStatus,
  makeSelectIsSAPHybrisCVP,
  makeSelectIsMotoristFirebladeCVP,
  makeSelectIsPartner,
  makeSelectQueryCountry,
} from '../app/selectors';
import browserHistory from '../../router/history';
import {
  REGISTRATION_MOBILE_PATHNAME,
  USER_REGISTRATION,
} from '../../shared/appPathnames';
import { REGISTER_PAGE } from '../../shared/formPages';
import {
  exchangeAccessCode,
  walletPass,
} from '@shell-b2c/http-frontend/dist/src/components/core/auth';
import { getFeatureFlagConfig } from '@shell-b2c/http-frontend/dist/src/components/core/config';
import {
  deleteStorage,
  deleteAllStorageData,
  setDataToStore,
  makeActivate,
  sendKochavaEvent,
} from '../app/utils';
import {
  makeSelectIsGoPlusActivated,
  makeSelectIsMgmUser,
  makeSelectLodCardsByType,
  makeSelectUUID,
} from '../profile/selectors';
import { LOD_PROXY_ACTIVATE_USER_URL } from '@shell-b2c/http-frontend/dist/src/components/core/endpoints';
import { lodAttach } from '@shell-b2c/http-frontend/dist/src/components/core/lod';
import {
  sendAdobeAnalyticsEvent,
  setQuery,
  redirectToCVP,
  setSpinner,
  setLocation,
} from '../app/actions';
import adobeAnalyticTags from '../../shared/adobeAnalyticTags';
import {
  showNotification,
  showDefaultErrorNotification,
  setNotificationVisible,
} from '../notification/actions';
import { ANDROID_APP, buildNotification, IOS_APP } from '../notification/utils';
import {
  LOGIN_PATHNAME,
  SOL_ATTACH_WELCOME_PATHNAME,
  WELCOME_GO_PLUS_PATHNAME,
  PROFILE_PATHNAME,
  LOYALTY_FUTURES_ATTACH_WELCOME_PATHNAME,
  SAP_HYBRIS_ATTACH_WELCOME_PATHNAME,
} from '../../shared/pathnames';
import { loadUserProfile } from '../profile/actions';
import {
  makeSelectIsGoPlusActivatedInSettings,
  makeSelectIsLoyaltyActivatedInAppSettings,
  makeSelectIsLoyaltyFuturesActivatedInAppSettings,
  makeSelectIsSAPHybrisActivatedInAppSettings,
  makeSelectGoPlusAttachCard,
  makeSelectCanAttachMultipleCards,
  makeSelectSettingsPartnersByClientId,
  makeSelectEmailAuthentication,
  makeSelectPhoneAuthentication,
} from '../settings/selectors';
import { LOYALTY_SERVICE } from '../../shared/formServices';
import { showMigrationFinishedNotification } from '../loyalty-futures/migration/actions';
import TickAnimation from '../../shared/components/TickAnimation';
import { SET_PROFILE_LOADED } from '../profile/constants';
import {
  makeSelectIsAndroid,
  makeSelectIsIOS,
  makeSelectIsMobile,
} from '../browser/selectors';
import { DIGITAL_CARD } from '../loyalty-futures/shared/constants';
import lokaliseTags from '../../shared/lokaliseTags';
import ConeAnimation from '../../shared/components/ConeAnimation';
import { sendLoginSuccessAnalytics } from '../login/actions';
import { attachLoyaltyDigitalCard } from '@shell-b2c/http-frontend/dist/src/components/core/user';
import { generateHash } from '../../http/core/utils';
import { cloneDeep } from 'lodash';
import { getiOSVersion } from '../../shared/utils';

export const ACTIVATE_ACCOUNT_SUCCESS = 'activate_account_success';
export const ACTIVATE_ACCOUNT_FAILURE = 'activate_account_failure';
export const LOYALTY_ACTIVATED = 'loyalty.activated';

export function initialize() {
  return (dispatch, getState) => {
    const registrationActivationStatus =
      makeSelectRegistrationActivationStatus()(getState());
    if (registrationActivationStatus) {
      return dispatch(
        showActivationNotification(registrationActivationStatus, false)
      );
    }
    return dispatch(
      showDefaultErrorNotification(() => browserHistory.push(LOGIN_PATHNAME))
    );
  };
}

export function showActivationNotification(status, mobile = false) {
  return async (dispatch, getState) => {
    const isMotoristApp = makeSelectIsMotoristApp()(getState());
    if (status === ACTIVATE_ACCOUNT_FAILURE) {
      dispatch(
        showNotification(
          buildNotification({
            animation: ConeAnimation,
            title: lokaliseTags.SSO_VERIFY_ACCOUNT_LABEL_FAILED,
            text: mobile
              ? lokaliseTags.SSO_VERIFY_VERIFY_PHONE_FAILED
              : lokaliseTags.SSO_VERIFY_LINK_FAILED,
            buttons: [
              {
                label: lokaliseTags.SSO_GENERAL_CONTINUE,
                event: () => {
                  dispatch(
                    sendAdobeAnalyticsEvent(
                      adobeAnalyticTags[REGISTER_PAGE].errorConfirmEmail,
                      'tileClick'
                    )
                  );
                },
              },
            ],
          })
        )
      );
    } else if (status === ACTIVATE_ACCOUNT_SUCCESS) {
      dispatch(activateAccountSuccess(isMotoristApp));
    }

    // Warning is shown so hide it
    const query = {
      status: false,
    };
    dispatch(setQuery(query));
    const storeQuery = getState().app.query;
    delete storeQuery.status;
    setDataToStore('query', JSON.stringify(storeQuery));
  };
}

const activateAccountSuccess = (isMotoristApp) => {
  return async (dispatch, getState) => {
    const accessCode = makeSelectQueryAccessCode()(getState());
    const isFirebladeApp = makeSelectIsMotoristFirebladeCVP()(getState());
    if (accessCode) {
      try {
        const response = await exchangeAccessCode({ code: accessCode });

        dispatch(loadUserProfile({ ...response }));
        const urlParams = dispatch(setTokensToStore(response, accessCode));
        if (isFirebladeApp) {
          await dispatch(setLocation(USER_REGISTRATION));
        }
        dispatch(sendAccountActivatedAnalytics(response));
        await dispatch(goToDestinedFlow(response, accessCode, urlParams));
      } catch (error) {
        if (error.alreadyShownNotification) return;
        if (isMotoristApp) {
          deleteAllStorageData();
          deleteStorage('authCode');
          dispatch(setQuery({ [accessCode]: false }));
          const redirectErrorData = {
            error: [{ 400: 'AccessCode Invalid' }],
          };
          dispatch(redirectToCVP(redirectErrorData));
        } else {
          browserHistory.push(LOGIN_PATHNAME);
        }
      }
    } else {
      const isPhoneAuth = makeSelectPhoneAuthentication()(getState());
      dispatch(
        showNotification(
          buildNotification({
            title: lokaliseTags.SSO_VERIFY_ACCOUNT_LABEL,
            text: {
              key: isPhoneAuth
                ? lokaliseTags.SSO_VERIFY_VERIFY_PHONE_VERIFIED_TEXT
                : lokaliseTags.SSO_VERIFY_EMAIL_VERIFIED_TEXT,
              values: [getState().app.query.email],
            },
            buttons: [
              {
                label: lokaliseTags.SSO_GENERAL_BACK,
              },
            ],
          })
        )
      );
    }
  };
};

export const callWalletGenerationAnalytics = () => {
  return (dispatch) => {
    dispatch(
      sendAdobeAnalyticsEvent(
        adobeAnalyticTags.register.registrationWallet,
        'tileClick'
      )
    );
  };
};

export const sendAccountActivatedAnalytics = (response) => {
  return (dispatch, getState) => {
    const isMailAuthentication = makeSelectEmailAuthentication()(getState());
    const isSolCVP = makeSelectIsSolCVP()(getState());
    const isSOLMigrationAccountActivationFlow =
      get(response, LOYALTY_ACTIVATED, false) &&
      makeSelectIsLoyaltyActivatedInAppSettings()(getState()) &&
      isSolCVP;

    const tag = {
      ...get(
        adobeAnalyticTags,
        isSOLMigrationAccountActivationFlow
          ? 'solMigrationConfirmation.pageLoad'
          : `${REGISTER_PAGE}.registrationComplete`
      ),
      signInType: 'Standard',
    };
    tag.confirmed_name = isMailAuthentication ? 'email' : 'mobile';

    const mobile = response?.profile?.phone?.mobile
      ? cloneDeep(response.profile.phone.mobile).replaceAll(' ', '')
      : undefined;

    const fakeId = isMailAuthentication
      ? response?.profile?.emailAddress
      : mobile;

    tag.fakeId = generateHash(fakeId);

    dispatch(sendAdobeAnalyticsEvent(tag, 'confirmed'));
    const ssoId = makeSelectUUID()(getState());
    dispatch(
      sendKochavaEvent('Registration Complete', { ssoId, fakeId: tag.fakeId })
    );
    dispatch(sendLoginSuccessAnalytics(false));
  };
};

export const setTokensToStore = (response, accessCode) => {
  return (dispatch, getState) => {
    const newTokens = {
      accessToken: response.accessToken,
      refreshToken: response.refreshToken,
      [accessCode]: true, // Mark as used so no exchange in handleUserProfile
    };
    dispatch(setQuery(newTokens));
    const urlParams = { ...getState().app.query, ...newTokens };
    setDataToStore('query', JSON.stringify(urlParams));
    setDataToStore('auth', JSON.stringify(newTokens), true);

    return urlParams;
  };
};

const goToGoPlusOrPartner = () => {
  return (dispatch, getState) => {
    const isMotoristApp = makeSelectIsMotoristApp()(getState());
    const isPartnerWebFlow =
      makeSelectIsPartnerCVP()(getState()) && !isMotoristApp;
    const isGoPlusWebFlow =
      makeSelectIsGoPlusActivatedInSettings()(getState()) &&
      makeSelectIsGoPlusCVP()(getState()) &&
      !isMotoristApp;

    if (isGoPlusWebFlow || isPartnerWebFlow) {
      dispatch(setQuery({ newUser: true }));
      browserHistory.push(WELCOME_GO_PLUS_PATHNAME);
      return true;
    }
    return false;
  };
};

export const goToSolWebFlow = (response, urlParams) => {
  return (_, getState) => {
    const isSolWebFlow =
      makeSelectIsLoyaltyActivatedInAppSettings()(getState()) &&
      urlParams.service === LOYALTY_SERVICE &&
      !get(response, LOYALTY_ACTIVATED, false);

    if (isSolWebFlow) {
      browserHistory.push(`${SOL_ATTACH_WELCOME_PATHNAME}?fromRegister=true`);
      return true;
    }
    return false;
  };
};

const goToFirebladeLoyaltyFuturesFlow = (accessCode) => {
  return (dispatch, getState) => {
    const isMotoristApp = makeSelectIsMotoristApp()(getState());
    const isLoyaltyFuturesFlow =
      makeSelectIsLoyaltyFuturesActivatedInAppSettings()(getState());
    const market = makeSelectQueryMarket()(getState());
    if (
      isMotoristApp &&
      (isLoyaltyFuturesFlow || market === 'en-TR' || market === 'tr-TR')
    ) {
      dispatch(setSpinner(true));
      dispatch(
        setLocation(`${REGISTRATION_MOBILE_PATHNAME}?accessCode=${accessCode}`)
      );
      return true;
    }
    return false;
  };
};

const goToLoyaltyFuturesFlow = (response) => {
  return async (dispatch, getState) => {
    const isLoyaltyFuturesFlow =
      makeSelectIsLoyaltyFuturesCVP()(getState()) &&
      makeSelectIsLoyaltyFuturesActivatedInAppSettings()(getState());
    const isGoPlusActivatedInProfile = makeSelectIsGoPlusActivated()(
      getState()
    );
    if (isLoyaltyFuturesFlow) {
      try {
        await dispatch(activateAnyLoyalty(response.uuid));
      } catch (error) {
        return Promise.reject(error);
      }

      if (
        !isGoPlusActivatedInProfile &&
        makeSelectGoPlusAttachCard()(getState())
      ) {
        browserHistory.push(
          `${LOYALTY_FUTURES_ATTACH_WELCOME_PATHNAME}?fromRegister=true`
        );
        return true;
      }

      if (
        isGoPlusActivatedInProfile &&
        response.loyalty.migrationOption === 'MIGRATE'
      ) {
        dispatch(showMigrationFinishedNotification());
        return true;
      }

      dispatch(showAccountVerifiedDefaultNotification());
      return true;
    }
    return false;
  };
};

const goToSapHybrisfFlow = () => {
  return (_, getState) => {
    const isGoPlusActivatedInProfile = makeSelectIsGoPlusActivated()(
      getState()
    );
    const isSAPHybrisFlow =
      makeSelectIsSAPHybrisCVP()(getState()) &&
      makeSelectIsSAPHybrisActivatedInAppSettings()(getState()) &&
      !isGoPlusActivatedInProfile;

    if (isSAPHybrisFlow) {
      browserHistory.push(
        `${SAP_HYBRIS_ATTACH_WELCOME_PATHNAME}?fromRegister=true`
      );
      return true;
    }
    return false;
  };
};

const goToArchetypesFlow = (response) => {
  return (dispatch, getState) => {
    const isPartnerCVP = makeSelectIsPartner()(getState());

    if (isPartnerCVP) {
      dispatch(showPartnerDefaultNotification(response.uuid));
      return true;
    }
    return false;
  };
};

const goToSolMigrationFlow = (response) => {
  return (dispatch, getState) => {
    const isSolCVP = makeSelectIsSolCVP()(getState());
    const isSOLMigrationAccountActivationFlow =
      get(response, LOYALTY_ACTIVATED, false) &&
      makeSelectIsLoyaltyActivatedInAppSettings()(getState()) &&
      isSolCVP;

    if (isSOLMigrationAccountActivationFlow) {
      dispatch(showAccountMigratedNotification());
      return true;
    }
    return false;
  };
};

export const goToDestinedFlow = (response, accessCode, urlParams) => {
  return async (dispatch) => {
    try {
      if (await dispatch(goToGoPlusOrPartner())) return Promise.resolve();
      if (await dispatch(goToSolWebFlow(response, urlParams)))
        return Promise.resolve();
      if (await dispatch(goToFirebladeLoyaltyFuturesFlow(accessCode)))
        return Promise.resolve();
      if (await dispatch(goToLoyaltyFuturesFlow(response)))
        return Promise.resolve();
      if (await dispatch(goToSapHybrisfFlow())) return Promise.resolve();
      if (await dispatch(goToArchetypesFlow(response)))
        return Promise.resolve();
      if (await dispatch(goToSolMigrationFlow(response)))
        return Promise.resolve();
      await dispatch(showAccountVerifiedDefaultNotification());
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

export const activateAnyLoyalty = (uuid, partner = false) => {
  return async (dispatch, getState) => {
    try {
      const isGoPlusActivatedInProfile = makeSelectIsGoPlusActivated()(
        getState()
      );

      const isSapHybrisActivatedinProfile =
        makeSelectIsSAPHybrisActivatedInAppSettings()(getState());
      const isLoyaltyActivated = makeSelectIsLoyaltyActivatedInAppSettings()(
        getState()
      );
      const digitalCards = makeSelectLodCardsByType(DIGITAL_CARD)(getState());

      if (digitalCards.length === 0) {
        await makeActivate({
          lfEnabled: isLoyaltyActivated,
          goPlusEnabled: makeSelectIsGoPlusActivatedInSettings()(getState()),
          isSapHybrisActivatedinProfile,
          activateParam: {
            market: makeSelectQueryMarket()(getState()),
            uuid,
          },
        });
      }
      if (partner) {
        if (isLoyaltyActivated) {
          await attachLoyaltyDigitalCard({});
        } else if (!isSapHybrisActivatedinProfile) {
          await lodAttach({});
        }
      } else if (!isGoPlusActivatedInProfile) {
        await lodAttach({});
      }

      dispatch({ type: SET_PROFILE_LOADED, isLoaded: false });

      return true;
    } catch (error) {
      dispatch(setNotificationVisible(false));
      const endpoint = get(error, 'config.url', '');

      const isProxyEndpointError =
        endpoint.indexOf(LOD_PROXY_ACTIVATE_USER_URL) !== -1;
      // If the problem was the lod/attach endpoint
      if (!isProxyEndpointError) {
        // Redirect without sending accessCode and force the user to login
        dispatch(
          showDefaultErrorNotification(
            () => dispatch(redirectToCVP({ sendAccessCode: false })),
            lokaliseTags.SSO_GENERIC_LOD
          )
        );
        return Promise.reject({ alreadyShownNotification: true });
      }
      dispatch(
        showDefaultErrorNotification(() => {
          if (makeSelectIsMotoristApp()(getState())) {
            dispatch(redirectToCVP());
          } else {
            browserHistory.push(LOGIN_PATHNAME);
          }
        }, lokaliseTags.SSO_GENERIC_LOD)
      );
      return Promise.reject({ alreadyShownNotification: true });
    }
  };
};
export function continueAfterAccountCreation() {
  return (dispatch, getState) => {
    const state = getState();
    const isSOLMigrationAccountActivationFlow =
      state.profile.loyalty.activated &&
      makeSelectIsLoyaltyActivatedInAppSettings()(state);
    const isSapHybrisCvp = makeSelectIsSAPHybrisCVP()(state);

    const tag = {
      ...get(
        adobeAnalyticTags,
        isSOLMigrationAccountActivationFlow
          ? 'solMigrationConfirmation.cta'
          : `${REGISTER_PAGE}.registrationCompleteCTA`
      ),
      signInType: 'Standard',
    };
    dispatch(sendAdobeAnalyticsEvent(tag, 'tileClick'));

    const isApp = makeSelectIsMotoristApp()(state);
    const isAnyLoyalty =
      makeSelectIsSolCVP()(state) ||
      makeSelectIsLoyaltyFuturesCVP()(state) ||
      isSapHybrisCvp;

    if (
      isApp ||
      makeSelectIsFRCVP()(state) ||
      isAnyLoyalty ||
      makeSelectIsPartner()(state)
    ) {
      dispatch(redirectToCVP());
    } else {
      browserHistory.push(PROFILE_PATHNAME);
    }
  };
}

function showPartnerDefaultNotification(uuid) {
  return async (dispatch, getState) => {
    const isPhoneAuth = makeSelectPhoneAuthentication()(getState());
    try {
      const { partner_name } = makeSelectSettingsPartnersByClientId()(
        getState()
      );
      try {
        await dispatch(activateAnyLoyalty(uuid, true));
      } catch (error) {
        return Promise.reject(error);
      }

      dispatch(
        showNotification(
          buildNotification({
            animation: TickAnimation,
            title: isPhoneAuth
              ? lokaliseTags.SSO_PHONE_CONFIRMATION_SCREEN_TITLE
              : lokaliseTags.SSO_EMAIL_CONFIRMATION_SCREEN_TITLE,
            text: lokaliseTags.SSO_EMAIL_CONFIRMATION_SCREEN_TEXT,
            automatedRedirect: {
              text: lokaliseTags.SSO_EMAIL_CONFIRMATION_SCREEN_SUBTITLE,
              partner: partner_name,
              miliseconds: 8000,
            },
            buttons: [
              {
                label: lokaliseTags.SSO_EMAIL_CONFIRMATION_SCREEN_BUTTON,
                event: () => dispatch(redirectToCVP()),
              },
            ],
          })
        )
      );
      return Promise.resolve();
    } catch {
      return dispatch(
        showDefaultErrorNotification(
          () => dispatch(redirectToCVP()),
          lokaliseTags.SSO_TECHNICAL_ERROR_ARCHETYPE
        )
      );
    }
  };
}

export function checkNotificationText(isMgmUser, isPhoneAuth, getState) {
  if (isMgmUser) {
    return lokaliseTags.SSO_VERIFY_EMAIL_VERIFIED_MGM_TEXT;
  } else if (isPhoneAuth) {
    return lokaliseTags.SSO_VERIFY_VERIFY_PHONE_VERIFIED_TEXT;
  } else {
    return {
      key: lokaliseTags.SSO_VERIFY_EMAIL_VERIFIED_TEXT,
      values: [getState().app.query.email],
    };
  }
}

const formatCVP = () => {
  return (_, getState) => {
    const goPlusFlag = makeSelectIsGoPlusActivatedInSettings()(getState());
    const lodFlag = makeSelectIsLoyaltyFuturesActivatedInAppSettings()(
      getState()
    );
    const solFlag = makeSelectIsLoyaltyActivatedInAppSettings()(getState());
    const sapHybrisflag = makeSelectIsSAPHybrisActivatedInAppSettings()(
      getState()
    );

    if (lodFlag) {
      return 'LF';
    }
    if (goPlusFlag) {
      return 'GO';
    }
    if (solFlag) {
      return 'SOL';
    }
    if (sapHybrisflag) {
      return 'HYBRIS';
    }
    return 'LF';
  };
};

const GOOGLE_ADD_WALLET_BASE_URL = 'https://pay.google.com/gp/v/save/';
const walletEvent = (barcodeFormat) => {
  return async (dispatch, getState) => {
    dispatch(callWalletGenerationAnalytics());
    const isAndroidApp = makeSelectIsAndroid()(getState());
    const BARCODE_FOR_IOS = 'PKBarcodeFormat';
    try {
      const walletToken = await walletPass({
        platform: isAndroidApp ? ANDROID_APP : IOS_APP,
        barcode: isAndroidApp
          ? barcodeFormat
          : `${BARCODE_FOR_IOS}${barcodeFormat
              .charAt(0)
              .toUpperCase()}${barcodeFormat.slice(1)}`,
        scheme: dispatch(formatCVP()),
      });

      if (isAndroidApp) {
        window.location.href = `${GOOGLE_ADD_WALLET_BASE_URL}${walletToken}`;
      } else {
        const blob = new Blob([walletToken], {
          type: 'application/vnd.apple.pkpass',
        });

        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, 'pass.pkpass');
        } else {
          const url = URL.createObjectURL(blob);

          window.location.href = url;

          URL.revokeObjectURL(url);
        }
      }
    } catch (e) {
      dispatch(showDefaultErrorNotification());
    }
  };
};

export const showAccountVerifiedDefaultNotification = () => {
  return async (dispatch, getState) => {
    let featureFlagSettings = { FE_CONFIGS: [] };
    try {
      featureFlagSettings = await getFeatureFlagConfig();
    } catch {
      //! Do nothing here and continue with the code as if feature flags settings is disabled
    }
    const country = makeSelectQueryCountry()(getState());
    const isFirebladeApp = makeSelectIsMotoristFirebladeCVP()(getState());
    const isMobile = makeSelectIsMobile()(getState());
    const isIOSApp = makeSelectIsIOS()(getState());
    const isMgmUser = makeSelectIsMgmUser()(getState());
    const isAndroidApp = makeSelectIsAndroid()(getState());
    const isPhoneAuth = makeSelectPhoneAuthentication()(getState());
    const closeAction = () => dispatch(continueAfterAccountCreation());
    const notificationText = checkNotificationText(
      isMgmUser,
      isPhoneAuth,
      getState
    );

    const appStoreButtons = isAndroidApp
      ? [ANDROID_APP]
      : isIOSApp
      ? [IOS_APP]
      : [ANDROID_APP, IOS_APP];

    const { FE_CONFIGS, [country]: countryConfig } = featureFlagSettings;
    const WALLET_SUPPORT = 'WALLET_SUPPORT';

    let barcodeFormat = null;

    const [major] = getiOSVersion();
    const isiOs17OrLater = major >= 17;
    const isValidDevice =
      isMobile && (isAndroidApp || (!isAndroidApp && isiOs17OrLater));

    const hasToShowWallet =
      FE_CONFIGS.includes(WALLET_SUPPORT) &&
      countryConfig?.activated &&
      !isFirebladeApp &&
      isValidDevice;

    if (hasToShowWallet) {
      barcodeFormat = countryConfig?.barcode_format;
    }

    dispatch(
      showNotification(
        buildNotification({
          animation: TickAnimation,
          title: lokaliseTags.SSO_VERIFY_ACCOUNT_LABEL,
          text: notificationText,
          buttons: [
            {
              label: isPhoneAuth
                ? lokaliseTags.SSO_VERIFY_VERIFY_PHONE_VERIFIED_CONTINUEINBROWSER_TEXT
                : lokaliseTags.SSO_VERIFY_EMAIL_VERIFIED_CONTINUE_IN_BROWSER_LINK,
              event: closeAction,
              variant: 'text',
            },
          ],
          QRCode: !isFirebladeApp && !isMobile,
          appStoreButtons,
          walletButton: isMobile
            ? {
                android: isAndroidApp,
                barcodeFormat,
                show: hasToShowWallet,
                event: () => dispatch(walletEvent(barcodeFormat)),
              }
            : false,
          closeAction,
        })
      )
    );
  };
};

export function showAccountMigratedNotification() {
  return (dispatch, getState) => {
    const isFirebladeApp = makeSelectIsMotoristFirebladeCVP()(getState());
    const isMobile = makeSelectIsMobile()(getState());
    const isIOSApp = makeSelectIsIOS()(getState());
    const isAndroidApp = makeSelectIsAndroid()(getState());
    const closeAction = () => dispatch(continueAfterAccountCreation());
    const buttons = [
      {
        label: lokaliseTags.SSO_MIGRATION_CONTINUE_BUTTON,
        event: closeAction,
      },
    ];

    if (makeSelectCanAttachMultipleCards()(getState())) {
      buttons.unshift({
        variant: 'outlined',
        label: lokaliseTags.SSO_MIGRATION_ATTACH_NEW_CARD,
        event: () => browserHistory.push(SOL_ATTACH_WELCOME_PATHNAME),
      });
    }

    dispatch(
      showNotification(
        buildNotification({
          animation: TickAnimation,
          title: lokaliseTags.SSO_MIGRATION_SECURITY_UPDATED_HEADER,
          text: lokaliseTags.SSO_MIGRATION_SECURITY_UPDATED_TEXT,
          buttons,
          QRCode: !isFirebladeApp && !isMobile,
          appStoreButtons: isAndroidApp
            ? [ANDROID_APP]
            : isIOSApp
            ? [IOS_APP]
            : [ANDROID_APP, IOS_APP],
          closeAction,
        })
      )
    );
  };
}
