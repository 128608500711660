import { SET_EMAIL_TO_VERIFY, SET_IS_REGISTER_FLOW } from './constants';
import { makeSelectEmailToVerify, makeSelectIsRegisterFlow } from './selectors';
import { initializeHeader } from '../header/actions';
import { makeSelectQuery, makeSelectIsMotoristApp } from '../app/selectors';
import { LOGIN_PATHNAME, EDIT_PROFILE_PATHNAME } from '../../shared/pathnames';
import { redirectToCVP } from '../app/actions';
import browserHistory from '../../router/history';
import { makeSelectFormPage } from '../dynamic-form/selectors';
import { REGISTER_PAGE } from '../../shared/formPages';
import { generateHash } from '../../http/core/utils';
import kochavaTags from '../../shared/kochavaTags';
import { sendKochavaEvent } from '../app/utils';

export function initializeVerifyEmail(setHeader) {
  return (dispatch, getState) => {
    const emailAddress = makeSelectEmailToVerify()(getState());
    const query = makeSelectQuery()(getState());
    const isRegisterFlow = makeSelectIsRegisterFlow()(getState());
    dispatch(
      initializeHeader(
        () => {
          dispatch(setEmailToVerify(''));

          if (!isRegisterFlow) {
            browserHistory.replace(EDIT_PROFILE_PATHNAME);
            return;
          }

          if (makeSelectIsMotoristApp()(getState()) || query.redirect) {
            dispatch(redirectToCVP());
          } else {
            browserHistory.replace(LOGIN_PATHNAME);
          }
        },
        null,
        setHeader,
        { headerLeftIcon: 'close' }
      )
    );
    if (!emailAddress) {
      browserHistory.replace('/');
    }
  };
}

export function setEmailToVerify(email) {
  return {
    type: SET_EMAIL_TO_VERIFY,
    payload: email,
  };
}

export function setIsRegisterFlow(isRegisterFlow) {
  return {
    type: SET_IS_REGISTER_FLOW,
    payload: isRegisterFlow,
  };
}

export function sendKochavaRegisterAnalytic() {
  return (dispatch, getState) => {
    const form = makeSelectFormPage(REGISTER_PAGE)(getState());
    const fakeId = generateHash(
      form.values.emailAddress || form.values.mobile?.replaceAll(' ', '')
    );
    dispatch(sendKochavaEvent(kochavaTags.register.confirmEmail, { fakeId }));
  };
}

export default { setEmailToVerify, initializeVerifyEmail, setIsRegisterFlow };
