import React from 'react';
import AES from 'crypto-js/aes';
import qs from 'qs';
import encUTF8 from 'crypto-js/enc-utf8';
import { Api } from '@shell-b2c/http-frontend/dist/src';
import {
  CLIENT_ID,
  DEBUG,
  KOCHAVA_GUID,
  KOCHAVA_TRACE_LEVEL,
} from '../../shared/constants';
import { Icon } from '@shell-b2c/web-design-system';
import { lodProxyActivateUser } from '@shell-b2c/http-frontend/dist/src/components/core/lod';
import { hybrisActivateUser } from '@shell-b2c/http-frontend/dist/src/components/core/hybris';
import { activateUser } from '@shell-b2c/http-frontend/dist/src/components/core/loyalty';
import Loader from '../../shared/components/Loader';
import { getTranslation } from '../../shared/translation-utils';
import { Kochava } from 'kochava';
import { makeSelectIsMobile } from '../browser/selectors';

const salt = '3@hap&$xnqp);';

export function getStoredData(name, parse = false) {
  let appData = {};
  const cookieData = () => {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(';');
    for (const element of ca) {
      let c = element;
      while (c.startsWith(' ')) c = c.substring(1, c.length);
      if (c.startsWith(nameEQ)) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  };

  if (typeof Storage !== 'undefined') {
    try {
      appData = localStorage.getItem(name);
      if (appData == null) appData = sessionStorage.getItem(name);
    } catch (e) {
      console.error('app-utils: ', e);
    }
  } else {
    try {
      appData = cookieData();
    } catch (e) {
      console.error('app-utils: ', e);
    }
  }
  let returnVal;
  try {
    returnVal = AES.decrypt(appData, salt);
  } catch (e) {
    returnVal = parse ? [] : '';
  }
  if (parse) {
    try {
      return JSON.parse(returnVal.toString(encUTF8));
    } catch (e) {
      return {};
    }
  }
  return returnVal.toString(encUTF8);
}

export function setDataToStore(name, data, session) {
  const dataToStore = AES.encrypt(data, salt);
  const date = new Date();
  const expire = new Date();

  if (typeof Storage !== 'undefined') {
    try {
      if (session) {
        sessionStorage.setItem(name, dataToStore);
      } else {
        localStorage.setItem(name, dataToStore);
      }
    } catch (e) {
      expire.setDate(date.getDate() + 1);
      document.cookie = `${name}=${dataToStore}; expires= ${expire.toString()}; path=/`;
    }
  } else {
    expire.setDate(date.getDate() + 1);
    document.cookie = `${name}=${dataToStore}; expires= ${expire.toString()}; path=/`;
  }
}

export function deleteStorage(name) {
  if (typeof Storage !== 'undefined') {
    localStorage.removeItem(name);
    sessionStorage.removeItem(name);
  } else {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  }
}

export function deleteAllStorageData() {
  deleteStorage('profile');
  deleteStorage('auth');
  deleteStorage(CLIENT_ID);
  deleteStorage('query');
  deleteStorage('webview');
  deleteStorage('service');
  deleteStorage('redirect');
  if (typeof Storage !== 'undefined') {
    Object.keys(sessionStorage || {}).forEach((name) => {
      if (name !== 'authCode') {
        sessionStorage.removeItem(name);
      }
    });
  }
  Api.accessToken = null;
}

export function getIcon(button, visible) {
  if (!visible) {
    return <span />;
  }
  switch (button.icon) {
    case 'back':
      return <Icon type="arrow-left" data-testid="arrow-left" />;
    case 'close':
      return <Icon type="close" data-testid="close" />;
    case 'loading':
      return <Loader loaded={false} />;
    default:
      return <span style={{ fontSize: '12px' }}>{translate(button.name)}</span>;
  }
}

export function addQueryStringParam(url, name, value) {
  if (!url) {
    return '';
  }

  const separator = url.indexOf('?') !== -1 ? '&' : '?';
  return `${url}${separator}${name}=${value}`;
}

export async function loadScript(url, condition = true) {
  if (condition) {
    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.src = url;
    script.async = true;

    document.body.appendChild(script);
  }
}

export function isIE11() {
  return !!window.MSInputMethodContext && !!document.documentMode;
}

export function cleanInitialUrl(queryString) {
  const finalUrlParams = {};
  const parsed = qs.parse(queryString, {
    ignoreQueryPrefix: true,
  });

  Object.keys(parsed).forEach((key) => {
    if (
      [
        'utm_campaign',
        'utm_source',
        'utm_medium',
        'utm_content',
        'cid',
        'adobe_mc',
        'adobe_aa_vid',
      ].indexOf(key) !== -1
    ) {
      finalUrlParams[key] = parsed[key];
    }
  });

  return qs.stringify(finalUrlParams, { addQueryPrefix: true });
}

export function compareScopes(queryScopes, configScopes) {
  const queryScopesData = {};
  for (let i = 0; i < queryScopes.length; i++) {
    const element = queryScopes[i].split(':');
    queryScopesData[element[0]] = queryScopesData[element[0]]
      ? [...queryScopesData[element[0]], element[1]]
      : [element[1]];
  }
  const newQueryScopes = [];
  Object.keys(queryScopesData).forEach((key) => {
    const queryScopesElement = queryScopesData[key];
    queryScopesElement.forEach((queryScopesElementValue) => {
      configScopes.forEach((configScope) => {
        if (
          queryScopesElementValue === configScope.name &&
          configScope.action === key
        ) {
          newQueryScopes.push(`${key}:${queryScopesElementValue}`);
        }
      });
    });
  });
  return newQueryScopes;
}

export function transformMobileMasked(mobile, visibleBackNumbers) {
  let retVal = '';
  for (let i = 0; i < mobile.length; i++) {
    const char = mobile[i];
    if (
      char !== '+' &&
      char !== ' ' &&
      i < mobile.length - visibleBackNumbers
    ) {
      retVal += 'X';
    } else {
      retVal += char;
    }
  }
  return retVal;
}

export async function makeActivate({
  lfEnabled,
  goPlusEnabled,
  isSapHybrisActivatedinProfile,
  activateParam,
}) {
  if (isSapHybrisActivatedinProfile) {
    await hybrisActivateUser();
  } else if (lfEnabled && !goPlusEnabled) {
    await activateUser({ ...activateParam });
  } else {
    await lodProxyActivateUser({ ...activateParam });
  }
}

const app = {};
const isAutomation = true;
const { translations, query } = app;
const showKey = app.showTag === 1 && (DEBUG || isAutomation);
export const translate = (prop, clean, values) =>
  getTranslation(
    prop,
    translations,
    showKey,
    clean,
    query.market,
    query.appVersion,
    values
  );

export function getConsentCookies() {
  if (document.cookie.match('_evidon_consent_cookie') == null) {
    return {};
  } else if (
    typeof localStorage != 'undefined' &&
    typeof localStorage[
      '_evidon_consent_ls_' + window.evidon?.notice?.activeSettings?.id
    ] == 'undefined' &&
    localStorage[
      '_evidon_consent_ls_' + window.evidon?.notice?.activeSettings?.id
    ] === ''
  ) {
    return {};
  } else {
    try {
      let cookiesAccepted = {};

      const evidonLocalStorage =
        window.localStorage[
          '_evidon_consent_ls_' + window.evidon?.notice?.activeSettings?.id
        ];

      const evidonLocalStorageCategories =
        JSON.parse(evidonLocalStorage).categories[
          window.evidon?.notice?.activeCountryId
        ];

      if (evidonLocalStorageCategories) {
        for (let key in evidonLocalStorageCategories) {
          if (evidonLocalStorageCategories.hasOwnProperty(key)) {
            cookiesAccepted[key.toUpperCase().replace(/\s/g, '_')] =
              evidonLocalStorageCategories[key];
          }
        }
      }

      return cookiesAccepted;
    } catch (e) {
      return {};
    }
  }
}

export class KochavaSetup {
  constructor() {
    this.instance = KochavaSetup.getKochavaInstance();
  }

  static getKochavaInstance() {
    if (!this.instance) {
      this.instance = Kochava.createForReact();
      this.instance.disableAutoPage(true);
      this.instance.useCookies(false);
      this.instance.startWithAppGuid(KOCHAVA_GUID);
      if (KOCHAVA_TRACE_LEVEL) {
        this.instance.setLogLevel('Trace');
      } else {
        this.instance.setLogLevel('Info');
      }
    }

    return this.instance;
  }
}

export const sendKochavaEvent = (tag, data) => {
  return (_, getState) => {
    const isMobile = makeSelectIsMobile()(getState());
    const acceptedCookies = getConsentCookies();

    if (
      acceptedCookies?.ADVERTISING_AND_SOCIAL_MEDIA_COOKIES === true ||
      isMobile
    ) {
      const { instance } = new KochavaSetup();

      instance.sendEvent(tag, data);
    }
  };
};
