import {
  SOL_LOYALTY_CARDS_PATHNAME,
  LOYALTY_PATHNAME,
  LOYALTY_MIGRATION_PATHNAME,
  SOL_CARD_INPUT_PATHNAME,
  WELCOME_LOYALTY_PATHNAME,
  SOL_ATTACH_WELCOME_PATHNAME,
  SOL_ATTACH_PAYSONSITE_OLD_SUCCESS_PATHNAME,
  SOL_ATTACH_PAYSONSITE_SUCCESS_PATHNAME,
  SOL_ATTACH_PAYSONSITE_OLD_ERROR_PATHNAME,
  SOL_ATTACH_PAYSONSITE_ERROR_PATHNAME,
} from '../shared/pathnames';

// Redirections [FROM, TO]
export default [
  [LOYALTY_PATHNAME, SOL_LOYALTY_CARDS_PATHNAME],
  [LOYALTY_MIGRATION_PATHNAME, SOL_CARD_INPUT_PATHNAME],
  [WELCOME_LOYALTY_PATHNAME, SOL_ATTACH_WELCOME_PATHNAME],
  [
    SOL_ATTACH_PAYSONSITE_OLD_SUCCESS_PATHNAME,
    SOL_ATTACH_PAYSONSITE_SUCCESS_PATHNAME,
  ],
  [
    SOL_ATTACH_PAYSONSITE_OLD_ERROR_PATHNAME,
    SOL_ATTACH_PAYSONSITE_ERROR_PATHNAME,
  ],
];
