export const additionalStepsArray = ['/verifyEmail'];
export const marketNoCommsPage = ['TR'];
export const registerFlowPathnames = [
  '/register',
  '/verifyEmail',
  '/comms',
  '/vehiclepreferences',
  '/aboutcomms',
  '/marketingcomms',
];
