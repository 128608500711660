export const SET_OTP_TOKEN = '[MFA] SET_OTP-TOKEN';
export const SET_OTP_MOBILE = '[2FA] SET_OTP-MOBILE';
export const SET_IS_SMS = '[2FA] SET_IS_SMS';
export const SET_ERROR = '[MFA] SET_ERROR';

const initialState = {
  otpToken: null,
  error: null,
  mobile: null,
  isSMS: null,
};

export default function (state = initialState, action) {
  if (action.type === SET_OTP_TOKEN) {
    return { ...state, otpToken: action.otpToken };
  } else if (action.type === SET_OTP_MOBILE) {
    return { ...state, mobile: action.mobile };
  } else if (action.type === SET_IS_SMS) {
    return { ...state, isSMS: action.isSMS };
  } else if (action.type === SET_ERROR) {
    return { ...state, error: action.error };
  }

  return state;
}
