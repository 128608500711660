import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import { makeSelectIsMobile } from '../../features/browser/selectors';
import NotificationHeader from './NotificationHeader';
import NotificationContent from './NotificationContent';
import NotificationButtons from './NotificationButtons';
import NotificationActions, { setSnackbarError } from './actions';
import Styling from './style';
import {
  makeSelectQueryRedirect,
  makeSelectIsMotoristApp,
} from '../../features/app/selectors';
import { UK_MARKET } from '../../shared/markets';
import { selectNotificationDomain } from './selectors';

export const Notification = ({
  translate,
  notification,
  notificationActions,
  isMobile,
  redirect,
  isMotoristApp,
}) => {
  const dispatch = useDispatch();
  const [snackIsOpen, setSnackIsOpen] = useState(false);
  const { snackbarError } = useSelector(selectNotificationDomain());

  const toggleNotificationClass = useCallback(() => {
    const element = document.querySelector('#main-container');
    if ('classList' in document.body && element) {
      element.classList.toggle('notification-visible');
    }
  }, []);

  useEffect(() => {
    if (snackbarError) setSnackIsOpen(true);
    dispatch(setSnackbarError(false));
  }, [snackbarError, dispatch]);

  useEffect(() => {
    toggleNotificationClass();
    return () => {
      toggleNotificationClass();
    };
  }, [toggleNotificationClass]);

  const wrapperStyles = isMobile
    ? { ...Styling.wrapper, ...{ position: 'fixed' } }
    : { ...Styling.wrapper, ...Styling.centered };

  return (
    <Grid container style={wrapperStyles}>
      <Grid item xs={12}>
        <NotificationHeader
          translate={translate}
          notification={notification}
          notificationActions={notificationActions}
          isMobile={isMobile}
          redirect={redirect}
          isMotoristApp={isMotoristApp}
        />
        <NotificationContent
          translate={translate}
          notification={notification}
          isMobile={isMobile}
          snackIsOpen={snackIsOpen}
          setSnackIsOpen={setSnackIsOpen}
        />
        <NotificationButtons
          translate={translate}
          notification={notification}
          notificationActions={notificationActions}
          isMobile={isMobile}
        />
      </Grid>
    </Grid>
  );
};

Notification.propTypes = {
  translate: PropTypes.func.isRequired,
  notificationActions: PropTypes.object.isRequired,
  notification: PropTypes.object.isRequired,
  isMobile: PropTypes.bool,
  redirect: PropTypes.string,
  isMotoristApp: PropTypes.bool.isRequired,
};

Notification.defaultProps = {
  market: UK_MARKET,
};

const mapStateToProps = (store) => ({
  notification: store.notification,
  isMobile: makeSelectIsMobile()(store),
  redirect: makeSelectQueryRedirect()(store),
  isMotoristApp: makeSelectIsMotoristApp()(store),
});

const mapDispatchToProps = (dispatch) => ({
  notificationActions: bindActionCreators(NotificationActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
