import {
  SET_MOBILE,
  SET_VERIFICATION_CODE_ERROR,
  SET_RESET_DATA,
} from './constants';

const initialState = {
  mobile: '',
  hasError: false,
  resetData: {},
};

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case SET_MOBILE:
      return {
        ...state,
        mobile: payload,
      };
    case SET_VERIFICATION_CODE_ERROR:
      return {
        ...state,
        hasError: payload,
      };
    case SET_RESET_DATA:
      return {
        ...state,
        resetData: payload,
      };
    default:
      return state;
  }
}
