import { SET_LOD_PROFILE_FORM } from './constants';

export const initialState = {
  data: {},
  form: {},
};

const lodProfile = (state = initialState, action) => {
  const { type, payload } = action;
  if (type === SET_LOD_PROFILE_FORM) {
    return {
      ...state,
      form: payload,
    };
  }

  return state;
};

export default lodProfile;
