export default {
  shellLogo: {
    width: '33px',
    height: '30px',
    margin: '16px',
    marginLeft: '5px',
  },

  headerContainer: {
    position: 'fixed',
    left: '0',
    top: '0',
    width: '100%',
    minWidth: '64px',
    height: '64px',
    boxSizing: 'border-box',
    padding: '0 15px',
    zIndex: '10000000',
    WebkitBackfaceVisibility: 'hidden',
  },

  headerContainerMobile: {
    width: 'calc(100% - 10px)',
    background: 'white',
  },

  closeIcon: {
    margin: '19px 5px',
    cursor: 'pointer',
  },
  left: {
    margin: '19px 0',
    display: 'block',
    overflow: 'hidden',
    paddingLeft: 0,
  },
  right: {
    margin: '19px 0',
    textAlign: 'right',
    overflow: 'hidden',
    paddingRight: 0,
  },
  icon: {
    height: '16px',
    width: '16px',
    cursor: 'pointer',
  },
  arrowLeftIcon: {
    cursor: 'pointer',
  },
  toggleTagHeader: {
    height: '50px',
  },
  defaultIcon: {
    fontSize: '12px',
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: 8,
  },
};
