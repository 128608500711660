import { POPUP_CHANGE_ATTRIBUTE, SHOW_POPUP } from './constants';
import { CLOSE_POPUP } from '../app/constants';
import mergeDeep from 'lodash/merge';
import cloneDeep from 'lodash/cloneDeep';

const PopupActions = {
  buttonClick(keepOpen, event) {
    return (dispatch) => {
      if (keepOpen !== true) {
        dispatch({
          type: POPUP_CHANGE_ATTRIBUTE,
          name: 'visible',
          value: false,
        });
      }
      if (event) {
        event();
      }
    };
  },
  saveCropImage(data) {
    return (dispatch) => {
      dispatch({
        type: POPUP_CHANGE_ATTRIBUTE,
        name: 'cropper',
        value: data,
      });
    };
  },
};

export function showPopup(popup, config = {}) {
  const popupClone = cloneDeep(popup);
  const value = mergeDeep(popupClone, config);

  return {
    type: SHOW_POPUP,
    value,
  };
}

export function closePopup(value) {
  return {
    type: CLOSE_POPUP,
    value,
  };
}

export default PopupActions;
