// This file includes all form page names
export const LOGIN_PAGE = 'login';
export const REGISTER_PAGE = 'register';
export const MIGRATION_PAGE = 'migration';
export const MIGRATION_PASSWORD_PAGE = 'migrationPassword';
export const EDIT_PROFILE_PAGE = 'editProfile';
export const CHANGE_PASSWORD_PAGE = 'changePassword';
export const FORGOT_PASSWORD_PAGE = 'forgotPassword';
export const RESET_PASSWORD_PAGE = 'resetPassword';
export const CHANGE_QUESTION_PAGE = 'changeQuestion';
export const VERIFY_PASSWORD_PAGE = 'verifyPassword';
export const DRIVE_PAGE = 'drive';
export const LOYALTY_PAGE = 'loyalty';
export const LOYALTY_INITIAL_PAGE = 'loyaltyInitial';
export const LOYALTY_MIGRATION_PAGE = 'loyaltyMigration';
export const SOL_LOGIN_PAGE = 'solLogin';
export const SOL_MIGRATION_PAGE = 'solMigration';
export const SOL_PAYONSITE_DETAILS_PAGE = 'solPayonsiteDetails';
export const SOL_FORGOT_PASSWORD_PAGE = 'solForgotPassword';
export const VERIFY_USER_PAGE = 'verifyUser';
export const DELETE_ACCOUNT_PAGE = 'deleteAccount';
export const CHANGE_EMAIL_PAGE = 'changeEmail';
export const LOD_PROFILE_PAGE = 'lodProfile';
export const LOD_ATTACH_PAGE = 'lodAttach';
export const CHANGE_MOBILE_PAGE = 'changeMobile';
export const VERIFY_MOBILE_CHANGE_PAGE = 'verifyMobileChange';
export const LOYALTY_FUTURES_CARD_INPUT_PAGE = 'loyaltyFuturesCardInput';
export const SAP_HYBRIS_CARD_INPUT_PAGE = 'sapHybrisCardInput';
export const LOYALTY_FUTURES_CARD_VALIDATION_PAGE =
  'loyaltyFuturesCardValidation';
export const LOYALTY_FUTURES_CARD_VALIDATION_BIRTHDATE_PAGE =
  'loyaltyFuturesCardValidationBirthDate';
export const LOYALTY_FUTURES_CARD_VALIDATION_MOBILE_PAGE =
  'loyaltyFuturesCardValidationMobile';
export const LOYALTY_FUTURES_MIGRATION_ID_PAGE = 'migrationId';
export const LOYALTY_FUTURES_MIGRATION_VALIDATION_PAGE =
  'loyaltyFuturesMigrationValidation';
export const LOYALTY_FUTURES_ATTACH_VALIDATION_PAGE =
  'loyaltyFuturesAttachValidation';
export const SOL_CARD_VALIDATION_PAGE = 'solMigrationValidation';
export const NO_FORM = 'NO_FORM';

export const TWO_FA_MOBILE_PAGE = 'twoFaMobile';
export const TWO_FA_PASSWORD_PAGE = 'passwordVerificationForm';

export const PROGRESSIVE_PROFILING_PAGE = 'progressiveProfilingForm';

export const AIRMILES_REGISTRATION_PAGE = 'airmilesRegistration';
export const AIRMILES_FORM_PAGE = 'airmilesLogin';
