import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import isEqual from 'lodash/isEqual';
import { sanitizeHtmlTranslation } from './translation-utils';
import {
  CAPTCHA_KEY_V3,
  AUTOMATION_CAPTCHA_KEY_V3,
  NUMBERS,
  HEXNUMBERS,
} from './constants';
import {
  makeSelectIsGoPlusActivatedInSettings,
  makeSelectIsLoyaltyActivatedInAppSettings,
  makeSelectIsLoyaltyFuturesActivatedInAppSettings,
  makeSelectIsSAPHybrisActivatedInAppSettings,
} from '../features/settings/selectors';
import {
  GO_PLUS_CVP,
  LOYALTY_FUTURES_CVP,
  SAP_HYBRIS_CVP,
  SOL_CVP,
} from './cvps';
import kochavaTags from './kochavaTags';

export function getCountryFromMarket(market = '') {
  return market.split('-')[1];
}

export function parseDateToDate(value, from, to) {
  const year = from.indexOf('yyyy');
  const month = from.indexOf('mm');
  const day = from.indexOf('dd');

  if (value == null || value === '') return '';

  let newDate = to;
  newDate = newDate.replace('yyyy', value.substring(year, year + NUMBERS.FOUR));
  newDate = newDate.replace('mm', value.substring(month, month + NUMBERS.TWO));
  newDate = newDate.replace('dd', value.substring(day, day + NUMBERS.TWO));
  return newDate;
}

/*
eslint-disable
*/
/**
 * Decodes utf-8 encoded string back into multi-byte Unicode characters.
 *
 * Can be achieved JavaScript by decodeURIComponent(escape(str)),
 * but this approach may be useful in other languages.
 *
 * Gist: https://gist.github.com/MarcelloDiSimone/933a13c6a5b6458ce29d972644bb5892
 *
 * @param {string} strUtf UTF-8 string to be decoded back to Unicode.
 * @returns {string} Decoded string.
 */
export function Utf8Decode(strUtf) {
  if (typeof strUtf != 'string' || strUtf.length <= NUMBERS.ZERO) {
    if (isNaN(strUtf) || !strUtf) {
      return null;
    }
    return strUtf.toString();
  }
  // note: decode 3-byte chars first as decoded 2-byte strings could appear to be 3-byte char!
  // note: decode 3-byte chars first as decoded 2-byte strings could appear to be 3-byte char!
  return sanitizeHtmlTranslation(
    String(strUtf)
      .replace(
        /[\u00e0-\u00ef][\u0080-\u00bf][\u0080-\u00bf]/g, // 3-byte chars
        (c) => {
          // (note parentheses for precedence)
          const cc =
            ((c.charCodeAt(NUMBERS.ZERO) & HEXNUMBERS.FIFTEEN) <<
              NUMBERS.TWELVE) |
            ((c.charCodeAt(NUMBERS.ONE) & HEXNUMBERS.SIXTYTHREE) <<
              NUMBERS.SIX) |
            (c.charCodeAt(NUMBERS.TWO) & HEXNUMBERS.SIXTYTHREE);
          return String.fromCharCode(cc);
        }
      )
      .replace(
        /[\u00c0-\u00df][\u0080-\u00bf]/g, // 2-byte chars
        (c) => {
          // (note parentheses for precedence)
          const cc =
            ((c.charCodeAt(0) & HEXNUMBERS.THIRTYONE) << NUMBERS.SIX) |
            (c.charCodeAt(1) & HEXNUMBERS.SIXTYTHREE);
          return String.fromCharCode(cc);
        }
      )
  );
}

export const hasToShowBirthdate = (profile) => {
  if (
    profile.goplus.activated ||
    (profile.loyalty.activated && !profile.profile.birthdate)
  ) {
    return true;
  }

  return !profile.loyalty.activated;
};

export const setScrollToTop = (id = 'content-wrapper') => {
  const container = document.getElementById(id);
  if (container) {
    window.scroll(0, 0);
    container.scrollTop = 0;
  }
};

export const isUserProfileModified = (profile, formData) => {
  let userProfileModified = false;
  Object.keys(profile).forEach((profileFieldName) => {
    if (isArray(profile[profileFieldName])) {
      if (!isEqual(profile[profileFieldName], formData[profileFieldName])) {
        userProfileModified = true;
      }
    } else if (isObject(profile[profileFieldName])) {
      if (isUserProfileModified(profile[profileFieldName], formData)) {
        userProfileModified = true;
      }
    } else if (
      !isEqual(profile[profileFieldName], formData[profileFieldName])
    ) {
      userProfileModified = true;
    }
  });
  return userProfileModified;
};

export const addUrlParams = (url, params) => {
  let firstParam = url.indexOf('?') === -1;
  let urlWithParams = url;
  params.forEach(({ param, value }) => {
    if (value) {
      if (firstParam) {
        urlWithParams = `${urlWithParams}?${param}=${value}`;
        firstParam = false;
      } else {
        urlWithParams = `${urlWithParams}&${param}=${value}`;
      }
    }
  });
  return urlWithParams;
};

export const hideMobileKeyboard = () => {
  document.activeElement.blur();
};

export function loadRecaptcha(siteKey, language = 'en') {
  const script = document.createElement('script');

  script.src = `https://www.google.com/recaptcha/enterprise.js?render=${siteKey}&hl=${language}`;

  document.body.appendChild(script);
}

export async function getCaptchaToken(action) {
  const isAutomation = !!window.Cypress;

  if (window.grecaptcha) {
    try {
      return window.grecaptcha.enterprise.execute(
        isAutomation ? AUTOMATION_CAPTCHA_KEY_V3 : CAPTCHA_KEY_V3,
        {
          action,
        }
      );
    } catch (error) {
      console.error('CAPTCHA ERROR: ', error);
    }
  }
  return null;
}

export function redirectWithData(path, params, method = 'post') {
  const form = document.createElement('form');
  form.method = method;
  form.action = path;

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const hiddenField = document.createElement('input');
      hiddenField.type = 'hidden';
      hiddenField.name = key;
      hiddenField.value = params[key];

      form.appendChild(hiddenField);
    }
  }

  document.body.appendChild(form);
  form.submit();
}

export const SAFARI_BROWSER = 'Safari';
export const CHROME_BROWSER = 'Chrome';
export const FIREFOX_BROWSER = 'Firefox';

export function getBrowser() {
  const userAgent = window.navigator.userAgent;

  if (userAgent.indexOf('Safari') > -1 && userAgent.indexOf('Chrome') === -1) {
    return SAFARI_BROWSER;
  }

  if (userAgent.indexOf('Chrome') > -1) {
    return CHROME_BROWSER;
  }

  if (userAgent.indexOf('Firefox') > -1) {
    return FIREFOX_BROWSER;
  }
}

export function getAppCVP(state) {
  const isLoyaltyFuturesActivatedInSettings =
    makeSelectIsLoyaltyFuturesActivatedInAppSettings()(state);
  const isSolActivatedInSettings =
    makeSelectIsLoyaltyActivatedInAppSettings()(state);
  const isGoPlusActivatedInSettings =
    makeSelectIsGoPlusActivatedInSettings()(state);
  const isSapHybrisActivatedInAppSettings =
    makeSelectIsSAPHybrisActivatedInAppSettings()(state);

  if (isLoyaltyFuturesActivatedInSettings) {
    return LOYALTY_FUTURES_CVP;
  }

  if (isSolActivatedInSettings) {
    return SOL_CVP;
  }

  if (isGoPlusActivatedInSettings) {
    return GO_PLUS_CVP;
  }

  if (isSapHybrisActivatedInAppSettings) {
    return SAP_HYBRIS_CVP;
  }
}

export function checkPartnerOnExternalSystemIds(
  partnersDataFromCoco,
  partnersDataFromProfile
) {
  const partnerData = partnersDataFromProfile
    ?.map((partnerFromProfile) => partnerFromProfile.identifier)
    .includes(partnersDataFromCoco?.partner_code);

  return !!partnerData;
}

export function transformPartnersDataFromCocoToExternalSystemId(
  partnerByClientId
) {
  return (
    partnerByClientId && {
      identifier: partnerByClientId.partner_code,
      name: partnerByClientId.partner_name,
    }
  );
}

/**
 * Function to identify the current step and return a
 * pre-defined event name for Kochava analytics
 *
 * @param key
 * @returns {string}
 */
export function getAnalyticsStepEventName(key) {
  const events = {
    firstName: kochavaTags.register.name,
    patronymicName: kochavaTags.register.name,
    lastName: kochavaTags.register.name,
    emailAddress: kochavaTags.register.email,
    mobile: kochavaTags.register.mobile,
    newPassword: kochavaTags.register.password,
    customerType: kochavaTags.register.vehicleType,
  };

  return events[key] || undefined;
}

export const getiOSVersion = () => {
  const userAgent = navigator.userAgent;
  const iphoneNameRegex = /iP(hone|od|ad)/;
  let isApple = false;
  try {
    if (iphoneNameRegex.test(userAgent)) {
      isApple = true;
    }
  } catch {
    //! Do nothing, continue
  }
  if (isApple) {
    const match = userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/);
    if (match !== null) {
      return [
        parseInt(match[1], 10),
        parseInt(match[2], 10),
        parseInt(match[3] || '0', 10),
      ];
    }
  }
  return [0, 0, 0];
};
