export const SET_SCOPES_CONFIG = '[SCOPES] SET_SCOPES_CONFIG';
export const SET_SCOPES_ERRORS = '[SCOPES] SET_SCOPES_ERRORS';
export const SET_ALREADY_ACCEPTED_SCOPES =
  '[SCOPES] SET_ALREADY_ACCEPTED_SCOPES';

const initialState = {
  scopesConfig: { name: '', scopes: [], logo: '' },
  scopesErrors: { missingScopes: [] },
  alreadyAcceptedScopes: [],
};

export default function (state = initialState, action) {
  if (action.type === SET_SCOPES_CONFIG) {
    return { ...state, scopesConfig: action.scopesConfig };
  }

  if (action.type === SET_SCOPES_ERRORS) {
    return { ...state, scopesErrors: action.scopesErrors };
  }

  if (action.type === SET_ALREADY_ACCEPTED_SCOPES) {
    return { ...state, alreadyAcceptedScopes: [...action.acceptedScopes] };
  }
  return state;
}
