import { createSelector } from 'reselect';
import get from 'lodash/get';
import { ONBOARDING_MGM } from './constants';
import { CONSENT_DISPLAY } from '../about-comms/constants';

const selectProfileDomain = () => (state) => state.profile;

const makeSelectUserProfile = () =>
  createSelector(selectProfileDomain(), (profile) => profile.profile);

const makeSelectLoDData = () =>
  createSelector(selectProfileDomain(), (profile) => ({
    lodCardId: get(profile, 'loyalty.accounts[0].card[0].cardId', ''),
    vehicleRegistrationNumber: get(
      profile,
      'loyalty.vehicleRegistrationNumber',
      ''
    ),
  }));

const makeSelectLodCardsByType = (cardType) =>
  createSelector(selectProfileDomain(), (profile) => {
    const lodAccounts = get(profile, 'loyalty.accounts', [
      {
        card: [],
      },
    ]);

    return (
      lodAccounts
        .flatMap(({ card }) => card)
        .filter((card) => card.cardType === cardType) || []
    );
  });

const makeSelectUUID = () =>
  createSelector(selectProfileDomain(), (profile) => profile.uuid);

const makeSelectIsGoplusMarketingConsentEnabled = () =>
  createSelector(selectProfileDomain(), (profile) =>
    get(profile, 'consents.goPlusMarketingOptIn', false)
  );

const makeSelectIsGoPlusActivated = () =>
  createSelector(selectProfileDomain(), (profile) =>
    get(profile, 'goplus.activated', false)
  );

const makeSelectProfilePhoneNumber = () =>
  createSelector(selectProfileDomain(), (profile) =>
    get(profile, 'profile.phone.mobile', null)
  );

const makeSelectProfileEmail = () =>
  createSelector(selectProfileDomain(), (profile) =>
    get(profile, 'profile.emailAddress', null)
  );

const makeSelectIsProfileLoaded = () =>
  createSelector(selectProfileDomain(), (profile) => profile.loaded);

const makeSelectIsMgmUser = () =>
  createSelector(selectProfileDomain(), (profile) => {
    const externalSystemIds = get(profile, 'externalSystemIds', null);

    const mgmPartner = externalSystemIds?.find((partner) =>
      [ONBOARDING_MGM].includes(partner.name)
    );

    return !!mgmPartner;
  });

const makeSelectConsentsPopup = () =>
  createSelector(selectProfileDomain(), (profile) =>
    get(profile, 'consents.PopUpStatus', CONSENT_DISPLAY)
  );

export default selectProfileDomain;

export {
  selectProfileDomain,
  makeSelectUserProfile,
  makeSelectUUID,
  makeSelectLoDData,
  makeSelectIsGoplusMarketingConsentEnabled,
  makeSelectIsGoPlusActivated,
  makeSelectIsProfileLoaded,
  makeSelectProfilePhoneNumber,
  makeSelectProfileEmail,
  makeSelectLodCardsByType,
  makeSelectIsMgmUser,
  makeSelectConsentsPopup,
};
