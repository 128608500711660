import SHA256 from 'crypto-js/sha256';
import AES from 'crypto-js/aes';
import encUTF8 from 'crypto-js/enc-utf8';

const salt = '3@hap&$xnqp);';

export function generateHash(data) {
  return SHA256(data).toString();
}

export function decryptData(data) {
  const bytes = AES.decrypt(data, salt);
  return bytes.toString(encUTF8);
}

export function encryptData(clientId) {
  const bytes = AES.encrypt(clientId, salt);
  return bytes.toString();
}
