import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import App from './features/app/App';
import { DEBUG } from './shared/constants';
import configureStore from './shared/configureStore';
import ErrorBoundary from './shared/components/ErrorBoundary';
import './index.css';
import * as serviceWorker from './serviceWorker';
import ScrollToTop from './shared/components/ScrollToTop';
import { reactPlugin } from './shared/appInsights';
import CustomRouter from './router/CustomRouter';

const store = configureStore();

const wrapperStyles = {
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
};
// eslint-disable-next-line
if (window?._satellite?.setDebug && DEBUG) {
  window._satellite.setDebug(DEBUG); // eslint-disable-line
}
ReactDOM.render(
  <Provider store={store}>
    <CustomRouter>
      <ErrorBoundary>
        <div style={wrapperStyles}>
          <AppInsightsContext.Provider value={reactPlugin}>
            <ScrollToTop />
            <App/>
          </AppInsightsContext.Provider>
        </div>
      </ErrorBoundary>
    </CustomRouter>
  </Provider>,
  document.getElementById('root') || document.createElement('div')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
