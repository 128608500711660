export const ENGLISH = 'en';
export const HUNGARIAN = 'hu';
export const POLISH = 'pl';
export const INDONESIAN = 'id';
export const BULGARIAN = 'bg';
export const CZECH = 'cs';
export const SLOVAK = 'sk';
export const FRENCH = 'fr';
export const ARABIC = 'ar';
export const SPANISH = 'es';
export const GERMAN = 'de';
export const DUTCH = 'nl';
export const DANISH = 'da';
