import { createSelector } from 'reselect';
import get from 'lodash/get';
import each from 'lodash/each';
import includes from 'lodash/includes';
import isArray from 'lodash/isArray';

import isPlainObject from 'lodash/isPlainObject';
import {
  TEXT_FORM_FIELD,
  MULTIPLE_SELECT_FORM_FIELD,
  PARENT_FORM_FIELD,
  SELECT_FORM_FIELD,
  MASK_FORM_FIELD,
  BUTTONS_LABELS,
  BACKEND_DATE_FORMAT,
  CHECKBOX_FORM_FIELD,
} from './constants';
import selectProfileDomain from '../profile/selectors';
import { makeSelectIsMobile } from '../browser/selectors';
import { makeSelectDateFormat } from '../settings/selectors';
import {
  makeSelectQuery,
  makeSelectCVP,
  makeSelectTranslations,
} from '../app/selectors';
import { LOGIN_PAGE, VERIFY_USER_PAGE } from '../../shared/formPages';
import {
  LOYALTY_SERVICE,
  DRIVE_SERVICE,
  PAYMENTS_SERVICE,
} from '../../shared/formServices';
import { getIcon } from '../../shared/icons';
import lokaliseTags from '../../shared/lokaliseTags';

function isRequired(required, services, profile) {
  if (required === true || required === 'true') {
    return true;
  }
  const loyaltyActivatedInProfile = get(profile, 'loyalty.activated', false);
  const loyaltyActivatedAndServiceInlcuded =
    loyaltyActivatedInProfile && services?.includes(LOYALTY_SERVICE);

  const driveActivatedInProfile = get(profile, 'drive.activated', false);
  const driveActivatedAndServiceIncluded =
    driveActivatedInProfile && services?.includes(DRIVE_SERVICE);

  const paymentsActivatedInProfile = get(profile, 'payments.activated', false);
  const paymentsActivatedAndServiceIncluded =
    paymentsActivatedInProfile && services?.includes(PAYMENTS_SERVICE);

  return services
    ? loyaltyActivatedAndServiceInlcuded ||
        driveActivatedAndServiceIncluded ||
        paymentsActivatedAndServiceIncluded
    : false;
}

function getErrorLabel(name, errors) {
  const errorKey = get(errors, name);
  return errorKey || '';
}

function getValue(value, prePopulate, type, translations) {
  if (!prePopulate) {
    return value || '';
  }

  if (type === 'tel') {
    // If empty field then show the country prefix
    if (!value || value === prePopulate) {
      return `${prePopulate} `;
    }

    // If value starts with the country prefix then split the string
    if (value.indexOf(prePopulate) !== -1) {
      return `${prePopulate} ${value.substr(prePopulate.length).trim()}`;
    }
  }

  // If prePopulate is a translation then we should return the real value
  if (prePopulate.indexOf('sso_') === 0) {
    return translations[prePopulate];
  }

  if (
    value &&
    (value.length < prePopulate.length || !value.startsWith(prePopulate))
  ) {
    return prePopulate;
  }

  return value || prePopulate;
}

function shouldSkipField(rules, cvp) {
  const addForCvp = get(rules, 'addForCvp', null);
  const removeForCvp = get(rules, 'removeForCvp', null);

  if (
    (isArray(addForCvp) && !includes(addForCvp, cvp)) ||
    (isArray(removeForCvp) && includes(removeForCvp, cvp))
  ) {
    return true;
  }

  return false;
}

function getFormFieldData({ type, rules, ...others }, settings) {
  if (rules && shouldSkipField(rules, settings.cvp)) {
    return {};
  }

  if (type === 'text' || type === 'number' || type === 'tel') {
    return createTextData({ type, ...others }, settings);
  } else if (type === 'password') {
    return createPasswordData({ ...others }, settings);
  } else if (type === 'email') {
    return createEmailData({ type, ...others }, settings);
  } else if (type === 'parent') {
    return createParentData({ type, ...others }, settings);
  } else if (type === 'select') {
    return createSelectData({ type, ...others }, settings);
  } else if (type === 'maskInput') {
    return createMaskInputData({ type, ...others }, settings);
  } else if (type === 'multiselect') {
    return createMultiSelectData({ ...others }, settings);
  } else if (type === 'radio') {
    return createRadioData({ ...others }, settings);
  } else if (type === 'checkbox') {
    return createCheckBoxData({ ...others }, settings);
  }

  return null;
}

function createTextData(
  {
    type,
    name,
    label,
    prePopulate,
    charLimit,
    disabledFunctions,
    required,
    services,
    readOnly,
    autocomplete,
  },
  { values, profile, errors, focus, translations }
) {
  return {
    formField: TEXT_FORM_FIELD,
    type,
    name,
    label,
    mandatory: isRequired(required, services, profile),
    value: getValue(values[name], prePopulate, type, translations),
    prePopulate,
    disabledFunctions,
    disabled: readOnly === true || false,
    errorLabel: getErrorLabel(name, errors),
    focus,
    charLimit,
    autocomplete,
  };
}

function createPasswordData(
  {
    name,
    label,
    disabledFunctions,
    required,
    validations,
    services,
    autocomplete,
  },
  { values, focus, profile, errors, page }
) {
  let type;
  let showTip;
  if (page === VERIFY_USER_PAGE) {
    type = 'password';
    showTip = false;
  } else {
    showTip = focus === name;
  }
  if (page === LOGIN_PAGE) {
    showTip = false;
  }
  const tipOptions = validations[validations.length - 1]
    ? validations[validations.length - 1].extras
    : [];
  return {
    formField: TEXT_FORM_FIELD,
    type: type || 'password',
    name,
    label,
    mandatory: isRequired(required, services, profile),
    value: getValue(values[name]),
    disabledFunctions,
    showTip,
    tipOptions,
    showForgetPasswordLink: name === 'currentPassword' || name === 'password',
    showPasswordIcon: page !== VERIFY_USER_PAGE,
    errorLabel: getErrorLabel(name, errors),
    focus,
    autocomplete,
  };
}

function createEmailData(
  {
    name,
    label,
    prePopulate,
    disabledFunctions,
    required,
    services,
    readOnly,
    autocomplete,
  },
  { values, profile, errors, page, query, focus }
) {
  const mandatory = isRequired(required, services, profile);
  const errorLabel = getErrorLabel(name, errors);
  const value = getValue(values[name], prePopulate);
  return {
    formField: TEXT_FORM_FIELD,
    type: 'email',
    name,
    label,
    mandatory,
    value,
    prePopulate,
    disabledFunctions,
    errorLabel,
    disabled: (page === LOGIN_PAGE && query.lock) || readOnly === true || false,
    focus,
    autocomplete,
  };
}

function createParentData({ name, order, label }, settings) {
  return {
    formField: PARENT_FORM_FIELD,
    name,
    label,
    children: generateFields({ order, ...settings }),
  };
}

function generateFields({ order, fields, page, isMobile, cvp, ...others }) {
  const steps = isMobile ? order.mobile : order.desktop;
  return steps.map((step) => {
    if (typeof step === 'string') {
      if (!fields[step]) return [];
      return getFormFieldData(fields[step], {
        fields,
        page,
        isMobile,
        cvp,
        ...others,
      });
    }
    return step.map((fieldName) => {
      if (fields[fieldName] && typeof fieldName === 'string') {
        return getFormFieldData(fields[fieldName], {
          fields,
          page,
          isMobile,
          cvp,
          ...others,
        });
      } else if (fieldName && typeof fieldName === 'object') {
        return fieldName.map((fName) =>
          getFormFieldData(fields[fName], {
            fields,
            page,
            isMobile,
            cvp,
            ...others,
          })
        );
      }
      return [];
    });
  });
}

function createSelectData(
  { name, label, options, selected, required, services },
  { values, profile, errors, focus }
) {
  const mandatory = isRequired(required, services, profile);
  const finalOptions = [];
  const preSelectedValue =
    selected !== undefined ? options[selected].value : '';
  options.forEach((option) => {
    finalOptions.push({
      value: option.value,
      text: option.label,
    });
  });
  return {
    formField: SELECT_FORM_FIELD,
    name,
    selectedValue: getValue(values[name] || preSelectedValue),
    label,
    mandatory,
    options: finalOptions,
    errorLabel: getErrorLabel(name, errors),
    focus,
  };
}

function createMask(format) {
  if (!format) {
    return [];
  }
  return format
    .toLowerCase()
    .split('')
    .map((element) => {
      if (['m', 'd', 'y'].indexOf(element) !== -1) {
        return /\d/;
      }
      return element;
    });
}

function createMaskInputData(
  {
    name,
    label,
    disabledFunctions,
    required,
    prePopulate,
    services,
    readOnly,
    disabled,
    autocomplete,
  },
  { values, profile, errors, backendDateFormat, marketDateFormat, focus }
) {
  const mandatory = isRequired(required, services, profile);
  const isBirthdate = name === 'birthdate' || 'solBirthdate';
  const hasValue = profile.profile.birthdate;

  return {
    name,
    formField: MASK_FORM_FIELD,
    type: 'text',
    isDateField: isBirthdate,
    label,
    placeholder: isBirthdate ? lokaliseTags.SSO_GENERAL_DATE_FORMAT : '',
    mask: createMask(marketDateFormat),
    disabledFunctions,
    mandatory,
    value: getValue(values[name], prePopulate),
    prePopulate,
    errorLabel: getErrorLabel(name, errors),
    backendDateFormat: backendDateFormat.toLowerCase(),
    marketDateFormat: marketDateFormat.toLowerCase(),
    focus,
    disabled: disabled || (hasValue && readOnly),
    autocomplete,
  };
}

function createMultiSelectData(
  { label, options, required, services, name },
  { profile, errors, values, focus }
) {
  const mandatory = isRequired(required, services, profile);
  const finalOptions = [];
  const fieldValue = getValue(values[name], null);
  if (options) {
    options.forEach((option) =>
      finalOptions.push({
        id: option.id,
        name,
        value: option.localized_name || option.name,
        isChecked: fieldValue.indexOf(option.id) !== -1,
      })
    );
  }
  return {
    formField: MULTIPLE_SELECT_FORM_FIELD,
    name,
    options: finalOptions,
    headerLabel: label,
    label: `${label}_text`,
    noOptionsLabel: lokaliseTags.SSO_FORMS_NO_OPTIONS_AVAILABLE,
    mandatory,
    errorLabel: getErrorLabel(name, errors),
    focus,
  };
}

function valueTransformer(value, profile) {
  if (value === '$FULLNAME$') {
    return `${profile.firstName} ${profile.lastName}`;
  } else if (value === '$LASTNAME$') {
    try {
      return `${profile.firstName} ${profile.lastName.substring(0, 1)}`;
    } catch (e) {
      return value;
    }
  }

  return value;
}

const createCheckBoxData = (
  { label, name, required, icon },
  { errors, values }
) => ({
  formField: CHECKBOX_FORM_FIELD,
  name,
  label,
  mandatory: required,
  errorLabel: getErrorLabel(name, errors),
  icon: getIcon(icon),
  focus,
  value: get(values[name], 'checked', false),
});

function createRadioData(
  { label, options, name, required, services },
  { profile, values, errors, focus }
) {
  const mandatory = isRequired(required, services, profile);
  const finalOptions = [];
  options.forEach((option, key) =>
    finalOptions.push({
      id: key,
      name,
      value: valueTransformer(option.value, profile.profile),
      isChecked: values[name] === option.key,
    })
  );
  return {
    formField: MULTIPLE_SELECT_FORM_FIELD,
    name,
    options: finalOptions,
    headerLabel: label,
    label: `${label}_text`,
    noOptionsLabel: lokaliseTags.SSO_FORMS_NO_OPTIONS_AVAILABLE,
    isRadio: true,
    mandatory,
    errorLabel: getErrorLabel(name, errors),
    focus,
  };
}

const selectDynamicFormDomain = () => (state) => state.dynamicForm;

const makeSelectFormPage = (page) =>
  createSelector(
    selectDynamicFormDomain(),
    makeSelectDateFormat(),
    ({ form, formData, editField, focus, errors }, marketDateFormat) => {
      const data = {
        order: {
          mobile: [],
          desktop: [],
        },
        fields: {},
        values: { ...formData },
        editField,
        focus,
        errors,
        backendDateFormat: BACKEND_DATE_FORMAT,
        marketDateFormat,
      };
      if (!isArray(page)) page = [page];

      for (let i = 0; i < page.length; i++) {
        const pageName = page[i];
        const formSettings = form[pageName];
        if (formSettings?.properties) {
          data.order = { ...formSettings.order };
          formSettings.properties.forEach((field) => {
            data.fields[field.name] = { ...field };
          });
        }
      }
      return { ...data };
    }
  );

const makeSelectFormFields = (page) =>
  createSelector(
    makeSelectFormPage(page),
    selectProfileDomain(),
    makeSelectIsMobile(),
    makeSelectQuery(),
    makeSelectCVP(),
    makeSelectTranslations(),
    (settings, profile, isMobile, query, cvp, translations) =>
      generateFields({
        page,
        profile,
        isMobile,
        query,
        cvp,
        translations,
        ...settings,
      })
  );

const makeSelectFormSettingsFields = (page) =>
  createSelector(
    makeSelectFormFields(page),
    selectDynamicFormDomain(),
    makeSelectIsMobile(),
    makeSelectHasToShowStepsDesktop(page),
    makeSelectHasToShowStepsMobile(page),
    (fields, { step }, isMobile, showStepsDesktop, showStepsMobile) => {
      if ((isMobile && showStepsMobile) || (!isMobile && showStepsDesktop)) {
        return get(fields, step, []);
      }
      return get(fields, 0, []);
    }
  );

const makeSelectHasToShowStepsDesktop = (page) =>
  createSelector(
    selectDynamicFormDomain(),
    ({ form }) => get(form, [page, 'order', 'desktop', 'length'], 0) > 1
  );

const makeSelectHasToShowStepsMobile = (page) =>
  createSelector(
    selectDynamicFormDomain(),
    ({ form }) => get(form, [page, 'order', 'mobile', 'length'], 0) > 1
  );

const makeSelectNumberDesktopSteps = (page) =>
  createSelector(
    selectDynamicFormDomain(),
    ({ form }) => get(form, [page, 'order', 'desktop', 'length'], 0) - 1
  );

const makeSelectNumberMobileSteps = (page) =>
  createSelector(
    selectDynamicFormDomain(),
    ({ form }) => get(form, [page, 'order', 'mobile', 'length'], 0) - 1
  );

const makeSelectFormSettings = (page) =>
  createSelector(
    makeSelectFormSettingsFields(page),
    selectDynamicFormDomain(),
    makeSelectIsMobile(),
    makeSelectButtonSettings(page),
    makeSelectHasToShowStepsDesktop(page),
    makeSelectHasToShowStepsMobile(page),
    makeSelectNumberDesktopSteps(page),
    makeSelectNumberMobileSteps(page),
    (
      fields,
      { step, focus },
      isMobile,
      buttonSettings,
      showStepsDesktop,
      showStepsMobile,
      numberStepsDesktop,
      numberStepsMobile
    ) => {
      if ((isMobile && showStepsMobile) || (!isMobile && showStepsDesktop)) {
        return {
          fields,
          focus,
          step,
          buttonSettings,
          isAuthMethodStep:
            isMobile &&
            fields.length === 1 &&
            (fields[0].name === 'emailAddress' || fields[0].name === 'mobile'),
          isPasswordStep:
            isMobile && fields.length === 1 && fields[0].name === 'newPassword',
          isLastFormStep:
            step === (isMobile ? numberStepsMobile : numberStepsDesktop),
        };
      }
      return {
        fields,
        focus,
        buttonSettings,
        isAuthMethodStep: true,
        isPasswordStep: true,
        isLastFormStep: true,
      };
    }
  );

// you have to add new form page buttons labels in dynamic-form-constants
const makeSelectButtonSettings = (page) =>
  createSelector(makeSelectIsMobile(), (isMobile) => {
    const label = get(
      BUTTONS_LABELS,
      [page, isMobile ? 'mobile' : 'desktop'],
      null
    );
    return {
      label,
    };
  });

const makeSelectIsDynamicFormLoaded = () =>
  createSelector(selectDynamicFormDomain(), (dynamicForm) =>
    get(dynamicForm, 'loaded', false)
  );

const makeSelectFormStep = () =>
  createSelector(selectDynamicFormDomain(), (dynamicForm) =>
    get(dynamicForm, 'step', 0)
  );

const makeSelectTotalFormPages = (page) =>
  createSelector(
    makeSelectIsMobile(),
    makeSelectNumberDesktopSteps(page),
    makeSelectNumberMobileSteps(page),
    (isMobile, numberStepsDesktop, numberStepsMobile) => {
      return isMobile ? numberStepsMobile : numberStepsDesktop;
    }
  );

const makeSelectFormErrors = () =>
  createSelector(selectDynamicFormDomain(), (dynamicForm) =>
    get(dynamicForm, 'errors', {})
  );

const makeSelectHasFormErrors = () =>
  createSelector(
    makeSelectFormErrors(),
    (errors) => Object.keys(errors).length > 0
  );
const makeSelectFormPageName = () =>
  createSelector(selectDynamicFormDomain(), (dynamicForm) =>
    get(dynamicForm, 'page', '')
  );

const makeSelectFormData = () =>
  createSelector(selectDynamicFormDomain(), (dynamicForm) =>
    get(dynamicForm, 'formData', {})
  );
const makeSelectFormForm = (page) =>
  createSelector(selectDynamicFormDomain(), (dynamicForm) =>
    get(dynamicForm, ['form', page], {})
  );
const makeSelectFormEmailVerified = () =>
  createSelector(selectDynamicFormDomain(), (dynamicForm) =>
    get(dynamicForm, ['emailVerified'], false)
  );

const makeSelectFormDataBackendFormated = (
  page,
  hasToRemoveEmptyValues = false
) =>
  createSelector(makeSelectFormPage(page), ({ fields, values }) => {
    const data = {};
    each(values, (value, fieldName) => {
      const parent = get(fields, [fieldName, 'parent']);
      if (parent) {
        if (!data[parent]) {
          data[parent] = {};
        }
        if (value || value === false) {
          data[parent][fieldName] = value;
        } else if (hasToRemoveEmptyValues) {
          data[parent][fieldName] = null;
        } else {
          // eslint-disable-next-line
          const { ...others } = data[parent];
          data[parent] = {
            ...others,
          };
        }
      } else if (value || value === false) {
        data[fieldName] = isPlainObject(value) ? { ...value } : value;
      } else if (hasToRemoveEmptyValues) {
        data[fieldName] = null;
      }
    });
    return data;
  });

const makeSelectIsLastFormStep = (page) =>
  createSelector(
    makeSelectIsMobile(),
    makeSelectFormStep(),
    makeSelectNumberDesktopSteps(page),
    makeSelectNumberMobileSteps(page),
    (isMobile, step, numberStepsDesktop, numberStepsMobile) => {
      return step === (isMobile ? numberStepsMobile : numberStepsDesktop);
    }
  );

export {
  selectDynamicFormDomain,
  makeSelectFormPage,
  makeSelectFormFields,
  makeSelectFormSettings,
  makeSelectButtonSettings,
  makeSelectNumberDesktopSteps,
  makeSelectNumberMobileSteps,
  makeSelectIsDynamicFormLoaded,
  makeSelectFormStep,
  makeSelectFormErrors,
  makeSelectFormPageName,
  makeSelectFormData,
  makeSelectFormForm,
  makeSelectFormDataBackendFormated,
  makeSelectHasFormErrors,
  shouldSkipField,
  makeSelectIsLastFormStep,
  makeSelectFormEmailVerified,
  makeSelectTotalFormPages,
};
