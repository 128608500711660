import { WHITE } from '../../shared/colors';

export default (theme) => ({
  containerFluid: {
    width: '100%',
    height: '100%',
    overflowX: 'hidden',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  container: {
    height: '100%',
    position: 'initial',
    padding: '0 15px 16px 15px',
    backgroundColor: WHITE,
    [theme.breakpoints.up('sm')]: {
      position: 'relative',
    },
  },
});
