import { SETTINGS_LOADED, SAVE_TCPP } from './constants';
import { UPDATE_ATTRIBUTE } from '../dynamic-form/constants';
import lokaliseTags from '../../shared/lokaliseTags';

const initialState = {
  loaded: false,
  returnPage: '',
  privacyPolicy: null,
  termsAndConditions: null,
  deleteAccount: {
    user: {
      currentPassword: '',
    },
    form: {
      properties: [
        {
          label: lokaliseTags.SSO_FORMS_ENTER_PASSWORD,
          name: 'currentPassword',
          tip: '',
          type: 'password',
          validations: [
            {
              message:
                lokaliseTags.SSO_FORMS_ERRORS_INCORRECT_USERNAME_OR_PASSWORD_PLEASE_TRY_AGAIN,
              rule: 'required',
              value: true,
            },
          ],
        },
      ],
      order: {
        mobile: [['currentPassword']],
        desktop: [['currentPassword']],
      },
    },
  },
};

export default (state = initialState, action) => {
  const { name, value } = action;

  switch (action.type) {
    case SETTINGS_LOADED:
      return {
        ...state,
        ...action.response,
        loaded: true,
      };

    case UPDATE_ATTRIBUTE:
      return {
        ...state,
        deleteAccount: {
          ...state.deleteAccount,
          form: {
            ...state.deleteAccount.form,
            formData: {
              ...state.deleteAccount.form.formData,
              [name]: value,
            },
          },
        },
      };
    case SAVE_TCPP:
      return {
        ...state,
        privacyPolicy: action.content['privacy_policy'],
        termsAndConditions: action.content['terms_conditions'],
      };
    default:
      return state;
  }
};
