import lokaliseTags from '../../shared/lokaliseTags';

export const PROFILE_HEADER = {
  title: {
    mobile: lokaliseTags.SSO_PROFILE_MY_PROFILE_LABEL,
    desktop: lokaliseTags.SSO_PROFILE_MY_PROFILE_LABEL,
  },
  subtitle: {
    mobile: {
      preText: lokaliseTags.SSO_PROFILE_EDIT_PROFILE,
      text: '',
      url: '',
      afterText: '',
    },
    desktop: {
      preText: '',
      text: '',
      url: '',
      afterText: '',
    },
  },
  menu: {
    leftMenu: {
      name: lokaliseTags.SSO_GENERAL_CLOSE,
      icon: 'close',
      mobile: true,
      desktop: false,
    },
  },
};
export const COMMUNICATIONS_HEADER = {
  title: {
    mobile: lokaliseTags.SSO_PROFILE_COMMUNICATIONS,
    desktop: lokaliseTags.SSO_PROFILE_COMMUNICATIONS,
  },
  menu: {
    leftMenu: {
      name: lokaliseTags.SSO_GENERAL_BACK,
      icon: 'back',
      mobile: true,
      desktop: false,
    },
  },
};
