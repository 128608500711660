import { LOYALTY_NEW_CHANGE_VALUE } from './constants';

export const initialState = {
  data: {
    cardId: '',
    birthdate: '',
    address1: '',
    address2: '',
    city: '',
    houseNumber: '',
    zipCode: '',
  },
  fails: 0,
  form: {},
};

const loyaltyNew = (state = initialState, action) => {
  const { type, name, value } = action;

  if (type === LOYALTY_NEW_CHANGE_VALUE) {
    return {
      ...state,
      [name]: value,
    };
  }

  return state;
};

export default loyaltyNew;
