import { LOGIN_PAGE } from '../../../shared/formPages';
import { SOL_PASSWORD_ATTRIBUTE_CHANGE } from './constants';

export const initialState = {
  form: {},
  errors: [],
  user: {
    signInEmailAddress: '',
    cardId: '',
  },
  previousPage: LOGIN_PAGE,
  returnPage: false,
  passwordTry: 0,
};

const solForgotPassword = (state = initialState, action) => {
  const { type, name, value } = action;

  if (type === SOL_PASSWORD_ATTRIBUTE_CHANGE) {
    return { ...state, [name]: value };
  }

  return state;
};

export default solForgotPassword;
