import {
  SET_LOGIN_FORM,
  SET_LOGIN_ATTRIBUTE,
  RESET_LOGIN_FORM,
} from './constants';

export const initialState = {
  user: {
    signInEmailAddress: '',
    currentPassword: '',
  },
  form: {},
  actions: {},
  showNotification: true,
  app: null,
  loginTry: 0,
  lock: false,
  showPhoneNumberForm: null,
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOGIN_FORM:
      return {
        ...state,
        form: action.data,
        loaded: true,
        lock: action.lock,
      };
    case SET_LOGIN_ATTRIBUTE:
      return {
        ...state,
        [action.name]: action.value,
      };
    case RESET_LOGIN_FORM:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default login;
