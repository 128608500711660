import lokaliseTags from '../../shared/lokaliseTags';
import { SECURITY_CHANGE_ATTRIBUTE } from './constants';

export const initialState = {
  data: {
    currentPassword: '',
  },
  form: {
    properties: [
      {
        label: lokaliseTags.SSO_FORMS_CURRENT_PASSWORD,
        name: 'currentPassword',
        type: 'password',
        required: true,
        validations: [
          {
            message: lokaliseTags.SSO_FORMS_ERRORS_INVALID_CURRENT_PASSWORD,
            rule: 'required',
            value: true,
          },
        ],
      },
    ],
    order: {
      desktop: [['currentPassword']],
      mobile: [['currentPassword']],
    },
  },
  userVerified: false,
  loading: false,
  loaded: true,
};

const security = (state = initialState, action) => {
  const { type, name, value } = action;

  if (type === SECURITY_CHANGE_ATTRIBUTE) {
    return { ...state, [name]: value };
  }

  return state;
};

export default security;
