import { createBrowserHistory } from 'history';

const ACTIONS = {
  PUSH: 'PUSH',
  POP: 'POP',
  REPLACE: 'REPLACE',
};

const appHistory = createBrowserHistory();
export const urlHistory = [];

appHistory.listen(({ location, action }) => {
  switch (action) {
    case ACTIONS.PUSH:
      urlHistory.push(location);
      break;

    case ACTIONS.POP:
      urlHistory.pop();
      break;

    case ACTIONS.REPLACE:
      if (!urlHistory.length) {
        urlHistory.push(location);
      } else {
        urlHistory[urlHistory.length - 1] = location;
      }
      break;

    default:
      break;
  }
});

const history = appHistory;

export default history;
