import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Notification from '../../features/notification/Notification';
import { showDefaultErrorNotification } from '../../features/notification/actions';
import { redirectToCVP } from '../../features/app/actions';
import { getTranslation } from '../../shared/translation-utils';
import { DEBUG } from '../../shared/constants';
import { selectAppDomain } from '../../features/app/selectors';
import { theme } from '../../shared/theme';

const muiTheme = createTheme(theme);

const chunkErrorType = 'Chunk';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    const errorType = error?.name;
    const isChunkError = errorType.includes(chunkErrorType);

    if (isChunkError) {
      window.location.reload(true);
    } else {
      this.setState({
        error,
        errorInfo,
      });
    }
  }

  render() {
    const { showErrorNotification, app } = this.props;
    const { translations, query } = app;
    const showKey = app.showTag === 1 && DEBUG;
    const translate = (prop, clean) =>
      getTranslation(
        prop,
        translations,
        showKey,
        clean,
        query.market,
        query.appVersion
      );
    if (this.state.errorInfo) {
      showErrorNotification();
      console.log(this.state.error.toString());
      console.log(this.state.errorInfo.componentStack);
      return (
        <ThemeProvider theme={muiTheme}>
          <CssBaseline />
          <Notification translate={translate} />
        </ThemeProvider>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  app: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
  showErrorNotification: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  showErrorNotification: () =>
    dispatch(
      showDefaultErrorNotification(() => {
        const redirectErrorData = {
          error: [{ 400: 'Uncaught Exception' }],
        };
        dispatch(redirectToCVP(redirectErrorData));
      })
    ),
});

const mapStateToProps = createStructuredSelector({
  app: selectAppDomain(),
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);
