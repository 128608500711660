export const SET_PROGRESSIVE_PROFILING = '[PP] SET_PROGRESSIVE_PROFILING_FLOW';

const initialState = {
  ppFlow: false,
};

export default function (state = initialState, action) {
  if (action.type === SET_PROGRESSIVE_PROFILING) {
    return { ...state, ppFlow: action.ppFlow };
  }
  return state;
}
