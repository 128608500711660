export const WIDGET_CVP = 'login';
export const SOL_CVP = 'sol';
export const MOTORIST_CVP = 'drive';
export const MOTORIST_AMERICA_CVP = 'motorist_america';
export const OASIS_CVP = 'oasis';
export const FR_CVP = 'p97';
export const GO_PLUS_CVP = 'goplus';
export const PARTNER_FIRST_UTILITY_CVP = 'partner_first_utility';
export const MOTORIST_FIREBLADE_CVP = 'motorist_fireblade';
export const MOTORIST_FIREBLADE_AMERICA_CVP = 'motorist_fireblade_america';
export const LOYALTY_FUTURES_CVP = 'loyalty_futures';
export const SAP_HYBRIS_CVP = 'sap_hybris';
export const PARTNER_1 = 'partner_1';
export const PARTNER_2 = 'partner_2';
export const PARTNER_3 = 'partner_3';
export const PARTNER_RAC = 'partner_rac';
export const PARTNER_SOURAV = 'partner_sourav';
export const PARTNER_TEST = 'partner_test';

export default {
  [WIDGET_CVP]: WIDGET_CVP,
  [SOL_CVP]: SOL_CVP,
  [MOTORIST_CVP]: MOTORIST_CVP,
  [OASIS_CVP]: OASIS_CVP,
  [FR_CVP]: FR_CVP,
  [GO_PLUS_CVP]: GO_PLUS_CVP,
  [PARTNER_FIRST_UTILITY_CVP]: PARTNER_FIRST_UTILITY_CVP,
  [MOTORIST_FIREBLADE_CVP]: MOTORIST_FIREBLADE_CVP,
  [MOTORIST_FIREBLADE_AMERICA_CVP]: MOTORIST_FIREBLADE_AMERICA_CVP,
  [LOYALTY_FUTURES_CVP]: LOYALTY_FUTURES_CVP,
  [MOTORIST_AMERICA_CVP]: MOTORIST_AMERICA_CVP,
  [SAP_HYBRIS_CVP]: SAP_HYBRIS_CVP,
  [PARTNER_1]: PARTNER_1,
  [PARTNER_2]: PARTNER_2,
  [PARTNER_3]: PARTNER_3,
  [PARTNER_RAC]: PARTNER_RAC,
  [PARTNER_SOURAV]: PARTNER_SOURAV,
  [PARTNER_TEST]: PARTNER_TEST,
};
