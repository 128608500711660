import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { points12 } from '../../shared/fontSizes';

function Debug({ app }) {
  const data = [];

  Object.keys(app.query || {}).forEach((d) => {
    let dataString = app.query[d];
    if (typeof dataString === 'object') {
      dataString = JSON.stringify(dataString);
    }
    data.push(
      <li key={d} style={Styling.item}>
        {d}: <span style={Styling.itemValue}>{dataString}</span>
      </li>
    );
  });

  return <ul style={Styling.debug}>{data}</ul>;
}

const Styling = {
  debug: {
    marginTop: '10px',
  },
  item: {
    textDecoration: 'none',
    listStyleType: 'none',
    margin: '0',
    padding: '0',
    lineHeight: '25px',
  },
  itemValue: {
    fontSize: points12,
    fontWeight: 'bold',
  },
};

Debug.propTypes = {
  app: PropTypes.object.isRequired,
};

const mapStateToProps = (store) => ({
  app: store.app,
});

export default connect(mapStateToProps)(Debug);
