import {
  SET_TRANSLATIONS,
  SAVE_QUERY,
  SAVE_QUERY_SCOPES,
  SHOW_SPINNER,
  CLOSE_POPUP,
  SEND_TOKENS,
  SET_CVP,
} from './constants';
import { TOGGLE_TAGS } from '../header/constants';
import { WIDGET_CVP } from '../../shared/cvps';
import { NUMBERS } from '../../shared/constants';

const initialState = {
  popup: {},
  query: {},
  translations: {},
  loading: false,
  timeOut: 60000,
  showTag: 0, // 0 = normal, 1 = show translation tags, 2 = show received information
  sendTokens: false,
  cvp: WIDGET_CVP,
  showPhoneNumberForm: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CVP:
      return {
        ...state,
        cvp: action.cvp || WIDGET_CVP,
      };
    case SHOW_SPINNER:
      return {
        ...state,
        loading: action.value,
      };
    case SEND_TOKENS:
      return {
        ...state,
        sendTokens: action.value,
      };
    case CLOSE_POPUP:
      return {
        ...state,
        popup: {
          ...state.popup,
        },
      };
    case SET_TRANSLATIONS:
      return {
        ...state,
        translations: action.translations,
      };
    case SAVE_QUERY:
      return {
        ...state,
        query: {
          ...state.query,
          ...action.query,
        },
      };
    case SAVE_QUERY_SCOPES:
      return {
        ...state,
        query: {
          ...state.query,
          scopes: action.scopes,
        },
      };
    case TOGGLE_TAGS:
      return {
        ...state,
        showTag: state.showTag === NUMBERS.TWO ? 0 : state.showTag + 1,
      };
    default:
      return state;
  }
};

export default reducer;
