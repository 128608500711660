import {
  EDIT_PROFILE_PATHNAME,
  COMMUNICATIONS_PATHNAME,
  LOD_PROFILE_PATHNAME,
  LOYALTY_FUTURES_PROFILE_PATHNAME,
  PAYMENTS_PATHNAME,
  SOL_LOYALTY_CARDS_PATHNAME,
  CHANGE_PASSWORD_PATHNAME,
  SOL_REPLACE_CARD_PATHNAME,
  SAP_HYBRIS_PROFILE_PATHNAME,
  PROFILE_SECURITY_PATHNAME,
  VEHICLE_PREFERENCES,
} from '../../shared/pathnames';
import { LOYALTY_FUTURES_CVP } from '../../shared/cvps';
import lokaliseTags from '../../shared/lokaliseTags';

const menuItems = [
  {
    path: EDIT_PROFILE_PATHNAME,
    label: lokaliseTags.SSO_PROFILE_PERSONAL_DETAILS_LABEL,
    showInMenu: () => true,
  },
  {
    path: LOYALTY_FUTURES_PROFILE_PATHNAME,
    label: lokaliseTags.SSO_PROFILE_SHELL_LOYALTY_FUTURES_LABEL,
    showInMenu: ({ isLoyaltyFutureActivated, cvp }) =>
      isLoyaltyFutureActivated && cvp === LOYALTY_FUTURES_CVP,
  },
  {
    path: CHANGE_PASSWORD_PATHNAME,
    label: lokaliseTags.SSO_PROFILE_CHANGE_PASSWORD_LABEL,
    showInMenu: () => true,
  },
  {
    path: LOD_PROFILE_PATHNAME,
    label: lokaliseTags.SSO_PROFILE_SHELL_GO_PLUS_LABEL,
    showInMenu: ({ isGoPlusActivated, cvp }) =>
      isGoPlusActivated && cvp !== LOYALTY_FUTURES_CVP,
  },
  {
    path: SAP_HYBRIS_PROFILE_PATHNAME,
    label: lokaliseTags.SSO_PROFILE_SHELL_GO_PLUS_LABEL,
    showInMenu: ({ isSapHybrisActivated }) => isSapHybrisActivated,
  },
  {
    path: SOL_LOYALTY_CARDS_PATHNAME,
    label: lokaliseTags.SSO_PROFILE_SHELL_LOYALTY_LABEL,
    showInMenu: ({ settings, isGoPlusActivated }) =>
      settings?.loyalty?.loyalty_enabled && !isGoPlusActivated,
  },
  {
    path: PAYMENTS_PATHNAME,
    label: lokaliseTags.SSO_PROFILE_SHELL_PAYMENTS_LABEL,
    showInMenu: ({ settings, isMotoristApp }) =>
      settings?.payments?.payments_enabled && isMotoristApp,
  },
  {
    path: COMMUNICATIONS_PATHNAME,
    label: lokaliseTags.SSO_PROFILE_COMMUNICATIONS,
    showInMenu: () => true,
  },
  {
    path: PROFILE_SECURITY_PATHNAME,
    label: lokaliseTags.PROFILE_SECURITY_TITLE,
    dataTestId: 'securityProfile',
    showInMenu: ({ twoFaEnabled }) => twoFaEnabled,
  },
  {
    path: VEHICLE_PREFERENCES,
    label: lokaliseTags.SSO_PROFILE_VEHICLE_PREFERENCES_LABEL,
    dataTestId: 'vehiclePreferences',
    showInMenu: ({ vehiclePreferences }) => vehiclePreferences,
  },
];

const itemsToOpenAsProfileOption = [
  {
    path: CHANGE_PASSWORD_PATHNAME,
    showInMenu: () => false,
  },
  {
    path: SOL_REPLACE_CARD_PATHNAME,
    showInMenu: () => false,
  },
  {
    path: SOL_REPLACE_CARD_PATHNAME,
    showInMenu: () => false,
  },
];

export default [...menuItems, ...itemsToOpenAsProfileOption];
