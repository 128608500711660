import {
  COMMUNICATIONS_PATHNAME,
  DELETE_ACCOUNT_PATHNAME,
  NEW_LOYALTY_PATHNAME,
  ATTACH_LOYALTY_PATHNAME,
  LOYALTY_PATHNAME,
  CHANGE_PASSWORD_PATHNAME,
  EDIT_PROFILE_PATHNAME,
  PROFILE_PATHNAME,
  RESET_PASSWORD_PATHNAME,
  LOD_PROFILE_PATHNAME,
  SAP_HYBRIS_ATTACH_WELCOME_PATHNAME,
  SOL_LOYALTY_CARDS_PATHNAME,
  SOL_CARD_INPUT_PATHNAME,
  SAP_HYBRIS_ATTACH_CARD_INPUT_PATHNAME,
  LOYALTY_FUTURES_CARD_INPUT_PATHNAME,
  SAP_HYBRIS_PROFILE_PATHNAME,
  LOYALTY_FUTURES_PROFILE_PATHNAME,
} from './pathnames';
import lokaliseTags from './lokaliseTags';

export const browserTabNameLogged = lokaliseTags.SSO_WIDGET_PAGE_NAME_LOGGED_IN;

export const browserTabNameLoggedOut =
  lokaliseTags.SSO_WIDGET_PAGE_NAME_LOGGED_OUT;

const tabNamePerPath = {
  [COMMUNICATIONS_PATHNAME]: browserTabNameLogged,
  [DELETE_ACCOUNT_PATHNAME]: browserTabNameLogged,
  [NEW_LOYALTY_PATHNAME]: browserTabNameLogged,
  [ATTACH_LOYALTY_PATHNAME]: browserTabNameLogged,
  [LOYALTY_PATHNAME]: browserTabNameLogged,
  [CHANGE_PASSWORD_PATHNAME]: browserTabNameLogged,
  [EDIT_PROFILE_PATHNAME]: browserTabNameLogged,
  [PROFILE_PATHNAME]: browserTabNameLogged,
  [RESET_PASSWORD_PATHNAME]: browserTabNameLogged,
  [LOD_PROFILE_PATHNAME]: browserTabNameLogged,
  [SAP_HYBRIS_ATTACH_WELCOME_PATHNAME]: browserTabNameLogged,
  [SOL_LOYALTY_CARDS_PATHNAME]: browserTabNameLogged,
  [SOL_CARD_INPUT_PATHNAME]: browserTabNameLogged,
  [SAP_HYBRIS_ATTACH_CARD_INPUT_PATHNAME]: browserTabNameLogged,
  [SAP_HYBRIS_PROFILE_PATHNAME]: browserTabNameLogged,
  [LOYALTY_FUTURES_CARD_INPUT_PATHNAME.replace(':flowType', 'attach')]:
    browserTabNameLogged,
  [LOYALTY_FUTURES_PROFILE_PATHNAME]: browserTabNameLogged,
};

const getBrowserTabName = (currentPathname, translate) => {
  return translate(
    tabNamePerPath[currentPathname] || browserTabNameLoggedOut,
    { clean: true, waiting: true }
  );
};

export default getBrowserTabName;
