import {
  RESET_FORGET_PASSWORD,
  PREVIOUS_PAGE,
  FORGOT_PASSWORD_CHANGE,
} from './constants';
import { LOGIN_PAGE } from '../../shared/formPages';

export const initialState = {
  form: {},
  errors: [],
  user: { emailaddress: '' },
  previousPage: LOGIN_PAGE,
  showPhoneNumberForm: null,
};

const forgotPassword = (state = initialState, action) => {
  switch (action.type) {
    case RESET_FORGET_PASSWORD:
      return {
        ...state,
        initialState,
      };
    case PREVIOUS_PAGE:
      return {
        ...state,
        previousPage: action.value,
      };
    case FORGOT_PASSWORD_CHANGE:
      return {
        ...state,
        [action.name]: action.value,
      };
    default:
      return state;
  }
};

export default forgotPassword;
