import { createSelector } from 'reselect';
import {
  makeSelectIsMotoristApp,
  makeSelectQueryUser,
} from '../../../app/selectors';
import { getStoredData } from '../../../app/utils';

const selectMigrationDomain = () => (state) => state.migration;

const makeSelectIsMigrationLoaded = () =>
  createSelector(selectMigrationDomain(), (migration) => migration.loaded);

const makeSelectCommunicationsSettings = () =>
  createSelector(
    makeSelectQueryUser(),
    makeSelectIsMotoristApp(),
    (user, isMotoristApp) => {
      const settings = {
        loyalty: false,
        payments: false,
        drive: false,
        newsletter: true,
      };
      const userData = user || getStoredData('profile', true);
      if (userData.payments && isMotoristApp) {
        settings.payments = true;
      }
      if (userData.loyalty) {
        settings.loyalty = true;
      }
      if (userData.drive && isMotoristApp) {
        settings.drive = true;
      }
      return settings;
    }
  );

const makeSelectMigrationData = () =>
  createSelector(
    selectMigrationDomain(),
    (migration) => migration.migrationData
  );

export {
  selectMigrationDomain,
  makeSelectIsMigrationLoaded,
  makeSelectCommunicationsSettings,
  makeSelectMigrationData,
};
