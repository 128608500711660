import { LOYALTY_ATTACH_CHANGE_VALUE } from './constants';

export const initialState = {
  data: {
    cardId: '',
    solPassword: '',
  },
  form: {},
  loginTry: 0,
};

const loyaltyAttach = (state = initialState, action) => {
  const { type, name, value } = action;

  if (type === LOYALTY_ATTACH_CHANGE_VALUE) {
    return {
      ...state,
      [name]: value,
    };
  }

  return state;
};

export default loyaltyAttach;
