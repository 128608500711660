import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import Styling from './style';
import { Icon, Text } from '@shell-b2c/web-design-system';
import QR from '../../assets/img/QR_App.svg';
import AppleWallet from '../../assets/img/AppleWallet.svg';
import GoogleWallet from '../../assets/img/GoogleWallet.svg';
import IOSButton from '../../assets/img/btn_AppStore.svg';
import AndroidButton from '../../assets/img/btn_GooglePlay.svg';
import { ANDROID_APP, IOS_APP } from './utils';
import { useSelector, useDispatch } from 'react-redux';
import {
  makeSelectSettingsDownloadAndroidUrl,
  makeSelectSettingsDownloadIOSUrl,
} from '../settings/selectors';
import { useTimeout } from '../../shared/hooks/useTimeout';
import { redirectToCVP } from '../app/actions';
import Subtitle from '../../shared/components/Subtitle';
import Snackbar from '../../shared/components/Snackbar';
import { NUMBERS } from '../../shared/constants';

const NotificationContent = (props) => {
  const { notification } = props.notification;
  const iOSDowloadUrl = useSelector(makeSelectSettingsDownloadIOSUrl());

  const androidDownloadUrl = useSelector(
    makeSelectSettingsDownloadAndroidUrl()
  );
  const dispatch = useDispatch();

  const { start, clear } = useTimeout(() => {
    dispatch(redirectToCVP());
  }, notification.automatedRedirect?.miliseconds || NUMBERS.EIGHT_THOUSAND);

  useEffect(() => {
    if (notification.automatedRedirect?.miliseconds) {
      start();
    }
    return () => {
      clear();
    };
    //eslint-disable-next-line
  }, [start, clear]);

  function renderAnimation() {
    const { animation } = notification;

    const hasAnimation = animation.component;
    if (!hasAnimation) return <div style={Styling.imageStyle} />;

    const Animation = animation.component;
    const animStyle = { margin: '0 auto' };
    return (
      <div style={Styling.notificationImage}>
        <div className="animation-anchor" style={{ ...animStyle }}>
          <Animation height={100} width={100} />
        </div>
      </div>
    );
  }

  function renderIcon() {
    const { icon } = notification;

    const hasIcon = icon;
    if (!hasIcon) return <></>;

    const iconWrapStyle = { margin: '0 auto' };
    const iconStyle = {
      height: 'auto',
      width: '50%',
      marginBottom: '1rem',
    };
    return (
      <div style={Styling.notificationImage}>
        <div className="animation-anchor" style={{ ...iconWrapStyle }}>
          <Icon type={`${icon}`} style={iconStyle} />
        </div>
      </div>
    );
  }

  function renderImage() {
    const { image } = notification;

    return (
      image.url && (
        <div style={Styling.notificationImage}>
          <img
            style={{
              ...Styling.imageStyle,
              ...image.style,
            }}
            src={image.url}
            alt=""
          />
        </div>
      )
    );
  }

  function renderText() {
    const { translate } = props;

    return (
      <Grid
        container
        style={{
          ...Styling.messageText,
          ...notification.message.textStyle,
        }}
      >
        <Grid item xs={12}>
          <Text color="textPrimary">
            {translate(notification.message.text)}
          </Text>
        </Grid>
      </Grid>
    );
  }

  function renderSubText() {
    const { translate } = props;

    return notification.message.subText ? (
      <Text style={{ ...Styling.messageText }}>
        {translate(notification.message.subText)}
      </Text>
    ) : null;
  }

  function renderTitle() {
    const { translate } = props;
    return (
      <Grid
        container
        style={{
          ...Styling.titleText,
          ...notification.message.titleStyle,
        }}
      >
        <Grid item xs={12}>
          <Text variant="h5">{translate(notification.message.title)}</Text>
        </Grid>
      </Grid>
    );
  }

  function renderQR() {
    return (
      notification.QRCode &&
      (iOSDowloadUrl || androidDownloadUrl) && (
        <Grid
          container
          style={{
            ...Styling.QRContainer,
          }}
        >
          <img style={{ ...Styling.QRCode }} src={QR} alt="QR code"></img>
        </Grid>
      )
    );
  }

  function renderAutomatedRedirect() {
    const { translate } = props;
    const { automatedRedirect } = notification;

    return (
      automatedRedirect?.miliseconds && (
        <Grid container>
          <Grid item xs={12}>
            <Subtitle style={{ ...Styling.subtitleText }} color="textPrimary">
              {translate(automatedRedirect.text, false, [
                automatedRedirect.partner,
              ])}
            </Subtitle>
          </Grid>
        </Grid>
      )
    );
  }

  const appStoreButtons = {
    [ANDROID_APP]: { url: androidDownloadUrl, src: AndroidButton },
    [IOS_APP]: { url: iOSDowloadUrl, src: IOSButton },
  };

  function getButton(button, index, appStoreAnalyticClickFn) {
    const appStoreButton = appStoreButtons[button];
    return (
      appStoreButton.url && (
        <AppStoreButton
          key={index}
          imageSrc={appStoreButton.src}
          buttonLink={appStoreButton.url}
          onClick={(e) => {
            e.preventDefault();
            if (appStoreAnalyticClickFn) {
              appStoreAnalyticClickFn(button);
            }
            return window.open(appStoreButton.url, '_blank');
          }}
        />
      )
    );
  }

  function renderAppStoreButtons() {
    return (
      notification.appStoreButtons &&
      (androidDownloadUrl || iOSDowloadUrl) && (
        <Grid
          container
          style={{
            ...Styling.appStoreButtonsContainer,
          }}
        >
          {notification.appStoreButtons.map((button, index) =>
            getButton(button, index, notification.appStoreAnalyticClickFn)
          )}
        </Grid>
      )
    );
  }
  function renderWalletButton() {
    return (
      notification.walletButton?.show && (
        <Grid
          container
          style={{
            ...Styling.appStoreButtonsContainer,
          }}
        >
          <img
            style={{ ...Styling.walletButtons }}
            src={notification.walletButton.android ? GoogleWallet : AppleWallet}
            alt={
              notification.walletButton.android ? 'GoogleWallet' : 'AppleWallet'
            }
            onClick={() => {
              if (notification.walletButton?.event) {
                notification.walletButton.event();
              }
            }}
          ></img>
        </Grid>
      )
    );
  }

  function renderSnackBar() {
    const { translate, snackIsOpen, setSnackIsOpen } = props;
    const { message } = notification.snackbar || { message: null };

    if (message)
      return (
        <Snackbar
          open={snackIsOpen}
          setOpen={setSnackIsOpen}
          message={translate(message, true)}
          time={6000}
        ></Snackbar>
      );
  }

  return (
    <Grid container style={{ ...Styling.contentWrapper }}>
      <Hidden xsDown>
        <Grid item sm={1} />
      </Hidden>

      <Grid
        item
        xs={12}
        sm={10}
        style={{
          ...Styling.contentStyle,
          ...notification.contentWrapper,
        }}
      >
        {renderAnimation()}
        {renderIcon()}
        {renderImage()}
        {renderTitle()}
        {renderText()}
        {renderSubText()}
        {renderQR()}
        {renderAutomatedRedirect()}
        {renderAppStoreButtons()}
        {renderWalletButton()}
      </Grid>

      <Hidden xsDown>
        <Grid item sm={1} />
      </Hidden>
      {renderSnackBar()}
    </Grid>
  );
};

NotificationContent.propTypes = {
  translate: PropTypes.func.isRequired,
  notification: PropTypes.object.isRequired,
  isMobile: PropTypes.bool,
};
function AppStoreButton({ imageSrc, buttonLink, onClick }) {
  return (
    <a
      href={buttonLink}
      target="_blank"
      rel="noopener noreferrer"
      onClick={onClick}
    >
      <img
        style={{ ...Styling.appStoreButton }}
        src={imageSrc}
        alt="AppStore button"
      ></img>
    </a>
  );
}
export default NotificationContent;
