import get from 'lodash/get';
import { CHANGE_VALUE } from './constants';
import { DEFAULT_HEADER, ATTACH_CARD_HEADER_ADDRESS } from './headers';
import {
  loadUserProfile,
  refreshProfile,
  setProfileOption,
} from '../../profile/actions';
import { redirectToCVP, verifyLoyalty, setSpinner } from '../../app/actions';
import {
  PROFILE_PATHNAME,
  LOYALTY_PATHNAME,
  ATTACH_LOYALTY_PATHNAME,
  SOL_PASSWORD_PATHNAME,
  SOL_ATTACH_WELCOME_PATHNAME,
  SOL_LOYALTY_CARDS_PATHNAME,
} from '../../../shared/pathnames';
import { LOYALTY_SERVICE } from '../../../shared/formServices';
import {
  LOYALTY_ATTACHED_APP_PATHNAME,
  ERROR_APP_PATHNAME,
} from '../../../shared/appPathnames';
import {
  makeSelectIsMotoristApp,
  makeSelectQueryRedirect,
  makeSelectIsMotoristFirebladeCVP,
  makeSelectQueryAuthCode,
  makeSelectIsLoyaltyService,
  makeSelectQueryMarket,
} from '../../app/selectors';
import {
  showNotification,
  showDefaultErrorNotification,
  setNotificationVisible,
} from '../../notification/actions';
import { SOL_PASSWORD_ATTRIBUTE_CHANGE } from '../forgot-password/constants';
import {
  addLoyalty,
  attachLoyaltyDigitalCard,
  getProfile,
} from '@shell-b2c/http-frontend/dist/src/components/core/user';
import {
  doValidation,
  setFormStep,
  setFormError,
} from '../../dynamic-form/actions';
import browserHistory, { urlHistory } from '../../../router/history';
import { Api } from '@shell-b2c/http-frontend/dist/src';
import { selectLoyaltyNewDomain } from '../attach/personalized-or-not/selectors';
import {
  MIGRATION_PASSWORD_PAGE,
  LOYALTY_FUTURES_MIGRATION_VALIDATION_PAGE,
} from '../../../shared/formPages';
import { makeSelectFormPageName } from '../../dynamic-form/selectors';
import { checkSolRegistrationStatus } from '@shell-b2c/http-frontend/dist/src/components/core/loyalty';
import { SOL_REGISTRATION_BELONGS_TO_THIS_CARD_NUMBER } from '../../../shared/solResponseErrorCodes';
import lokaliseTags from '../../../shared/lokaliseTags';
import { buildNotification } from '../../notification/utils';
import ConeAnimation from '../../../shared/components/ConeAnimation';
import { SEND_UID } from '../../app/constants';

export function initializeHeader(setHeader) {
  return async (dispatch, getState) => {
    const { query } = getState().app;
    const header = { ...DEFAULT_HEADER };
    const isMotoristApp = makeSelectIsMotoristApp()(getState());
    const isLoyaltyActivated = getState().profile.loyalty.activated;

    if (
      urlHistory.find(
        (location) => location.pathname === SOL_ATTACH_WELCOME_PATHNAME
      )
    ) {
      // If the flow is Loyalty and we start from registration or login then
      // we should go to the sol/attach/welcome screen
      header.menu.leftMenu.icon = 'back';
      header.menu.leftMenu.event = () => {
        browserHistory.back();
      };
    } else {
      if (isLoyaltyActivated) {
        header.menu.leftMenu.desktop = false;
      }

      header.menu.leftMenu.event = () => {
        if (isLoyaltyActivated) {
          browserHistory.push(PROFILE_PATHNAME);
        } else if (isMotoristApp) {
          dispatch(redirectToCVP());
        } else {
          browserHistory.push(PROFILE_PATHNAME);
        }
      };
      if (query.service === LOYALTY_SERVICE && !isLoyaltyActivated) {
        header.menu.leftMenu.desktop = false;
        header.menu.leftMenu.mobile = false;
      }
    }

    setHeader(header);
  };
}

export function solResetPassword() {
  return (dispatch) => {
    dispatch({
      type: SOL_PASSWORD_ATTRIBUTE_CHANGE,
      name: 'returnPage',
      value: ATTACH_LOYALTY_PATHNAME,
    });
    browserHistory.push(SOL_PASSWORD_PATHNAME);
  };
}

export function showAddress(data, completeLoyalty, setHeader) {
  return (dispatch, getState) => {
    const loyaltyMigration = getState().dynamicForm.form.loyaltyMigration;
    const props = loyaltyMigration.properties;
    let requiredDone = true;
    Object.keys(props || {}).forEach((d) => {
      if (
        props[d].required &&
        (data[props[d].name] === undefined || data[props[d].name] === '')
      ) {
        requiredDone = false;
      } else if (props[d].required) {
        Object.keys(props[d].validations || {}).forEach(() => {
          dispatch(doValidation(loyaltyMigration, data, props[d].name));
        });
      }
    });

    if (
      Object.keys(getState().dynamicForm.errors).length === 0 &&
      requiredDone
    ) {
      completeLoyalty();
    } else {
      dispatch({
        type: CHANGE_VALUE,
        name: 'address',
        value: true,
      });
      const header = ATTACH_CARD_HEADER_ADDRESS;
      header.menu.leftMenu.event = () => {
        dispatch({
          type: CHANGE_VALUE,
          name: 'address',
          value: false,
        });
        const step = 0;
        dispatch(setFormStep(step));
        const newHeader = ATTACH_CARD_HEADER_ADDRESS;
        newHeader.menu.leftMenu.event = () => {
          browserHistory.push(LOYALTY_PATHNAME);
        };
        setHeader(newHeader);
      };
      setHeader(header);
    }
  };
}

export function saveLoyalty({ options = {}, ...loyaltyData } = {}) {
  return async (dispatch, getState) => {
    const isMigrationFlow = [
      MIGRATION_PASSWORD_PAGE,
      LOYALTY_FUTURES_MIGRATION_VALIDATION_PAGE,
    ].includes(makeSelectFormPageName()(getState()));

    try {
      if (loyaltyData?.loyalty?.cardId) {
        await addLoyalty(loyaltyData);
      } else {
        await attachLoyaltyDigitalCard(
          Object.keys(loyaltyData).length
            ? loyaltyData
            : { loyalty: { activated: true } }
        );
      }

      const profile = await getProfile({ noSpinner: true });
      dispatch(loadUserProfile({ ...profile }));

      const isMotoristApp = makeSelectIsMotoristApp()(getState());
      const hasRedirect = makeSelectQueryRedirect()(getState());
      const isFirebladeCVP = makeSelectIsMotoristFirebladeCVP()(getState());
      const isLoyaltyService = makeSelectIsLoyaltyService()(getState());
      const authCode = makeSelectQueryAuthCode()(getState());
      dispatch(setSendUid());

      const uuid = Api.uuid || get(profile, 'uuid', false);
      let callback;

      if (!isLoyaltyService || !authCode) {
        if (hasRedirect || isMotoristApp) {
          callback =
            (hasError = false) =>
            () => {
              dispatch(setSpinner(true));
              let redirectData = {};
              if (!isMigrationFlow || isMotoristApp) {
                redirectData = {
                  url:
                    hasError && isFirebladeCVP
                      ? ERROR_APP_PATHNAME
                      : LOYALTY_ATTACHED_APP_PATHNAME,
                };
              }
              dispatch(redirectToCVP(redirectData));
            };
        } else {
          callback = () => () => {
            browserHistory.push(PROFILE_PATHNAME);
            dispatch(setProfileOption(SOL_LOYALTY_CARDS_PATHNAME));
            dispatch(setNotificationVisible(false));
          };
        }
      }

      dispatch(verifyLoyalty({ uuid, options }, callback));
    } catch (error) {
      const {
        form: { description },
      } = selectLoyaltyNewDomain()(getState());

      dispatch(setNotificationVisible(false));

      if (isMigrationFlow) {
        return dispatch(showLoyaltyMigrationFailedNotification());
      }

      if (error?.errors?.length && description !== undefined) {
        return dispatch(setFormError(error.getFormErrors()));
      }

      dispatch(
        showDefaultErrorNotification(
          null,
          lokaliseTags.SSO_ERROR_GENERIC_JANRAIN
        )
      );
    }
  };
}

export function showLoyaltyMigrationFailedNotification() {
  return (dispatch) => {
    dispatch(
      showNotification(
        buildNotification({
          animation: ConeAnimation,
          title: lokaliseTags.SSO_LOYALTY_MIGRATION_FAILED,
          text: lokaliseTags.SSO_ERROR_GENERIC_SOL_MIGRATION_FAILED_TEXT,
          buttons: [
            {
              label: lokaliseTags.SSO_GENERAL_TRY_AGAIN,
            },
          ],
        })
      )
    );
  };
}

export function showLoyaltyCardBlockedNotification() {
  return (dispatch) => {
    dispatch(
      showNotification(
        buildNotification({
          animation: ConeAnimation,
          title: lokaliseTags.SSO_LOYALTY_SOL_CARD_BLOCKED,
          text: lokaliseTags.SSO_LOYALTY_SOL_CARD_BLOCKED_TEXT,
          buttons: [
            {
              label: lokaliseTags.SSO_GENERAL_CONTINUE,
            },
          ],
        })
      )
    );
  };
}

export function changeCard(value) {
  return async (dispatch) => {
    dispatch({
      type: CHANGE_VALUE,
      name: 'changeCardIframe',
      value,
    });
  };
}

export function handleCardUpdatedMessage(setHeader) {
  return async (dispatch) => {
    await dispatch(refreshProfile());
    dispatch(setProfileOption(SOL_LOYALTY_CARDS_PATHNAME));
    setHeader(DEFAULT_HEADER);
  };
}

export function isSOLRegistrationValid({ cardId, emailAddress }) {
  return async (_, getState) => {
    const market = makeSelectQueryMarket()(getState());
    const { response_status: status } =
      (await checkSolRegistrationStatus({
        market,
        cardId,
        emailAddress,
      })) || {};
    const response = {
      isValid: false,
      code: status,
    };

    if (SOL_REGISTRATION_BELONGS_TO_THIS_CARD_NUMBER === status) {
      response.isValid = true;
    }

    return response;
  };
}
export function setSendUid() {
  return {
    type: SEND_UID,
  };
}
export default {
  solResetPassword,
  showAddress,
  saveLoyalty,
  changeCard,
  handleCardUpdatedMessage,
};
