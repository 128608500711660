import omit from 'lodash/omit';
import { LOYALTY_FUTURES_CVP } from '../../cvps';
import lokaliseTags from '../../lokaliseTags';
import { Utf8Decode, getCountryFromMarket } from '../../utils';
import {
  getBirthDate,
  getGender,
  getDriveDataFromAppData,
  getPaymentsDataFromAppData,
} from '../common-utils';
import { cleanLodUser } from '../global-utils';
import { createUserInitialLoyaltyProfileFromCard } from '../sol-utils';

const initialUserProfileData = {
  newPassword: null,
  consents: {
    loyaltyOptIn: false,
    driveOptIn: false,
    newsletters: false,
  },
  profile: {
    firstName: null,
    lastName: null,
    emailAddress: null,
    phoneNumber: null,
    birthdate: null,
    gender: lokaliseTags.SSO_GENDER_UNDISCLOSED,
    generalUserId: 'no-guid-provided',
    udid: 'no-udid-provided',
    primaryAddress: {
      street: null,
      city: null,
      houseNumber: null,
      zip: null,
    },
  },
  market: {
    code: null,
    country: null,
  },
};

const initialLoyaltyData = {
  cardId: null,
  migrationOption: 'MIGRATE',
};

export function getLoyaltyDataFromAppData(profile, loyalty = {}) {
  const { fields, ...others } = loyalty;
  const data = {
    loyalty: {
      ...initialLoyaltyData,
      ...others,
    },
  };
  if (fields) {
    data.profile = {
      ...profile,
      birthdate: profile.birthdate || getBirthDate(fields.date_of_birth),
      emailAddress: (profile.emailAddress || Utf8Decode(fields.email)).trim(),
      firstName: profile.firstName || Utf8Decode(fields.first),
      lastName: profile.lastName || Utf8Decode(fields.family),
      phoneNumber: profile.phoneNumber || Utf8Decode(fields.mphone),
      gender: getGender(profile.gender),
      primaryAddress: {
        ...profile.primaryAddress,
        street: profile.primaryAddress.street || Utf8Decode(fields.street),
        city: profile.primaryAddress.city || Utf8Decode(fields.city),
        zip: profile.primaryAddress.zip || Utf8Decode(fields.zip),
        houseNumber:
          profile.primaryAddress.houseNumber || Utf8Decode(fields.houseNumber),
      },
    };
  }
  return data;
}

export function getLoyaltyDataFromSOLData(solData) {
  const { fields, dob } = solData;
  const hasNotificationsActivated =
    solData.general_marketing || solData.personal_marketing || false;
  const loyalty = {
    ...initialLoyaltyData,
    cardId: solData.card_id,
  };
  return {
    consents: {
      loyaltyOptIn: hasNotificationsActivated,
      newsletters: hasNotificationsActivated,
    },
    profile: {
      birthdate: getBirthDate(fields.date_of_birth || dob),
      emailAddress: (Utf8Decode(fields.email) || '').trim(),
      firstName: Utf8Decode(fields.first),
      lastName: Utf8Decode(fields.family),
      phoneNumber: Utf8Decode(fields.mphone),
      gender: getGender(fields.gender),
      primaryAddress: {
        street: Utf8Decode(fields.street),
        city: Utf8Decode(fields.city),
        zip: Utf8Decode(fields.zip),
        houseNumber: Utf8Decode(fields.houseNumber),
      },
    },
    loyalty,
  };
}

export function getLoyaltyAttachDataFromSOLData(solData) {
  return {
    street: Utf8Decode(solData.fields.street),
    birthdate: solData.dob,
    cardId: solData.card_id,
    city: Utf8Decode(solData.fields.city),
    zip: Utf8Decode(solData.fields.zip),
    houseNumber: Utf8Decode(solData.fields.houseNumber),
    gender: getGender(solData.fields.gender),
  };
}

export function createUserProfileFromAppData(user, udid, guid, cvp) {
  if (cvp === LOYALTY_FUTURES_CVP) {
    return createProfileFromLodUser(user);
  } else {
    return createProfileFromSOLUser(user, guid, udid);
  }
}

function createProfileFromLodUser(user) {
  const cleanedUser = cleanLodUser(user);
  const addressData = cleanedUser.address;
  const { postCode: zip, ...rest } = addressData;

  const data = {
    ...initialUserProfileData,
    profile: {
      ...initialUserProfileData.profile,
      firstName: cleanedUser.firstName,
      lastName: cleanedUser.lastName,
      birthdate: cleanedUser.dob,
      phoneNumber: cleanedUser.mobilePhone,
      gender: cleanedUser.gender?.toLowerCase(),
      primaryAddress: {
        zip,
        ...rest,
      },
    },
    market: {
      code: cleanedUser.market,
      country: getCountryFromMarket(cleanedUser.market),
    },
    consents: {
      goPlusMarketingOptIn: false,
      newsletters: false,
    },
    loyalty: {
      activatedGoPlus: true,
      migrationOption: 'MIGRATE',
      accounts: [
        {
          accountProvider: 'LF',
          status: 'ENABLED',
          card: [
            {
              cardEnabled: 'true',
              cardType: 'PLASTIC',
              cardStatusReason: 'Migrated in SSO',
              cardId: cleanedUser.cardId,
            },
          ],
        },
      ],
    },
  };

  if (cleanedUser.email) {
    data.profile.emailAddress = cleanedUser.email;
  }

  return data;
}

function createProfileFromSOLUser(user, guid, udid) {
  const data = {
    ...initialUserProfileData,
    profile: {
      ...initialUserProfileData.profile,
      ...user.profile,
      primaryAddress: {
        ...initialUserProfileData.profile.primaryAddress,
        ...user.profile.primaryAddress,
      },
      gender: getGender(user.profile.gender),
      generalUserId:
        guid ||
        user.profile.generalUserId ||
        initialUserProfileData.profile.generalUserId,
      udid: udid || user.profile.udid || initialUserProfileData.profile.udid,
    },
    market: {
      ...initialUserProfileData.market,
      ...user.market,
    },
    consents: {
      ...initialUserProfileData.consents,
      ...user.consents,
    },
  };
  // order matters here, because we need to check and add data following this order
  // profile > payments > drive > loyalty
  if (user.loyalty) {
    const loyaltyData = getLoyaltyDataFromAppData(data.profile, user.loyalty);
    data.profile = {
      ...loyaltyData.profile,
    };
    data.loyalty = {
      ...loyaltyData.loyalty,
    };
  }
  if (user.drive) {
    const driveData = getDriveDataFromAppData(
      data.profile,
      user.drive,
      user.myGarage
    );
    data.profile = {
      ...driveData.profile,
    };
    data.drive = {
      ...driveData.drive,
    };
    data.myGarage = {
      ...driveData.myGarage,
    };
  }
  if (user.payments) {
    const paymentsData = getPaymentsDataFromAppData(
      data.profile,
      user.payments
    );
    data.profile = {
      ...paymentsData.profile,
    };
    data.payments = {
      ...paymentsData.payments,
    };
  }
  return data;
}

export function createUserProfileFromSOLData(solData, market) {
  const { consents, ...others } = getLoyaltyDataFromSOLData(solData);
  return {
    ...initialUserProfileData,
    ...others,
    market: {
      code: market,
      country: getCountryFromMarket(market),
    },
    consents: {
      ...initialUserProfileData.consents,
      ...consents,
    },
  };
}

export function cleanMigrationFormData(migrationData) {
  const data = {
    ...omit(migrationData, [
      'noSetStates',
      'submitCall',
      'mode',
      'missingFields',
      'loaded',
      'page',
      'focus',
      'step',
      'emailAddress',
      'profile',
    ]),
    profile: {
      ...migrationData.profile,
      generalUserId: migrationData.profile.generalUserId || 'no-guid-provided',
      udid: migrationData.profile.udid || 'no-udid-provided',
      emailAddress: (
        migrationData.emailAddress ||
        migrationData.profile.emailAddress ||
        ''
      )
        .trim()
        .toLowerCase(),
    },
  };
  return data.profile.gender ? data : omit(data, ['profile.gender']);
}

export function createUserProfileFromLoyaltyInitialFormData({
  cardId,
  cardType,
  migrationOption,
  street,
  zip,
  city,
  houseNumber,
  ...others
}) {
  return {
    loyalty: createUserInitialLoyaltyProfileFromCard({
      cardId,
      cardType,
      migrationOption,
    }),
    profile: {
      ...others,
      primaryAddress: {
        street,
        zip,
        city,
        houseNumber,
      },
    },
  };
}

export function getCardIdRegex() {
  return '^700432[0-9]{11}$';
}
