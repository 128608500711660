import camelCase from 'lodash/camelCase';
import get from 'lodash/get';
import {
  SAVE_QUERY,
  SAVE_QUERY_SCOPES,
  SET_TRANSLATIONS,
  SHOW_SPINNER,
  SET_CVP,
  SEND_TOKENS,
  noDeleteSpinnerMarker,
  MISSING_MARKET,
  ERRORS_WITH_OWN_NOTIFICATION,
  NO_BACKEND_CONNECTION,
  NO_HREF,
  appAuthorizationError,
  appPathNamesDeleteSpinner,
  appPathnamesnoDeleteSpinner,
  TEST_MARKET_VERSION,
} from './constants';
import qs from 'qs';
import { SETTINGS_LOADED } from '../settings/constants';
import {
  LOYALTY_ACTIVATION_FAILED,
  AUTHORIZATION_FAILED,
  CONTENT_SETTINGS_FAILED,
  TRANSLATIONS_FAILED,
  BACKEND_NOTIFICATION,
  OFFLINE_NOTIFICATION,
  getTranslationsFailedNotification,
  NO_MARKET,
} from './notifications';
import { SET_IS_LOYALTY_VERIFIED } from '../profile/constants';
import {
  EXIT_APP_PATHNAME,
  ERROR_APP_PATHNAME,
  HIDE_NAVIGATION_APP_PATHNAME,
  WIDGET_READY_APP_PATHNAME,
  UPDATE_PROFILE_APP_PATHNAME,
  UPDATE_CREDENTIALS_PATHNAME,
  SAVE_CREDENTIALS_PATHNAME,
} from '../../shared/appPathnames';
import {
  PROFILE_PATHNAME,
  PROGRESSIVE_PROFILING_SUCCESS,
  REGISTER_TC_AND_PRIVACY_PATHNAME,
  SOL_ATTACH_WELCOME_PATHNAME,
} from '../../shared/pathnames';
import {
  addUrlParams,
  hideMobileKeyboard,
  setScrollToTop,
  loadRecaptcha,
} from '../../shared/utils';
import { toggleTag } from '../header/actions';
import { activateUser } from '@shell-b2c/http-frontend/dist/src/components/core/loyalty';
import {
  makeSelectIsLoyaltyActivatedInAppSettings,
  makeSelectCanAttachMultipleCards,
  makeSelectEmailAuthentication,
  makeSelectIsLoyaltyFuturesActivatedInAppSettings,
  makeSelectIsSAPHybrisActivatedInAppSettings,
  makeSelectIsGoPlusActivatedInSettings,
} from '../settings/selectors';
import {
  makeSelectIsMotoristApp,
  makeSelectQuery,
  makeSelectQueryMarket,
  makeSelectQueryMgm,
  makeSelectAccessToken,
  makeSelectCVP,
  makeSelectIsAutomation,
  makeSelectIsDriveCVP,
  makeSelectQueryScopes,
  makeSelectQueryClientId,
  makeSelectQueryRedirect,
} from './selectors';
import { getAuthorization } from '../../http/widget/auth';
import { accessCode as getAccessCode } from '@shell-b2c/http-frontend/dist/src/components/core/auth';
import {
  loadScript,
  deleteAllStorageData,
  setDataToStore,
  deleteStorage,
  getStoredData,
  cleanInitialUrl,
  compareScopes,
} from './utils';
import { createUserProfileFromAppData } from '../../shared/mapping';
import {
  showNotification,
  showDefaultErrorNotification,
  setNotificationVisible,
} from '../notification/actions';
import {
  makeSelectIsGoPlusActivated,
  makeSelectIsMgmUser,
  makeSelectUUID,
} from '../profile/selectors';
import {
  SDA_ENVIRONMENT,
  DEBUG,
  ADOBE_SCRIPT_URL_NO_COOKIE_CONSENT,
  ADOBE_SCRIPT_URL,
  DEFAULT_COCO_VERSION,
  CAPTCHA_KEY_V3,
  AUTOMATION_CAPTCHA_KEY_V3,
  NUMBERS,
} from '../../shared/constants';
import browserHistory from '../../router/history';
import { Api } from '@shell-b2c/http-frontend/dist/src';
import { makeSelectIsMobile } from '../browser/selectors';
import {
  getLokaliseTranslations,
  checkEmailExist,
} from '@shell-b2c/http-frontend/dist/src/components/core/settings';
import {
  MOTORIST_CVP,
  MOTORIST_AMERICA_CVP,
  MOTORIST_FIREBLADE_AMERICA_CVP,
  MOTORIST_FIREBLADE_CVP,
  FR_CVP,
} from '../../shared/cvps';
import { getSettings } from '@shell-b2c/http-frontend/dist/src/components/coco';
import { ACTIVATION_ERROR } from '../../shared/solResponseErrorCodes';
import adobeAnalyticTags from '../../shared/adobeAnalyticTags';
import { getProfile } from '@shell-b2c/http-frontend/dist/src/components/core/user';
import { loadUserProfile } from '../profile/actions';
import { buildNotification } from '../notification/utils';
import SandWatchAnimation from '../../shared/components/SandWatchAnimation';
import lokaliseTags from '../../shared/lokaliseTags';
import TickAnimation from '../../shared/components/TickAnimation';
import { AUTH_REDIRECTION } from '@shell-b2c/http-frontend/dist/src/components/core/endpoints';
import { decryptData, encryptData, generateHash } from '../../http/core/utils';
import { isFunction } from 'lodash';
import { getClientConfigScopes } from '@shell-b2c/http-frontend/dist/src/components/core/scopes';
import { makeSelectIsPPFlow } from '../progressive-profiling/selectors';

window.ssoDeeplink = null;
window.setLocationHref = async (url, params, dispatch) => {
  let skipSetSpinner = false;
  for (const pathname of appPathnamesnoDeleteSpinner) {
    if (
      url.includes(noDeleteSpinnerMarker) &&
      pathname === url.split(noDeleteSpinnerMarker)[0]
    ) {
      skipSetSpinner = true;
      url = url.split(noDeleteSpinnerMarker).join('');
      break;
    }
  }
  let redirectUrl = url.split('?')[0];
  const hasAccessCodeParam = params
    ? params.filter((p) => p.param === 'accessCode')
    : null;
  if (hasAccessCodeParam)
    redirectUrl = addUrlParams(redirectUrl, hasAccessCodeParam);

  try {
    const authRedirectionResponse = await dispatch(
      checkRedirectionWhitelisted(redirectUrl)
    );
    for (const appPathname in appPathNamesDeleteSpinner) {
      if (skipSetSpinner) break;
      if (
        authRedirectionResponse.includes(appPathNamesDeleteSpinner[appPathname])
      ) {
        dispatch(setSpinner(false));
      }
    }

    const urlWithParams = params ? addUrlParams(url, params) : url;
    if (window.Cypress) {
      window.ssoDeeplink = urlWithParams;
    } else {
      window.location.href = urlWithParams;
    }
  } catch (error) {
    dispatch(
      showDefaultErrorNotification(() => {},
      lokaliseTags.SSO_TECHNICAL_ERROR_REDIRECT)
    );
  }
};

export function setLocation(url, params) {
  return (dispatch) => {
    window.setLocationHref(url, params, dispatch);
  };
}

export function saveQuery(query) {
  return (dispatch) => {
    dispatch(setQuery(query));
  };
}

export function setQuery(query) {
  const formattedQuery = { ...query };
  const EXPLORE_KEYS = [
    'client_id',
    'redirect_uri',
    'access_code',
    'auth_code',
    'api_version',
  ];

  EXPLORE_KEYS.forEach((value) => {
    if (formattedQuery[value]) {
      const prop = value === 'redirect_uri' ? 'redirect' : camelCase(value);
      formattedQuery[prop] = formattedQuery[value];
      formattedQuery[value] = undefined;
    }
  });

  return {
    type: SAVE_QUERY,
    query: formattedQuery,
  };
}

export function missingMarket() {
  return (dispatch) => {
    const redirectErrorData = {
      error: [{ 401: MISSING_MARKET }],
    };
    dispatch(redirectToCVP(redirectErrorData));
  };
}
export function missingData() {
  return (dispatch) => {
    const redirectErrorData = {
      error: [{ 401: 'Missing udid and/or guid' }],
    };
    dispatch(redirectToCVP(redirectErrorData));
  };
}

function handleVerifyLoyaltyError(skipRedirect, callback, returnId) {
  return (dispatch, getState) => {
    const query = makeSelectQuery()(getState());
    const sendUid = getState().loyalty.sendUid;
    // Remove any previous notification like the setupLoyalty one
    dispatch(setNotificationVisible(false));
    let redirect = query.redirect;
    if (sendUid) {
      redirect += `${
        redirect?.indexOf('?') === -1 ? '?' : '&'
      }uuid=${encodeURIComponent(returnId)}`;
    }
    const notification = LOYALTY_ACTIVATION_FAILED;
    if (query.redirect) {
      notification.header.icon.event = () => {
        dispatch(redirectToCVP());
      };
    } else {
      notification.header.icon.event = () => {
        dispatch(setLocation(redirect));
      };
    }
    if (makeSelectIsMotoristApp()(getState())) {
      if (!skipRedirect) {
        if (callback) {
          dispatch(callback(true));
        } else {
          dispatch(setLocation(redirect));
        }
      }
    } else {
      dispatch(showNotification(notification));
    }
  };
}

function handleVerifyLoyaltyOk(skipRedirect, callback) {
  return (dispatch, getState) => {
    const query = makeSelectQuery()(getState());
    dispatch({
      type: SET_IS_LOYALTY_VERIFIED,
    });
    if (!skipRedirect) {
      const canAttachMultipleCards = makeSelectCanAttachMultipleCards()(
        getState()
      );
      if (makeSelectIsPPFlow()(getState())) {
        return browserHistory.push(`${PROGRESSIVE_PROFILING_SUCCESS}`);
      }
      const notification = {
        animation: TickAnimation,
        title: lokaliseTags.SSO_LOYALTY_SETUP_LOYALTY_COMPLETED_TITLE,
        text: lokaliseTags.SSO_LOYALTY_SETUP_LOYALTY_COMPLETED_TEXT,
        buttons: [
          {
            label: lokaliseTags.SSO_LOYALTY_SETUP_LOYALTY_COMPLETED_BUTTON,
            event: () => {
              dispatch(setSpinner(true));
              dispatch(
                sendAdobeAnalyticsEvent(
                  adobeAnalyticTags.solConfirmationScreen.continueButton,
                  'tileClick'
                )
              );
              if (isFunction(callback)) {
                dispatch(callback());
              } else if (callback === true || query.redirect) {
                dispatch(redirectToCVP());
              } else {
                browserHistory.push(PROFILE_PATHNAME);
              }
            },
          },
        ],
      };

      if (canAttachMultipleCards) {
        notification.buttons.unshift({
          variant: 'outlined',
          label: lokaliseTags.SSO_MIGRATION_ATTACH_NEW_CARD,
          event: () => {
            dispatch(
              sendAdobeAnalyticsEvent(
                adobeAnalyticTags.solConfirmationScreen.attachNewButton,
                'tileClick'
              )
            );
            browserHistory.push(SOL_ATTACH_WELCOME_PATHNAME);
          },
        });
      }

      dispatch(
        sendAdobeAnalyticsEvent(
          adobeAnalyticTags.solConfirmationScreen.pageLoad
        )
      );

      dispatch(showNotification(buildNotification(notification)));
    } else {
      // Skip redirect when coming from migration as
      // we are showing the confirm email screen
      dispatch(setNotificationVisible(false));
    }
  };
}

export function setupLoyaltyNotification() {
  return (dispatch) => {
    dispatch(
      showNotification(
        buildNotification({
          animation: SandWatchAnimation,
          title: lokaliseTags.SSO_LOYALTY_LOADING_TITLE,
          text: lokaliseTags.SSO_LOYALTY_LOADING_TEXT,
        })
      )
    );
  };
}

export function verifyLoyalty({ uuid, options = {} }, callback, skipRedirect) {
  return async (dispatch, getState) => {
    const query = makeSelectQuery()(getState());
    let { profile } = getState();
    const newFlow = query.authCode || query.clientId ? redirectToCVP : false;
    const newFlowBool = !!(query.authCode || query.clientId);
    const returnId = uuid || profile.uuid;
    if (skipRedirect) dispatch(setupLoyaltyNotification());
    if (
      makeSelectIsLoyaltyActivatedInAppSettings()(getState()) &&
      returnId &&
      !profile.isLoyaltyVerified
    ) {
      const data = {
        market: query.market,
        uuid: returnId,
        ...options,
      };

      try {
        const response = await activateUser(data, { noSpinner: true });

        if (query.accessToken) {
          profile = await getProfile({ noSpinner: true });
          dispatch(loadUserProfile({ ...profile }));
        }

        if (response.response_status === ACTIVATION_ERROR) {
          dispatch(
            handleVerifyLoyaltyError(
              skipRedirect,
              callback || newFlow,
              returnId
            )
          );
        } else if (response.response_status === 0) {
          dispatch(
            handleVerifyLoyaltyOk(skipRedirect, callback || newFlowBool)
          );
        } else {
          const resp = await activateUser(data);
          if (resp.response_status === 0) {
            dispatch(
              handleVerifyLoyaltyOk(skipRedirect, callback || newFlowBool)
            );
          } else {
            dispatch(
              handleVerifyLoyaltyError(
                skipRedirect,
                callback || newFlow,
                returnId
              )
            );
          }
        }
      } catch (error) {
        dispatch(
          handleVerifyLoyaltyError(skipRedirect, callback || newFlow, returnId)
        );
      }
    } else if (callback) {
      dispatch(callback());
    } else if (newFlow) {
      dispatch(newFlow());
    } else if (query.redirect) {
      dispatch(redirectToCVP());
    } else {
      browserHistory.push(PROFILE_PATHNAME);
    }
  };
}
function getCocoVersionParam() {
  return async (_, getState) => {
    const query = makeSelectQuery()(getState());
    if (query['test-market'] === 'true') {
      return TEST_MARKET_VERSION;
    } else if (query.appVersion) {
      return query.appVersion;
    } else {
      return DEFAULT_COCO_VERSION;
    }
  };
}
export function settingsContent() {
  return async (dispatch, getState) => {
    const query = makeSelectQuery()(getState());
    const cvp = makeSelectCVP()(getState());
    try {
      const cocoVersion = await dispatch(getCocoVersionParam());
      const response = await getSettings(
        [MOTORIST_CVP, FR_CVP, MOTORIST_AMERICA_CVP].indexOf(cvp) !== -1
          ? 'sso_motorist'
          : 'sso',
        query.market,
        cocoVersion
      );
      dispatch({
        type: SETTINGS_LOADED,
        response,
      });
    } catch (error) {
      manageContentErrors(error, dispatch, CONTENT_SETTINGS_FAILED, {
        market: query.market,
      });
      throw new Error('NO_CONFIG');
    }
  };
}

function translationContent() {
  return async (dispatch, getState) => {
    const market = makeSelectQueryMarket()(getState());
    try {
      const response = await getLokaliseTranslations({ market });
      dispatch({
        type: SET_TRANSLATIONS,
        translations: response,
      });
    } catch (error) {
      manageContentErrors(error, dispatch, TRANSLATIONS_FAILED, { market });
      throw new Error('NO_TRANSLATIONS');
    }
  };
}
export function checkRedirectionWhitelisted(redirectUrl) {
  return async (dispatch) => {
    try {
      const href = `${SDA_ENVIRONMENT}/api/v2/${AUTH_REDIRECTION}/?redirect=${encodeURIComponent(
        redirectUrl
      )}`;

      return await Api.get(`${href}&urlInBody=true`, {
        noSpinner: true,
      });
    } catch (error) {
      dispatch(
        showDefaultErrorNotification(() => {},
        lokaliseTags.SSO_TECHNICAL_ERROR_REDIRECT)
      );
    }
  };
}

export function manageContentErrors(
  error,
  dispatch,
  notificationMsg,
  { market }
) {
  const failedEvent = () => {
    const redirectErrorData = {
      error: [{ 500: NO_BACKEND_CONNECTION }],
    };
    dispatch(redirectToCVP(redirectErrorData));
  };
  if (error?.resourceNotFound() || error?.marketInvalid()) {
    const notification = notificationMsg;
    notification.header.icon.event = failedEvent;
    dispatch(showNotification(notification));
  } else if (notificationMsg === TRANSLATIONS_FAILED) {
    const translationsFailedNotification =
      getTranslationsFailedNotification(market);
    translationsFailedNotification.header.icon.event = failedEvent;
    translationsFailedNotification.buttons[0].event = failedEvent;
    dispatch(showNotification(translationsFailedNotification));
  } else {
    dispatch(showDefaultErrorNotification(null, lokaliseTags.SSO_GENERIC_COCO));
  }
}

function loadAdobeScript(cvp) {
  return async (_, getState) => {
    const isMobile = makeSelectIsMobile()(getState());

    if (
      isMobile ||
      [
        MOTORIST_CVP,
        MOTORIST_AMERICA_CVP,
        MOTORIST_FIREBLADE_AMERICA_CVP,
        MOTORIST_FIREBLADE_CVP,
        FR_CVP,
      ].includes(cvp)
    ) {
      await loadScript(ADOBE_SCRIPT_URL_NO_COOKIE_CONSENT);
    } else {
      await loadScript(ADOBE_SCRIPT_URL);
    }
  };
}

export function showOfflineErrorNotification() {
  return (dispatch) => {
    const notification = OFFLINE_NOTIFICATION;
    const redirectErrorData = {
      error: [{ 400: 'No internet connection available' }],
    };
    notification.buttons[0].event = () => {
      if (!navigator.onLine) {
        dispatch(redirectToCVP(redirectErrorData));
      }
    };
    hideMobileKeyboard();
    dispatch(showNotification(notification));
  };
}

export function initializeApp(location) {
  return async (dispatch, getState) => {
    try {
      let query = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });
      query = dispatch(modifyStorage(query));
      // Save all parameters provided by app or web
      await dispatch(saveQuery(query));
      await dispatch(saveStorage(query));
      // Make sure we have a nice and clean url
      const entryPointUrl = `${location.pathname}${cleanInitialUrl(
        location.search
      )}`;
      document.cookie = `entryURL=${entryPointUrl}`;
      browserHistory.replace(entryPointUrl);

      const { market, accessToken, refreshToken, authCode, clientId } =
        makeSelectQuery()(getState());
      const isMobile = makeSelectIsMobile()(getState());
      const isAutomation = makeSelectIsAutomation()(getState());
      if (isAutomation) {
        // Show translation tags to automation
        dispatch(toggleTag());
      }

      await Api.initialize({
        market,
        isMobile,
        baseUrl: SDA_ENVIRONMENT,
        apiVersion: 2,
        accessToken,
        refreshToken,
      });

      const response = await getAuthorization({
        authCode,
        clientId,
      });

      await dispatch(loadAdobeScript(response.cvp || response.owner));

      dispatch({
        type: SET_CVP,
        cvp: response.cvp || response.owner,
      });

      const isMotoristApp = makeSelectIsMotoristApp()(getState());
      if (isMotoristApp) {
        await dispatch(setLocation(WIDGET_READY_APP_PATHNAME));
        // for ios not to show any kind of navigation
        await dispatch(setLocation(HIDE_NAVIGATION_APP_PATHNAME));
      }
      await dispatch(translationContent());
      await dispatch(settingsContent());
      const redirectUrl = makeSelectQueryRedirect()(getState());
      if (redirectUrl !== '')
        await dispatch(checkRedirectionWhitelisted(redirectUrl));

      if (DEBUG) {
        window.appCrypto = {
          decryptData,
          encryptData,
          generateHash,
        };
      }

      const _isAutomation = !!window.Cypress;

      loadRecaptcha(
        _isAutomation ? AUTOMATION_CAPTCHA_KEY_V3 : CAPTCHA_KEY_V3,
        query.market
      );
      await dispatch(handleScopesConfig());
    } catch (error) {
      if (ERRORS_WITH_OWN_NOTIFICATION.indexOf(error.message) !== -1) return;
      dispatch(authorizationFailedNotification());
    }
  };
}

export const handleScopesConfig = () => {
  return async (dispatch, getState) => {
    const scopes = makeSelectQueryScopes()(getState());

    if (scopes.length === 0) return;

    const clientId = makeSelectQueryClientId()(getState());

    if (!clientId) {
      dispatch(
        showDefaultErrorNotification(
          () => dispatch(redirectToCVP()),
          lokaliseTags.SSO_GENERIC_COCO
        )
      );
      return;
    }
    try {
      const configScopesResponse = await getClientConfigScopes(clientId);
      const newScopes = compareScopes(scopes, configScopesResponse.scopes);
      dispatch({
        type: SAVE_QUERY_SCOPES,
        scopes: newScopes,
      });
      if (newScopes.length === 0) {
        const redirectData = {
          params: [
            { param: 'status', value: 'invalid_scopes' },
            { param: 'scopes', value: scopes.join(' ') },
          ],
        };
        dispatch(
          showDefaultErrorNotification(
            () => dispatch(redirectToCVP(redirectData)),
            lokaliseTags.SSO_TECHNICAL_ERROR_SCOPES
          )
        );
      }
    } catch {
      dispatch(
        showDefaultErrorNotification(
          () => dispatch(redirectToCVP()),
          lokaliseTags.SSO_TECHNICAL_ERROR_SCOPES
        )
      );
    }
  };
};

export function authorizationFailedNotification() {
  return (dispatch, getState) => {
    const notification = AUTHORIZATION_FAILED;
    const isMobile = makeSelectIsMobile()(getState());
    notification.header.icon.event = () => {
      const redirectErrorData = {
        error: [{ 500: 'No backend connection' }],
      };
      if (isMobile) {
        const href = EXIT_APP_PATHNAME;
        const params = [
          {
            param: 'objects',
            value: encodeURIComponent(JSON.stringify(redirectErrorData.error)),
          },
        ];
        dispatch(setLocation(addUrlParams(href, params)));
      } else {
        dispatch(redirectToCVP(redirectErrorData));
      }
    };
    dispatch(showNotification(notification));
  };
}

const getHref = (redirectData) => {
  return async (dispatch, getState) => {
    const isDriveApp = makeSelectIsDriveCVP()(getState());
    const isMotoristApp = makeSelectIsMotoristApp()(getState());
    const { redirect, cvpUniqueIdentifier } = makeSelectQuery()(getState());
    if (redirectData?.showSpinner || redirectData?.showSpinner === undefined) {
      dispatch(setSpinner(true));
    }
    const {
      url = EXIT_APP_PATHNAME,
      sendAccessCode = true,
      error = false,
      accessToken = makeSelectAccessToken()(getState()),
      ignoreSpinner = false,
      params = [],
    } = redirectData;

    params.unshift({
      param: 'cvpUniqueIdentifier',
      value: cvpUniqueIdentifier,
    });

    let _href;
    try {
      if (error && isMotoristApp) {
        throw new Error();
      } else if (
        error?.[0] &&
        error[0][NUMBERS.FOUR_HUNDRED_ONE] === MISSING_MARKET
      ) {
        return { error: MISSING_MARKET };
      }

      if (accessToken && sendAccessCode) {
        const { accessCode } = await getAccessCode(
          { accessToken },
          { noSpinner: true }
        );

        if (
          isMotoristApp &&
          url === UPDATE_PROFILE_APP_PATHNAME &&
          !ignoreSpinner
        ) {
          dispatch(setSpinner(false));
        }
        params.push({ param: 'accessCode', value: accessCode });
      }
    } catch (err) {
      deleteAllStorageData();
      if (!isMotoristApp) {
        params.push({ param: 'error', value: 'authorizationFailed' });
        _href = redirect || url;
        if (!_href) return { error: NO_HREF };
        return {
          params,
          href: _href,
        };
      } else {
        params.push({
          param: 'objects',
          value: encodeURIComponent(
            JSON.stringify(error || appAuthorizationError.error)
          ),
        });
        return { params, href: ERROR_APP_PATHNAME };
      }
    }

    _href = isMotoristApp || isDriveApp ? url || redirect : redirect;
    if (!_href) return { error: NO_HREF };
    return {
      params,
      href: _href,
    };
  };
};

export function redirectToCVP(redirectData = {}) {
  return async (dispatch) => {
    const {
      params = [],
      href = null,
      error = null,
    } = await dispatch(getHref(redirectData));

    if (error && error === MISSING_MARKET) {
      dispatch(setSpinner(false));
      dispatch(showNotification(NO_MARKET));
      return;
    } else if (error && error === NO_HREF) {
      dispatch(setSpinner(false));
      dispatch(
        showDefaultErrorNotification(null, lokaliseTags.SSO_GENERIC_COCO)
      );
      return;
    }
    dispatch(setLocation(href, params));
  };
}

export function noBackendNotification() {
  return (dispatch) => {
    const notification = BACKEND_NOTIFICATION;
    notification.buttons[0].label = lokaliseTags.SSO_GENERAL_CLOSE;
    notification.buttons[0].event = () => {
      const redirectErrorData = {
        error: [{ 408: 'No backend connection' }],
      };
      dispatch(redirectToCVP(redirectErrorData));
    };
    dispatch(showNotification(notification));
  };
}

export function setSpinner(isVisible = false) {
  if (!isVisible) setScrollToTop();

  return {
    type: SHOW_SPINNER,
    value: isVisible,
  };
}

export function setSendTokens(value) {
  return {
    type: SEND_TOKENS,
    value,
  };
}

export function goPlusRedirect() {
  return (dispatch, getState) => {
    const isGoPlusActivated = makeSelectIsGoPlusActivated()(getState());

    if (isGoPlusActivated) {
      dispatch(redirectToCVP());
    } else {
      browserHistory.push(REGISTER_TC_AND_PRIVACY_PATHNAME);
    }
  };
}

function getLoyaltyEngine() {
  return (_, getState) => {
    const isLoyaltyFuturesActivatedInSettings =
      makeSelectIsLoyaltyFuturesActivatedInAppSettings()(getState());
    const isLoyaltyActivatedInSettings =
      makeSelectIsLoyaltyActivatedInAppSettings()(getState());
    const isSapHybrisActivatedInSettings =
      makeSelectIsSAPHybrisActivatedInAppSettings()(getState());
    const isGoPlusActivatedInSettings = makeSelectIsGoPlusActivatedInSettings()(
      getState()
    );

    if (isGoPlusActivatedInSettings) return 'GOPlus';
    if (isLoyaltyFuturesActivatedInSettings) return 'LOD';
    if (isLoyaltyActivatedInSettings) return 'SOL';
    if (isSapHybrisActivatedInSettings) return 'Hybris';
    return 'none';
  };
}
export function sendAdobeAnalyticsEvent(payload, trackClick = 'VPV') {
  return (dispatch, getState) => {
    const isAdobeAnalyticsAvailable = get(window._satellite, 'track', false); // eslint-disable-line
    if (isAdobeAnalyticsAvailable) {
      const market = makeSelectQueryMarket()(getState());
      const isMotoristApp = makeSelectIsMotoristApp()(getState());
      const userID = makeSelectUUID()(getState());
      const cvp = makeSelectCVP()(getState());
      const mgm =
        makeSelectQueryMgm()(getState()) || makeSelectIsMgmUser()(getState());
      const isEmailAuthentication = makeSelectEmailAuthentication()(getState());
      const loyaltyEngine = dispatch(getLoyaltyEngine());
      const SSOEvent = {
        siteSection: 'SSO',
        country: market,
        userType: mgm ? 'New_mgm' : 'New',
        signInType: 'Standard',
        status: userID ? 'Logged In' : 'Logged Out',
        platform: isMotoristApp ? 'App' : 'Desktop',
        cvp: 'SSO',
        initiator: cvp,
        userID: userID || null,
        mcvid: null,
        timestamp: new Date().toISOString(),
        broadlog: null,
        pushState: null,
        loyaltyEngine,
        defaultAuthentication: isEmailAuthentication ? 'email' : 'phone',
        ...payload,
      };
      if (trackClick === 'tileClick' && !payload.clickarea) {
        SSOEvent.clickarea = payload.pageName;
      }
      window.digitalData = {
        SSOEvent,
      };
      if (DEBUG) {
        console.log(
          '[SSO - Adobe Analytics] ',
          window.digitalData.SSOEvent,
          trackClick
        ); // eslint-disable-line
      }
      window._satellite.track(trackClick); // eslint-disable-line
    }
  };
}

export function validateEmailExistInJanrain({ email, recaptchaToken }) {
  return async () => {
    try {
      await checkEmailExist({ email, recaptchaToken });
      return true;
    } catch (error) {
      if (error?.resourceNotFound()) {
        return false;
      }

      throw new Error();
    }
  };
}

export function showSetUpNotification() {
  return (dispatch) => {
    dispatch(
      sendAdobeAnalyticsEvent(adobeAnalyticTags.setUpNotification.pageLoad)
    );

    dispatch(
      showNotification(
        buildNotification({
          animation: SandWatchAnimation,
          title: lokaliseTags.SSO_LOYALTY_LOADING_TITLE,
          text: lokaliseTags.SSO_LOYALTY_LOADING_TEXT,
        })
      )
    );
  };
}

function createMockForm({ username, password, type }) {
  const mockForm = document.createElement('form');
  mockForm.name = 'mockForm';
  const nameInput = document.createElement('input');
  nameInput.type = 'text';
  nameInput.name = 'nameInput';
  nameInput.autocomplete = 'username email';
  nameInput.value = username;
  mockForm.appendChild(nameInput);
  const passwordInput = document.createElement('input');
  passwordInput.type = 'password'; // NOSONAR
  passwordInput.name = 'passwordInput'; // NOSONAR
  passwordInput.autocomplete = type;
  passwordInput.value = password;
  mockForm.appendChild(passwordInput);

  return mockForm;
}

export function saveCredentials({
  username,
  password,
  type = 'new-password',
  skipForm = false,
  noDeleteSpinner = false,
}) {
  return async (dispatch, getState) => {
    const isMobileApp = makeSelectIsMotoristApp()(getState());

    if (isMobileApp) {
      const val = noDeleteSpinner ? '&noDeleteSpinner' : '';
      dispatch(
        setLocation(
          `${
            type === 'current-password'
              ? UPDATE_CREDENTIALS_PATHNAME
              : `${SAVE_CREDENTIALS_PATHNAME}${val}`
          }?username=${username}&password=${password}`
        )
      );
    }

    if (skipForm) return;

    const mockForm = createMockForm({
      username,
      password,
      type,
    });

    if (window.PasswordCredential) {
      const c = await navigator.credentials.create({ password: mockForm });
      navigator.credentials.store(c);
    }
  };
}

const commonElements = [
  'webview',
  'guid',
  'udid',
  'redirect',
  'service',
  'appVersion',
  'market',
  'partner',
  'test-market',
];

const elementsToDeleteFromStorage = ['query', ...commonElements, 'auth'];

const elementsToStoreOnQuery = [
  ...commonElements,
  'd',
  'authCode',
  'accessCode',
  'clientId',
  'accessToken',
  'refreshToken',
  'scopes',
];

const elementsToStoreOnStorage = [
  'query',
  'market',
  'redirect',
  'd',
  'service',
  'appVersion',
  'authCode',
  'partner',
];

const storeElementsToQuery = (type, query, storedMarket, storedQuery) => {
  return (dispatch) => {
    const x = {
      clientId: function () {
        return storedQuery?.clientId;
      },
      scopes: function () {
        return storedQuery?.scopes;
      },
      partner: function () {
        return query?.partner === 'true';
      },
      market: function () {
        if (storedMarket) return storedMarket;
        dispatch(missingMarket());
        throw new Error('NO_MARKET');
      },
      accessToken: function () {
        const auth = getStoredData('auth', true);
        return auth.accessToken;
      },
      refreshToken: function () {
        const auth = getStoredData('auth', true);
        return auth.refreshToken;
      },
      'test-market': function () {
        return false;
      },
      default: function () {
        return getStoredData(type);
      },
    };
    return (x[type] || x['default'])();
  };
};

export const modifyStorage = (query) => {
  return (dispatch) => {
    try {
      const storedMarket = getStoredData('market', false);
      const storedQuery = JSON.parse(getStoredData('query') || '{}');

      if (query?.market && storedMarket && query.market !== storedMarket) {
        elementsToDeleteFromStorage.forEach((element) => {
          deleteStorage(element);
        });
      }

      // Maybe user did hard refresh check for previous given data
      if (Object.keys(query).length === 0) {
        query = getStoredData('query', true);
      }

      elementsToStoreOnQuery.forEach((element) => {
        if (!query[element])
          query[element] = dispatch(
            storeElementsToQuery(element, query, storedMarket, storedQuery)
          );
      });
      let user = false;
      if (query.user) {
        if (typeof query.user === 'string') {
          user = JSON.parse(query.user);
          query.user = createUserProfileFromAppData(
            user,
            query.udid,
            query.guid
          );
        } else {
          user = query.user;
        }
      }
      if (user?.profile?.generalUserId) {
        query.guid = user.profile.generalUserId;
      }
      if (user?.profile?.udid) {
        query.udid = user.profile.udid;
      }
      if (query.scopes && typeof query.scopes === 'string') {
        query.scopes = query.scopes.split(' ');
      }

      if (!query.guid) query.guid = 'no-guid-provided';
      if (!query.udid && !query.webview) query.udid = 'no-udid-provided';
      else if (!query.udid && query.webview) {
        dispatch(missingData());
      }

      return query;
    } catch (e) {
      console.error(e);
      throw e;
    }
  };
};

export const saveStorage = (query) => {
  return () => {
    try {
      setDataToStore('query', JSON.stringify(query));
      setDataToStore('market', query.market);

      elementsToStoreOnStorage.forEach((element) => {
        let session = false;
        if (element === 'd' || element === 'authCode') session = true;

        if (query[element]) {
          const data =
            element === 'partner' ? query[element].toString() : query[element];
          setDataToStore(element, data, session);
        }
      });
    } catch (e) {
      console.log(e);
      return;
    }
  };
};

export default {
  saveQuery,
  missingMarket,
  missingData,
  verifyLoyalty,
  initializeApp,
  setSpinner,
  redirectToCVP,
  validateEmailExistInJanrain,
  modifyStorage,
  saveStorage,
};
