import React, { Suspense, useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect, useDispatch } from 'react-redux';
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Grid } from '@shell-b2c/web-design-system';
import { theme } from '../../shared/theme';
import { getTranslation } from '../../shared/translation-utils';
import Notification from '../notification/Notification';
import Debug from './Debug';
import Popup from '../popup/Popup';
import {
  initializeApp,
  redirectToCVP,
  showOfflineErrorNotification,
} from '../app/actions';
import Styling from './style';
import { DEBUG, NUMBERS } from '../../shared/constants';
import Header from '../header/Header';
import { makeSelectIsMobile, makeSelectIsIOS } from '../browser/selectors';
import { makeSelectIsSettingsLoaded } from '../settings/selectors';
import {
  selectAppDomain,
  makeSelectIsTranslationsLoaded,
  makeSelectIsAppLoading,
  makeSelectIsAutomation,
} from '../app/selectors';
import { selectHeaderDomain } from '../header/selectors';
import { selectNotificationDomain } from '../notification/selectors';
import { selectPopupDomain } from '../popup/selectors';
import { PROFILE_PATHNAME } from '../../shared/pathnames';
import getBrowserTabName from '../../shared/getBrowserTabName';
import { Api } from '@shell-b2c/http-frontend/dist/src';
import { toggleTag } from '../header/actions';
import Offline from '../../shared/components/Offline';
import Loader from '../../shared/components/Loader';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { routes } from '../../router';
import redirections from '../../router/redirections';
import { appPathnamesSetHeader } from './constants';

const muiTheme = createTheme(theme);

export const App = ({
  notification,
  popup,
  app,
  classes,
  isSettingsLoaded,
  isTranslationsLoaded,
  isMobile,
  isAppLoading,
  isAutomation,
}) => {
  const location = useLocation();
  const { pathname } = location;
  const { translations, query } = app;

  const dispatch = useDispatch();
  const [header, setHeader] = useState({
    step: null,
    disabledHeader: false,
    actions: [],
    background: null,
    menu: {
      leftMenu: {
        event: null,
        name: '',
        icon: '',
        mobile: false,
        desktop: false,
        enabled: true,
      },
      rightMenu: {
        event: null,
        name: '',
        icon: '',
        mobile: false,
        desktop: false,
        enabled: true,
      },
    },
  });

  const headerFunctions = {
    setHeader: useCallback(
      (headerParam) => {
        return setHeader({
          ...header,
          actions: [],
          menu: { ...header.menu, ...headerParam.menu },
          background: headerParam.background ?? header.background,
          step: headerParam.step ? headerParam.step : undefined,
          specialSet: headerParam.specialSet ?? false,
          title: headerParam.title?.desktop,
        });
      },
      [] //eslint-disable-line
    ),
    addHeaderAction: useCallback(
      (event, otherParams = {}) => {
        const setLeftIcon = !!otherParams.headerLeftIcon;
        const newHeader = {
          ...header,
          actions: [event, ...header.actions],
        };
        if (setLeftIcon && newHeader?.menu)
          newHeader.menu.leftMenu.icon = otherParams.headerLeftIcon;
        return setHeader({ ...newHeader });
      },
      [] //eslint-disable-line
    ),
    removeHeaderAction: useCallback(
      () => {
        return setHeader({
          ...header,
          actions: header.actions.slice(1),
        });
      },
      [] //eslint-disable-line
    ),
    setHeaderMenuLeft: useCallback(
      (menuIn) => {
        return setHeader({
          ...header,
          menu: {
            ...header.menu,
            leftMenu: {
              ...header.menu.leftMenu,
              ...menuIn,
            },
          },
        });
      },
      [] //eslint-disable-line
    ),
  };
  const l = useLocation();
  useEffect(() => {
    dispatch(initializeApp(l));
  }, [dispatch]); //eslint-disable-line

  const getContainerStyles = () => {
    const customStyles = {};

    if (pathname !== '/') {
      customStyles.WebkitOverflowScrolling = 'touch';
    }

    return customStyles;
  };

  const getHeaderNullish = () => {
    const loadingConditions =
      isAppLoading || !isSettingsLoaded || !isTranslationsLoaded;
    return (
      isMobile &&
      (appPathnamesSetHeader.includes(pathname) || loadingConditions)
    );
  };

  const getHeader = () => {
    if (getHeaderNullish()) {
      return null;
    }
    return (
      <Header
        translate={translate}
        app={app}
        header={header}
        isMobile={isMobile}
        handleRedirectToCVP={() => dispatch(redirectToCVP())}
        handleToggleTag={() => dispatch(toggleTag())}
        handleRemoveAction={() => headerFunctions.removeHeaderAction()}
        location={location}
        headerFunctions={headerFunctions}
      />
    );
  };

  const showKey = app.showTag === 1 && (DEBUG || isAutomation);
  const translate = (prop, clean, values) =>
    getTranslation(
      prop,
      translations,
      showKey,
      clean,
      query.market,
      query.appVersion,
      values
    );

  const allRoutes = routes.map((route) => (
    <Route
      key={route.path}
      path={route.path}
      element={
        <route.component
          {...route}
          translate={translate}
          headerFunctions={headerFunctions}
        />
      }
    />
  ));

  const allRedirections = redirections?.map(([from, to]) => (
    <Route key={`${from}-${to}`} path={from} element={<Navigate to={to} />} />
  ));

  let routesData = (
    <Routes>
      {allRedirections}
      {allRoutes}
    </Routes>
  );
  if (app.showTag === NUMBERS.TWO && DEBUG) {
    routesData = <Debug />;
  }

  document.title = getBrowserTabName(pathname, translate);
  const sizes = gridSizes[pathname] || gridSizes.DEFAULT;

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={muiTheme}>
        <Grid
          container
          style={getContainerStyles()}
          justifyContent="center"
          className={
            pathname === '/' && isMobile
              ? `overview ${classes.containerFluid}`
              : classes.containerFluid
          }
        >
          <CssBaseline />
          {getHeader()}
          <Grid
            xs={12}
            item
            id="main-container"
            style={{
              display: 'flex',
              justifyContent: 'center',
              height: '100%',
              paddingTop: notification.visible
                ? 0
                : isMobile
                ? NUMBERS.SIXTY_FOUR
                : NUMBERS.NINETY_TWO,
            }}
          >
            <Offline
              translate={translate}
              onNoConnectionClick={() =>
                dispatch(showOfflineErrorNotification())
              }
            />
            <Grid
              item
              xs={sizes.xs}
              sm={sizes.sm}
              md={sizes.md}
              lg={sizes.lg}
              id="content-wrapper"
              className={classes.container}
            >
              {notification.visible && <Notification translate={translate} />}
              {popup.visible && <Popup translate={translate} />}
              <Suspense fallback={<SuspenseFallback />}>
                <Loader
                  loaded={
                    isSettingsLoaded &&
                    isTranslationsLoaded &&
                    Api.apiToken !== null &&
                    Api.apiToken !== undefined
                  }
                >
                  {routesData}
                </Loader>
              </Suspense>
            </Grid>
          </Grid>
        </Grid>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

function SuspenseFallback() {
  return <Loader className="main-loader" loaded={false} />;
}

const gridSizes = {
  [PROFILE_PATHNAME]: {
    xs: 12,
    sm: 10,
    md: 10,
    lg: 8,
  },
  DEFAULT: {
    xs: 12,
    sm: 8,
    md: 6,
    lg: 6,
  },
};

App.propTypes = {
  app: PropTypes.object.isRequired,
  notification: PropTypes.object.isRequired,
  popup: PropTypes.object.isRequired,
  isMobile: PropTypes.bool,
  isIOS: PropTypes.bool,
  isSettingsLoaded: PropTypes.bool,
  isTranslationsLoaded: PropTypes.bool,
  isAppLoading: PropTypes.bool,
  isAutomation: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  app: selectAppDomain(),
  notification: selectNotificationDomain(),
  popup: selectPopupDomain(),
  header: selectHeaderDomain(),
  isSettingsLoaded: makeSelectIsSettingsLoaded(),
  isMobile: makeSelectIsMobile(),
  isIOS: makeSelectIsIOS(),
  isTranslationsLoaded: makeSelectIsTranslationsLoaded(),
  isAppLoading: makeSelectIsAppLoading(),
  isAutomation: makeSelectIsAutomation(),
});

export default connect(mapStateToProps)(withStyles(Styling(muiTheme))(App));

//<Redirections />
