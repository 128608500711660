export const SET_LOYALTY_FUTURES_DATA = '[LF] SET_LOYALTY_FUTURES_DATA';
export const SET_LOYALTY_FUTURES_CARD_INPUT_FORM =
  '[LF] SET_LOYALTY_FUTURES_CARD_INPUT_FORM';
export const SET_LOYALTY_FUTURES_CARD_VALIDATION_FORM =
  '[LF] SET_LOYALTY_FUTURES_CARD_VALIDATION_FORM';

export const SET_LOYALTY_FUTURES_CARD_VALIDATION_FORM_PAGE =
  '[LF] SET_LOYALTY_FUTURES_CARD_VALIDATION_FORM_PAGE';

const initialState = {
  selectedCard: null,
  checkedLoyaltyTerms: false,
  cardInputForm: null,
  cardValidationForm: null,
  selectedFormPage: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOYALTY_FUTURES_DATA:
      return {
        ...state,
        selectedCard: action.card,
        checkedLoyaltyTerms: action.checkedLoyaltyTerms,
      };
    case SET_LOYALTY_FUTURES_CARD_INPUT_FORM:
      return { ...state, cardInputForm: action.form };

    case SET_LOYALTY_FUTURES_CARD_VALIDATION_FORM:
      return { ...state, cardValidationForm: action.form };

    case SET_LOYALTY_FUTURES_CARD_VALIDATION_FORM_PAGE:
      return { ...state, selectedFormPage: action.formPage };

    default:
      return state;
  }
}
