import lokaliseTags from '../../shared/lokaliseTags';

export const DEFAULT_HEADER = {
  title: {
    mobile: lokaliseTags.SSO_OVERVIEW_SIGN_IN_LABEL,
    desktop: lokaliseTags.SSO_OVERVIEW_SIGN_IN_LABEL,
  },
  subtitle: {
    desktop: {
      preText: lokaliseTags.SSO_OVERVIEW_OR_LABEL,
      text: lokaliseTags.SSO_OVERVIEW_CREATE_AN_ACCOUNT_LABEL,
      url: 'register',
      afterText: '',
    },
  },
  menu: {
    leftMenu: {
      name: lokaliseTags.SSO_GENERAL_CLOSE,
      icon: 'close',
      mobile: true,
      desktop: false,
    },
  },
};
export const APP_HEADER = {};
export const LOGIN_HEADER = {
  title: {
    mobile: lokaliseTags.SSO_OVERVIEW_SIGN_IN_LABEL,
    desktop: lokaliseTags.SSO_OVERVIEW_SIGN_IN_LABEL,
  },
  subtitle: {
    desktop: {
      preText: lokaliseTags.SSO_OVERVIEW_OR_LABEL,
      text: lokaliseTags.SSO_OVERVIEW_CREATE_AN_ACCOUNT_LABEL,
      url: 'register',
      afterText: '',
    },
  },
  menu: {
    leftMenu: {
      name: lokaliseTags.SSO_GENERAL_BACK,
      icon: 'back',
      mobile: true,
      desktop: false,
    },
  },
};
export const MPP_FORGOT_PASSWORD = {
  title: {
    mobile: lokaliseTags.SSO_OVERVIEW_MPP_FORGOT_PASSWORD,
    desktop: lokaliseTags.SSO_OVERVIEW_MPP_FORGOT_PASSWORD,
  },
  subtitle: {},
  menu: {
    leftMenu: {
      name: lokaliseTags.SSO_GENERAL_CLOSE,
      icon: 'close',
      mobile: true,
      desktop: false,
    },
  },
};
export const CLOSE_HEADER = {
  menu: {
    leftMenu: {
      event: null,
      name: lokaliseTags.SSO_GENERAL_CLOSE,
      icon: 'close',
      mobile: true,
      desktop: false,
    },
  },
};
