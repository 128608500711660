export const PROFILE_LOADED = 'PROFILE_LOADED';
export const RECEIVE_NOTIFICATIONS = 'RECEIVE_NOTIFICATIONS';
export const SET_PROFILE_LOADING_STATE = 'SET_PROFILE_LOADING_STATE';
export const SET_PROFILE_OPTION = 'SET_PROFILE_OPTION';
export const RESET_DATA = 'RESET_DATA';
export const SET_IS_LOYALTY_VERIFIED = 'SET_IS_LOYALTY_VERIFIED';
export const UPDATE_CONSENTS = 'UPDATE_CONSENTS';
export const UPDATE_VEHICLE_REGISTRATION_NUMBER =
  'UPDATE_VEHICLE_REGISTRATION_NUMBER';
export const UPDATE_CUSTOMER_TYPE = 'UPDATE_CUSTOMER_TYPE';
export const SET_PROFILE_LOADED = 'SET_PROFILE_LOADED';
export const ONBOARDING_MGM = 'ONBOARDING_MGM';
