import { FOCUS_ATTRIBUTE } from '../dynamic-form/constants';
import {
  DELETE_ACCOUNT_HEADER,
  TERMS_CONDITIONS_HEADER,
  PRIVACY_POLICY_HEADER,
  DELETE_ACCOUNT_NOW_HEADER,
} from '../../features/settings/headers';
import { DELETE_ACCOUNT_PAGE } from '../../shared/formPages';
import { DELETE_ACCOUNT_APP_PATHNAME } from '../../shared/appPathnames';
import { SET_PROFILE_LOADING_STATE } from '../profile/constants';
import {
  showNotification,
  showDefaultErrorNotification,
} from '../notification/actions';
import {
  makeSelectIsMotoristApp,
  makeSelectQueryRedirect,
  makeSelectCVP,
  makeSelectQuery,
  makeSelectQueryScopes,
} from '../app/selectors';
import {
  login,
  deleteAccount,
} from '@shell-b2c/http-frontend/dist/src/components/core/auth';
import ProfileActions from '../profile/actions';
import { acceptTermsAndConditions } from '../register/actions';
import { deleteStorage } from '../app/utils';
import { redirectToCVP, setSpinner } from '../app/actions';
import selectSettingsDomain, {
  makeSelectTermsAndConditions,
} from './selectors';
import { PREVIOUS_PAGE } from '../forgot-password/constants';
import { setForm, setFormError } from '../dynamic-form/actions';
import browserHistory from '../../router/history';
import { MOTORIST_AMERICA_CVP, FR_CVP, MOTORIST_CVP } from '../../shared/cvps';
import { getTCPP } from '@shell-b2c/http-frontend/dist/src/components/coco';
import { SAVE_TCPP } from './constants';
import { buildNotification } from '../notification/utils';
import ConeAnimation from '../../shared/components/ConeAnimation';
import TickAnimation from '../../shared/components/TickAnimation';
import lokaliseTags from '../../shared/lokaliseTags';

function handleBack() {
  return (dispatch, getState) => {
    const settings = selectSettingsDomain()(getState());
    if (settings.returnPage !== '') {
      dispatch(returnToPage());
    } else if (makeSelectIsMotoristApp()(getState())) {
      dispatch(redirectToCVP());
    }
  };
}

export function initializePrivacyPolicy(setHeader) {
  return (dispatch) => {
    const header = PRIVACY_POLICY_HEADER;
    header.menu.leftMenu.event = () => {
      dispatch(handleBack());
    };
    setHeader(header);
  };
}

export function initializeTermsConditions(setHeader) {
  return (dispatch) => {
    const header = TERMS_CONDITIONS_HEADER;
    header.menu.leftMenu.event = () => {
      dispatch(handleBack());
    };
    setHeader(header);
  };
}

export function setupDeleteAccount(profile, { setHeader }) {
  return (dispatch, getState) => {
    let header;
    const closeEvent = () => {
      dispatch(handleBack());
    };

    if (profile.profile.providers.length === 0) {
      header = DELETE_ACCOUNT_HEADER;
      header.menu.leftMenu.event = closeEvent;
    } else {
      header = DELETE_ACCOUNT_NOW_HEADER;
      header.menu.leftMenu.event = closeEvent;
    }
    setHeader(header);

    const data = {
      form: getState().settings.deleteAccount.form,
      user: getState().settings.deleteAccount.user,
      errors: {},
    };
    const deleteAccountForm = {
      name: DELETE_ACCOUNT_PAGE,
      data,
    };
    dispatch(setForm(deleteAccountForm));
  };
}

export function initializeDeleteAccount(
  event,
  setupDeleteAccountCallback,
  setHeader
) {
  return (dispatch) => {
    dispatch(
      ProfileActions.handleUserProfile(setupDeleteAccountCallback, {
        event,
        setHeader,
      })
    );
    dispatch({
      type: PREVIOUS_PAGE,
      value: 'delete_account',
    });
  };
}

export function returnToPage() {
  return (dispatch, getState) => {
    const page = getState().settings.returnPage;
    if (page === 'toc') {
      browserHistory.push(getState().dynamicForm.page);
      dispatch(acceptTermsAndConditions());
    } else {
      browserHistory.push(page);
    }
  };
}

export function deleteToLogin() {
  return async (dispatch, getState) => {
    const { udid } = makeSelectQuery()(getState());

    dispatch({
      type: FOCUS_ATTRIBUTE,
      name: '',
    });
    dispatch({
      type: SET_PROFILE_LOADING_STATE,
      value: true,
    });

    try {
      const scopes = makeSelectQueryScopes()(getState()).join(' ');
      await login(
        {
          email: getState().profile.profile.emailAddress.toLowerCase(),
          password: getState().dynamicForm.formData.currentPassword,
          udid,
        },
        { scopes }
      );
      dispatch(setSpinner(false));

      dispatch(
        showNotification(
          buildNotification({
            animation: ConeAnimation,
            title: lokaliseTags.SSO_SETTINGS_DATA_LOST_LABEL,
            text: lokaliseTags.SSO_SETTINGS_DATA_LOST_MESSAGE_TEXT,
            closeAction: () => {
              dispatch({
                type: SET_PROFILE_LOADING_STATE,
                value: false,
              });
            },
            buttons: [
              {
                label: lokaliseTags.SSO_SETTINGS_DELETE_ACCOUNT_LABEL,
                event: () => {
                  dispatch(completeDeleteAccount());
                },
              },
            ],
          })
        )
      );
    } catch (error) {
      dispatch({
        type: SET_PROFILE_LOADING_STATE,
        value: false,
      });
      if (error.scopesError()) {
        dispatch(
          showDefaultErrorNotification(
            () => dispatch(redirectToCVP()),
            lokaliseTags.SSO_TECHNICAL_ERROR_SCOPES
          )
        );
        return;
      }
      let errorMessage = 'password_not_valid';
      const errorData = error.getData(0);
      if (errorData.fields.currentPassword) {
        errorMessage = error.data.fields.currentPassword[0];
      } else if (
        errorData.fields.signInForm &&
        errorData.fields.signInForm[0] ===
          lokaliseTags.SSO_FORMS_ERRORS_INCORRECT_USERNAME_OR_PASSWORD_PLEASE_TRY_AGAIN
      ) {
        errorMessage = lokaliseTags.SSO_FORMS_ERRORS_PASSWORD_NOT_VALID;
      }
      dispatch(setFormError({ currentPassword: errorMessage }));
    }
  };
}

export function completeDeleteAccount() {
  return async (dispatch, getState) => {
    try {
      await deleteAccount();
      const isMotoristApp = makeSelectIsMotoristApp()(getState());
      const redirect = makeSelectQueryRedirect()(getState());

      dispatch(
        showNotification(
          buildNotification({
            animation: TickAnimation,
            title: lokaliseTags.SSO_SETTINGS_ACCOUNT_DELETED_LABEL,
            text: lokaliseTags.SSO_SETTINGS_ACCOUNT_DELETED_MESSAGE_TEXT,
            buttons: [
              {
                label: lokaliseTags.SSO_GENERAL_DONE_LABEL,
                event: () => {
                  deleteStorage('profile');
                  deleteStorage('auth');
                  deleteStorage('query');
                  deleteStorage('webview');
                  deleteStorage('service');
                  deleteStorage('redirect');

                  if (isMotoristApp || redirect) {
                    const redirectData = {
                      url: DELETE_ACCOUNT_APP_PATHNAME,
                    };
                    dispatch(redirectToCVP(redirectData));
                  } else {
                    browserHistory.push('/');
                  }
                },
              },
            ],
          })
        )
      );
    } catch (error) {
      dispatch(showDefaultErrorNotification());
    }
  };
}

export function getTermsAndConditionsAndPrivacyPolicy() {
  return async (dispatch, getState) => {
    const cvp = makeSelectCVP()(getState());
    const query = makeSelectQuery()(getState());

    if (makeSelectTermsAndConditions()(getState())) return;

    try {
      const { content } = await getTCPP(
        [MOTORIST_CVP, FR_CVP, MOTORIST_AMERICA_CVP].indexOf(cvp) !== -1
          ? 'sso_motorist'
          : 'sso',
        query.market,
        query.appVersion || '0.0.0'
      );

      if (content?.['privacy_policy'] && content?.['terms_conditions']) {
        dispatch({ type: SAVE_TCPP, content });
      } else {
        throw Error(`No content found in COCO`);
      }
    } catch (error) {
      dispatch(
        showDefaultErrorNotification(
          () => browserHistory.back(),
          lokaliseTags.SSO_GENERIC_COCO
        )
      );
    }
  };
}

export default {
  completeDeleteAccount,
  deleteToLogin,
  initializeDeleteAccount,
  initializePrivacyPolicy,
  initializeTermsConditions,
  setupDeleteAccount,
  getTermsAndConditionsAndPrivacyPolicy,
};
