import {
  HIDE_NAVIGATION_APP_PATHNAME,
  LOD_ATTACHED_APP_PATHNAME,
  LOGIN_READY_APP_PATHNAME,
  LOYALTY_ACTIVATED_APP_PATHNAME,
  PAYMENTS_SETTINGS_APP_PATHNAME,
  SAVE_CREDENTIALS_PATHNAME,
  SDA_MIGRATION_APP_PATHNAME,
  SETUP_DRIVE_APP_PATHNAME,
  SETUP_GOPLUS_PATHNAME,
  SETUP_LOYALTY_APP_PATHNAME,
  SETUP_PAYMENTS_APP_PATHNAME,
  SHOW_NAVIGATION_APP_PATHNAME,
  STATION_LOCATOR_APP_PATHNAME,
  UPDATE_CREDENTIALS_PATHNAME,
  UPDATE_PROFILE_APP_PATHNAME,
  VERIFY_MIGRATION_APP_PATHNAME,
  WIDGET_READY_APP_PATHNAME,
} from '../../shared/appPathnames';

export const CLOSE_POPUP = 'CLOSE_POPUP';
export const SEND_TOKENS = 'SEND_TOKENS';
export const SET_PROFILE = 'SET_PROFILE';
export const SEND_UID = 'SEND_UID';
export const SET_TRANSLATIONS = 'SET_TRANSLATIONS';
export const SAVE_QUERY = 'SAVE_QUERY';
export const SAVE_QUERY_SCOPES = 'SAVE_QUERY_SCOPES';
export const SHOW_SPINNER = 'SHOW_SPINNER';
export const SET_CVP = 'SET_CVP';
export const NO_BACKEND_CONNECTION = 'No backend connection';
export const MISSING_MARKET = 'MISSING_MARKET';
export const NO_HREF = 'NO_HREF';
export const appAuthorizationError = {
  error: [{ 401: 'authorizationFailed' }],
};
export const TEST_MARKET_VERSION = '99.99.99';

export const ERRORS_WITH_OWN_NOTIFICATION = [
  'NO_MARKET',
  'NO_TRANSLATIONS',
  'NO_CONFIG',
];

export const noDeleteSpinnerMarker = '&noDeleteSpinner';

export const appPathNamesDeleteSpinner = [
  UPDATE_PROFILE_APP_PATHNAME,
  SETUP_DRIVE_APP_PATHNAME,
  HIDE_NAVIGATION_APP_PATHNAME,
  WIDGET_READY_APP_PATHNAME,
  SHOW_NAVIGATION_APP_PATHNAME,
  STATION_LOCATOR_APP_PATHNAME,
  VERIFY_MIGRATION_APP_PATHNAME,
  SETUP_LOYALTY_APP_PATHNAME,
  PAYMENTS_SETTINGS_APP_PATHNAME,
  SETUP_PAYMENTS_APP_PATHNAME,
  SDA_MIGRATION_APP_PATHNAME,
  LOYALTY_ACTIVATED_APP_PATHNAME,
  LOD_ATTACHED_APP_PATHNAME,
  SETUP_GOPLUS_PATHNAME,
  LOGIN_READY_APP_PATHNAME,
  SAVE_CREDENTIALS_PATHNAME,
  UPDATE_CREDENTIALS_PATHNAME,
];

export const appPathnamesnoDeleteSpinner = [
  UPDATE_PROFILE_APP_PATHNAME,
  SAVE_CREDENTIALS_PATHNAME,
];
export const appPathnamesSetHeader = ['/'];
