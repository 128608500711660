import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { setScrollToTop } from '../utils';

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    setScrollToTop();
  }, [pathname]);

  return null;
}
