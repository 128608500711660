// This file contains all path names(urls) of the Widget
export const LOGIN_PATHNAME = '/login';
export const REGISTER_PATHNAME = '/register';
export const FORGOT_PASSWORD_PATHNAME = '/forgotpassword';
export const RESET_PASSWORD_PATHNAME = '/resetpassword';
export const PROFILE_PATHNAME = '/profile';
export const EDIT_PROFILE_PATHNAME = '/editprofile';
export const CHANGE_PASSWORD_PATHNAME = '/changepassword';
export const SOL_LOYALTY_CARDS_PATHNAME = '/sol/profile/loyaltyCards';
export const SOL_REPLACE_CARD_PATHNAME = '/sol/profile/replaceCard';
export const SOL_CARD_INPUT_PATHNAME = '/sol/attach/cardInput';
export const SOL_ATTACH_WELCOME_PATHNAME = '/sol/attach/welcome';
export const SOL_ATTACH_DIGITAL_B2B_PATHNAME = '/sol/attach/digital/b2b';
export const SOL_ATTACH_PAYSONSITE_DETAILS_PATHNAME =
  '/sol/attach/payonsite/details';
export const SOL_ATTACH_PAYSONSITE_SUCCESS_PATHNAME =
  '/sol/attach/payonsite/success';
export const SOL_ATTACH_PAYSONSITE_ERROR_PATHNAME =
  '/sol/attach/payonsite/error';
export const SOL_ATTACH_SMARTDEAL_PATHNAME = '/sol/attach/smartdeal';
export const ATTACH_LOYALTY_PATHNAME = '/attachloyalty';
export const NEW_LOYALTY_PATHNAME = '/newloyalty';
export const SOL_MIGRATION_PATHNAME = '/solmigration';
export const SOL_PASSWORD_PATHNAME = '/solpassword';
export const SOL_RESET_PASSWORD_PATHNAME = '/solResetPassword';
export const DELETE_ACCOUNT_PATHNAME = '/deleteaccount';
export const TERMS_PATHNAME = '/terms';
export const PRIVACY_PATHNAME = '/privacy';
export const COMMUNICATIONS_PATHNAME = '/communications';
export const REGISTER_COMMUNICATIONS_PATHNAME = '/comms';
export const REGISTER_TC_AND_PRIVACY_PATHNAME = '/tc';
export const SECURITY_UPDATE_TC_PATHNAME = '/securityUpdateTC';
export const SECURITY_UPDATE_OVERVIEW_PATHNAME = '/securityUpdateOverview';
export const SECURITY_UPDATE_PASSWORD_PATHNAME = '/securityUpdatePassword';
export const SECURITY_UPDATE_CONFIRM_EMAIL_PATHNAME =
  '/securityUpdateConfirmEmail';
export const SECURITY_UPDATE_RESET_PASSWORD = '/resetPassword/securityUpdate';
export const VERIFY_EMAIL_PATHNAME = '/verifyEmail';
export const VERIFY_EMAIL_CHANGE_PATHNAME = '/verifyEmailChange';
export const CONFIRM_EMAIL_CHANGE_PATHNAME = '/confirmationEmailChange';
export const WELCOME_GO_PLUS_PATHNAME = '/welcomeGoPlus';
export const LOYALTY_FUTURES_ATTACH_WELCOME_PATHNAME =
  '/loyaltyFutures/attach/welcome';
export const LOD_PROFILE_PATHNAME = '/lodProfile';
export const LOYALTY_FUTURES_PROFILE_PATHNAME = '/loyaltyFutures/profile';
export const LOYALTY_FUTURES_CARD_INPUT_PATHNAME =
  '/loyaltyFutures/:flowType/cardInput';
export const LOYALTY_FUTURES_CARD_VALIDATION_PATHNAME =
  '/loyaltyFutures/:flowType/cardValidation';
export const SECURITY_UPDATE_PATHNAME =
  '/loyaltyFutures/:flowType/securityUpdate';
export const MIGRATION_ID_PATHNAME = '/loyaltyFutures/migration/id';
export const MIGRATION_PASSWORD_PATHNAME = '/loyaltyFutures/migration/password';
export const LEGAL_CONTENT_PATHNAME = '/legal';
export const LOYALTY_FUTURES_MIGRATION_USER_DATA_VALIDATION_PATHNAME =
  '/loyaltyFutures/migration/userDataValidation';
export const LOYALTY_FUTURES_ATTACH_USER_DATA_VALIDATION_PATHNAME =
  '/loyaltyFutures/attach/attachDataValidation/:cardType';
export const LOYALTY_FUTURES_COMMUNICATION =
  '/loyaltyFutures/migration/communication';
export const LOYALTY_FUTURES_SIGNIN_CARDID = '/loyaltyFutures/signIn/cardInput';
export const SETUP_GOPLUS_COMMS = '/setupGoplusComms';
export const SETUP_PARTNER_COMMS = '/setupPartnerComms';
export const SETUP_WEB_GOPLUS_COMMS = '/setupWebGoplusForm';
export const MIGRATION_PATHNAME = '/migration';
export const LOGOUT_PATHNAME = '/logout';
export const VERIFICATION_CODE_PATHNAME = '/verificationCode';
export const VERIFY_MOBILE_CHANGE_PATHNAME = '/verifyMobileChange';
export const CHANGE_MOBILE_PATHNAME = '/changeMobile';
export const CHANGE_EMAIL_PATHNAME = '/changeEmail';
export const ACCOUNT_ACTIVATION_PATHNAME = '/accountActivation';
export const SEE_YOU_SOON_PATHNAME = '/seeYouSoon';
export const PAYMENTS_PATHNAME = '/payments';
export const SOL_MIGRATION_USER_DATA_VALIDATION_PATHNAME =
  '/sol/migration/userDataValidation';
export const TERMS_CONDITIONS_GO_PLUS = '/tcgoplus';

// Airmiles
export const AIRMILES_REGISTRATION_FORM_PAGE = '/amregistration';
export const AIRMILES_REGISTRATION_SPINNER_PAGE = '/amspinner';
export const AIRMILES_COMMS_PAGE = '/amcomms';
export const AIRMILES_CONFIRMATION_PAGE = '/amconfirmation';
export const AIRMILES_LOGIN_FORM_PAGE = '/amlogin';
export const AIRMILES_LINKING = '/amlinking';

// Legacy pathnames
// Check src/router/redirections.js to know what PATHNAME you need to use

export const LOYALTY_PATHNAME = '/loyalty';
export const LOYALTY_MIGRATION_PATHNAME = '/loyaltymigration';
export const WELCOME_LOYALTY_PATHNAME = '/welcomeLoyalty';
export const SOL_ATTACH_PAYSONSITE_OLD_SUCCESS_PATHNAME =
  '/sol/attach/cardtype/payonsite/success';
export const SOL_ATTACH_PAYSONSITE_OLD_ERROR_PATHNAME =
  '/sol/attach/cardtype/payonsite/error';
export const SAP_HYBRIS_ATTACH_WELCOME_PATHNAME = '/sap-hybris/attach/welcome';
export const SAP_HYBRIS_ATTACH_CARD_INPUT_PATHNAME =
  '/sap-hybris/attach/cardInput';
export const SAP_HYBRIS_PROFILE_PATHNAME = '/sap-hybris/profile';
export const LOYALTY_ATTACH_PATHNAME = '/loyalty/attach';
export const LOYALTY_PROFILE_PATHNAME = '/loyalty/profile';
export const MFA_OTP_PATHNAME = '/mfa/otp';
export const MFA_HELP_PATHNAME = '/mfa/help';
export const TWOFA_PROMPT_PATHNAME = '/twofa/prompt';
export const TWOFA_MOBILE_PATHNAME = '/twofa/mobile';
export const TWOFA_HELP_PATHNAME = '/twofa/help';
export const TWOFA_CHECK_PASSWORD = '/twofa/checkPassword';
export const PROFILE_SECURITY_PATHNAME = '/profileSecurity';
export const ERROR_PAGE = '/errorPage';

export const SCOPES_AUTHORIZATION_SCREEN = '/scopes/authorization';
export const SCOPES_ACCEPTED_SCREEN = '/scopes/accepted';

export const PROGRESSIVE_PROFILING_ONBOARDING = '/pp/onboarding';
export const PROGRESSIVE_PROFILING_DYNAMICFORM = '/pp/dynamicForm';
export const PROGRESSIVE_PROFILING_SUCCESS = '/pp/success';
export const PROGRESSIVE_PROFILING_LOYALTY_REQUIRED = '/pp/loyaltyRequired';

export const PROMPT_CHECK_POPUP_ABOUT_COMMS = '/aboutcomms';
export const PROMPT_CHECK_POPUP_MARKETING = '/marketingcomms';

export const VEHICLE_PREFERENCES = '/vehiclepreferences';
