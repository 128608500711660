import { saveProfile } from '@shell-b2c/http-frontend/dist/src/components/core/user';
import {
  SDA_LOGIN_APP_PATHNAME,
  OPEN_HELP_MFA_APP,
  UPDATE_PROFILE_APP_PATHNAME,
} from '../../shared/appPathnames';
import lokaliseTags from '../../shared/lokaliseTags';
import {
  redirectToCVP,
  sendAdobeAnalyticsEvent,
  setQuery,
  setSpinner,
} from '../app/actions';
import {
  makeSelectAccessToken,
  makeSelectIsMotoristFirebladeCVP,
  makeSelectQuery,
} from '../app/selectors';
import { sendKochavaEvent, setDataToStore } from '../app/utils';
import browserHistory from '../../router/history';
import { handleLoginSuccess } from '../login/actions';
import {
  SET_ERROR,
  SET_OTP_TOKEN,
  SET_OTP_MOBILE,
  SET_IS_SMS,
} from './reducer';
import { selectMfaDomain } from './selectors';
import adobeAnalyticTags from '../../shared/adobeAnalyticTags';
import {
  showDefaultErrorNotification,
  showNotification,
} from '../notification/actions';
import {
  LOGIN_PATHNAME,
  PROFILE_PATHNAME,
  PROFILE_SECURITY_PATHNAME,
  TWOFA_MOBILE_PATHNAME,
} from '../../shared/pathnames';
import { LOGIN_PAGE } from '../../shared/formPages';
import { makeSelectIsMobile } from '../browser/selectors';
import { setLocation } from '../../features/app/actions';
import { makeSelectIsMotoristApp } from '../../features/app/selectors';
import {
  twoFaSend,
  twoFaVerify,
} from '@shell-b2c/http-frontend/dist/src/components/core/twofa';
import { buildNotification } from '../notification/utils';
import TickAnimation from '../../shared/components/TickAnimation';
import { selectTwoFaMobileDomain } from '../twofa/mobile/selectors';
import { selectProfileDomain } from '../profile/selectors';
import { loadUserProfile } from '../profile/actions';
import { logout } from '../logout/actions';
import ConeAnimation from '../../shared/components/ConeAnimation';
import { generateHash } from '../../http/core/utils';
import { makeSelectFormData } from '../dynamic-form/selectors';
import { cloneDeep } from 'lodash';
import { makeSelectEmailAuthentication } from '../settings/selectors';
import kochavaTags from '../../shared/kochavaTags';

export function initializeOtp(setHeader) {
  return async (dispatch, getState) => {
    const { otpToken, isSMS } = selectMfaDomain()(getState());
    const { settingsFlow } = selectTwoFaMobileDomain()(getState());
    const accessToken = makeSelectAccessToken()(getState());
    if (settingsFlow) {
      const { signInMobile } = makeSelectFormData()(getState());
      const analyticsObject = adobeAnalyticTags.twoFaOtpFlow.pageLoad;
      const mobile = signInMobile
        ? cloneDeep(signInMobile).replaceAll(' ', '')
        : undefined;
      analyticsObject.fakeId = generateHash(mobile);
      dispatch(sendAdobeAnalyticsEvent(analyticsObject));
      if (!accessToken) {
        const adobeAnalyticErrorTag = {
          ...adobeAnalyticTags[LOGIN_PAGE].tag,
          errorMessage: '2fa token missing',
        };
        dispatch(sendAdobeAnalyticsEvent(adobeAnalyticErrorTag, 'error'));
        browserHistory.push(LOGIN_PATHNAME);
        return;
      }
    } else {
      const isMailAuthentication = makeSelectEmailAuthentication()(getState());
      const { signInMobile } = makeSelectFormData()(getState());
      const { signInEmailAddress } = makeSelectFormData()(getState());
      const mobile = signInMobile
        ? cloneDeep(signInMobile).replaceAll(' ', '')
        : undefined;
      const analyticsObject = adobeAnalyticTags.otpFlow.pageLoad;
      const fakeId =
        isMailAuthentication || !isSMS ? signInEmailAddress : mobile;
      analyticsObject.fakeId = generateHash(fakeId);
      dispatch(sendAdobeAnalyticsEvent(analyticsObject));
      if (!otpToken) {
        const adobeAnalyticErrorTag = {
          ...adobeAnalyticTags[LOGIN_PAGE].tag,
          errorMessage: 'OTP token missing',
        };
        dispatch(sendAdobeAnalyticsEvent(adobeAnalyticErrorTag, 'error'));
        browserHistory.push(LOGIN_PATHNAME);
        return;
      }
    }

    const header = {
      menu: {
        leftMenu: {
          name: lokaliseTags.SSO_GENERAL_BACK,
          icon: 'back',
          mobile: true,
          desktop: false,
        },
        rightMenu: {
          name: lokaliseTags.SSO_GENERAL_CLOSE,
          icon: 'close',
          mobile: false,
          desktop: true,
          enabled: true,
        },
      },
    };
    const headerEvent = () => {
      const { signInEmailAddress, signInMobile } = makeSelectFormData()(
        getState()
      );
      const analyticsObject = adobeAnalyticTags.otpFlow.clickCloseButton;
      const mobileNumber = signInMobile
        ? cloneDeep(signInMobile).replaceAll(' ', '')
        : undefined;

      analyticsObject.fakeId = generateHash(
        isSMS ? mobileNumber : signInEmailAddress
      );
      dispatch(sendAdobeAnalyticsEvent(analyticsObject));
    };
    header.menu.leftMenu.event = () => {
      headerEvent();
      browserHistory.back();
    };
    header.menu.rightMenu.event = () => {
      headerEvent();
      dispatch(redirectToCVP());
    };
    setHeader(header);
    dispatch(setError(null));

    const twoFASendRequest = settingsFlow
      ? {
          request_type: '2fa_setup',
          access_token: accessToken,
        }
      : {
          otp_token: otpToken,
        };
    try {
      await twoFaSend(twoFASendRequest);
      dispatch(setSpinner(false));
    } catch (error) {
      if (error.serverError()) {
        dispatch(
          showDefaultErrorNotification(() => {
            browserHistory.replace(LOGIN_PATHNAME);
          })
        );
        dispatch(setSpinner(false));
        return;
      }
      dispatch(showDefaultErrorNotification());
      return;
    }
  };
}
export const show2faSuccessScreen = (callbackfn) => {
  return async (dispatch, getState) => {
    const { fromProfile } = selectTwoFaMobileDomain()(getState());
    const isMobile = makeSelectIsMobile()(getState());
    dispatch(
      sendAdobeAnalyticsEvent(
        adobeAnalyticTags.twoFaFlow.successScreen.pageLoad
      )
    );
    dispatch(
      showNotification(
        buildNotification({
          animation: TickAnimation,
          title: lokaliseTags.TWOFA_SUCCESS_TITLE,
          text: lokaliseTags.TWOFA_SUCCESS_BODY,
          buttons: [
            {
              label: lokaliseTags.TWOFA_SUCCESS_DONE_BUTTON,
              event: () => {
                dispatch(
                  sendAdobeAnalyticsEvent(
                    adobeAnalyticTags.twoFaFlow.successScreen.buttonClick,
                    'tileClick'
                  )
                );
                if (fromProfile)
                  return browserHistory.push(
                    isMobile
                      ? PROFILE_SECURITY_PATHNAME
                      : `${PROFILE_PATHNAME}?page=${PROFILE_SECURITY_PATHNAME.slice(
                          1
                        )}`
                  );

                return callbackfn();
              },
              testId: 'success-button',
            },
          ],
        })
      )
    );
  };
};
export function handleOtpSubmit(code, checkboxState, translate, setAutoFocus) {
  return async (dispatch, getState) => {
    const { otpToken, isSMS } = selectMfaDomain()(getState());
    const { settingsFlow } = selectTwoFaMobileDomain()(getState());
    const _accessToken = makeSelectAccessToken()(getState());
    setAutoFocus(false);
    const isFirebladeORMotorist = makeSelectIsMotoristFirebladeCVP()(
      getState()
    );
    const { udid } = makeSelectQuery()(getState());

    const tag = adobeAnalyticTags.otpFlow.clickNextButton;
    tag.mfa_otp_primary_device = isFirebladeORMotorist && checkboxState;
    dispatch(sendAdobeAnalyticsEvent(tag, 'tileClick'));

    try {
      const isMailAuthentication = makeSelectEmailAuthentication()(getState());
      const { signInMobile } = makeSelectFormData()(getState());
      const { signInEmailAddress } = makeSelectFormData()(getState());
      const mobile = signInMobile
        ? cloneDeep(signInMobile).replaceAll(' ', '')
        : undefined;

      const fakeID = generateHash(
        isMailAuthentication || !isSMS ? signInEmailAddress : mobile
      );
      dispatch(sendKochavaEvent(kochavaTags.loginTags.mfaClick, { fakeID }));

      const otpVerifyRequestBody = settingsFlow
        ? { request_type: '2fa_setup', access_token: _accessToken, code }
        : {
            otp_token: otpToken,
            code,
          };
      const otpVerifyResponse = await twoFaVerify(
        otpVerifyRequestBody,
        !settingsFlow
      );
      const { accessToken, refreshToken, ...other } = otpVerifyResponse;

      if (!settingsFlow) {
        const newTokens = {
          accessToken,
          refreshToken,
        };
        dispatch(setQuery(newTokens));
        setDataToStore('auth', JSON.stringify(newTokens), true);
        dispatch(loadUserProfile({ ...other }));

        if (isFirebladeORMotorist && checkboxState) {
          try {
            await saveProfile({
              profile: {
                udid,
                firstName: otpVerifyResponse.profile.firstName,
                lastName: otpVerifyResponse.profile.lastName,
              },
            });
          } catch (error) {
            if (error.serverError()) {
              dispatch(
                showDefaultErrorNotification(() => {
                  browserHistory.replace(LOGIN_PATHNAME);
                })
              );
              return;
            }
            dispatch(showDefaultErrorNotification());
            return;
          }
        }
      }
      if (window?.onbeforeunload) {
        window.onbeforeunload = null;
      }
      if (settingsFlow) {
        const profile = selectProfileDomain()(getState());
        return dispatch(
          show2faSuccessScreen(() =>
            dispatch(
              handleLoginSuccess({
                profile,
                url: UPDATE_PROFILE_APP_PATHNAME + '&noDeleteSpinner',
                translate,
                loginFlow: true,
                mfaFlow: true,
                ignorePromptsCheck: true,
              })
            )
          )
        );
      }
      return dispatch(
        handleLoginSuccess({
          profile: otpVerifyResponse,
          url: SDA_LOGIN_APP_PATHNAME,
          translate,
          loginFlow: true,
          mfaFlow: true,
        })
      );
    } catch (error) {
      if (error.verificationCodeNotValid()) {
        browserHistory.push(LOGIN_PATHNAME);
        return;
      }

      if (error.otpCodeInvalid()) {
        setAutoFocus(true);
        dispatch(
          setError(
            settingsFlow
              ? lokaliseTags.TWOFA_OTP_ERROR_VERIFY
              : lokaliseTags.SSO_MFA_OTP_ERROR_INVALID
          )
        );
        dispatch(
          sendAdobeAnalyticsEvent(
            adobeAnalyticTags.otpFlow.codeExpiredError,
            'error'
          )
        );
        return;
      }

      if (error.otpCodeExpired()) {
        browserHistory.push(`${TWOFA_MOBILE_PATHNAME}?expired=true`);
        return;
      }

      if (error.serverError()) {
        dispatch(
          showDefaultErrorNotification(() => {
            browserHistory.replace(LOGIN_PATHNAME);
          })
        );
      } else {
        dispatch(
          showDefaultErrorNotification(() => {
            dispatch(logout(true, false, { doRedirectToCVP: true }));
          })
        );
      }
      return;
    }
  };
}

export function handleHavingIssues(setShowModal) {
  return (dispatch) => {
    dispatch(
      sendAdobeAnalyticsEvent(
        adobeAnalyticTags.otpFlow.clickHavingIssues,
        'tileClick'
      )
    );
    setShowModal(true);
  };
}

export function setOtpToken(otpToken) {
  return {
    type: SET_OTP_TOKEN,
    otpToken,
  };
}
export function setOtpMobile(mobile) {
  return {
    type: SET_OTP_MOBILE,
    mobile,
  };
}
export function setIsSMS(isSMS) {
  return {
    type: SET_IS_SMS,
    isSMS,
  };
}

export function setError(error) {
  return {
    type: SET_ERROR,
    error,
  };
}

export function handleHelpResendButton() {
  return async (dispatch, getState) => {
    dispatch(
      sendAdobeAnalyticsEvent(
        adobeAnalyticTags.otpHelpFlow.clickResendCode,
        'tileClick'
      )
    );

    const { otpToken } = selectMfaDomain()(getState());
    const otpSendRequest = {
      otp_token: otpToken,
    };
    try {
      await twoFaSend(otpSendRequest, { noSpinner: true });
    } catch (error) {
      if (error.serverError()) {
        dispatch(
          showDefaultErrorNotification(() => {
            browserHistory.replace(LOGIN_PATHNAME);
          })
        );
        return;
      }

      dispatch(showDefaultErrorNotification());
      return;
    }
  };
}
export function handleHelpNotificationError(setHelpError) {
  return async (dispatch) => {
    setHelpError(false);
    dispatch(
      showNotification(
        buildNotification({
          animation: ConeAnimation,
          title: lokaliseTags.TWOFA_OTP_SESSION_EXPIRED_ERROR_TITLE,
          text: lokaliseTags.TWOFA_OTP_SESSION_EXPIRED_ERROR_BODY,
          buttons: [
            {
              label: lokaliseTags.TWOFA_OTP_SESSION_EXPIRED_ERROR_CTA,
              event: () => {
                browserHistory.push(TWOFA_MOBILE_PATHNAME);
              },
            },
          ],
        })
      )
    );
  };
}
export function handleShowHelp(helpURL) {
  return async (dispatch, getState) => {
    dispatch(
      sendAdobeAnalyticsEvent(
        adobeAnalyticTags.otpHelpFlow.clickVisitHelp,
        'tileClick'
      )
    );
    if (
      makeSelectIsMobile()(getState()) &&
      makeSelectIsMotoristApp()(getState())
    ) {
      dispatch(setLocation(OPEN_HELP_MFA_APP));
    } else {
      window.open(helpURL ?? 'https://support.shell.com', '_blank');
    }
  };
}
export function sendClosingAnalytics() {
  return async (dispatch, getState) => {
    const { isSMS } = selectMfaDomain()(getState());
    const { signInMobile, signInEmailAddress } = makeSelectFormData()(
      getState()
    );
    const analyticsObject = adobeAnalyticTags.otpFlow.pageUnLoad;
    const mobile = signInMobile
      ? cloneDeep(signInMobile).replaceAll(' ', '')
      : undefined;
    analyticsObject.fakeId = generateHash(isSMS ? mobile : signInEmailAddress);
    dispatch(sendAdobeAnalyticsEvent(analyticsObject));
  };
}
