import lokaliseTags from '../../../shared/lokaliseTags';
export const CHANGE_VALUE = 'CHANGE_VALUE';
export const CARD_STATION = 'CARD_STATION';

export const CARD_TYPE_V_POWER = 'B2C_VPC';
export const CARD_TYPE_B2C = 'B2C';
export const CARD_TYPE_B2B = 'B2B';
export const CARD_TYPE_ADAC = 'ADAC';

export const SOL_CARD_TYPE_LABELS = {
  [CARD_TYPE_V_POWER]: lokaliseTags.SSO_MULTICARD_TYPE_VPOWER,
  [CARD_TYPE_B2C]: lokaliseTags.SSO_MULTICARD_TYPE_B2C,
  [CARD_TYPE_B2B]: lokaliseTags.SSO_MULTICARD_TYPE_B2B,
  [CARD_TYPE_ADAC]: lokaliseTags.SSO_MULTICARD_TYPE_ADAC,
};

export const SOL_CARD_TYPE_SORT_ORDER = {
  [CARD_TYPE_V_POWER]: 1,
  [CARD_TYPE_B2C]: 2,
  [CARD_TYPE_B2B]: 3,
  [CARD_TYPE_ADAC]: 4,
};
