import lokaliseTags from '../../../shared/lokaliseTags';

export const DEFAULT_HEADER = {
  menu: {
    leftMenu: {
      name: lokaliseTags.SSO_GENERAL_CLOSE,
      icon: 'back',
      mobile: true,
      desktop: false,
    },
  },
};
