// This file contains all process env variables needed to setup Widget
// WHY here?: Keep here because if we update some variable name we only have to update it here
export const ENV = process.env.REACT_APP_ENV;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const SDA_ENVIRONMENT = process.env.REACT_APP_SDA_ENVIRONMENT;
export const DEBUG = process.env.REACT_APP_DEBUG === 'true' || false;
export const KOCHAVA_TRACE_LEVEL =
  process.env.REACT_APP_KOCHAVA_TRACE_LEVEL === 'true' || false;
export const CAPTCHA_KEY_V3 = process.env.REACT_APP_CAPTCHA_KEY_V3;
export const AUTOMATION_CAPTCHA_KEY_V3 =
  process.env.REACT_APP_AUTOMATION_CAPTCHA_KEY_V3;
export const DEFAULT_COCO_VERSION = process.env.REACT_APP_DEFAULT_COCO_VERSION;
export const ADOBE_SCRIPT_URL = process.env.REACT_APP_ADOBE_SCRIPT_URL;
export const ADOBE_SCRIPT_URL_NO_COOKIE_CONSENT =
  process.env.REACT_APP_ADOBE_SCRIPT_URL_NO_COOKIE_CONSENT;
export const APIGEE_API_KEY = process.env.REACT_APP_APIGEE_API_KEY;
export const APIGEE_COCO_BASE_URL = process.env.REACT_APP_APIGEE_COCO_BASE_URL;
export const APP_INSIGHTS_INSTRUMENTATION_KEY =
  process.env.REACT_APP_APP_INSIGHTS_INSTRUMENTATION_KEY;
export const KOCHAVA_GUID = process.env.REACT_APP_KOCHAVA_GUID;
export const SML_APIKEY = process.env.REACT_APP_SML_APIKEY;
export const LMN_RESET_PASSWORD_LINK =
  process.env.REACT_APP_LMN_RESET_PASSWORD_LINK;

export const NUMBERS = {
  ZERO: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
  SIX: 6,
  EIGHT: 8,
  ELEVEN: 11,
  TWELVE: 12,
  THIRTEEN: 13,
  SIXTEEN: 16,
  TWENTY: 20,
  TWENTYEIGHT: 28,
  THIRTY: 30,
  THIRTY_TWO: 32,
  SIXTY_FOUR: 64,
  SIXTY_SEVEN: 67,
  SEVENTY_FIVE: 75,
  NINETY_TWO: 92,
  HUNDRED: 100,
  ONE_HUNDRED_EIGHTY_NINE: 189,
  ONE_HUNDRED_NINETY: 190,
  TWO_HUNDRED: 200,
  TWO_HUNDRED_THIRTY: 230,
  TWO_HUNDRED_TWENTY_NINE: 229,
  THREE_HUNDRED: 300,
  THREE_HUNDRED_THIRTY_THREE: 333,
  THREE_HUNDRED_SIXTY_ONE: 361,
  FOUR_HUNDRED: 400,
  FOUR_HUNDRED_ONE: 401,
  FOUR_HUNDRED_FOUR: 404,
  SEVEN_HUNDRED_SIXTY_SEVEN: 767,
  SEVEN_HUNDRED_SIXTY_EIGHT: 768,
  ONE_THOUSAND: 1000,
  NINETEEN_SEVENTY: 1970,
  SIX_THOUSAND: 6000,
  FIFTEEN_THOUSAND: 15000,
  EIGHT_THOUSAND: 8000,
};
export const HEXNUMBERS = {
  FIFTEEN: 0x0f,
  SIXTYTHREE: 0x3f,
  THIRTYONE: 0x1f,
};
export const NEGATIVE_NUMBERS = {
  THIRTY_TWO: -32,
  THIRTY_THREE: -33,
  TWENTYFOUR_FIFTY: -2450,
};
