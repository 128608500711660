import {
  ApplicationInsights,
  DistributedTracingModes,
} from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import history from '../router/history';
import { APP_INSIGHTS_INSTRUMENTATION_KEY, ENV } from './constants';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: APP_INSIGHTS_INSTRUMENTATION_KEY,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history },
    },
    disableFetchTracking: false,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    enableCorsCorrelation: true,
    distributedTracingMode: DistributedTracingModes.AI_AND_W3C,
  },
});

try {
  if (ENV !== 'DEV') {
    appInsights.loadAppInsights();
  }
} catch (err) {
  console.error('AppInsights Initialization Error: ', err.message);
}

export { reactPlugin, appInsights };
