export const SET_SAP_HYBRIS_CARD_INPUT_FORM =
  '[SH] SET_SAP_HYBRIS_CARD_INPUT_FORM';

const initialState = {
  cardInputForm: null,
};

export default function reducer(state = initialState, action) {
  if (action.type === SET_SAP_HYBRIS_CARD_INPUT_FORM) {
    return { ...state, cardInputForm: action.form };
  }

  return state;
}
