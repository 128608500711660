import {
  RED,
  MID_GREY,
  WHITE,
  YELLOW,
  PALE_GREY,
  VERY_DARK_GREY,
} from '../../shared/colors';
import { points12, points14 } from '../../shared/fontSizes';

export default {
  fade: {
    backgroundColor: 'rgba(64,64,64,0.5)',
    position: 'fixed',
    top: '0',
    left: '0',
    height: '100%',
    width: '100%',
    zIndex: '10000000',
  },
  centered: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  wrapper: {
    backgroundColor: WHITE,
    boxShadow: '2px 2px 10px 2px rgba(0,0,0,0.2)',
    borderRadius: 4,
    overflow: 'hidden',
  },
  popupSize: {
    width: '100%',
    height: '100%',
  },
  contentWrapper: {
    height: '100%',
    width: '100%',
  },
  contentStyle: {
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: '16px',
  },
  titleText: {
    fontWeight: 'bold',
    color: RED,
    fontSize: points14,
    lineHeight: '18px',
  },
  noteText: {
    color: MID_GREY,
    fontSize: points12,
    lineHeight: '12px',
    whiteSpace: 'pre-line',
  },
  messageText: {
    whiteSpace: 'pre-line',
    color: VERY_DARK_GREY,
  },
  popupImage: {},
  headerWrapper: {
    zIndex: '10000000',
  },
  headerStyle: {
    fontWeight: 'bold',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  buttonPrimary: {
    margin: 0,
    minWidth: 0,
    borderTop: `1px solid ${YELLOW}`,
  },
  buttonDefault: {
    margin: 0,
    minWidth: 0,
    borderTop: `1px solid ${PALE_GREY}`,
  },
  inlineButtons: {
    flexWrap: 'nowrap',
    padding: '0 12px',
  },
  inlineButtonWrapper: {
    margin: '0 4px',
  },
};
