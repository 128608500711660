import { SET_PROGRESSIVE_PROFILING } from './reducer';

export const selectProgressiveProfilingDomain = () => (state) =>
  state.progressiveProfiling;
export const setPPFlow = (flow) => ({
  type: SET_PROGRESSIVE_PROFILING,
  ppFlow: flow,
});

export const makeSelectIsPPFlow = () => (state) =>
  state.progressiveProfiling.ppFlow;
