
import React from 'react'
import { RotatingLines } from 'react-loader-spinner'
import { DARK_GREY } from '../../shared/colors';
import { LoaderOptions } from '../loader';

const Loader = ({ loaded = true, children }) => {
  return !loaded ? <div style={LoaderOptions}>
    <RotatingLines
      strokeColor={DARK_GREY}
      width="30"
    />
  </div> : <>{children}</>
}

export default Loader;