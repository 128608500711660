import lokaliseTags from '../../shared/lokaliseTags';

export const SEE_YOU_SOON_HEADER = {
  menu: {
    leftMenu: {
      name: lokaliseTags.SSO_GENERAL_CLOSE,
      icon: 'close',
      mobile: true,
      desktop: false,
    },
    rightMenu: {
      name: lokaliseTags.SSO_GENERAL_CLOSE,
      icon: 'close',
      mobile: false,
      desktop: true,
    },
  },
};
