/**
 * This function get formData and query params to create a registration object to be sent to register endpoint
 * @param {Object} formData registration form data from dynamic form
 * @param {Object} query query params
 */
export function getRegistrationData(
  {
    newPassword,
    loyaltyOptIn,
    driveOptIn,
    goPlusMarketingOptIn,
    newsletters,
    mobileGoPlusMarketingOptIn,
    mobileLoyaltyOptIn,
    mobileNewsletters,
    emailAddress,
    mobile,
    ...others
  },
  { udid, guid, cvpUniqueIdentifier },
  profileFormatter
) {
  const registrationData = {
    newPassword,
    consents: {
      loyaltyOptIn: loyaltyOptIn || false,
      driveOptIn: driveOptIn || false,
      newsletters: newsletters || false,
      goPlusMarketingOptIn: goPlusMarketingOptIn || false,
      vocOptIn: goPlusMarketingOptIn || false,
      mobileLoyaltyOptIn: mobileLoyaltyOptIn || false,
      mobileNewsletters: mobileNewsletters || false,
      mobileGoPlusMarketingOptIn: mobileGoPlusMarketingOptIn || false,
    },
    profile: {
      generalUserId: guid,
      udid,
      ...others,
      ...profileFormatter?.(),
    },
  };

  if (emailAddress) {
    registrationData.profile.emailAddress = emailAddress.toLowerCase();
  } else if (mobile) {
    registrationData.profile.mobile = mobile.replace(/\s/g, '');
  }

  if (cvpUniqueIdentifier) {
    registrationData.cvpUniqueIdentifier = {
      identifier: cvpUniqueIdentifier,
    };
  }

  return registrationData;
}
