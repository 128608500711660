import { createSelector } from 'reselect';

/**
 * Direct selector to the app state domain
 */
const selectNotificationDomain = () => (state) => state.notification;

const makeSelectIsNotificationVisible = () => createSelector(
  selectNotificationDomain(),
  (notification) => notification.visible,
);

export {
  selectNotificationDomain,
  makeSelectIsNotificationVisible,
};
