import { createSelector } from 'reselect';

export const selectMigrationDomain = () => (state) => {
  return state.loyaltyFuturesMigration;
};

export const makeSelectMigrationData = () =>
  createSelector(selectMigrationDomain(), (migration) => migration.data);

export const makeSelectMigrationCard = () =>
  createSelector(
    makeSelectMigrationData(),
    (migrationData) => migrationData?.loyalty?.accounts[0].card[0].cardId
  );
