import { DEFAULT_ERROR_NOTIFICATION } from '../../features/app/notifications';
import {
  NOTIFICATION_CHANGE_ATTRIBUTE,
  VISIBLE,
  SHOW_NOTIFICATION,
  FORCE,
  SET_NOTIFICATION_SNACKBAR_ERROR,
} from './constants';
import { setScrollToTop } from '../../shared/utils';
import { NUMBERS } from '../../shared/constants';

export function buttonClick(keepOpen, event, redirect) {
  return (dispatch) => {
    setTimeout(() => {
      if (keepOpen !== true) {
        dispatch(setNotificationVisible(false));
      }
      if (event) {
        event();
      } else if (redirect) {
        dispatch(redirect());
      }
    }, NUMBERS.HUNDRED);
  };
}

export const setSnackbarError = (value) => {
  return { type: SET_NOTIFICATION_SNACKBAR_ERROR, value };
};

export function setNotificationVisible(isVisible = false) {
  return {
    type: NOTIFICATION_CHANGE_ATTRIBUTE,
    name: VISIBLE,
    value: isVisible,
  };
}

export function setForceNotification(isForced = false) {
  return {
    type: NOTIFICATION_CHANGE_ATTRIBUTE,
    name: FORCE,
    value: isForced,
  };
}

export function showDefaultErrorNotification(
  okButton,
  message,
  headerIconEvent
) {
  return (dispatch) => {
    dispatch(setNotificationVisible(false));
    setScrollToTop();

    const notification = DEFAULT_ERROR_NOTIFICATION;

    if (headerIconEvent) notification.header.icon.event = headerIconEvent;
    if (okButton) {
      notification.buttons[0].event = okButton;
    }

    if (message) {
      notification.message.text = message;
    }

    dispatch({
      type: SHOW_NOTIFICATION,
      value: notification,
    });
  };
}

export function showNotification(notification) {
  setScrollToTop();
  return {
    type: SHOW_NOTIFICATION,
    value: notification,
  };
}

export default {
  buttonClick,
};
