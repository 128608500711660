import { SET_REGISTER_FORM } from './constants';
import { DEFAULT_HEADER, WIZARD_HEADER } from '../../features/register/headers';
import {
  makeSelectQuery,
  makeSelectQueryCountry,
  makeSelectQueryRedirect,
  makeSelectQueryPartner,
  makeSelectQueryMgm,
  makeSelectIsMotoristApp,
  makeSelectIsMotoristFirebladeCVP,
  makeSelectQueryScopes,
} from '../app/selectors';
import {
  LOGIN_PATHNAME,
  VERIFY_EMAIL_PATHNAME,
  SECURITY_UPDATE_TC_PATHNAME,
  VERIFICATION_CODE_PATHNAME,
  SCOPES_AUTHORIZATION_SCREEN,
  REGISTER_TC_AND_PRIVACY_PATHNAME,
  REGISTER_PATHNAME,
  PROMPT_CHECK_POPUP_ABOUT_COMMS,
  VEHICLE_PREFERENCES,
} from '../../shared/pathnames';
import { REGISTRATION_READY_PATHNAME } from '../../shared/appPathnames';
import {
  makeSelectFormData,
  makeSelectFormStep,
  makeSelectFormPageName,
  makeSelectFormDataBackendFormated,
  makeSelectIsLastFormStep,
  makeSelectTotalFormPages,
  makeSelectFormForm,
  makeSelectFormPage,
} from '../dynamic-form/selectors';
import {
  getAnalyticsStepEventName,
  getCaptchaToken,
  setScrollToTop,
  transformPartnersDataFromCocoToExternalSystemId,
} from '../../shared/utils';
import { setEmailToVerify } from '../verify-email/actions';
import verifyEmailChangeActions from '../verify-email-change/actions';
import { REGISTER_PAGE, MIGRATION_PAGE } from '../../shared/formPages';
import { getRegistrationData } from './utils';
import {
  validateForm,
  previousStep,
  resetForm,
  setForm,
  sendAdobeAnalyticsClickFormEvent,
  setFormData,
  sendAdobeAnalyticsVPVFormEvent,
  setFormStep,
  nextStep,
} from '../dynamic-form/actions';
import { registerUser } from '@shell-b2c/http-frontend/dist/src/components/core/user';
import {
  getRegistrationForm,
  getRegistrationMobileForm,
} from '@shell-b2c/http-frontend/dist/src/components/core/forms';
import { makeSelectIsMobile } from '../browser/selectors';
import {
  redirectToCVP,
  setLocation,
  sendAdobeAnalyticsEvent,
  setSpinner,
  saveCredentials,
} from '../app/actions';
import {
  showDefaultErrorNotification,
  showNotification,
} from '../notification/actions';
import { REGISTER_WARNING } from '../notification/states-constants';
import { loadUserProfile } from '../profile/actions';
import browserHistory from '../../router/history';
import { HTTP_STATUS } from '@shell-b2c/http-frontend/dist/src/components/HttpError';
import { transitionToCommunications } from '../tc-and-privacy/actions';
import adobeAnalyticTags from '../../shared/adobeAnalyticTags';
import { selectRegisterDomain } from './selectors';
import { makeSelectIsPhoneNumberFormActive } from '../auth/selectors';
import { setAuthType } from '../auth/actions';
import selectSettingsDomain, {
  makeSelectDefaultAuthentication,
  makeSelectPhoneAuthentication,
  makeSelectSettingsPartnersByClientId,
  makeSelectSettingsShowVehiclePreferenceScreen,
} from '../settings/selectors';
import { buildNotification } from '../notification/utils';
import ConeAnimation from '../../shared/components/ConeAnimation';
import lokaliseTags from '../../shared/lokaliseTags';
import { setScopesErrors } from '../scopes/auth/actions';
import { createUser } from '../register-add-communications/utils';
import {
  additionalStepsArray,
  marketNoCommsPage,
} from '../../shared/registerFlowConstants';
import { generateHash } from '../../http/core/utils';
import { sendKochavaEvent } from '../app/utils';
import { getFeatureFlagConfig } from '@shell-b2c/http-frontend/dist/src/components/core/config';
import { SETTINGS_LOADED } from '../settings/constants';

export function getErrorPageByNoPartnerData() {
  return (dispatch, getState) => {
    const state = getState();
    const partner = makeSelectQueryPartner()(state);
    if (partner) {
      const partnersData = makeSelectSettingsPartnersByClientId()(state);

      if (!partnersData)
        dispatch(
          showDefaultErrorNotification(
            () => dispatch(redirectToCVP()),
            lokaliseTags.SSO_TECHNICAL_ERROR_ARCHETYPE
          )
        );
    }
  };
}

export function initializeRegistration(setHeader) {
  return async (dispatch, getState) => {
    const state = getState();
    const form = state.register;

    if (makeSelectIsMotoristApp()(state)) {
      dispatch(setLocation(REGISTRATION_READY_PATHNAME));
    }
    const registerForm = {
      name: REGISTER_PAGE,
      data: form,
    };
    try {
      dispatch(getErrorPageByNoPartnerData());

      dispatch(setForm(registerForm));
      dispatch(setFormData({}));
      const market = makeSelectQuery()(state).market;

      if (market) {
        const country = makeSelectQueryCountry()(state);

        if (country) {
          dispatch(
            setBackButtonBehaviorForRegistration(
              DEFAULT_HEADER,
              false,
              setHeader
            )
          );
        } else {
          const redirectErrorData = {
            error: [{ 402: lokaliseTags.SSO_GENERAL_WRONG_MARKET_FORMAT }],
          };
          dispatch(redirectToCVP(redirectErrorData));
        }
      } else {
        const redirectErrorData = {
          error: [{ 401: lokaliseTags.SSO_GENERAL_MISSING_MARKET_FORMAT }],
        };
        dispatch(redirectToCVP(redirectErrorData));
      }
      const defaultAuthMethod = makeSelectDefaultAuthentication()(getState());
      dispatch(setAuthType(defaultAuthMethod === 'phone'));

      const country = makeSelectQueryCountry()(getState());
      const featureFlagSettings = await getFeatureFlagConfig();
      const { [country]: countryConfig = {} } = featureFlagSettings;
      dispatch({
        type: SETTINGS_LOADED,
        response: { featureFlagSettings: countryConfig },
      });

      const isMobile = makeSelectIsMobile()(getState());
      if (isMobile) {
        dispatch(sendAdobeAnalyticsVPVFormEvent(0, REGISTER_PAGE));
      } else {
        const tag = adobeAnalyticTags[REGISTER_PAGE].tag;
        tag.signInType = 'Standard';
        dispatch(sendAdobeAnalyticsEvent(tag));
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
}

export function setBackButtonBehaviorForRegistration(
  theHeader,
  checkStep,
  setHeader
) {
  return (dispatch, getState) => {
    const header = { ...theHeader };
    const step = makeSelectFormStep()(getState());
    const isMotoristFirebladeCVP = makeSelectIsMotoristFirebladeCVP()(
      getState()
    );
    header.menu.leftMenu.event = () => {
      if (checkStep && step) {
        dispatch(previousStep());
      } else if (isMotoristFirebladeCVP) {
        dispatch(redirectToCVP());
      } else {
        browserHistory.push('/');
      }
    };
    setHeader(header);
  };
}

export function getRegisterForm(setHeader) {
  return async (dispatch, getState) => {
    const registerDomain = selectRegisterDomain()(getState());
    const isPhoneNumberActive = makeSelectIsPhoneNumberFormActive()(getState());
    const formStep = makeSelectFormStep()(getState());

    if (
      (isPhoneNumberActive &&
        registerDomain.form.description === 'registrationMobileForm') ||
      (!isPhoneNumberActive &&
        registerDomain.form.description === 'registrationForm')
    ) {
      dispatch(registrationSetup(getState().register.form));
      const formPage = makeSelectFormPageName()(getState());

      dispatch(
        setBackButtonBehaviorForRegistration(
          DEFAULT_HEADER,
          true,
          formPage !== REGISTER_PAGE ? () => {} : setHeader
        )
      );
    } else {
      try {
        let getRegistrationFormRequest;
        if (isPhoneNumberActive) {
          getRegistrationFormRequest = getRegistrationMobileForm;
        } else {
          getRegistrationFormRequest = getRegistrationForm;
        }

        const responseForm = await getRegistrationFormRequest();
        const data = {
          ...responseForm,
          loaded: true,
        };
        dispatch({
          type: SET_REGISTER_FORM,
          data,
        });

        const header = WIZARD_HEADER;
        header.subtitle.mobile.preText.values = [1, data.order.mobile.length];
        dispatch(registrationSetup(data));
        const formPage = makeSelectFormPageName()(getState());

        dispatch(
          setBackButtonBehaviorForRegistration(
            WIZARD_HEADER,
            true,
            formPage !== REGISTER_PAGE ? () => {} : setHeader
          )
        );
        dispatch(setFormStep(formStep));
      } catch (error) {
        dispatch(showDefaultErrorNotification());
      }
    }
  };
}

export function handleRegistrationSubmit(setHeaderMenuLeft) {
  return async (dispatch, getState) => {
    const isLastFormStep = makeSelectIsLastFormStep(REGISTER_PAGE)(getState());
    const isMobile = makeSelectIsMobile()(getState());

    const pageFormNumber = makeSelectFormStep()(getState());
    const formPage = makeSelectFormPageName()(getState());
    const totalFormPages = makeSelectTotalFormPages(formPage)(getState());
    const form = makeSelectFormPage(REGISTER_PAGE)(getState());
    const kochavaAnalyticsData = setupKochavaAnalyticParams(
      form,
      totalFormPages,
      pageFormNumber,
      isMobile
    );

    if (isLastFormStep) {
      dispatch(startRegisterWithSSO(kochavaAnalyticsData));
      if (!isMobile) {
        setScrollToTop();
      }
      return;
    }

    dispatch(
      nextStep(null, null, setHeaderMenuLeft, {
        passedKochavaAnalytics: kochavaAnalyticsData,
      })
    );
  };
}

/**
 * Function that gathers all needed information to create a Kochava analytics event
 * For the registration form it's needed:
 * - an event name
 * - a fake ID based on a hashed version of the email / phone
 *
 * This function supports multiple event name handling
 * It also ensures every event name is unique to avoid duplicated events
 *
 * @param form
 * @param totalFormPages
 * @param pageFormNumber
 * @param isMobile
 * @returns {{fakeId, eventNames: string[]} | null}
 */
function setupKochavaAnalyticParams(
  form,
  totalFormPages,
  pageFormNumber,
  isMobile
) {
  const currentStep =
    form.order[isMobile ? 'mobile' : 'desktop'][pageFormNumber].flat();
  const analyticsKeys =
    typeof currentStep === 'string' ? [currentStep] : currentStep;
  const eventNames = analyticsKeys.map((key) => getAnalyticsStepEventName(key));
  let fakeId;

  if (!eventNames.length || totalFormPages < 0) {
    return null;
  }

  if (form.values.emailAddress || form.values.mobile) {
    fakeId = generateHash(
      form.values.emailAddress || form.values.mobile.replaceAll(' ', '')
    );
  }

  return { eventNames: [...new Set(eventNames)], fakeId };
}

export function startRegisterWithSSO(kochavaAnalyticsData) {
  return async (dispatch, getState) => {
    const isMobile = makeSelectIsMobile()(getState());
    const showVehiclePreferenceScreen =
      makeSelectSettingsShowVehiclePreferenceScreen()(getState());
    try {
      if (kochavaAnalyticsData) {
        for (const eventName of kochavaAnalyticsData.eventNames) {
          if (eventName) {
            dispatch(
              sendKochavaEvent(eventName, {
                fakeId: kochavaAnalyticsData.fakeId,
              })
            );
          }
        }
      }

      await dispatch(validateForm(REGISTER_PAGE));
      const pageFormNumber = makeSelectFormStep()(getState());
      const formPage = makeSelectFormPageName()(getState());
      const totalFormPages = makeSelectTotalFormPages(formPage)(getState());
      if (pageFormNumber === 1 && totalFormPages > 0) {
        dispatch(
          sendAdobeAnalyticsEvent(adobeAnalyticTags.register.addressTags)
        );
      } else if (isMobile) {
        dispatch(sendAdobeAnalyticsClickFormEvent(REGISTER_PAGE));
      } else {
        dispatch(
          sendAdobeAnalyticsEvent(
            adobeAnalyticTags[REGISTER_PAGE].ctaTag,
            'tileClick'
          )
        );
      }
      if (showVehiclePreferenceScreen) browserHistory.push(VEHICLE_PREFERENCES);
      else dispatch(acceptTermsAndConditions());
      return true;
    } catch (error) {
      return false;
    }
  };
}

export function acceptTermsAndConditions() {
  return async (dispatch, getState) => {
    const formPage = makeSelectFormPageName()(getState());
    const country = makeSelectQueryCountry()(getState());

    const settings = selectSettingsDomain()(getState());
    let countryConfig = settings?.featureFlagSettings;

    if (!countryConfig) {
      const country = makeSelectQueryCountry()(getState());
      const featureFlagSettings = await getFeatureFlagConfig();
      countryConfig = featureFlagSettings?.[country];
      dispatch({
        type: SETTINGS_LOADED,
        response: { featureFlagSettings: countryConfig },
      });
    }

    if (formPage === REGISTER_PAGE) {
      const isMotoristFirebladeCVP = makeSelectIsMotoristFirebladeCVP()(
        getState()
      );

      if (countryConfig?.show_marketing_comms_screen) {
        browserHistory.push(PROMPT_CHECK_POPUP_ABOUT_COMMS);
      } else if (isMotoristFirebladeCVP && country === 'CH') {
        dispatch(createUser());
      } else {
        dispatch(transitionToCommunications());
      }
    } else if (formPage === MIGRATION_PAGE) {
      browserHistory.push(SECURITY_UPDATE_TC_PATHNAME);
    } else {
      browserHistory.replace('/');
    }
  };
}

export function createUserInSSO(consents = {}) {
  return async (dispatch, getState) => {
    const state = getState();
    const query = makeSelectQuery()(state);
    const redirect = makeSelectQueryRedirect()(state);
    const mgm = makeSelectQueryMgm()(state);
    const partner = makeSelectQueryPartner()(state);
    const formData = makeSelectFormData()(state);
    const isPhoneNumberActive = makeSelectIsPhoneNumberFormActive()(getState());

    const registrationData = getRegistrationData(formData, query, () =>
      makeSelectFormDataBackendFormated(REGISTER_PAGE, true)(getState())
    );
    dispatch(setSpinner(true));
    const recaptchaToken = await getCaptchaToken('register');

    let externalSystemIds = null;

    if (mgm)
      externalSystemIds = {
        identifier: query.mgm,
        name: 'ONBOARDING_MGM',
      };
    if (partner)
      externalSystemIds = transformPartnersDataFromCocoToExternalSystemId(
        makeSelectSettingsPartnersByClientId()(getState())
      );

    try {
      const data = {
        ...registrationData,
        consents: { ...registrationData.consents, ...consents.consents },
        recaptchaToken,
      };
      if (redirect) data.redirect = redirect;
      if (externalSystemIds) data.externalSystemIds = externalSystemIds;

      const scopes = makeSelectQueryScopes()(getState()).join(' ');
      const response = await registerUser({
        user: data,
        withMobile: isPhoneNumberActive,
        scopes,
      });

      dispatch(loadUserProfile({ uuid: response.uuid }));

      dispatch(
        saveCredentials({
          username: formData.emailAddress || formData.mobile,
          password: formData.newPassword,
        })
      );
      const fakeId = generateHash(
        isPhoneNumberActive
          ? registrationData?.profile?.mobile?.replaceAll(' ', '')
          : registrationData?.profile?.emailAddress
      );
      dispatch(
        sendAdobeAnalyticsEvent({
          ...adobeAnalyticTags[REGISTER_PAGE].VerifyEmail,
          fakeId,
        })
      );

      dispatch(verifyEmailChangeActions.setUUID(response.uuid));

      if (isPhoneNumberActive) {
        browserHistory.push(VERIFICATION_CODE_PATHNAME, {
          mobile: formData.mobile,
        });
      } else {
        dispatch(setEmailToVerify(formData.emailAddress));
        browserHistory.push(VERIFY_EMAIL_PATHNAME);
        dispatch(resetForm());
      }
    } catch (error) {
      if (error.deviceNotAllowed()) {
        dispatch(
          showNotification(
            buildNotification({
              animation: ConeAnimation,
              title: lokaliseTags.SSO_REGISTER_DEVICE_CANNOT_REGISTER,
              text: lokaliseTags.SSO_REGISTER_DEVICE_CANNOT_REGISTER_TEXT,
              buttons: [
                {
                  label: lokaliseTags.SSO_GENERAL_TRY_AGAIN,
                },
              ],
            })
          )
        );
        browserHistory.push(LOGIN_PATHNAME);
      } else if (error.scopesError()) {
        dispatch(
          showDefaultErrorNotification(
            () => dispatch(redirectToCVP()),
            lokaliseTags.SSO_TECHNICAL_ERROR_SCOPES
          )
        );
        return;
      } else if (error.scopesRequired()) {
        const missingScopes = error.errors.map((err) => {
          const detail = err.detail?.split(':');
          return {
            ...err,
            action: detail[0],
            name: detail[1],
          };
        });
        const scopesErrors = {
          missingScopes,
          otpToken: error?.data.otp_token || null,
        };

        dispatch(setScopesErrors(scopesErrors));
        browserHistory.push(SCOPES_AUTHORIZATION_SCREEN);
      } else {
        const notification = REGISTER_WARNING;
        let warning;
        if (error.emailOrMobileAlreadyExist()) {
          warning =
            lokaliseTags.SSO_FORMS_ERRORS_EMAIL_ADDRESS_IS_ALREADY_IN_USE;
        } else if (error.status === HTTP_STATUS.BAD_REQUEST) {
          warning = error.getDetail(0);
        }
        notification.message.text = warning;
        dispatch(showNotification(notification));
      }
    }
  };
}

export function onBack() {
  return (dispatch, getState) => {
    const isMotoristFirebladeCVP = makeSelectIsMotoristFirebladeCVP()(
      getState()
    );
    if (isMotoristFirebladeCVP) {
      dispatch(redirectToCVP());
    } else {
      browserHistory.push('/');
    }
  };
}

function registrationSetup(data) {
  return (dispatch, getState) => {
    const registerForm = {
      name: REGISTER_PAGE,
      data: {
        form: data,
        errors: {},
        user: getState().register.user,
      },
    };
    dispatch(setForm(registerForm));
  };
}

export function getStepCounter(translate) {
  return (_, getState) => {
    const country = makeSelectQueryCountry()(getState());
    const { featureFlagSettings } = selectSettingsDomain()(getState());
    const mobile = makeSelectIsMobile()(getState());
    const page = makeSelectFormPageName()(getState());
    const form = makeSelectFormForm(page)(getState());
    const phoneAuth = makeSelectPhoneAuthentication()(getState());
    const stepAddArr = [...additionalStepsArray];
    const marketNoComms = [...marketNoCommsPage];

    const isSpecialCommsNeeded =
      featureFlagSettings?.show_marketing_comms_screen;

    const showVehiclePreferenceScreen =
      makeSelectSettingsShowVehiclePreferenceScreen()(getState());

    if (isSpecialCommsNeeded) {
      stepAddArr.unshift('/marketingcomms');
      stepAddArr.unshift('/aboutcomms');
    } else if (!marketNoComms.includes(country)) {
      stepAddArr.unshift('/comms');
    }
    if (phoneAuth) {
      stepAddArr.pop();
      stepAddArr.push('/verificationCode');
    }

    if (showVehiclePreferenceScreen) stepAddArr.unshift(VEHICLE_PREFERENCES);

    const formSteps =
      form.order && form?.order[mobile ? 'mobile' : 'desktop'].length;
    const totalSteps = formSteps + stepAddArr.length;
    const formStep = makeSelectFormStep()(getState());
    const registrationStep =
      window.location.pathname === REGISTER_PATHNAME
        ? formStep + 1
        : formSteps + stepAddArr.indexOf(window.location.pathname) + 1;
    return translate(lokaliseTags.SSO_REGISTRATION_STEPS_PARTIAL, false, [
      registrationStep,
      totalSteps,
    ]);
  };
}

export const goToTcAndPP = () => {
  return (dispatch) => {
    browserHistory.push(REGISTER_TC_AND_PRIVACY_PATHNAME);
    const tag = {
      ...adobeAnalyticTags[REGISTER_PAGE].clickTCAndPPLink,
    };
    dispatch(sendAdobeAnalyticsEvent(tag));
  };
};

export default {
  initializeRegistration,
  getRegisterForm,
  handleRegistrationSubmit,
  onBack,
  createUserInSSO,
  startRegisterWithSSO,
  acceptTermsAndConditions,
  getStepCounter,
};
