import { setSpinner } from '../../../features/app/actions';
import { Api } from '@shell-b2c/http-frontend/dist/src';
import {
  UPDATE_CONSENTS_URL,
  ACCESS_CODE_URL,
  REFRESH_URL,
  AUTH_REDIRECTION,
  MOBILE_LOGIN_URL,
  LOGIN_URL,
  OTP_VERIFY,
  PROMOTION_CODE_URL,
  ADD_LOYALTY_URL,
  ATTACH_LOYALTY_DIGITAL_CARD_URL,
} from '@shell-b2c/http-frontend/dist/src/components/core/endpoints';
import { makeSelectIsAppLoading } from '../../../features/app/selectors';

let pendingRequestsCount = 0;
let overrideSpinnerCount = 0;
let redirectRequestsCount = 0;

const WHITE_LIST_REQUESTS = [
  UPDATE_CONSENTS_URL,
  ACCESS_CODE_URL,
  REFRESH_URL,
  MOBILE_LOGIN_URL,
  LOGIN_URL,
  OTP_VERIFY,
  PROMOTION_CODE_URL,
  ADD_LOYALTY_URL,
  ATTACH_LOYALTY_DIGITAL_CARD_URL,
];

const checkAndSetSpinner = (toSet) => {
  return (dispatch, getState) => {
    const isSpinnerActive = makeSelectIsAppLoading()(getState());
    if (toSet !== isSpinnerActive) dispatch(setSpinner(toSet));
  };
};

const addRequest = (config) => {
  return async (dispatch) => {
    if (
      (config && WHITE_LIST_REQUESTS.includes(config.url)) ||
      config.noSpinner
    ) {
      overrideSpinnerCount += 1;
      return;
    }
    if (config?.url.includes(AUTH_REDIRECTION)) {
      redirectRequestsCount++;
    }
    pendingRequestsCount += 1;
    dispatch(checkAndSetSpinner(true));
  };
};

function removeRequest(dispatch, requestFailed) {
  if (overrideSpinnerCount > 0) {
    overrideSpinnerCount -= 1;
    return;
  }

  if (redirectRequestsCount > 0 && !requestFailed) {
    redirectRequestsCount--;
    if (pendingRequestsCount > 0) {
      pendingRequestsCount -= 1;
    }
    return;
  }
  pendingRequestsCount -= 1;
  if (pendingRequestsCount <= 0) {
    dispatch(checkAndSetSpinner(false));
  }
}

export default function ({ dispatch }) {
  Api.axiosInstance.interceptors.request.use((config) => {
    dispatch(addRequest(config));
    return config;
  });

  Api.axiosInstance.interceptors.response.use(
    (response) => {
      removeRequest(dispatch, false);
      return response;
    },
    (error) => {
      removeRequest(dispatch, true);
      return Promise.reject(error);
    }
  );

  return (next) => (action) => next(action);
}
