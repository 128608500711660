import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Styling from './style';
import { Button } from '@shell-b2c/web-design-system';

const PopupButtons = ({ translate, popupActions, popup }) => {
  const getButton = (option, index) => {
    const ammount = popup.buttons.length;
    const isPrimary = index === ammount - 1;

    const style = {
      ...(isPrimary ? Styling.buttonPrimary : Styling.buttonDefault),
      ...(option.style || {}),
    };

    return (
      <Button
        id={option.label}
        onClick={() =>
          popupActions.buttonClick(option.keepOpen || false, option.event)
        }
        fullWidth
        variant={!isPrimary ? 'outlined' : 'contained'}
        color="primary"
        style={style}
        data-testid={`submit-Button-${option.label ?? ''}`}
        key={option.label}
      >
        {translate(option.label)}
      </Button>
    );
  };

  const generateButtons = () => {
    const amount = popup.vertical ? 1 : popup.buttons.length;

    return popup.buttons.map((option, index) => (
      <Grid
        item
        xs={12 / amount}
        key={option.label}
        style={
          popup.inlineButtons
            ? Styling.inlineButtonWrapper
            : option.wrapperStyle || {}
        }
      >
        {getButton(option, index)}
      </Grid>
    ));
  };

  return (
    <Grid container style={popup.inlineButtons ? Styling.inlineButtons : {}}>
      {generateButtons()}
    </Grid>
  );
};

PopupButtons.propTypes = {
  translate: PropTypes.func.isRequired,
  popupActions: PropTypes.object.isRequired,
  popup: PropTypes.object.isRequired,
};

export default PopupButtons;
