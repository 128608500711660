import React from 'react';
import PropTypes from 'prop-types';

import Styling from './style';
import { Text } from '@shell-b2c/web-design-system';

function PopupHeader({ translate, popup }) {
  return (
    <div style={Styling.headerWrapper}>
      <Text variant="body2" style={{ ...Styling.headerStyle, ...popup.header.style }}>
        {translate(popup.header.title)}
      </Text>
    </div>
  );
}

PopupHeader.propTypes = {
  translate: PropTypes.func.isRequired,
  popup: PropTypes.object.isRequired,
};

export default PopupHeader;
