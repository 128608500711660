import lazy from 'react-lazy-with-preload';

import {
  COMMUNICATIONS_PATHNAME,
  PRIVACY_PATHNAME,
  TERMS_PATHNAME,
  DELETE_ACCOUNT_PATHNAME,
  SOL_PASSWORD_PATHNAME,
  SOL_MIGRATION_PATHNAME,
  NEW_LOYALTY_PATHNAME,
  ATTACH_LOYALTY_PATHNAME,
  CHANGE_PASSWORD_PATHNAME,
  EDIT_PROFILE_PATHNAME,
  PROFILE_PATHNAME,
  RESET_PASSWORD_PATHNAME,
  FORGOT_PASSWORD_PATHNAME,
  REGISTER_PATHNAME,
  LOGIN_PATHNAME,
  REGISTER_TC_AND_PRIVACY_PATHNAME,
  REGISTER_COMMUNICATIONS_PATHNAME,
  SECURITY_UPDATE_OVERVIEW_PATHNAME,
  SECURITY_UPDATE_PASSWORD_PATHNAME,
  SECURITY_UPDATE_CONFIRM_EMAIL_PATHNAME,
  SECURITY_UPDATE_RESET_PASSWORD,
  VERIFY_EMAIL_CHANGE_PATHNAME,
  VERIFY_EMAIL_PATHNAME,
  LOD_PROFILE_PATHNAME,
  SOL_RESET_PASSWORD_PATHNAME,
  WELCOME_GO_PLUS_PATHNAME,
  SOL_ATTACH_WELCOME_PATHNAME,
  SETUP_GOPLUS_COMMS,
  SETUP_WEB_GOPLUS_COMMS,
  SETUP_PARTNER_COMMS,
  MIGRATION_PATHNAME,
  LOGOUT_PATHNAME,
  VERIFICATION_CODE_PATHNAME,
  VERIFY_MOBILE_CHANGE_PATHNAME,
  CHANGE_MOBILE_PATHNAME,
  CHANGE_EMAIL_PATHNAME,
  CONFIRM_EMAIL_CHANGE_PATHNAME,
  ACCOUNT_ACTIVATION_PATHNAME,
  SEE_YOU_SOON_PATHNAME,
  LOYALTY_FUTURES_PROFILE_PATHNAME,
  LOYALTY_FUTURES_CARD_INPUT_PATHNAME,
  LOYALTY_FUTURES_CARD_VALIDATION_PATHNAME,
  SECURITY_UPDATE_PATHNAME,
  MIGRATION_ID_PATHNAME,
  LOYALTY_FUTURES_MIGRATION_USER_DATA_VALIDATION_PATHNAME,
  LOYALTY_FUTURES_ATTACH_USER_DATA_VALIDATION_PATHNAME,
  MIGRATION_PASSWORD_PATHNAME,
  LEGAL_CONTENT_PATHNAME,
  LOYALTY_FUTURES_ATTACH_WELCOME_PATHNAME,
  LOYALTY_FUTURES_COMMUNICATION,
  SOL_CARD_INPUT_PATHNAME,
  SOL_LOYALTY_CARDS_PATHNAME,
  SOL_REPLACE_CARD_PATHNAME,
  SOL_ATTACH_DIGITAL_B2B_PATHNAME,
  SOL_ATTACH_PAYSONSITE_DETAILS_PATHNAME,
  SOL_ATTACH_PAYSONSITE_SUCCESS_PATHNAME,
  SOL_ATTACH_PAYSONSITE_ERROR_PATHNAME,
  SOL_ATTACH_SMARTDEAL_PATHNAME,
  SAP_HYBRIS_ATTACH_WELCOME_PATHNAME,
  SAP_HYBRIS_ATTACH_CARD_INPUT_PATHNAME,
  SAP_HYBRIS_PROFILE_PATHNAME,
  SOL_MIGRATION_USER_DATA_VALIDATION_PATHNAME,
  LOYALTY_ATTACH_PATHNAME,
  LOYALTY_PROFILE_PATHNAME,
  MFA_OTP_PATHNAME,
  ERROR_PAGE,
  TWOFA_MOBILE_PATHNAME,
  SCOPES_AUTHORIZATION_SCREEN,
  SCOPES_ACCEPTED_SCREEN,
  TWOFA_PROMPT_PATHNAME,
  PROFILE_SECURITY_PATHNAME,
  TWOFA_HELP_PATHNAME,
  TWOFA_CHECK_PASSWORD,
  PROGRESSIVE_PROFILING_ONBOARDING,
  PROGRESSIVE_PROFILING_LOYALTY_REQUIRED,
  PROGRESSIVE_PROFILING_DYNAMICFORM,
  PROGRESSIVE_PROFILING_SUCCESS,
  TERMS_CONDITIONS_GO_PLUS,
  AIRMILES_REGISTRATION_FORM_PAGE,
  AIRMILES_REGISTRATION_SPINNER_PAGE,
  AIRMILES_COMMS_PAGE,
  AIRMILES_LOGIN_FORM_PAGE,
  AIRMILES_CONFIRMATION_PAGE,
  AIRMILES_LINKING,
  PROMPT_CHECK_POPUP_ABOUT_COMMS,
  PROMPT_CHECK_POPUP_MARKETING,
  VEHICLE_PREFERENCES,
} from '../shared/pathnames';

const routes = [
  {
    path: LOGIN_PATHNAME,
    name: 'login',
    component: lazy(() => import('../features/login/Login')),
  },
  {
    path: REGISTER_PATHNAME,
    name: 'register',
    component: lazy(() => import('../features/register/Register')),
  },
  {
    path: FORGOT_PASSWORD_PATHNAME,
    name: 'forgotpassword',
    component: lazy(() => import('../features/forgot-password/ForgotPassword')),
  },
  {
    path: SECURITY_UPDATE_RESET_PASSWORD,
    name: 'resetPasswordSecurityUpdate',
    component: lazy(() => import('../features/reset-password/SecurityUpdate')),
  },
  {
    path: RESET_PASSWORD_PATHNAME,
    name: 'resetpassword',
    component: lazy(() => import('../features/reset-password/ResetPassword')),
  },
  {
    path: SEE_YOU_SOON_PATHNAME,
    name: 'seeYouSoon',
    component: lazy(() => import('../features/logout/SeeYouSoon')),
  },
  {
    path: LOYALTY_ATTACH_PATHNAME,
    name: 'loyaltyAttach',
    component: lazy(() => import('../shared/components/AttachWelcomeManager')),
  },
  {
    path: LOYALTY_PROFILE_PATHNAME,
    name: 'loyaltyProfile',
    component: lazy(() => import('../shared/components/ProfileManager')),
  },
  {
    path: ACCOUNT_ACTIVATION_PATHNAME,
    name: 'account-activation',
    component: lazy(() =>
      import('../features/account-activation/AccountActivation')
    ),
  },
  {
    path: PROFILE_PATHNAME,
    name: 'profile',
    component: lazy(() => import('../features/profile/Profile')),
  },
  {
    path: EDIT_PROFILE_PATHNAME,
    name: 'editprofile',
    component: lazy(() => import('../features/edit-profile/EditProfile')),
  },
  {
    path: CHANGE_PASSWORD_PATHNAME,
    name: 'changepassword',
    component: lazy(() => import('../features/change-password/ChangePassword')),
  },
  {
    path: SOL_CARD_INPUT_PATHNAME,
    name: 'solCardInput',
    component: lazy(() => import('../features/sol/attach/CardInput')),
  },
  {
    path: SOL_LOYALTY_CARDS_PATHNAME,
    name: 'solLoyaltyCards',
    component: lazy(() => import('../features/sol/profile/LoyaltyCards')),
  },
  {
    path: SOL_REPLACE_CARD_PATHNAME,
    name: 'solReplaceCard',
    component: lazy(() => import('../features/sol/profile/ReplaceCard')),
  },
  {
    path: ATTACH_LOYALTY_PATHNAME,
    name: 'attachloyalty',
    component: lazy(() =>
      import('../features/sol/attach/registered/LoyaltyAttach')
    ),
  },
  {
    path: `${NEW_LOYALTY_PATHNAME}/:cardType`,
    name: 'newloyalty',
    component: lazy(() =>
      import('../features/sol/attach/personalized-or-not/LoyaltyNew')
    ),
  },
  {
    path: SOL_MIGRATION_PATHNAME,
    name: 'solmigration',
    component: lazy(() =>
      import('../features/sol/migration/card-number/SolMigration')
    ),
  },
  {
    path: SOL_PASSWORD_PATHNAME,
    name: 'solpassword',
    component: lazy(() =>
      import('../features/sol/forgot-password/SolForgotPassword')
    ),
  },
  {
    path: DELETE_ACCOUNT_PATHNAME,
    name: 'deleteaccount',
    component: lazy(() => import('../features/delete-account/DeleteAccount')),
  },
  {
    path: TERMS_PATHNAME,
    name: 'terms',
    component: lazy(() => import('../features/terms/Terms')),
  },
  {
    path: PRIVACY_PATHNAME,
    name: 'privacy',
    component: lazy(() => import('../features/privacy/Privacy')),
  },
  {
    path: COMMUNICATIONS_PATHNAME,
    name: 'communications',
    component: lazy(() => import('../features/communications/Communications')),
  },
  {
    path: REGISTER_TC_AND_PRIVACY_PATHNAME,
    name: 'registerTCAndPrivacy',
    component: lazy(() => import('../features/tc-and-privacy/TCAndPrivacy')),
  },
  {
    path: REGISTER_COMMUNICATIONS_PATHNAME,
    name: 'registerCommunications',
    component: lazy(() =>
      import(
        '../features/register-add-communications/RegisterAddCommunications'
      )
    ),
  },
  {
    path: SECURITY_UPDATE_OVERVIEW_PATHNAME,
    name: 'securityUpdateOverview',
    component: lazy(() =>
      import('../features/sol/migration/email/SecurityUpdate')
    ),
  },
  {
    path: `${LEGAL_CONTENT_PATHNAME}/:contentType`,
    name: 'migrationContent',
    component: lazy(() => import('../features/tc-and-privacy/Legal')),
  },
  {
    path: SECURITY_UPDATE_PASSWORD_PATHNAME,
    name: 'securityUpdatePassword',
    component: lazy(() => import('../features/sol/migration/email/Migration')),
  },
  {
    path: SECURITY_UPDATE_CONFIRM_EMAIL_PATHNAME,
    name: 'securityUpdateConfirmEmail',
    component: lazy(() => import('../features/verify-email/VerifyEmail')),
  },
  {
    path: VERIFY_EMAIL_PATHNAME,
    name: 'verifyEmailPathName',
    component: lazy(() => import('../features/verify-email/VerifyEmail')),
  },
  {
    path: CONFIRM_EMAIL_CHANGE_PATHNAME,
    name: 'confirmEmailPathName',
    component: lazy(() =>
      import('../features/confirm-change-email/ConfirmChangeEmail')
    ),
  },
  {
    path: VERIFY_EMAIL_CHANGE_PATHNAME,
    name: 'verifyEmailChange',
    component: lazy(() =>
      import('../features/verify-email-change/VerifyEmailChange')
    ),
  },
  {
    path: LOD_PROFILE_PATHNAME,
    name: 'lodProfile',
    component: lazy(() => import('../features/goplus/lod-profile/LodProfile')),
  },
  {
    path: LOYALTY_FUTURES_PROFILE_PATHNAME,
    name: 'loyaltyFuturesProfile',
    component: lazy(() =>
      import('../features/loyalty-futures/profile/Profile')
    ),
  },
  {
    path: LOYALTY_FUTURES_CARD_INPUT_PATHNAME,
    name: 'loyaltyFuturesCardInput',
    component: lazy(() =>
      import('../features/loyalty-futures/sign-in/CardInput')
    ),
  },
  {
    path: LOYALTY_FUTURES_CARD_VALIDATION_PATHNAME,
    name: 'loyaltyFuturesCardValidation',
    component: lazy(() =>
      import('../features/loyalty-futures/sign-in/CardValidation')
    ),
  },
  {
    path: SECURITY_UPDATE_PATHNAME,
    name: 'migrationSecurityUpdate',
    component: lazy(() =>
      import('../features/loyalty-futures/shared/SecurityUpdate')
    ),
  },
  {
    path: MIGRATION_ID_PATHNAME,
    name: 'migrationId',
    component: lazy(() => import('../features/loyalty-futures/migration/Id')),
  },
  {
    path: LOYALTY_FUTURES_MIGRATION_USER_DATA_VALIDATION_PATHNAME,
    name: 'migrationValidation',
    component: lazy(() =>
      import('../features/loyalty-futures/migration/UserDataValidation')
    ),
  },
  {
    path: LOYALTY_FUTURES_ATTACH_USER_DATA_VALIDATION_PATHNAME,
    name: 'attachDataValidation',
    component: lazy(() =>
      import('../features/loyalty-futures/attach/AttachDataValidation')
    ),
  },
  {
    path: MIGRATION_PASSWORD_PATHNAME,
    name: 'migrationPassword',
    component: lazy(() =>
      import('../features/loyalty-futures/migration/Password')
    ),
  },
  {
    path: LOYALTY_FUTURES_COMMUNICATION,
    name: 'loyaltyFuturesCommunication',
    component: lazy(() =>
      import('../features/loyalty-futures/migration/Communication')
    ),
  },
  {
    path: SOL_MIGRATION_USER_DATA_VALIDATION_PATHNAME,
    name: 'solMigrationValidation',
    component: lazy(() =>
      import('../features/sol/migration/email/UserDataValidation')
    ),
  },
  {
    path: SOL_RESET_PASSWORD_PATHNAME,
    name: 'solResetPassword',
    component: lazy(() =>
      import('../features/sol/reset-password/SolResetPassword')
    ),
  },
  {
    path: WELCOME_GO_PLUS_PATHNAME,
    name: 'welcomeGoPlus',
    component: lazy(() =>
      import('../features/goplus/welcome-go-plus/WelcomeGoPlus')
    ),
  },
  {
    path: SOL_ATTACH_WELCOME_PATHNAME,
    name: 'welcomeLoyalty',
    component: lazy(() => import('../features/sol/attach/welcome/Welcome')),
  },
  {
    path: SOL_ATTACH_DIGITAL_B2B_PATHNAME,
    name: 'attachDigitalb2b',
    component: lazy(() => import('../features/sol/attach/digital/B2b')),
  },
  {
    path: SOL_ATTACH_PAYSONSITE_DETAILS_PATHNAME,
    name: 'attachPayOnSiteDetails',
    component: lazy(() => import('../features/sol/attach/payonsite/Details')),
  },
  {
    path: SOL_ATTACH_PAYSONSITE_SUCCESS_PATHNAME,
    name: 'attachPayOnSiteSuccess',
    component: lazy(() => import('../features/sol/attach/payonsite/Success')),
  },
  {
    path: ERROR_PAGE,
    name: 'errorPage',
    component: lazy(() => import('../shared/components/ErrorPage')),
  },
  {
    path: SOL_ATTACH_PAYSONSITE_ERROR_PATHNAME,
    name: 'attachPayOnSiteError',
    component: lazy(() => import('../features/sol/attach/payonsite/Error')),
  },
  {
    path: SOL_ATTACH_SMARTDEAL_PATHNAME,
    name: 'attachSmartdeal',
    component: lazy(() => import('../features/sol/attach/Smartdeal')),
  },
  {
    path: LOYALTY_FUTURES_ATTACH_WELCOME_PATHNAME,
    name: 'loyaltyFuturesAttachWelcome',
    component: lazy(() => import('../features/loyalty-futures/attach/Welcome')),
  },
  {
    path: SETUP_GOPLUS_COMMS,
    name: 'setupGoplusComms',
    component: lazy(() =>
      import('../features/goplus/lod-attach-consents/LodAttachConsents')
    ),
  },
  {
    path: SETUP_WEB_GOPLUS_COMMS,
    name: 'setupWebGoplusForm',
    component: lazy(() =>
      import('../features/goplus/lod-consents/LodConsents')
    ),
  },
  {
    path: SETUP_PARTNER_COMMS,
    name: 'setupWebPartnerComms',
    component: lazy(() => import('../features/tc-and-privacy/TCAndPrivacy')),
  },
  {
    path: LOGOUT_PATHNAME,
    name: 'logout',
    component: lazy(() => import('../features/logout/Logout')),
  },
  {
    path: VERIFICATION_CODE_PATHNAME,
    name: 'verificationCode',
    component: lazy(() =>
      import('../features/verification-code/VerificationCode')
    ),
  },
  {
    path: VERIFY_MOBILE_CHANGE_PATHNAME,
    name: 'verifyMobileChange',
    component: lazy(() =>
      import('../features/verify-mobile-change/VerifyMobileChange')
    ),
  },
  {
    path: CHANGE_MOBILE_PATHNAME,
    name: 'changeMobile',
    component: lazy(() => import('../features/change-mobile/ChangeMobile')),
  },
  {
    path: CHANGE_EMAIL_PATHNAME,
    name: 'changeEmail',
    component: lazy(() => import('../features/change-email/ChangeEmail')),
  },
  {
    path: MIGRATION_PATHNAME,
    name: 'migration',
    component: lazy(() =>
      import('../features/sol/migration/email/SecurityUpdate')
    ),
  },
  {
    path: SAP_HYBRIS_ATTACH_WELCOME_PATHNAME,
    name: 'welcomeSAPHybris',
    component: lazy(() => import('../features/sap-hybris/attach/Welcome')),
  },
  {
    path: SAP_HYBRIS_ATTACH_CARD_INPUT_PATHNAME,
    name: 'SAPHybrisCardInput',
    component: lazy(() => import('../features/sap-hybris/attach/CardInput')),
  },
  {
    path: SAP_HYBRIS_PROFILE_PATHNAME,
    name: 'SAPHybrisProfile',
    component: lazy(() => import('../features/sap-hybris/profile/Profile')),
  },
  {
    path: MFA_OTP_PATHNAME,
    name: 'mfaOtp',
    component: lazy(() => import('../features/mfa/Otp')),
  },
  {
    path: TWOFA_MOBILE_PATHNAME,
    name: 'twoFAMobile',
    component: lazy(() => import('../features/twofa/mobile')),
  },
  {
    path: TWOFA_PROMPT_PATHNAME,
    name: 'twoFAPrompt',
    component: lazy(() => import('../features/twofa/prompt')),
  },
  {
    path: TWOFA_HELP_PATHNAME,
    name: 'twoFAHelp',
    component: lazy(() => import('../features/twofa/help')),
  },
  {
    path: TWOFA_CHECK_PASSWORD,
    name: 'twoFACheckPassword',
    component: lazy(() =>
      import('../features/twofa/checkPassword/CheckPassword')
    ),
  },
  {
    path: SCOPES_AUTHORIZATION_SCREEN,
    name: 'scopesAuthScreen',
    component: lazy(() => import('../features/scopes/auth/Auth')),
  },
  {
    path: SCOPES_ACCEPTED_SCREEN,
    name: 'scopesAcceptedScreen',
    component: lazy(() => import('../features/scopes/accepted/Accepted')),
  },
  {
    path: PROFILE_SECURITY_PATHNAME,
    name: 'profileSecurityOption',
    component: lazy(() => import('../features/profile/security/Security')),
  },
  {
    path: PROGRESSIVE_PROFILING_ONBOARDING,
    name: 'progressiveProfilingOnboarding',
    component: lazy(() =>
      import('../features/progressive-profiling/onboarding')
    ),
  },
  {
    path: PROGRESSIVE_PROFILING_LOYALTY_REQUIRED,
    name: 'progressiveProfilingLoyaltyRequired',
    component: lazy(() =>
      import('../features/progressive-profiling/loyaltyRequired')
    ),
  },
  {
    path: PROGRESSIVE_PROFILING_DYNAMICFORM,
    name: 'progressiveProfilingDynamicForm',
    component: lazy(() =>
      import('../features/progressive-profiling/dynamicForm')
    ),
  },
  {
    path: PROGRESSIVE_PROFILING_SUCCESS,
    name: 'progressiveProfilingSuccess',
    component: lazy(() =>
      import('../features/progressive-profiling/successScreen')
    ),
  },
  {
    path: TERMS_CONDITIONS_GO_PLUS,
    name: 'updatedTermsAndConditionsGoPlus',
    component: lazy(() =>
      import(
        '../features/migrate-noLoyalty-to-loyalty/terms-and-conditions/TermsAndConditions'
      )
    ),
  },
  {
    path: AIRMILES_REGISTRATION_FORM_PAGE,
    name: 'airmilesRegistrationFormPage',
    component: lazy(() =>
      import(
        '../features/migrate-noLoyalty-to-loyalty/registration/RegistrationForm'
      )
    ),
  },
  {
    path: AIRMILES_REGISTRATION_SPINNER_PAGE,
    name: 'airmilesRegistrationSpinnerPage',
    component: lazy(() =>
      import('../features/airmiles/registerNotification/RegisterNotification')
    ),
  },
  {
    path: AIRMILES_COMMS_PAGE,
    name: 'airmilesCommsPage',
    component: lazy(() =>
      import('../features/migrate-noLoyalty-to-loyalty/comms/Comms')
    ),
  },
  {
    path: AIRMILES_LOGIN_FORM_PAGE,
    name: 'airmilesLoginFormPage',
    component: lazy(() =>
      import('../features/migrate-noLoyalty-to-loyalty/login/AirmilesLogin')
    ),
  },
  {
    path: AIRMILES_CONFIRMATION_PAGE,
    name: 'airmilesConfPage',
    component: lazy(() =>
      import(
        '../features/migrate-noLoyalty-to-loyalty/confirmation/Confirmation'
      )
    ),
  },
  {
    path: AIRMILES_LINKING,
    name: 'airmilesLinkingPage',
    component: lazy(() =>
      import(
        '../features/migrate-noLoyalty-to-loyalty/linkingAfterLogin/LinkingAirmiles'
      )
    ),
  },
  {
    path: PROMPT_CHECK_POPUP_ABOUT_COMMS,
    name: 'aboutComms',
    component: lazy(() => import('../features/about-comms/AboutComms')),
  },
  {
    path: PROMPT_CHECK_POPUP_MARKETING,
    name: 'marketingComms',
    component: lazy(() => import('../features/marketing-comms/MarketingComms')),
  },
  {
    path: VEHICLE_PREFERENCES,
    name: 'vehiclePreferences',
    component: lazy(() =>
      import('../features/vehicle-preferences/VehiclePreferences')
    ),
  },
  {
    path: '/',
    name: 'home',
    component: lazy(() => import('../features/home/Home')),
  },
];

export default routes;
