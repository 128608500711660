import React, { useEffect, useState } from 'react';
import { Snackbar } from '@shell-b2c/web-design-system';
import { useNetwork } from '../hooks/useNetwork';
import { makeStyles } from '@mui/styles';
import { useTimeout } from '../hooks/useTimeout';
import lokaliseTags from '../lokaliseTags';
import { NUMBERS } from '../constants';

function Offline({ translate, onNoConnectionClick }) {
  const online = useNetwork();
  const classes = useStyles();
  const { start } = useTimeout(() => setOpen(false), NUMBERS.SIX_THOUSAND);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(!online);

    if (!online) {
      start();
    }
  }, [online, start]);

  return (
    !online && (
      <div
        id="offline-ref"
        className={classes.mask}
        onClick={onNoConnectionClick}
      >
        <Snackbar
          open={open}
          message={translate(
            lokaliseTags.SSO_GENERAL_NO_INTERNET_CONNECTION_HEADER
          )}
        />
      </div>
    )
  );
}

const useStyles = makeStyles({
  mask: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: '99999999',
  },
});

export default Offline;
