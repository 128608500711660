import ConeAnimation from '../../shared/components/ConeAnimation';
import lokaliseTags from '../../shared/lokaliseTags';

export const REGISTER_WARNING = {
  header: {
    icon: {
      name: lokaliseTags.SSO_GENERAL_CLOSE,
      icon: 'close',
    },
  },
  animation: {
    component: ConeAnimation,
  },
  message: {
    title: lokaliseTags.SSO_GENERAL_ERROR,
    text: '',
  },
  buttons: [
    {
      label: lokaliseTags.SSO_GENERAL_TRY_AGAIN,
    },
  ],
};
