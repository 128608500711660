import {
  SET_MIGRATION_ID_FORM,
  SET_MIGRATION_PASSWORD_FORM,
  SET_MIGRATION_VALIDATION_FORM,
  SET_MIGRATION_ID,
  SET_MIGRATION_PASSWORD,
  SET_MIGRATION_DATA,
  SET_MIGRATION_COMMUNICATION,
} from './reducer';
import {
  makeSelectQueryPartner,
  makeSelectQueryRedirect,
} from '../../app/selectors';
import {
  getMigrationIdForm,
  getMigrationPasswordForm,
  getMigrationValidationForm,
} from '@shell-b2c/http-frontend/dist/src/components/core/forms';
import { DEFAULT_HEADER } from './headers';
import {
  setSpinner,
  redirectToCVP,
  validateEmailExistInJanrain,
  sendAdobeAnalyticsEvent,
  saveCredentials,
} from '../../app/actions';
import {
  selectMigrationDomain,
  makeSelectMigrationData,
  makeSelectMigrationCard,
} from './selectors';
import {
  LOYALTY_FUTURES_MIGRATION_ID_PAGE,
  MIGRATION_PASSWORD_PAGE,
  LOYALTY_FUTURES_MIGRATION_VALIDATION_PAGE,
} from '../../../shared/formPages';
import {
  showDefaultErrorNotification,
  showNotification,
} from '../../notification/actions';
import {
  setForm,
  validateForm,
  updateAttribute,
  setFormError,
  resetForm,
  setFormData,
} from '../../dynamic-form/actions';
import browserHistory from '../../../router/history';
import {
  makeSelectFormData,
  makeSelectFormDataBackendFormated,
  makeSelectFormPageName,
} from '../../dynamic-form/selectors';
import {
  MIGRATION_PASSWORD_PATHNAME,
  LOGIN_PATHNAME,
  VERIFICATION_CODE_PATHNAME,
  LOYALTY_FUTURES_MIGRATION_USER_DATA_VALIDATION_PATHNAME,
  LOYALTY_FUTURES_COMMUNICATION,
  SECURITY_UPDATE_CONFIRM_EMAIL_PATHNAME,
} from '../../../shared/pathnames';
import { VALIDATION_ERROR } from '../../dynamic-form/constants';
import {
  migrateAccount,
  migrateAccountMobile,
} from '@shell-b2c/http-frontend/dist/src/components/core/migration';
import { checkPhoneExist } from '@shell-b2c/http-frontend/dist/src/components/core/settings';
import { lodMobileVerification } from '@shell-b2c/http-frontend/dist/src/components/core/lod';
import { closePopup, showPopup } from '../../popup/actions';
import { buildPopup } from '../../popup/utils';
import adobeAnalyticTags from '../../../shared/adobeAnalyticTags';
import { makeSelectIsPhoneNumberFormActive } from '../../auth/selectors';
import { showEmailAlreadyExistPopup } from '../../sol/migration/email/actions';
import { setEmailToVerify } from '../../verify-email/actions';
import verifyEmailChangeActions from '../../verify-email-change/actions';
import {
  getCaptchaToken,
  transformPartnersDataFromCocoToExternalSystemId,
} from '../../../shared/utils';
import { makeSelectSettingsPartnersByClientId } from '../../settings/selectors';
import lokaliseTags from '../../../shared/lokaliseTags';
import ConeAnimation from '../../../shared/components/ConeAnimation';
import { buildNotification } from '../../notification/utils';
import TickAnimation from '../../../shared/components/TickAnimation';

// MIGRATION ID
// ---------------------------------------------------------------------
export function initializeMigrationId(setHeader) {
  return async (dispatch, getState) => {
    const header = DEFAULT_HEADER;
    header.menu.leftMenu.event = () => {
      browserHistory.back();
    };
    setHeader(header);

    const isPhoneNumberActive = makeSelectIsPhoneNumberFormActive()(getState());

    try {
      dispatch(setSpinner(true));

      const migration = selectMigrationDomain()(getState());
      let migrationIdForm = null;

      if (migration.migrationIdForm) {
        migrationIdForm = migration.migrationIdForm;
      } else {
        migrationIdForm = await getMigrationIdForm();
      }

      // Initialize Phone number
      const migrationData = makeSelectMigrationData()(getState());

      const idProp = isPhoneNumberActive ? 'mobile' : 'emailAddress';

      if (migrationData.profile[idProp]) {
        dispatch(
          updateAttribute({
            name: idProp,
            value: migrationData.profile[idProp],
          })
        );
      }

      dispatch({
        type: SET_MIGRATION_ID_FORM,
        form: migrationIdForm,
      });

      dispatch(
        setForm({
          name: LOYALTY_FUTURES_MIGRATION_ID_PAGE,
          data: {
            form: migrationIdForm,
            errors: {},
          },
        })
      );

      dispatch(setSpinner(false));

      dispatch(
        sendAdobeAnalyticsEvent(
          adobeAnalyticTags[LOYALTY_FUTURES_MIGRATION_ID_PAGE].pageLoad,
          'VPV'
        )
      );
    } catch (error) {
      dispatch(showDefaultErrorNotification());
    }
  };
}

export function handleMigrationIdSubmit() {
  return async (dispatch, getState) => {
    dispatch(
      sendAdobeAnalyticsEvent(
        adobeAnalyticTags[LOYALTY_FUTURES_MIGRATION_ID_PAGE].nextButton,
        'tileClick'
      )
    );
    try {
      await dispatch(validateForm(LOYALTY_FUTURES_MIGRATION_ID_PAGE));
      const isPhoneNumberActive = makeSelectIsPhoneNumberFormActive()(
        getState()
      );
      const { areConsentsAccepted, mobile, emailAddress } =
        makeSelectFormData()(getState());

      if (isPhoneNumberActive) {
        const formattedMobile = mobile.replace(/ /g, '');
        const migrationCard = makeSelectMigrationCard()(getState());

        dispatch({
          type: SET_MIGRATION_ID,
          idType: 'mobile',
          id: formattedMobile,
          areConsentsAccepted: areConsentsAccepted?.checked || false,
        });

        const phoneExistsInJanrain = await dispatch(
          validatePhoneExistInJanrain(formattedMobile)
        );

        if (phoneExistsInJanrain) {
          return dispatch(showIdAlreadyExistPopup(formattedMobile));
        }

        const phoneExistsInLod = await dispatch(
          validatePhoneExistInLOD(formattedMobile, migrationCard)
        );

        if (phoneExistsInLod) {
          dispatch(
            sendAdobeAnalyticsEvent(
              adobeAnalyticTags[LOYALTY_FUTURES_MIGRATION_ID_PAGE]
                .errorNumberAlreadyExists,
              'error'
            )
          );
          return dispatch(
            setFormError({
              mobile: lokaliseTags.SSO_FORMS_ERRORS_PHONE_EXISTS_LOD,
            })
          );
        }
      } else {
        dispatch({
          type: SET_MIGRATION_ID,
          idType: 'emailAddress',
          id: emailAddress,
          areConsentsAccepted: areConsentsAccepted?.checked || false,
        });
        const pageName = makeSelectFormPageName()(getState());
        const recaptchaToken = await getCaptchaToken(pageName);
        const emailExistsInJanrain = await dispatch(
          validateEmailExistInJanrain({ email: emailAddress, recaptchaToken })
        );

        if (emailExistsInJanrain) {
          return dispatch(showEmailAlreadyExistPopup());
        }
      }

      browserHistory.push(MIGRATION_PASSWORD_PATHNAME);
    } catch (error) {
      if (error?.type === VALIDATION_ERROR) {
        return;
      }

      dispatch(showDefaultErrorNotification());
    }
  };
}

function showIdAlreadyExistPopup(mobile) {
  return (dispatch) => {
    const popup = buildPopup({
      text: {
        key: lokaliseTags.SSO_MIGRATION_PROMPT_TEXT_PHONE_EXISTS,
        values: [mobile],
      },
      options: {
        inlineButtons: true,
      },
      buttons: [
        {
          label: lokaliseTags.SSO_GENERAL_CANCEL_BUTTON,
          event: () => {
            dispatch(
              sendAdobeAnalyticsEvent(
                adobeAnalyticTags.loyaltyFuturesPhoneAlreadyExistsDialog
                  .cancelButton,
                'tileClick'
              )
            );
            dispatch(closePopup());
          },
          outlined: true,
        },
        {
          label: lokaliseTags.SSO_LOGIN_SIGN_IN_BUTTON,
          event: () => {
            sendAdobeAnalyticsEvent(
              adobeAnalyticTags.loyaltyFuturesPhoneAlreadyExistsDialog
                .signInButton,
              'tileClick'
            );
            browserHistory.push(LOGIN_PATHNAME);
          },
        },
      ],
    });
    dispatch(
      sendAdobeAnalyticsEvent(
        adobeAnalyticTags.loyaltyFuturesPhoneAlreadyExistsDialog.pageLoad,
        'VPV'
      )
    );
    dispatch(showPopup(popup));
  };
}

function validatePhoneExistInJanrain(mobile) {
  return async () => {
    try {
      await checkPhoneExist({ mobile });
      return true;
    } catch (error) {
      if (error?.resourceNotFound()) {
        return false;
      }

      throw new Error();
    }
  };
}

function validatePhoneExistInLOD(mobile, card) {
  return async () => {
    try {
      await lodMobileVerification({ mobile, cardId: card });
      return false;
    } catch (error) {
      if (error?.emailOrMobileAlreadyExist()) {
        return true;
      }

      throw new Error();
    }
  };
}

// MIGRATION PASSWORD
// ----------------------------------------------------------------------

export function initializeMigrationPassword(setHeader) {
  return async (dispatch, getState) => {
    const header = DEFAULT_HEADER;
    header.menu.leftMenu.event = () => {
      browserHistory.back();
    };
    setHeader(header);
    dispatch(resetForm());

    try {
      dispatch(setSpinner(true));

      const migration = selectMigrationDomain()(getState());

      let migrationPasswordForm = null;

      if (migration.migrationPasswordForm) {
        migrationPasswordForm = migration.migrationPasswordForm;
      } else {
        migrationPasswordForm = await getMigrationPasswordForm();
      }

      dispatch({
        type: SET_MIGRATION_PASSWORD_FORM,
        form: migrationPasswordForm,
      });

      dispatch(
        setForm({
          name: MIGRATION_PASSWORD_PAGE,
          data: {
            form: migrationPasswordForm,
            errors: {},
          },
        })
      );

      dispatch(setSpinner(false));

      dispatch(
        sendAdobeAnalyticsEvent(
          adobeAnalyticTags[MIGRATION_PASSWORD_PAGE].pageLoad,
          'VPV'
        )
      );
    } catch (error) {
      dispatch(showDefaultErrorNotification());
    }
  };
}

export function handleMigrationPasswordSubmit() {
  return async (dispatch, getState) => {
    dispatch(
      sendAdobeAnalyticsEvent(
        adobeAnalyticTags[MIGRATION_PASSWORD_PAGE].nextButton,
        'tileClick'
      )
    );
    try {
      const formData = makeSelectFormData()(getState());
      const migrationData = makeSelectMigrationData()(getState());
      const isPhoneNumberActive = makeSelectIsPhoneNumberFormActive()(
        getState()
      );

      await dispatch(validateForm(MIGRATION_PASSWORD_PAGE));

      dispatch({
        type: SET_MIGRATION_PASSWORD,
        newPassword: formData.newPassword,
      });

      const data = {
        ...migrationData,
        newPassword: formData.newPassword,
      };

      if (isPhoneNumberActive && migrationData.profile.emailAddress) {
        const pageName = makeSelectFormPageName()(getState());
        const recaptchaToken = await getCaptchaToken(pageName);
        const emailExistsInJanrain = await dispatch(
          validateEmailExistInJanrain({
            email: migrationData.profile.emailAddress,
            recaptchaToken,
          })
        );

        if (emailExistsInJanrain) {
          delete data.profile.emailAddress;

          dispatch({
            type: SET_MIGRATION_DATA,
            data,
          });

          await dispatch(showEmailAlreadyRegisteredNotification());
          return;
        }
      }

      browserHistory.push(LOYALTY_FUTURES_COMMUNICATION);
    } catch (error) {
      if (error?.type === VALIDATION_ERROR) {
        return;
      }

      dispatch(showDefaultErrorNotification());
    }
  };
}

function migrate(data) {
  return async (dispatch, getState) => {
    try {
      const isPhoneNumberActive = makeSelectIsPhoneNumberFormActive()(
        getState()
      );
      if (isPhoneNumberActive) {
        await migrateAccountMobile(data);
        browserHistory.push(VERIFICATION_CODE_PATHNAME, {
          mobile: data.profile.mobile,
        });
        dispatch(
          saveCredentials({
            username: data.profile.mobile,
            password: data.newPassword,
          })
        );
      } else {
        const migrateAccountResponse = await migrateAccount(data);

        dispatch(
          verifyEmailChangeActions.setUUID(migrateAccountResponse?.uuid)
        );
        dispatch(setEmailToVerify(data.profile.emailAddress));
        browserHistory.push(SECURITY_UPDATE_CONFIRM_EMAIL_PATHNAME);
        dispatch(
          saveCredentials({
            username: data.profile.emailAddress,
            password: data.newPassword,
          })
        );
      }
    } catch (error) {
      if (
        error?.badRequest() &&
        window.location.pathname !==
          LOYALTY_FUTURES_MIGRATION_USER_DATA_VALIDATION_PATHNAME
      ) {
        return browserHistory.push(
          LOYALTY_FUTURES_MIGRATION_USER_DATA_VALIDATION_PATHNAME
        );
      }

      dispatch(showDefaultErrorNotification());
    }
  };
}

// COMMUNICATION

export function handleCommunication(communicationAccepted) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const migrationData = makeSelectMigrationData()(state);
      const redirect = makeSelectQueryRedirect()(state);
      const partner = makeSelectQueryPartner()(state);
      let externalSystemIds = null;
      if (partner)
        externalSystemIds = makeSelectSettingsPartnersByClientId()(state);

      const data = {
        ...migrationData,
        consents: Object.keys(migrationData.consents).reduce((acc, current) => {
          return {
            ...acc,
            [current]: communicationAccepted,
          };
        }, {}),
      };
      if (redirect) data.redirect = redirect;
      if (externalSystemIds)
        data.externalSystemIds =
          transformPartnersDataFromCocoToExternalSystemId(externalSystemIds);

      dispatch({
        type: SET_MIGRATION_COMMUNICATION,
        areConsentsAccepted: communicationAccepted,
      });

      dispatch(migrate(data));
    } catch (error) {
      dispatch(showDefaultErrorNotification());
    }
  };
}

// MIGRATION USER DATA VALIDATION
// ----------------------------------------------------------------------

export function initializeMigrationUserDataValidation(setHeader) {
  return async (dispatch, getState) => {
    const header = DEFAULT_HEADER;
    header.menu.leftMenu.event = () => {
      browserHistory.back();
    };
    setHeader(header);
    dispatch(resetForm());

    try {
      dispatch(setSpinner(true));

      const migration = selectMigrationDomain()(getState());

      let migrationValidationForm = null;

      if (migration.migrationValidationForm) {
        migrationValidationForm = migration.migrationValidationForm;
      } else {
        migrationValidationForm = await getMigrationValidationForm();
      }

      dispatch({
        type: SET_MIGRATION_VALIDATION_FORM,
        form: migrationValidationForm,
      });

      dispatch(
        setForm({
          name: LOYALTY_FUTURES_MIGRATION_VALIDATION_PAGE,
          data: {
            form: migrationValidationForm,
            errors: {},
          },
        })
      );
      const migrationData = makeSelectMigrationData()(getState());
      dispatch(
        setFormData({
          ...migrationData?.profile,
          ...migrationData?.profile?.primaryAddress,
        })
      );
      dispatch(validateForm(LOYALTY_FUTURES_MIGRATION_VALIDATION_PAGE));
      dispatch(setSpinner(false));
    } catch (error) {
      if (error?.type === VALIDATION_ERROR) {
        return;
      }
      dispatch(showDefaultErrorNotification());
    }
  };
}

export function handleMigrationUserDataValidationSubmit() {
  return async (dispatch, getState) => {
    try {
      await dispatch(validateForm(LOYALTY_FUTURES_MIGRATION_VALIDATION_PAGE));
      const state = getState();
      const formData = makeSelectFormDataBackendFormated(
        LOYALTY_FUTURES_MIGRATION_VALIDATION_PAGE
      )(state);
      const redirect = makeSelectQueryRedirect()(state);
      const currentMigrationData = makeSelectMigrationData()(state);
      const partner = makeSelectQueryPartner()(state);
      let externalSystemIds = null;
      if (partner)
        externalSystemIds = makeSelectSettingsPartnersByClientId()(state);

      const migrationData = {
        ...currentMigrationData,
        profile: formData,
      };
      if (redirect) migrationData.redirect = redirect;
      if (externalSystemIds)
        migrationData.externalSystemIds =
          transformPartnersDataFromCocoToExternalSystemId(externalSystemIds);

      dispatch({
        type: SET_MIGRATION_DATA,
        data: migrationData,
      });

      dispatch(migrate(migrationData));
    } catch (error) {
      if (error?.type === VALIDATION_ERROR) {
        return;
      }

      dispatch(showDefaultErrorNotification());
    }
  };
}

// NOTIFICATIONS
// ----------------------------------------------------------------------
export function showMigrationFinishedNotification() {
  return (dispatch) => {
    dispatch(
      sendAdobeAnalyticsEvent(
        adobeAnalyticTags.loyaltyFuturesMigrationConfirmationPage.pageLoad,
        'VPV'
      )
    );

    dispatch(
      showNotification(
        buildNotification({
          animation: TickAnimation,
          title: lokaliseTags.SSO_MIGRATION_SECURITY_UPDATED_HEADER,
          text: lokaliseTags.SSO_MIGRATION_SECURITY_UPDATED_BODY,
          buttons: [
            {
              label: lokaliseTags.SSO_MIGRATION_CONTINUE_BUTTON,
              event: () => {
                dispatch(
                  sendAdobeAnalyticsEvent(
                    adobeAnalyticTags.loyaltyFuturesMigrationConfirmationPage
                      .continueButton,
                    'tileClick'
                  )
                );
                dispatch(setSpinner(true));
                dispatch(redirectToCVP());
              },
            },
          ],
        })
      )
    );
  };
}

function showEmailAlreadyRegisteredNotification() {
  return (dispatch) => {
    dispatch(
      sendAdobeAnalyticsEvent(
        adobeAnalyticTags.loyaltyFuturesMailError.pageLoad,
        'VPV'
      )
    );

    dispatch(
      showNotification(
        buildNotification({
          animation: ConeAnimation,
          title: lokaliseTags.SSO_FORMS_ERRORS_EMAIL_REGISTERED,
          text: lokaliseTags.SSO_FORMS_ERRORS_CSC_EMAIL_ERROR,
          buttons: [
            {
              label: lokaliseTags.SSO_CONTINUE_WITHOUT_EMAIL_BUTTON,
              event: () => {
                dispatch(
                  sendAdobeAnalyticsEvent(
                    adobeAnalyticTags.loyaltyFuturesMailError
                      .continueWithouEmailButton,
                    'tileClick'
                  )
                );

                browserHistory.push(LOYALTY_FUTURES_COMMUNICATION);
              },
            },
          ],
        })
      )
    );
  };
}
