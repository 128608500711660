import get from 'lodash/get';
import isObject from 'lodash/isObject';
import { CLOSE_HEADER } from '../../../../features/login/headers';
import { SDA_MIGRATION_APP_PATHNAME } from '../../../../shared/appPathnames';
import { VALIDATION_ERROR } from '../../../dynamic-form/constants';
import {
  MIGRATION_PAGE,
  SOL_CARD_VALIDATION_PAGE,
} from '../../../../shared/formPages';
import {
  makeSelectFormData,
  makeSelectFormSettings,
  makeSelectFormErrors,
  makeSelectFormDataBackendFormated,
} from '../../../dynamic-form/selectors';
import {
  SECURITY_UPDATE_CONFIRM_EMAIL_PATHNAME,
  SECURITY_UPDATE_PASSWORD_PATHNAME,
  LOGIN_PATHNAME,
  SOL_MIGRATION_USER_DATA_VALIDATION_PATHNAME,
} from '../../../../shared/pathnames';
import {
  verifyLoyalty,
  redirectToCVP,
  sendAdobeAnalyticsEvent,
  setSpinner,
  saveCredentials,
} from '../../../app/actions';
import adobeAnalyticTags from '../../../../shared/adobeAnalyticTags';
import {
  makeSelectQuery,
  makeSelectIsMotoristApp,
  makeSelectQueryMarket,
  makeSelectQueryRedirect,
  makeSelectQueryPartner,
} from '../../../app/selectors';
import {
  MIGRATION_LOADED,
  SET_MIGRATION_DATA,
  TOGGLE_MIGRATION_LOADED,
} from './constants';
import {
  setEmailToVerify,
  setIsRegisterFlow,
} from '../../../verify-email/actions';
import verifyEmailChangeActions from '../../../verify-email-change/actions';
import {
  getMigrationForm,
  getMigrationValidationForm,
} from '@shell-b2c/http-frontend/dist/src/components/core/forms';
import { migrateAccount } from '@shell-b2c/http-frontend/dist/src/components/core/migration';
import {
  validateForm,
  resetForm,
  setForm,
  previousStep,
  onAttributeChange,
  setFormData,
  validateStep,
  setFormStep,
} from '../../../dynamic-form/actions';
import { deleteStorage } from '../../../app/utils';
import {
  showDefaultErrorNotification,
  showNotification,
} from '../../../notification/actions';
import { showPopup, closePopup } from '../../../popup/actions';
import browserHistory from '../../../../router/history';
import { cleanMigrationFormData } from '../../../../shared/mapping';
import { buildPopup } from '../../../popup/utils';
import { createUserInitialLoyaltyProfileFromCard } from '../../../../shared/mapping/sol-utils';
import { cardStatus } from '@shell-b2c/http-frontend/dist/src/components/core/loyalty';
import {
  makeSelectCanAttachMultipleCards,
  makeSelectSettingsPartnersByClientId,
} from '../../../settings/selectors';
import { makeSelectMigrationData } from './selectors';
import { transformPartnersDataFromCocoToExternalSystemId } from '../../../../shared/utils';
import { buildNotification } from '../../../notification/utils';
import ConeAnimation from '../../../../shared/components/ConeAnimation';
import lokaliseTags from '../../../../shared/lokaliseTags';

// SECURITY UPDATE
// ---------------------------------------------------------------------
export function initializeSecurityUpdate(setHeader) {
  return (dispatch, getState) => {
    const redirect = makeSelectQueryRedirect()(getState());
    const isMotoristApp = makeSelectIsMotoristApp()(getState());

    const header = CLOSE_HEADER;
    header.menu.leftMenu.event = () => {
      if (redirect || isMotoristApp) {
        dispatch(redirectToCVP());
      } else {
        browserHistory.push(LOGIN_PATHNAME);
      }
    };
    setHeader(header);
    dispatch(setFormStep(0));

    dispatch(
      sendAdobeAnalyticsEvent(adobeAnalyticTags.solMigrationOverview.pageLoad)
    );
  };
}

export function handleSecurityUpdateNext() {
  return (dispatch) => {
    dispatch(
      sendAdobeAnalyticsEvent(
        adobeAnalyticTags.solMigrationOverview.cta,
        'tileClick'
      )
    );
    browserHistory.push(SECURITY_UPDATE_PASSWORD_PATHNAME);
  };
}

function initializeHeaderMigration(setHeader) {
  return async (dispatch, getState) => {
    const dynamicForm = getState().dynamicForm;
    if (dynamicForm.step === 0) {
      const header = {
        menu: {
          leftMenu: {
            name: lokaliseTags.SSO_GENERAL_CLOSE,
            icon: 'close',
            mobile: true,
            desktop: false,
            event: () => dispatch(redirectToCVP()),
          },
        },
      };
      setHeader(header);
    } else {
      const header = {
        menu: {
          leftMenu: {
            name: lokaliseTags.SSO_GENERAL_BACK,
            icon: 'back',
            mobile: true,
            desktop: false,
            event: () => dispatch(previousStepSecurityUpdate(setHeader)),
          },
        },
      };
      setHeader(header);
    }
  };
}

export function previousStepSecurityUpdate(setHeader) {
  return async (dispatch, getState) => {
    dispatch(previousStep());
    const dynamicForm = getState().dynamicForm;
    if (dynamicForm.step === 0) {
      dispatch(initializeHeaderMigration(setHeader));
    }
  };
}

function handleMigrationSubmit(setHeaderMenuLeft) {
  return async (dispatch, getState) => {
    const { isLastFormStep } = makeSelectFormSettings(MIGRATION_PAGE)(
      getState()
    );

    try {
      if (isLastFormStep) {
        dispatch(
          sendAdobeAnalyticsEvent(
            adobeAnalyticTags.solMigrationPassword.cta,
            'tileClick'
          )
        );

        return dispatch(migrateUser());
      }

      dispatch(
        sendAdobeAnalyticsEvent(
          adobeAnalyticTags.solMigrationEmail.cta,
          'tileClick'
        )
      );

      await dispatch(validateStep(setHeaderMenuLeft));

      return dispatch(
        sendAdobeAnalyticsEvent(adobeAnalyticTags.solMigrationPassword.pageLoad)
      );
    } catch (error) {
      if (error?.type === VALIDATION_ERROR) {
        if (
          !isLastFormStep &&
          error?.detail?.emailAddress ===
            lokaliseTags.SSO_FORMS_ERRORS_EMAIL_ADDRESS_IS_ALREADY_IN_USE
        ) {
          dispatch(showEmailAlreadyExistPopup());
        }

        const tag = !isLastFormStep
          ? 'solMigrationEmail'
          : 'solMigrationPassword';
        const errors = makeSelectFormErrors()(getState());
        const adobeAnalyticErrorTag = {
          ...adobeAnalyticTags[tag].pageLoad,
          errorMessage: isObject(errors)
            ? Object.values(errors).join('|')
            : errors,
        };
        return dispatch(
          sendAdobeAnalyticsEvent(adobeAnalyticErrorTag, 'error')
        );
      }
    }
  };
}

export function showEmailAlreadyExistPopup() {
  return (dispatch, getState) => {
    const { emailAddress } = makeSelectFormData()(getState());
    const popup = buildPopup({
      text: {
        key: lokaliseTags.SSO_MIGRATION_PROMPT_EMAIL_EXISTS,
        values: [emailAddress],
      },
      buttons: [
        {
          label: lokaliseTags.SSO_GENERAL_CANCEL_BUTTON,
          event: () => {
            dispatch(
              sendAdobeAnalyticsEvent(
                adobeAnalyticTags.solMigrationEmail.popupEmailExists
                  .cancelButton,
                'tileClick'
              )
            );
            dispatch(closePopup());
          },
          outlined: true,
        },
        {
          label: lokaliseTags.SSO_MIGRATION_CONFIRM_EMAIL_PROMPT_SIGN_IN,
          event: () => {
            dispatch(
              sendAdobeAnalyticsEvent(
                adobeAnalyticTags.solMigrationEmail.popupEmailExists
                  .signInButton,
                'tileClick'
              )
            );
            browserHistory.push(LOGIN_PATHNAME);
          },
        },
      ],
    });

    dispatch(showPopup(popup));

    dispatch(
      sendAdobeAnalyticsEvent(
        adobeAnalyticTags.solMigrationEmail.popupEmailExists.pageLoad
      )
    );
  };
}

function initializeMigrationForm() {
  return async (dispatch, getState) => {
    const query = makeSelectQuery()(getState());

    if (!query.user) {
      const redirectErrorData = {
        error: 'Missing user param from url',
      };
      return dispatch(redirectToCVP(redirectErrorData));
    }

    try {
      const migrationFormResponse = await getMigrationForm();
      const form = { ...migrationFormResponse };

      dispatch({
        type: MIGRATION_LOADED,
        form,
      });

      const migrationForm = {
        name: MIGRATION_PAGE,
        data: {
          form,
          user: { ...query.user },
        },
      };

      dispatch(setForm(migrationForm));
      dispatch(
        setFormData({
          ...makeSelectFormData()(getState()),
          emailAddress: query.user.profile.emailAddress.trim(),
        })
      );

      return dispatch(
        sendAdobeAnalyticsEvent(adobeAnalyticTags.solMigrationEmail.pageLoad)
      );
    } catch (error) {
      return dispatch(showDefaultErrorNotification());
    }
  };
}

function toggleConsent(name, value) {
  return (dispatch) => {
    let isChecked = value;
    if (Object.hasOwn(value, 'checked')) {
      isChecked = value.checked;
    }
    if (name === 'loyaltyOptIn' && !isChecked) {
      dispatch(
        showPopup(
          buildPopup({
            title: lokaliseTags.SSO_PROFILE_OFFERS_NOT_AVAILABLE,
            text: lokaliseTags.SSO_PROFILE_OFFERS_NOT_AVAILABLE_TEXT,
            buttons: [
              {
                label: lokaliseTags.SSO_PROFILE_TURN_OFF,
                event: () => {
                  dispatch(closePopup());
                  dispatch(onAttributeChange(`consents.${name}`, false));
                  dispatch(sendAdobeAnalyticsFromConsent(name, false));
                },
                outlined: true,
              },
              {
                label: lokaliseTags.SSO_PROFILE_TURN_ON,
                event: () => {
                  dispatch(closePopup());
                },
              },
            ],
          })
        )
      );
    } else {
      dispatch(onAttributeChange(`consents.${name}`, isChecked));
      dispatch(sendAdobeAnalyticsFromConsent(name, isChecked));
    }
  };
}

function sendAdobeAnalyticsFromConsent(name, isChecked) {
  return (dispatch) => {
    const names = {
      newsletters: 'newsletter',
      loyaltyOptIn: 'loyalty',
    };

    const states = {
      [true]: 'checked',
      [false]: 'unchecked',
    };

    dispatch(
      sendAdobeAnalyticsEvent(
        {
          ...adobeAnalyticTags.solMigrationEmail.toggledConsent,
          cta: adobeAnalyticTags.solMigrationEmail.toggledConsent.cta(
            names[name],
            states[isChecked]
          ),
        },
        'tileClick'
      )
    );
  };
}

function getCardType(cardId) {
  return async (_, getState) => {
    const data = {
      market: getState().app.query.market,
      cardId,
    };
    try {
      const { card_type } = await cardStatus(data);

      return card_type;
    } catch (error) {
      return null;
    }
  };
}

function migrateUser() {
  return async (dispatch, getState) => {
    try {
      await dispatch(validateForm(MIGRATION_PAGE));
      const state = getState();
      const redirect = makeSelectQueryRedirect()(state);
      const market = makeSelectQueryMarket()(state);
      const formData = cleanMigrationFormData(
        makeSelectFormData()(state),
        market
      );
      const partner = makeSelectQueryPartner()(state);
      let externalSystemIds = null;
      if (partner)
        externalSystemIds = makeSelectSettingsPartnersByClientId()(state);

      if (externalSystemIds)
        formData.externalSystemIds =
          transformPartnersDataFromCocoToExternalSystemId(externalSystemIds);

      if (redirect) formData.redirect = redirect;
      const attachMultipleCards = makeSelectCanAttachMultipleCards()(state);
      const cardId = formData.loyalty.cardId;

      // Generate loyalty.accounts with the correct cardType
      if (cardId) {
        const cardType = await dispatch(getCardType(cardId));
        if (cardType) {
          const { accounts } = createUserInitialLoyaltyProfileFromCard(
            { cardId, cardType },
            attachMultipleCards
          );
          if (accounts) {
            formData.loyalty.accounts = accounts;
          }
        }
      }

      dispatch({ type: SET_MIGRATION_DATA, data: formData });

      const migrateAccountResponse = await migrateAccount(formData);
      dispatch(
        saveCredentials({
          password: formData.newPassword,
          username: formData.profile.emailAddress,
        })
      );
      dispatch(migrationSuccess(migrateAccountResponse, formData));
    } catch (error) {
      if (error?.type === VALIDATION_ERROR) {
        const errors = makeSelectFormErrors()(getState());
        const adobeAnalyticErrorTag = {
          ...adobeAnalyticTags.solMigrationPassword.pageLoad,
          errorMessage: isObject(errors)
            ? Object.values(errors).join('|')
            : errors,
        };
        return dispatch(
          sendAdobeAnalyticsEvent(adobeAnalyticErrorTag, 'error')
        );
      }

      if (error.badRequest?.()) {
        return browserHistory.push(SOL_MIGRATION_USER_DATA_VALIDATION_PATHNAME);
      }

      if (error.deviceNotAllowed?.()) {
        dispatch(
          showNotification(
            buildNotification({
              animation: ConeAnimation,
              title: lokaliseTags.SSO_REGISTER_DEVICE_CANNOT_REGISTER,
              text: lokaliseTags.SSO_REGISTER_DEVICE_CANNOT_REGISTER_TEXT,
              buttons: [
                {
                  label: lokaliseTags.SSO_GENERAL_TRY_AGAIN,
                },
              ],
            })
          )
        );
        return browserHistory.push(LOGIN_PATHNAME);
      }

      return dispatch(
        showNotification(
          buildNotification({
            animation: ConeAnimation,
            title: lokaliseTags.SSO_LOYALTY_MIGRATION_FAILED,
            text: lokaliseTags.SSO_ERROR_GENERIC_JANRAIN_MIGRATION_FAILED_TEXT,
            closeAction: () => {
              dispatch(redirectToCVP());
            },
            buttons: [
              {
                label: lokaliseTags.SSO_GENERAL_TRY_AGAIN,
                event: () => {
                  browserHistory.push(LOGIN_PATHNAME);
                },
              },
            ],
          })
        )
      );
    }
  };
}

function migrationSuccess(migrateAccountResponse, migrationData) {
  return (dispatch, getState) => {
    const isMotoristApp = makeSelectIsMotoristApp()(getState());

    if (!get(migrateAccountResponse, 'loyalty.activated', false)) {
      dispatch(
        verifyLoyalty(
          {
            uuid:
              get(migrateAccountResponse, 'uuid.uuid', false) ||
              get(migrateAccountResponse, 'uuid', false),
          },
          null,
          true
        )
      );
      dispatch(setIsRegisterFlow(true));
    }
    deleteStorage('profile');
    dispatch(verifyEmailChangeActions.setUUID(migrateAccountResponse.uuid));
    dispatch(setEmailToVerify(migrationData.profile.emailAddress));
    if (isMotoristApp) {
      const redirectData = {
        url: SDA_MIGRATION_APP_PATHNAME,
      };
      dispatch(redirectToCVP(redirectData));
    }
    browserHistory.push(SECURITY_UPDATE_CONFIRM_EMAIL_PATHNAME);
    dispatch(resetForm());
  };
}
export function initializeMigrationUserDataValidation(setHeader) {
  return async (dispatch, getState) => {
    setHeader({
      menu: {
        leftMenu: {
          name: lokaliseTags.SSO_GENERAL_CLOSE,
          icon: 'back',
          mobile: true,
          desktop: false,
          event: () => {
            dispatch({ type: TOGGLE_MIGRATION_LOADED, loaded: false });
            browserHistory.back();
          },
        },
      },
    });

    try {
      dispatch(setSpinner(true));
      const migrationValidationForm = await getMigrationValidationForm();

      const solMigrationValidationForm = {
        name: SOL_CARD_VALIDATION_PAGE,
        data: {
          errors: {},
          form: migrationValidationForm,
        },
      };

      dispatch(setForm(solMigrationValidationForm));

      const migrationData = makeSelectMigrationData()(getState());

      dispatch(
        setFormData({
          ...migrationData?.profile,
          ...migrationData?.profile?.primaryAddress,
        })
      );

      dispatch(validateForm(SOL_CARD_VALIDATION_PAGE));

      dispatch(setSpinner(false));
    } catch (error) {
      if (error?.type === VALIDATION_ERROR) {
        return;
      }
      dispatch(showDefaultErrorNotification());
    }
  };
}

export function handleMigrationUserDataValidationSubmit() {
  return async (dispatch, getState) => {
    try {
      await dispatch(validateForm(SOL_CARD_VALIDATION_PAGE));
      const state = getState();
      const storedData = makeSelectMigrationData()(state);
      const redirect = makeSelectQueryRedirect()(state);
      const formData = makeSelectFormDataBackendFormated(
        SOL_CARD_VALIDATION_PAGE
      )(state);
      const partner = makeSelectQueryPartner()(state);

      let externalSystemIds = null;
      if (partner)
        externalSystemIds = makeSelectSettingsPartnersByClientId()(state);

      const migrationData = {
        ...storedData,
        profile: {
          ...storedData.profile,
          ...formData,
          primaryAddress: {
            ...storedData.profile.primaryAddress,
            ...formData.primaryAddress,
          },
        },
      };
      if (redirect) migrationData.redirect = redirect;

      if (externalSystemIds)
        migrationData.externalSystemIds =
          transformPartnersDataFromCocoToExternalSystemId(externalSystemIds);

      const migrationAccountResponse = await migrateAccount(migrationData);
      dispatch(
        saveCredentials({
          password: migrationData.newPassword,
          username: migrationData.profile.emailAddress,
        })
      );
      dispatch(migrationSuccess(migrationAccountResponse, migrationData));
    } catch (error) {
      if (error?.type === VALIDATION_ERROR) {
        return;
      }
      dispatch(showDefaultErrorNotification());
    }
  };
}

export default {
  initializeSecurityUpdate,
  initializeMigrationForm,
  initializeHeaderMigration,
  toggleConsent,
  migrateUser,
  previousStepSecurityUpdate,
  handleMigrationSubmit,
};
