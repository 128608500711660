import { RESET_PASSWORD_VALUE_CHANGE } from './constants';

export const initialState = {
  data: {
    newPassword: '',
  },
  form: {},
};

const resetPassword = (state = initialState, action) => {
  const { type, name, value } = action;

  if (type === RESET_PASSWORD_VALUE_CHANGE) {
    return { ...state, [name]: value };
  }

  return state;
};

export default resetPassword;
