import lokaliseTags from '../lokaliseTags';
import { parseDateToDate, Utf8Decode } from '../utils';

export const initialDriveData = {
  displayName: false,
  driverType: [],
  euroShellCardHolder: false,
  emailAddress: null,
  migrationOption: 'MIGRATE',
};

export const initialPaymentsData = {
  eReceiptYn: null,
  emailAddress: null,
  migrationOption: 'MIGRATE',
};

export const initialMyGarageData = {
  migrationOption: 'MIGRATE',
};

export function getDriveDataFromAppData(profile, drive, myGarage) {
  return {
    profile: {
      ...profile,
      emailAddress: drive.emailAddress || profile.emailAddress,
    },
    drive: {
      ...initialDriveData,
      ...drive,
    },
    myGarage: {
      ...initialMyGarageData,
      ...myGarage,
    },
  };
}

export function getPaymentsDataFromAppData(profile, payments) {
  return {
    profile: {
      ...profile,
      emailAddress: payments.emailAddress || profile.emailAddress,
    },
    payments: {
      ...initialPaymentsData,
      ...payments,
    },
  };
}

export function getBirthDate(birthDate) {
  if (birthDate && birthDate.length === 8) {
    return parseDateToDate(birthDate, 'yyyymmdd', 'yyyy-mm-dd');
  }
  return birthDate || null;
}

export function getGender(gender) {
  const formattedGender = Utf8Decode(gender);
  if (
    formattedGender &&
    (formattedGender.toString() === '1' ||
      formattedGender === 'male' ||
      formattedGender === lokaliseTags.SSO_GENDER_MALE)
  ) {
    return lokaliseTags.SSO_GENDER_MALE;
  } else if (
    formattedGender &&
    (formattedGender.toString() === '2' ||
      formattedGender === 'female' ||
      formattedGender === lokaliseTags.SSO_GENDER_FEMALE)
  ) {
    return lokaliseTags.SSO_GENDER_FEMALE;
  } else if (!formattedGender) {
    return null;
  }
  return lokaliseTags.SSO_GENDER_UNDISCLOSED;
}
