export function createUserInitialLoyaltyProfileFromCard(
  { cardId, cardType, migrationOption },
  shouldAttachAccounts = false
) {
  const loyaltyProfile = {
    activated: true,
    cardStatus: 'UNVERIFIED',
    migrationOption: migrationOption ?? 'MIGRATE',
    cardId,
  };

  if (shouldAttachAccounts && cardId && cardType) {
    loyaltyProfile.accounts = [
      {
        accountProvider: 'SOL',
        status: 'ENABLED',
        card: [
          {
            cardId,
            cardType,
            cardEnabled: true,
          },
        ],
      },
    ];
  }

  return loyaltyProfile;
}
