import { createSelector } from 'reselect';
import { getCountryFromMarket } from '../../shared/utils';
import {
  GO_PLUS_CVP,
  FR_CVP,
  SOL_CVP,
  PARTNER_FIRST_UTILITY_CVP,
  MOTORIST_FIREBLADE_CVP,
  MOTORIST_FIREBLADE_AMERICA_CVP,
  LOYALTY_FUTURES_CVP,
  MOTORIST_CVP,
  SAP_HYBRIS_CVP,
} from '../../shared/cvps';
import { ENV, NUMBERS } from '../../shared/constants';
import { LOYALTY_SERVICE } from '../../shared/formServices';
import { makeSelectSettingsRedirect } from '../settings/selectors';

/**
 * Direct selector to the app state domain
 */
const selectAppDomain = () => (state) => state.app;

const makeSelectQuery = () =>
  createSelector(
    selectAppDomain(),
    makeSelectSettingsRedirect(),
    (app, settingsRedirect) => {
      const query = app.query;

      if (!query.redirect && settingsRedirect) {
        query.redirect = settingsRedirect.replace(
          '{{lang}}',
          query?.market?.split('-')[0]
        );
      }

      return query;
    }
  );

const makeSelectQueryMarket = () =>
  createSelector(makeSelectQuery(), (query) => query.market);

const makeSelectQueryUser = () =>
  createSelector(makeSelectQuery(), (query) => query.user);

const makeSelectQueryCountry = () =>
  createSelector(makeSelectQuery(), (query) =>
    getCountryFromMarket(query.market)
  );

const makeSelectQueryMgm = () =>
  createSelector(makeSelectQuery(), (query) => query.mgm);

const makeSelectIsAppLoading = () =>
  createSelector(selectAppDomain(), (app) => app.loading);

const makeSelectIsUserLogged = () =>
  createSelector(makeSelectQuery(), (query) => !!query.accessToken);

const makeSelectCVP = () => createSelector(selectAppDomain(), (app) => app.cvp);

const makeSelectIsGoPlusCVP = () =>
  createSelector(selectAppDomain(), (app) => app.cvp === GO_PLUS_CVP);

const makeSelectIsPartnerCVP = () =>
  createSelector(
    selectAppDomain(),
    (app) => app.cvp === PARTNER_FIRST_UTILITY_CVP
  );

const makeSelectIsSolCVP = () =>
  createSelector(selectAppDomain(), (app) => app.cvp === SOL_CVP);

const makeSelectIsFRCVP = () =>
  createSelector(selectAppDomain(), (app) => app.cvp === FR_CVP);

const makeSelectIsDriveCVP = () =>
  createSelector(selectAppDomain(), (app) => app.cvp === MOTORIST_CVP);

const makeSelectIsMotoristFirebladeCVP = () =>
  createSelector(
    selectAppDomain(),
    (app) =>
      [MOTORIST_FIREBLADE_CVP, MOTORIST_FIREBLADE_AMERICA_CVP].indexOf(
        app.cvp
      ) !== -1
  );

const makeSelectIsLoyaltyFuturesCVP = () =>
  createSelector(selectAppDomain(), (app) => app.cvp === LOYALTY_FUTURES_CVP);

const makeSelectIsSAPHybrisCVP = () =>
  createSelector(selectAppDomain(), (app) => app.cvp === SAP_HYBRIS_CVP);

const makeSelectHasMigrationTokenExpiredQueryError = () =>
  createSelector(
    makeSelectQuery(),
    (query) => query.tokenStatus === 'migration_token_invalid'
  );

const makeSelectIsMotoristApp = () =>
  createSelector(
    makeSelectQuery(),
    makeSelectIsMotoristFirebladeCVP(),
    (query, isMotoristFireBladeCVP) =>
      parseInt(query.webview, 10) === 1 || isMotoristFireBladeCVP
  );

const makeSelectIsPartner = () =>
  createSelector(
    selectAppDomain(),
    (app) => app.cvp.substring(0, NUMBERS.EIGHT) === 'partner_'
  );

const makeSelectAccessToken = () =>
  createSelector(makeSelectQuery(), (query) => query.accessToken);

const makeSelectQueryRedirect = () =>
  createSelector(makeSelectQuery(), (query) => query.redirect);

const makeSelectQueryPartner = () =>
  createSelector(makeSelectQuery(), (query) => query.partner);

const makeSelectIsTranslationsLoaded = () =>
  createSelector(
    selectAppDomain(),
    (app) => Object.keys(app.translations).length > 0
  );

const makeSelectQueryAuthCode = () =>
  createSelector(makeSelectQuery(), (query) => query.authCode);

const makeSelectIsLoyaltyService = () =>
  createSelector(
    makeSelectQuery(),
    (query) => query.service === LOYALTY_SERVICE
  );

const makeSelectQueryScopes = () =>
  createSelector(makeSelectQuery(), (query) =>
    query.scopes ? query.scopes : []
  );

const makeSelectQueryClientId = () =>
  createSelector(makeSelectQuery(), (query) => query.clientId);

const makeSelectRegistrationActivationStatus = () =>
  createSelector(makeSelectQuery(), (query) => query.status);

const makeSelectQueryLock = () =>
  createSelector(makeSelectQuery(), (query) => query.lock);

const makeSelectQueryAccessCode = () =>
  createSelector(makeSelectQuery(), (query) => query.accessCode);

const makeSelectIsAutomation = () =>
  createSelector(
    makeSelectQuery(),
    (query) => query.automation === 'true' && ENV !== 'PROD'
  );

const makeSelectTranslations = () =>
  createSelector(selectAppDomain(), (app) => app.translations);

const makeSelectMarketType = () =>
  createSelector(makeSelectQuery(), (query) => query.marketType);

export {
  selectAppDomain,
  makeSelectQuery,
  makeSelectCVP,
  makeSelectIsAppLoading,
  makeSelectQueryCountry,
  makeSelectQueryMarket,
  makeSelectIsUserLogged,
  makeSelectIsGoPlusCVP,
  makeSelectIsSAPHybrisCVP,
  makeSelectIsFRCVP,
  makeSelectIsDriveCVP,
  makeSelectHasMigrationTokenExpiredQueryError,
  makeSelectIsMotoristApp,
  makeSelectIsLoyaltyFuturesCVP,
  makeSelectIsSolCVP,
  makeSelectIsMotoristFirebladeCVP,
  makeSelectAccessToken,
  makeSelectQueryRedirect,
  makeSelectQueryAuthCode,
  makeSelectIsTranslationsLoaded,
  makeSelectIsLoyaltyService,
  makeSelectQueryClientId,
  makeSelectRegistrationActivationStatus,
  makeSelectQueryLock,
  makeSelectQueryAccessCode,
  makeSelectIsPartnerCVP,
  makeSelectQueryUser,
  makeSelectIsAutomation,
  makeSelectTranslations,
  makeSelectMarketType,
  makeSelectQueryMgm,
  makeSelectQueryPartner,
  makeSelectIsPartner,
  makeSelectQueryScopes,
};
