import { makeSelectFormPageName } from '../dynamic-form/selectors';
import {
  REGISTER_PAGE,
  MIGRATION_PAGE,
  LOGIN_PAGE,
} from '../../shared/formPages';
import {
  REGISTER_COMMUNICATIONS_PATHNAME,
  SECURITY_UPDATE_PASSWORD_PATHNAME,
  LOGIN_PATHNAME,
  WELCOME_GO_PLUS_PATHNAME,
  SETUP_WEB_GOPLUS_COMMS,
  PROFILE_PATHNAME,
  PROGRESSIVE_PROFILING_DYNAMICFORM,
} from '../../shared/pathnames';
import { makeSelectIsMobile } from '../browser/selectors';
import {
  makeSelectIsGoPlusActivated,
  makeSelectIsGoplusMarketingConsentEnabled,
} from '../profile/selectors';
import {
  redirectToCVP,
  setQuery,
  sendAdobeAnalyticsEvent,
  setSpinner,
} from '../app/actions';
import { makeSelectIsGoPlusActivatedInSettings } from '../settings/selectors';
import browserHistory from '../../router/history';
import { transitionToGoPlusWeb } from '../goplus/welcome-go-plus/actions';
import { makeSelectIsGoPlusFlow } from '../goplus/lod-attach-consents/selectors';
import {
  makeSelectIsGoPlusCVP,
  makeSelectIsPartnerCVP,
  makeSelectQuery,
  makeSelectQueryCountry,
} from '../app/selectors';
import { exchangeAccessCode } from '@shell-b2c/http-frontend/dist/src/components/core/auth';
import { loadUserProfile } from '../profile/actions';
import { showDefaultErrorNotification } from '../notification/actions';
import adobeAnalyticTags from '../../shared/adobeAnalyticTags';
import { getTermsAndConditionsAndPrivacyPolicy } from '../settings/actions';
import { DEFAULT_HEADER } from './headers';
import lokaliseTags from '../../shared/lokaliseTags';
import { createUser } from '../register-add-communications/utils';
import { makeSelectIsPPFlow } from '../progressive-profiling/selectors';
import { deleteStorage, getStoredData } from '../app/utils';
import { logout } from '../logout/actions';

// TERMS AND PRIVACY (Separated - used in flows)
// ----------------------------------------------------------------------
export function initializeLegal(contentType, setHeader) {
  const TERMS_PATH = 'terms';
  return (dispatch) => {
    const header = DEFAULT_HEADER;
    header.menu.leftMenu.event = () => {
      browserHistory.back();
      if (contentType === TERMS_PATH) {
        dispatch(
          sendAdobeAnalyticsEvent(
            adobeAnalyticTags.termsAndConditionsPage.backButton,
            'tileClick'
          )
        );
      } else {
        dispatch(
          sendAdobeAnalyticsEvent(
            adobeAnalyticTags.privacyPolicyPage.backButton,
            'tileClick'
          )
        );
      }
    };
    if (contentType === TERMS_PATH) {
      dispatch(
        sendAdobeAnalyticsEvent(
          adobeAnalyticTags.termsAndConditionsPage.pageLoad,
          'VPV'
        )
      );
    } else {
      dispatch(
        sendAdobeAnalyticsEvent(
          adobeAnalyticTags.privacyPolicyPage.pageLoad,
          'VPV'
        )
      );
    }
    setHeader(header);
    dispatch(getTermsAndConditionsAndPrivacyPolicy());
  };
}

const registerFormPages = [REGISTER_PAGE];
const formPages = [MIGRATION_PAGE, LOGIN_PAGE, ...registerFormPages];

export const setHeaders = (setHeader) => {
  return (dispatch, getState) => {
    const isMobile = makeSelectIsMobile()(getState());
    const isPartnerCVP = makeSelectIsPartnerCVP()(getState());
    const fromPP = makeSelectIsPPFlow()(getState());
    const isGoPlusCVP = makeSelectIsGoPlusCVP()(getState());

    if (isMobile || fromPP) {
      dispatch(setMobileHeader(setHeader, fromPP));
      return;
    }

    const isGoPlusFlow = makeSelectIsGoPlusFlow()(getState());
    let desktopHeader = {
      menu: {
        rightMenu: {
          name: '',
          icon: '',
          mobile: false,
          desktop: false,
        },
      },
    };
    if ((!fromPP && isGoPlusCVP && isGoPlusFlow) || isPartnerCVP) {
      const desktopHeader = {
        menu: {
          rightMenu: {
            name: lokaliseTags.SSO_GENERAL_CLOSE,
            icon: 'close',
            mobile: false,
            desktop: true,
          },
        },
      };
      desktopHeader.menu.rightMenu.event = () => {
        const redirect = getState().app.query.redirect;
        if (redirect) {
          dispatch(redirectToCVP());
        } else {
          dispatch(transitionToGoPlusWeb());
        }
      };
    }

    setHeader(desktopHeader);
  };
};

const controlTokens = () => {
  return async (dispatch, getState) => {
    const { accessToken, accessCode } = makeSelectQuery()(getState());
    if (accessCode && !accessToken) {
      await dispatch(updateAccessToken(accessCode));
    }
  };
};

// TERMS AND PRIVACY (Unified - used in register)
// ----------------------------------------------------------------------
export function initialize(setHeader) {
  return async (dispatch, getState) => {
    const fromPP = makeSelectIsPPFlow()(getState());
    const formPage = makeSelectFormPageName()(getState());
    const isGoPlusActivatedInSettings = makeSelectIsGoPlusActivatedInSettings()(
      getState()
    );
    const isGoPlusActivatedInProfile = makeSelectIsGoPlusActivated()(
      getState()
    );
    const isGoPlusCVP = makeSelectIsGoPlusCVP()(getState());

    try {
      dispatch(getTermsAndConditionsAndPrivacyPolicy());

      await dispatch(controlTokens());

      if (getStoredData('ppFlow') === 'enabled' && !fromPP) {
        dispatch(setSpinner(true));
        deleteStorage('ppFlow');
        return dispatch(logout(true, false, { doRedirectToCVP: true }));
      }

      const formPageIsIncluded = formPages.includes(formPage);
      const goPlusSettings =
        isGoPlusActivatedInProfile ||
        (isGoPlusCVP && !isGoPlusActivatedInSettings);

      const conditionToGoToHome =
        !formPageIsIncluded && !fromPP && goPlusSettings;

      if (conditionToGoToHome) {
        browserHistory.replace('/');
        return;
      }

      if (registerFormPages.includes(formPage)) {
        dispatch(
          sendAdobeAnalyticsEvent(
            adobeAnalyticTags[REGISTER_PAGE].termsAndConditions
          )
        );
      } else if (fromPP) {
        dispatch(
          sendAdobeAnalyticsEvent(
            adobeAnalyticTags.progressiveProfiling.terms.pageLoad
          )
        );
      } else if (formPage === LOGIN_PAGE) {
        dispatch(
          sendAdobeAnalyticsEvent(
            adobeAnalyticTags.termsAndConditionsNoLoyaltyMigration
              .enterTcAndPpPage
          )
        );
      }

      dispatch(setHeaders(setHeader));
    } catch (error) {
      dispatch(
        showDefaultErrorNotification(() => {
          dispatch(redirectToCVP());
        }, lokaliseTags.SSO_ERROR_GENERIC_JANRAIN)
      );
    }
  };
}

export function setMobileHeader(setHeader, fromPP = false) {
  return (_, getState) => {
    const formPage = makeSelectFormPageName()(getState());
    const isGoPlusActivatedInSettings = makeSelectIsGoPlusActivatedInSettings()(
      getState()
    );
    const isFromRegister = formPage === REGISTER_PAGE;

    const header = {
      menu: {
        leftMenu: {
          name: lokaliseTags.SSO_GENERAL_BACK,
          icon: 'back',
          mobile: true,
          desktop: false,
          event: () => {
            if (formPage === REGISTER_PAGE) {
              browserHistory.back();
              sendAdobeAnalyticsEvent(
                adobeAnalyticTags[REGISTER_PAGE].termsAndConditionsBackArrow,
                'tileClick'
              );
            } else if (formPage === MIGRATION_PAGE) {
              browserHistory.push(SECURITY_UPDATE_PASSWORD_PATHNAME);
            } else if (isGoPlusActivatedInSettings) {
              const redirect = getState().app.query.redirect;
              browserHistory.push(redirect ? LOGIN_PATHNAME : PROFILE_PATHNAME);
            } else if (fromPP) {
              browserHistory.push(PROGRESSIVE_PROFILING_DYNAMICFORM);
            } else if (formPage === LOGIN_PAGE) {
              browserHistory.back();
            } else {
              browserHistory.push('/');
            }
          },
        },
      },
    };
    if (!isFromRegister)
      header.rightMenu = {
        name: lokaliseTags.SSO_GENERAL_CLOSE,
        icon: 'close',
        mobile: false,
        desktop: true,
        event: () => {
          browserHistory.push(PROGRESSIVE_PROFILING_DYNAMICFORM);
        },
      };
    setHeader(header);
  };
}

function updateAccessToken(accessCode) {
  return async (dispatch) => {
    const exchangeAccessCodeResponse = await exchangeAccessCode({
      code: accessCode,
    });

    if (exchangeAccessCodeResponse.accessToken) {
      const newTokens = {
        accessToken: exchangeAccessCodeResponse.accessToken,
        refreshToken: exchangeAccessCodeResponse.refreshToken,
        [accessCode]: true, // Mark as used so no exchange in handleUserProfile
      };
      dispatch(setQuery(newTokens));
      dispatch(loadUserProfile({ ...exchangeAccessCodeResponse }));
    }
  };
}
export function transitionToCommunications() {
  return (dispatch, getState) => {
    const fromPP = makeSelectIsPPFlow()(getState());
    const isGoPlusActivatedInSettings = makeSelectIsGoPlusActivatedInSettings()(
      getState()
    );
    const isGoPlusActivatedInProfile = makeSelectIsGoPlusActivated()(
      getState()
    );

    const isPartnerCVP = makeSelectIsPartnerCVP()(getState());
    const formPage = makeSelectFormPageName()(getState());
    if (formPage === REGISTER_PAGE || fromPP) {
      const country = makeSelectQueryCountry()(getState());
      if (fromPP) {
        dispatch(
          sendAdobeAnalyticsEvent(
            adobeAnalyticTags.progressiveProfiling.terms.clickNext
          )
        );
      }
      if ((country !== 'CH' && country !== 'TR') || fromPP) {
        browserHistory.push(REGISTER_COMMUNICATIONS_PATHNAME);
      } else dispatch(createUser());
    } else if (
      (isGoPlusActivatedInSettings || isPartnerCVP) &&
      !isGoPlusActivatedInProfile
    ) {
      const isGoPlusCommunicationConsentsAccepted =
        makeSelectIsGoplusMarketingConsentEnabled()(getState());
      if (isGoPlusCommunicationConsentsAccepted) {
        browserHistory.push(WELCOME_GO_PLUS_PATHNAME);
      } else {
        browserHistory.push(SETUP_WEB_GOPLUS_COMMS);
      }
    } else if (
      (isGoPlusActivatedInSettings || isPartnerCVP) &&
      isGoPlusActivatedInProfile
    ) {
      dispatch(redirectToCVP());
    } else if (formPage === LOGIN_PAGE) {
      browserHistory.back();
    } else {
      browserHistory.push('/');
    }
  };
}
