export const EMPTY = -1;
export const INVALID_KEY = -2;
export const EMPTY_CARD_ID = -3;
export const INVALID_CARD_ID = -4;
export const TOO_SHORT_CARD_ID = -5;
export const INVALID_CARD_PAN = -6;
export const NO_USER = -7;
export const INVALID_CARD_ID_LENGTH = -8;
export const EMPTY_PASSWORD = -9;
export const INVALID_PASSWORD = -10;
export const THERE_IS_NO_USER_WITH_THIS_PASSWORD = -11;
export const EMPTY_LANG_CODE = -12;
export const INVALID_LANG_CODE = -13;
export const THERE_IS_NO_SITE_LANGUAGE_WITH_THIS_LANG_CODE = -14;
export const EMPTY_COUNTRY_CODE = -15;
export const INVALID_COUNTRY_CODE = -16;
export const THERE_IS_NO_SITE_WITH_THIS_COUNTRY_CODE = -17;
export const EMPTY_TITLE = -18;
export const INVALID_TITLE = -19;
export const EMPTY_FAMILY_NAME = -20;
export const INVALID_FAMILY_NAME = -21;
export const EMPTY_FIRST_NAME = -22;
export const INVALID_FIRST_NAME = -23;
export const EMPTY_COMMUNICATION_OPTIN_STATUS = -24;
export const INVALID_COMMUNICATION_OPTIN_STATUS = -25;
export const MISSING_VERSION = -26;
export const UNSUPPORTED_VERSION = -27;
export const INVALID_USER_ID = -30;
export const CARD_BLOCKED = -32;
export const CARD_DISABLED = -33;
export const THERE_IS_NO_USER_DETAIL_BASE_DATA = -100;
export const THERE_IS_NO_REGISTERED_MOBILE_USER = -200;
export const TOO_MANY_REGISTERED_MOBILE_USER = -201;
export const THERE_IS_NO_SOL_USER_PERS_DATA = -202;
export const TOO_MANY_SOL_USER_PERS_DATA = -203;
export const EMPTY_OLD_PASSWORD = -300;
export const THERE_IS_NO_USER_WITH_THIS_OLD_PASSWORD = -301;
export const THERE_IS_NO_USER_WITH_THIS_OLD_EMAIL = -301;
export const EMPTY_NEW_PASSWORD = -302;
export const EMPTY_DOB = -400;
export const INVALID_DOB = -401;
export const THERE_IS_NO_USER_WITH_THIS_CARD_NUMBER = -402;
export const USER_HAS_ANOTHER_BIRTHDATE = -403;
export const EMPTY_EMAIL_ADDRESS = -600;
export const INVALID_EMAIL_ADDRESS = -601;
export const EMPTY_REMINDER_QUESTION_ANSWERS = -602;
export const THIS_IS_NOT_A_POSSIBLE_SOL_CARD_NUMBER = -603;
export const SOL_REGISTRATION_BELONGS_TO_THIS_CARD_NUMBER = -604;

export const EMPTY_ID_IN_ANSWERS_JSON_ARRAY = -605;
export const EMPTY_ANSWER_IN_ANSWERS_JSON_ARRAY = -606;
export const PARSE_ERROR_IN_ANSWERS_JSON_ARRAY = -607;
export const LESS_ANSWERS_THAN_STORED_IN_SOL = -700;
export const ANSWER_ID_IS_NOT_AMONG_STORED_IN_SOL = -701;
export const ANSWER_DOES_NOT_EQUAL_TO_STORED_IN_SOL = -702;
export const USER_HAS_NO_CARD_BALANCE_IN_SOL = -703;
export const GIFT_IS_NOT_ALLOWED_FOR_MOBILE_PARTNER = -1305;
export const THERE_IS_NO_VALID_PRODUCT_WITH_THIS_CODE = -1301;
export const USER_DOES_NOT_HAVE_ENOUGH_POINTS = -1302;
export const AIRMILES_LOGIN_ERROR = -1400;
export const CARD_ALREADY_MIGRATED_TO_SSO = -2501;
export const CARD_NON_PERSONALISED_BUT_NOT_REGISTERED = -2502;
export const CARD_PERSONALIZED_BUT_NOT_REGISTERED = -2503;
export const CARD_NOT_FOUND = -2504;
export const DISALLOWED_CARD_TYPE = -2505;
export const CARD_ADAC_PERSONALIZED_NOT_REGISTERED = -2506;
export const CARD_PAYONSITE_NOT_PAID = -2507;
export const ADAC_MEMBERSHIP_NOT_VALID = -2508;
export const ACTIVATION_ERROR = -2550;
export const RESPONSE_STATUS_ERROR_SOL_REGISTERED_CARD = -9997;
export const RESPONSE_STATUS_ERROR_PERSONALIZED_BUT_NOT_SOL_REGISTERED_CARD =
  -9998;
export const RESPONSE_STATUS_ERROR_NOT_PERSONALIZED_CARD = -9999;
export const GENERAL_ERROR = -10000;
export const GENERAL_ERROR_WITH_MESSAGE = -10001;
