// URL card types (Params)
export const NOT_PERSONALIZED_CARD_TYPE = 'notPersonalized';
export const PERSONALIZED_CARD_TYPE = 'personalized';
export const DIGITAL_CARD_TYPE = 'digital';

// Card Types
export const DIGITAL_CARD = 'DIGITAL';
export const PERSONALIZED_CARD = 'PERSONALIZED';
export const NOT_PERSONALIZED_CARD = 'NOT_PERSONALIZED';
export const REGISTERED_CARD = 'REGISTERED';
export const PLASTIC_CARD = 'PLASTIC';

// Flows
export const ATTACH_FLOW = 'attach';
export const SIGN_IN_FLOW = 'signIn';
export const MIGRATION_FLOW = 'migration';
