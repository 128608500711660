import React, { useEffect } from 'react';
import { Snackbar as Snack } from '@shell-b2c/web-design-system';
import { useTimeout } from '../hooks/useTimeout';

export function Snackbar({
  open,
  setOpen,
  message,
  time = 6000,
  onHandleClose = () => {},
  setZindex = false,
}) {
  const { start } = useTimeout(() => setOpen(false), time);
  useEffect(() => {
    if (open) {
      start();
    }
  }, [open, start]);
  return (
    <div style={setZindex ? { zIndex: '10000001', position: 'relative' } : {}}>
      <Snack
        open={open}
        message={message}
        horizontalAlignment={'left'}
        onHandleClose={onHandleClose}
      />
    </div>
  );
}
export default Snackbar;
