import React from 'react';
import Styling from './style';
import { Text } from '@shell-b2c/web-design-system';
import { RED, VERY_DARK_GREY, MID_GREY } from '../../shared/colors';
import { NUMBERS } from '../../shared/constants';

const PopupContent = ({ popup, translate }) => {
  const renderAnimation = () => {
    const { animation } = popup;
    const hasAnimation = animation?.component;
    if (!hasAnimation) return null;

    const Animation = animation?.component;
    const width = animation?.width || NUMBERS.SEVENTY_FIVE;
    const height = animation?.height || NUMBERS.SEVENTY_FIVE;

    return (
      <div
        style={{ ...Styling.popupImage, ...(animation?.containerStyle || {}) }}
      >
        <Animation width={width} height={height} />
      </div>
    );
  };

  const renderImage = () => {
    const { image } = popup;
    if (image === undefined) {
      return null;
    }

    if (React.isValidElement(image)) {
      return <div>{image}</div>;
    } else if (typeof image === 'function') {
      const Image = image;
      return (
        <div>
          <Image />
        </div>
      );
    } else {
      return (
        <div>
          <img src={image} alt="" />
        </div>
      );
    }
  };

  const renderText = () => {
    const { message } = popup;
    if (message && message.title !== '' && message.text !== '') {
      return (
        <span style={message.containerStyle}>
          {message.title && (
            <Text
              variant="h6"
              style={{
                ...Styles.popupTitle,
                ...(message.titleStyle || {}),
              }}
              gutterBottom
            >
              {translate(message.title)}
            </Text>
          )}
          {message.text && (
            <Text
              style={{
                ...Styles.popupMessage,
                ...(message.textStyle || {}),
              }}
              gutterBottom
            >
              {translate(message.text)}
            </Text>
          )}
          {message.note && (
            <Text
              variant="body2"
              style={{
                ...Styles.popupNote,
                ...(message.noteStyle || {}),
              }}
            >
              {translate(message.note)}
            </Text>
          )}
        </span>
      );
    }
    return <span />;
  };

  const backgroundStyle = popup.contentWrapper || {};

  return (
    <div style={{ ...Styling.contentWrapper, ...backgroundStyle }}>
      <div>
        {renderAnimation()}
        {renderImage()}
      </div>
      <div style={Styling.contentStyle}>{renderText()}</div>
    </div>
  );
};

const Styles = {
  popupTitle: {
    color: RED,
  },
  popupMessage: {
    color: VERY_DARK_GREY,
  },
  popupNote: {
    color: MID_GREY,
  },
};

export default PopupContent;
