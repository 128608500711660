import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Styling from './style';
import PopupHeader from './PopupHeader';
import PopupContent from './PopupContent';
import PopupButtons from './PopupButtons';
import PopupActions from './actions';

export function Popup(props) {
  const { translate, popupActions } = props;
  const { popup } = props.popup;

  useEffect(() => {
    toggleNotificationClass();
    return () => {
      toggleNotificationClass();
    };
  }, []);

  return (
    <div style={Styling.fade}>
      <div style={Styling.centered}>
        <div style={{ ...Styling.wrapper, ...(popup.wrapperStyle || {}) }}>
          <div style={Styling.popupSize}>
            <PopupHeader popup={popup} translate={translate} />
            <PopupContent
              popup={popup}
              popupActions={popupActions}
              translate={translate}
            />
            <PopupButtons
              popup={popup}
              popupActions={popupActions}
              translate={translate}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function toggleNotificationClass() {
  const element = document.querySelector('#main-container');
  if ('classList' in document.body && element) {
    element.classList.toggle('popup-visible');
  }
}

Popup.propTypes = {
  translate: PropTypes.func.isRequired,
  popup: PropTypes.object.isRequired,
  popupActions: PropTypes.object.isRequired,
};

const mapStateToProps = (store) => ({
  popup: store.popup,
});

const mapDispatchToProps = (dispatch) => ({
  popupActions: bindActionCreators(PopupActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Popup);
