import {
  MIGRATION_LOADED,
  RESET_MIGRATION,
  SET_MIGRATION_DATA,
  TOGGLE_MIGRATION_LOADED,
} from './constants';

export const initialState = {
  user: {
    firstName: '',
    lastName: '',
    emailAddress: '',
    gender: '',
    newPassword: '',
    securityQuestion: '',
    securityAnswer: '',
  },
  confirmation: {},
  form: {
    properties: [
      {
        name: 'emailAddress',
        type: 'email',
        validations: [
          {
            message: 'email_address_is_already_in_use',
            value: true,
            rule: 'unique',
          },
          {
            message: 'email_address_cannot_be_more_than_128_characters',
            value: 128,
            rule: 'maxLength',
          },
          {
            message: 'email_address_is_required',
            value: true,
            rule: 'required',
          },
          {
            message: 'email_address_must_be_at_least_3_characters',
            value: 3,
            rule: 'minLength',
          },
          {
            message: 'email_address_is_not_formatted_correctly',
            value: {
              pattern:
                "(?:[a-zA-Z0-9!#$%&'*+\\/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&'*+\\/=?^_`{|}~-]+)*|\"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*')@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-zA-Z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])",
              flags: '',
            },
            rule: 'regex',
          },
        ],
        label: 'email_address',
        tip: 'fields_email_address_tip',
        required: true,
      },
      {
        label: 'desired_password',
        name: 'newPassword',
        tip: '8 Characters - 1 UpperCase - 1 Number',
        type: 'password',
        required: true,
        validations: [
          {
            message: 'password_is_required',
            value: true,
            rule: 'required',
          },
          {
            message: 'password_is_not_formatted_correctly',
            value: {
              pattern:
                '^(?=.*[\\p{Ll}])(?=.*[\\p{Lu}])(?=.*\\d)(\\pL|[0-9_!@#-$%&<>]|\\p{P}){8,}$',
              flags: '',
            },
            rule: 'xregexp',
            extras: [
              {
                message: 'min_8_chars',
                value: 8,
                rule: 'minLength',
              },
              {
                message: 'min_1_uppercase',
                value: {
                  pattern: '[\\p{Lu}]',
                  flags: '',
                },
                rule: 'xregexp',
              },
              {
                message: 'min_1_number',
                value: {
                  pattern: '[\\d]{1,}',
                  flags: '',
                },
                rule: 'xregexp',
              },
            ],
          },
        ],
      },
    ],
    order: {
      desktop: ['emailAddress', 'newPassword'],
      mobile: [['emailAddress', 'newPassword']],
    },
  },
  editField: {
    name: '',
    value: '',
  },
  errors: {},
  migrationData: null,
  loaded: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_MIGRATION:
      return {
        ...initialState,
      };
    case MIGRATION_LOADED:
      return {
        ...state,
        form: {
          ...state.form,
          properties: action.form.properties,
          order: action.form.order,
        },
        loaded: true,
      };
    case TOGGLE_MIGRATION_LOADED:
      return {
        ...state,
        loaded: action.loaded,
      };
    case SET_MIGRATION_DATA:
      return {
        ...state,
        migrationData: action.data,
      };
    default:
      return state;
  }
};
