import merge from 'lodash/fp/merge';
import { VERY_DARK_GREY } from '../../shared/colors';
export function buildPopup({
  title,
  titleStyle,
  text,
  textStyle,
  image,
  buttons = [],
  options = {},
}) {
  const popup = {
    message: {
      title,
      titleStyle,
      text,
      textStyle,
    },
    image,
    buttons: [],
    vertical: true,
    wrapperStyle: {
      width: '100%',
      marginLeft: '16px',
      marginRight: '16px',
      paddingBottom: '16px',
      maxWidth: '380px',
    },
    ...options,
  };

  for (let index = 0; index < buttons.length; index++) {
    const button = buttons[index];

    popup.buttons.push({
      wrapperStyle: {
        margin: index < buttons.length - 1 ? '5px 16px' : '5px 16px 0',
      },
      style: {
        border: button.outlined ? `1px solid ${VERY_DARK_GREY}` : 0,
      },
    });
  }
  return merge(popup, { buttons });
}
