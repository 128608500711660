import { createSelector } from 'reselect';

const selectVerifyEmailChangeDomain = () => (state) => state.verifyEmailChange;

const makeSelectUUID = () =>
  createSelector(
    selectVerifyEmailChangeDomain(),
    (verifyEmailChange) => verifyEmailChange.uuid
  );

export { makeSelectUUID };
