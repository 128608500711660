import {
  token,
  exchangeAuthCode,
  setApiToken,
} from '@shell-b2c/http-frontend/dist/src/components/core/auth';
import { CLIENT_ID } from '../../shared/constants';
import { generateHash } from '../core/utils';
import { getStoredData, setDataToStore } from '../../features/app/utils';

export function getAuthorization({ authCode, clientId }) {
  const authData = authCode
    ? getStoredData(authCode, true)
    : getStoredData(clientId || CLIENT_ID, true);

  if (authData && Object.keys(authData).length) {
    setApiToken(authData.api_token || authData.token);
    return Promise.resolve(authData);
  }

  if (authCode) {
    return exchangeAuthCode({ authCode }).then((response) => {
      setDataToStore(authCode, JSON.stringify(response));
      return response;
    });
  }

  return token({ digest: generateHash(clientId || CLIENT_ID) }).then(
    (response) => {
      setDataToStore(clientId || CLIENT_ID, JSON.stringify(response));
      return response;
    }
  );
}
